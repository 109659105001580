import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-guest-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './guest-header.component.html',
  styleUrl: './guest-header.component.scss',
})
export class GuestHeaderComponent implements OnInit {
  countryLogoName: any;
  countryLogoAttachment: any;
  staticText: any = TranslationService.staticTextData;

  constructor(private router: Router) {}

  ngOnInit() {
    const appConfig = JSON.parse(localStorage.getItem('appConfig') || '{}');
    this.countryLogoName = appConfig?.branding_setup?.name;
    this.countryLogoAttachment =
      appConfig?.branding_setup?.country_logo[0]?.attachment;
  }

  navigate() {
    this.router.navigate(['/auth/login']);
  }
}
