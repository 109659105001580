import { TranslationService } from 'src/app/shared/services/translation.service';
const staticText = TranslationService.staticTextData;

export const TABLE_COLUMN_OPTIONS = {
  display_name: {
    field: staticText?.common?.display_name ?? 'Display Name',
    view_Action: true,
    column_options: {
      column_minWidth: 200,
      column_sortable: true,
      column_sorting_key: 'display_name',
      column_search_config: {
        search_enabled: true,
        search_type: 'text',
        search_key: 'display_name',
      },
    },
  },
  created_by: {
    field: staticText?.common?.created_by ?? 'Created By',
    column_options: {
      column_minWidth: 150,
      column_sortable: true,
      column_search_config: {
        search_enabled: true,
        search_type: 'text',
        search_key: 'created_by',
      },
    },
  },
  created_on: {
    field: staticText?.common?.created_on ?? 'Created On',
    column_options: {
      column_minWidth: 100,
      column_sortable: true,
      column_search_config: {
        search_enabled: true,
        search_type: 'date-range',
        search_key_start: 'created_on_before',
        search_key_end: 'created_on_after',
        date_range_start: '',
        date_range_end: '',
      },
    },
  },
  last_updated_by: {
    field: staticText?.common?.last_updated_by ?? 'Last Updated By',
    column_options: {
      column_minWidth: 200,
      column_sortable: true,
      column_search_config: {
        search_enabled: true,
        search_type: 'text',
        search_key: 'last_updated_by',
      },
    },
  },
  last_updated_on: {
    field: staticText?.common?.last_updated_on ?? 'Last Updated On',
    column_options: {
      column_minWidth: 200,
      column_sortable: true,
      column_search_config: {
        search_enabled: true,
        search_type: 'date-range',
        search_key_start: 'last_updated_on_before',
        search_key_end: 'last_updated_on_after',
        date_range_start: '',
        date_range_end: '',
      },
    },
  },
  is_active: {
    field: staticText?.common?.status ?? 'Status',
    column_options: {
      column_minWidth: 140,
      column_sortable: false,
      column_search_config: {
        search_enabled: false,
        search_type: 'text',
        search_key: 'is_active',
      },
    },
  },
  status: {
    field: staticText?.common?.status ?? 'Status',
    column_options: {
      column_minWidth: 70,
      column_sortable: true,
      column_sorting_key: 'status__action_complete_label',
      column_search_config: {
        search_enabled: true,
        search_type: 'text',
        search_key: 'status__action_complete_label',
      },
    },
  },
  action: {
    field: staticText?.common?.action ?? 'Action',
    type: 'action',
    column_options: {
      column_maxWidth: 150,
    },
  },
};

export const PRODUCT_TABLE_COLUMN_OPTIONS = {
  product_name: {
    field: staticText?.common?.product_name ?? 'Product Name',
    view_Action: true,
    column_options: {
      column_minWidth: 180,
      column_sortable: true,
      column_sorting_key: 'product__display_name',
      column_search_config: {
        search_enabled: true,
        search_type: 'text',
        search_key: 'product__display_name',
      },
    },
  },
  brand_name: {
    field: staticText?.common?.brand_name ?? 'Brand Name',
    column_options: {
      column_minWidth: 120,
      column_sortable: true,
      column_sorting_key: 'product__brand_name',
      column_search_config: {
        search_enabled: true,
        search_type: 'text',
        search_key: 'product__brand_name',
      },
    },
  },
  product_type: {
    field: staticText?.common?.product_type ?? 'Product Type',
    column_options: {
      column_minWidth: 120,
      column_sortable: true,
      column_sorting_key: 'product__category__product_type__display_name',
      column_search_config: {
        search_enabled: true,
        search_type: 'text',
        search_key: 'product__category__product_type__display_name',
      },
    },
  },
  product_category: {
    field: staticText?.common?.product_category ?? 'Product Category',
    column_options: {
      column_minWidth: 120,
      column_sortable: true,
      column_sorting_key: 'product__category__display_name',
      column_search_config: {
        search_enabled: true,
        search_type: 'text',
        search_key: 'product__category__display_name',
      },
    },
  },
  packaging_type: {
    field: staticText?.common?.packaging_type ?? 'Packaging Type',
    column_options: {
      column_minWidth: 120,
      column_sortable: true,
      column_sorting_key: 'packaging__type__display_name',
      column_search_config: {
        search_enabled: true,
        search_type: 'text',
        search_key: 'packaging__type__display_name',
      },
    },
  },
  volume_and_unit: {
    field: staticText?.common?.volume_and_unit ?? 'Volume & Unit',
    column_options: {
      column_minWidth: 120,
      column_sortable: true,
      column_sorting_key: 'packaging__type__display_name',
      column_search_config: {
        search_enabled: true,
        search_type: 'text',
        search_key: 'packaging__type__display_name',
      },
    },
  },
  site: {
    field: staticText?.common?.site ?? 'Site',
    column_options: {
      column_minWidth: 120,
      column_sortable: true,
      column_sorting_key: 'site__company__company__name',
      column_search_config: {
        search_enabled: true,
        search_type: 'text',
        search_key: 'site__name',
      },
    },
  },
  license_type: {
    field: staticText?.common?.license_type ?? 'License Type',
    column_options: {
      column_minWidth: 120,
      column_sortable: true,
      column_sorting_key: 'site__company__company__license_type__display_name',
      column_search_config: {
        search_enabled: true,
        search_type: 'text',
        search_key: 'site__company__company__license_type__display_name',
      },
    },
  },
  company_name: {
    field: staticText?.common?.company_name ?? 'Company Name',
    column_options: {
      column_minWidth: 180,
      column_sortable: true,
      column_sorting_key: 'site__company__company__name',
      column_search_config: {
        search_enabled: true,
        search_type: 'text',
        search_key: 'site__company__company__name',
      },
    },
  },
  created_by: {
    field: staticText?.common?.created_by ?? 'Created By',
    column_options: {
      column_minWidth: 110,
      column_sortable: true,
      column_sorting_key: 'created_by',
      column_search_config: {
        search_enabled: true,
        search_type: 'text',
        search_key: 'created_by',
      },
    },
  },
  created_on: {
    field: staticText?.common?.created_on ?? 'Created On',
    column_options: {
      column_minWidth: 100,
      column_sortable: true,
      column_sorting_key: 'created_on',
      column_search_config: {
        search_enabled: true,
        search_type: 'date-range',
        search_key_start: 'created_on_before',
        search_key_end: 'created_on_after',
        date_range_start: '',
        date_range_end: '',
      },
    },
  },
  last_updated_by: {
    field: staticText?.common?.last_updated_by ?? 'Last Updated By',
    column_options: {
      column_minWidth: 180,
      column_sorting_key: 'last_updated_by',
      column_sortable: true,
      column_search_config: {
        search_enabled: true,
        search_type: 'text',
        search_key: 'last_updated_by',
      },
    },
  },
  last_updated_on: {
    field: staticText?.common?.last_updated_on ?? 'Last Updated On',
    column_options: {
      column_minWidth: 110,
      column_sortable: true,
      column_sorting_key: 'last_updated_on',
      column_search_config: {
        search_enabled: true,
        search_type: 'date-range',
        search_key_start: 'last_updated_on_before',
        search_key_end: 'last_updated_on_after',
        date_range_start: '',
        date_range_end: '',
      },
    },
  },
  approval_status: {
    field: staticText?.common?.approval_status ?? 'Approval Status',
    column_options: {
      column_minWidth: 70,
      column_sortable: true,
      column_sorting_key: 'status__action_complete_label',
      column_search_config: {
        search_enabled: true,
        search_type: 'text',
        search_key: 'status__action_complete_label',
      },
    },
  },
  full_name: {
    field: staticText?.common?.volume_and_uom ?? 'Volume & UOM',
    view_Action: true,
    column_options: {
      column_minWidth: 200,
      column_sortable: true,
      column_sorting_key: 'full_name',
      column_search_config: {
        search_enabled: true,
        search_type: 'text',
        search_key: 'full_name',
      },
    },
  },
};

export const TABLE_TOP_FILTERS = {
  multi_dd_search: {
    status: true,
    name: '',
    placeholder: '',
    type: 'multidropdown-search',
  },
  dd_search: {
    status: true,
    name: '',
    placeholder: '',
    type: 'dropdown-search',
  },
  date_search: {
    status: true,
    name: '',
    type: 'date',
    isRange: true,
    placeholder: '',
    formControlName_start: 'start_date',
    formControlName_end: 'end_date',
  },
};

export const FILTER_STATUS_DATA = [
  {
    id: 1,
    name: staticText?.common?.active ?? 'Active',
    key: 'True',
  },
  {
    id: 2,
    name: staticText?.common?.inactive ?? 'Inactive',
    key: 'False',
  },
];
