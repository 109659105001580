

<section class="header container-fluid">
    <div class="row d-flex align-items-center py" #header>

        <div class="header_logo_flx">
           

            <div class="application-logo cursor-pointer" (click)="navigate()">
                <img src="assets/images/common/logo.svg" class="" alt="logo">
            </div>

            <div class="">
                <div class="mb-0 fw-300 d_flx_rwanda cursor-pointer" (click)="navigate()">
                    <span class="username header-logo">
                    
                        <img *ngIf="countryLogoAttachment; else fallbackLogo" [src]="countryLogoAttachment" class="logo-image"
                            alt="logo" />
                        <ng-template #fallbackLogo>
                            <img src="assets/images/common/rwanda_logo.svg" class="logo-image" alt="default logo" />
                        </ng-template>
                    </span>
                    <span class="rwanda_img">
                        {{
                        countryLogoName ||
            
                        staticText?.common?.company_secondary_name
                        }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</section>