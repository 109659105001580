import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SharedModule } from '../../shared.module';
import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'app-next-status-action',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './next-status-action.component.html',
  styleUrl: './next-status-action.component.scss',
})
export class NextStatusActionComponent implements OnInit {
  @Input() nextAction: any;
  @Output() action = new EventEmitter();
  staticText: any = TranslationService.staticTextData;
  actionForm: FormGroup | any;
  actionOptions: any = [];
  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.createActionForm();
    this.setActionOptions();
  }

  createActionForm() {
    this.actionForm = this.formBuilder.group({
      action: ['', [Validators.required]],
    });
  }

  doAction(data?: any) {
    this.action.emit(data);
  }

  onActionSelect(options: any) {}

  setActionOptions() {
    this.actionOptions = [];
    this.nextAction?.next_states?.forEach((o: any) => {
      this.actionOptions.push({ ...o, id: o.id, name: o.label, value: o.slug });
    });
  }
}
