import {
  ElementRef,
  Inject,
  Injectable,
  LOCALE_ID,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { formatDate } from '@angular/common';
// import { saveAs } from 'file-saver';
// import { ConfirmModalComponent } from '../components/modal/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
// import { SuccessModalComponent } from '../components/modal/success-modal/success-modal.component';
// import { ViewModalComponent } from '../components/modals/view-modal/view-modal.component';
import { NgxPermissionsService } from 'ngx-permissions';
import { Router } from '@angular/router';
// import { ResetPasswordPromptModalComponent } from '../components/modals/reset-password-prompt-modal/reset-password-prompt-modal.component';
// @ts-ignore
/* import html2pdf from 'html2pdf.js'; */
import {
  ApiMethod,
  Endpoints,
  ErrorCodes,
  PASSWORD_POLICY,
} from 'src/app/core/services/utils/constants';
// import { LogframeLevelsComponent } from '../components/modals/logframe-levels/logframe-levels.component';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { HttpService } from 'src/app/core/services/http/http.service';
// import { ToastrService } from 'ngx-toastr';
// import { MasterCreateModalComponent } from '../components/modals/master-create-modal/master-create-modal.component';
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  showSideBar = new BehaviorSubject(true);
  showSideBar$ = this.showSideBar.asObservable();
  workPlanBudget: any = {};
  isProject: boolean = false;
  currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  selectedItemView: any;
  canDeactivateLogout: boolean = false;
  confirmDeactivate: boolean = false;
  resetPass: boolean = false;
  userProfileView: boolean = false;
  selectedTypeName!: string;
  addMarker: boolean = false;
  removeMarker: boolean = false;
  updateMarker: boolean = false;
  closePopUp: boolean = false;
  downloadApk = new Subject<any>();
  downloadApkEvents = this.downloadApk.asObservable();
  mapDataSubmission = new Subject<any>();
  mapSubmissionEvent$ = this.mapDataSubmission.asObservable();
  workplanDate = new Subject<any>();
  workplanDateEvent$ = this.workplanDate.asObservable();
  workplanpatchBudget = new Subject<any>();
  workplanpatchBudgetEvent$ = this.workplanpatchBudget.asObservable();
  budgetDataSubmissions = new Subject<any>();
  budgetSubmissionEvents$ = this.budgetDataSubmissions.asObservable();
  private logFrameDataSubmission = new Subject<any>();
  logFrameDataSubmissionEvent$ = this.logFrameDataSubmission.asObservable();

  @ViewChild('document', { static: false }) pdfTable: ElementRef | any;
  mode: string = '';
  callBack: any;
  currentData: any;
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private dialog: MatDialog,
    private permissionsService: NgxPermissionsService,
    private router: Router,
    private _loaderService: LoaderService,
    private _http: HttpService // , private toastr: ToastrService
  ) {}

  getValue(data: any): any {
    return data?.value;
  }

  setSidebarView(value: boolean) {
    this.showSideBar.next(value);
  }
  convertToLocalTimeZone(date: string) {
    return new Date(date).toLocaleString(
      this.locale,
      <any>this.currentTimeZone
    );
  }

  convertUTCDateToLocalDate(date: string | Date) {
    date = new Date(date);
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );
    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();
    newDate.setHours(hours - offset);
    return newDate;
  }

  filterParamsConstructor(
    params: any,
    offset?: number,
    endValue?: number,
    isLimit?: boolean
  ) {
    let paramValues = `?offset=${offset || 0}&end=${endValue || 5}`;
    if (params) {
      for (const [key, value] of Object.entries(params)) {
        if (
          typeof key === typeof 'string' &&
          (typeof value == typeof 'string' || typeof value == 'number') &&
          value !== '' &&
          value != null &&
          value != undefined
        ) {
          paramValues += `&${key}=${
            value == 'all' || value == 'All' ? '' : value
          }`;
        } else if (value instanceof Date) {
          paramValues += `&${key}=${formatDate(
            value,
            'YYYY-MM-dd',
            this.locale
          )}`;
        }
      }
    }
    return paramValues;
  }

  async export2Word(filename: string) {
    var preHtml =
      "<html ><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    var postHtml = '</body></html>';
    let content: any = document.getElementById('document');
    var css =
      '<style>' +
      '@page WordSection1{size: 841.95pt 595.35pt;mso-page-orientation: landscape;}' +
      'div.WordSection1 {page: WordSection1;}' +
      'h5.text-decoration-underline{text-decoration:underline;}' +
      `table, th, td {
          border: 1px solid black;
          border-collapse: collapse;
        }
        div.pl-2{padding-left:15px;}
        tbody.text-center,td.text-center{text-align:center;}
        div.tab-title{color:#0075c4;font-size:18px;padding:0px 10px;}
        td.sub-head{color:#0075c4 !important;}
        td,th{padding:5px 10px;}
        td.label{font-size:18px;font-weight:bold}` +
      '</style>';
    var html = preHtml + css + content.innerHTML + postHtml;
    var blob = new Blob(['\ufeff', html], {
      type: 'application/msword',
    });
    let dateFormat = formatDate(new Date(), 'yyyyMMddHHmmss', this.locale);
    // saveAs(blob, filename + '_' + dateFormat + ".doc");
  }
  /*
  downloadAsPDF(filename: string) {
    const elementRef: any = document.getElementById("document");
    const opt = {
      margin: 10,
      filename: `${filename}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };
    
    html2pdf().from(elementRef.innerHTML).set(opt).save();
    
  }
*/
  // logFrameShowPopup(event: any, data?: any, action?: any, create?: any) {
  //   if (action == 2)
  //     this.deleteLogframes(data, event,create)
  //   else
  //     this.createLogframe(event, data, action, create)
  // }

  // deleteLogframes(data: any, ev: any,create:any) {
  //   let name = ev.name ? ev.name : ev;
  //   let endpoint: any = this.deleteLogframeEndpoint(name) + data.id;
  //   if (create && name != 'Indicators') {
  //     let projectId:any = localStorage.getItem('projectId') || 0;
  //     endpoint = `${endpoint}?project=${projectId}`;
  //   }
  //   this._loaderService.loaderShow();
  //   this._http
  //     .requestCall(endpoint, ApiMethod.DELETE, {}, { observe: 'response' })
  //     .subscribe(
  //       (res: any) => {
  //         this._loaderService.loaderHide();
  //         if (res?.status == 204) {
  //           if (name == 'Indicators') {
  //             name = 'Indicator'
  //           }
  //           this.showAlertModal('Deleted', `${name} has been deleted successfully`, 'delete').subscribe((res: any) => {
  //             if (res)
  //               this.getLogframeDetails()
  //           })
  //         }
  //         else
  //           this.toastr.error(res?.body?.message);
  //       })
  // }

  // deleteLogframeEndpoint(name: any) {
  //   let endpoint: any;
  //   if (name == 'Result Area' || name == 'Component')
  //     endpoint = Endpoints.DELETE_LOGFRAME_COMPONENT;
  //   else if (name == 'Outcome')
  //     endpoint = Endpoints.DELETE_LOGFRAME_OUTCOME_OUTPUT;
  //   else if (name == 'Output')
  //     endpoint = Endpoints.DELETE_LOGFRAME_OUTCOME_OUTPUT;
  //   else if (name == 'Indicators')
  //     endpoint = Endpoints.DELETE_LOGFRAME_INDICATORS;
  //   return endpoint;
  // }

  // createLogframe(ev: any, data?: any, action?: any, create?: any) {
  //   this.currentData = data;
  //   this.selectedTypeName = ev.name ? ev.name : ev;
  //   let project = ev?.create ? ev?.create : create;
  //   this.isProject = project ? project :false;

  //   let dialogRef = this.dialog.open(LogframeLevelsComponent, {
  //     hasBackdrop: true,
  //     width: 'auto',
  //     data: {
  //       type: this.selectedTypeName,
  //       values: data,
  //       action: action,
  //       create: project,
  //     }
  //   })
  //   dialogRef.afterClosed().subscribe(res => {
  //     if (res)
  //       this.createFunction(res);
  //   });
  // }

  // createFunction(res: any) {
  //   let endpoint: any;
  //   let payload: any;
  //   let programmeInd:any;
  //   let id = localStorage.getItem('programId') ? localStorage.getItem('programId') : localStorage.getItem('projectId');
  //   if (this.selectedTypeName == 'Result Area') {
  //     endpoint = Endpoints.POST_LOGFRAME_COMPONENT;
  //     payload = {
  //       name: res?.name,
  //       program: id,
  //       display_name:res?.display_name
  //     }
  //   }
  //   else if (this.selectedTypeName == 'Outcome') {
  //     endpoint = Endpoints.POST_LOGFRAME_OUTCOME_OUTPUT;
  //     programmeInd = res?.program_output
  //     payload = {
  //       name: res?.name,
  //       display_name:res?.display_name,
  //       component: res?.component,
  //       program_output: res?.program_output,
  //     }
  //     if(this.isProject)
  //     {
  //       payload.project = res?.project
  //     }
  //     else{
  //       payload.code = res?.code
  //     }
  //   }
  //   else if (this.selectedTypeName == 'Output') {
  //     endpoint = Endpoints.POST_LOGFRAME_OUTCOME_OUTPUT;
  //     payload = {
  //       name: res?.name,
  //       display_name:res?.display_name,
  //       component: res?.component,
  //       parent: res?.parent,
  //     }
  //     if(this.isProject)
  //     {
  //       payload.project = res?.project
  //     }
  //     else{
  //       payload.code = res?.code
  //     }
  //   }
  //   else if (this.selectedTypeName == 'Indicators') {
  //     endpoint = Endpoints.POST_LOGFRAME_INDICATORS;
  //     payload = {
  //       name: res?.name,
  //       display_name:res?.display_name,
  //       parent: res?.parent,
  //       duration_type:res?.duration_type,
  //       quantitative_type:res?.qualitative?.id,
  //       description:res?.description,
  //       program:programmeInd
  //     }
  //     if(this.isProject)
  //     payload.project = res?.project
  //   }
  //   this._loaderService.loaderShow();
  //   this._http.requestCall(res.id ? endpoint + res.id + '/' : endpoint, ((res.id ? ApiMethod.PUT : ApiMethod.POST)), payload, { observe: 'response' }).subscribe((response: any) => {
  //     this._loaderService.loaderHide();
  //     if (response?.status === 200 || response?.status === 201) {
  //       this.closePopUp = true;
  //       this.showSuccessModal("Success", `${this.selectedTypeName} has been ${res?.id ? 'Updated' : 'Created'} successfully`).subscribe((result: any) => {
  //         if (result)
  //           this.getLogframeDetails();
  //       });
  //     }
  //     else
  //       this.showAlertModal("Error", `${this.selectedTypeName} already exists!`, 'reject').subscribe((result: any) => { });
  //   })
  // }
  // getLogframeDetails() {
  //   let endpoint: any = "";
  //   if (localStorage.getItem('programId') && localStorage.getItem('projectId'))
  //     localStorage.removeItem('programId')
  //   const programId: any = localStorage.getItem('programId');
  //   const projectId = localStorage.getItem('projectId');
  //   if (programId)
  //     endpoint = Endpoints.GET_LOGFRAME_LIST_RECORDS + '?program=' + programId;
  //   else if (projectId)
  //     endpoint = Endpoints.GET_LOGFRAME_LIST_RECORDS + '?project=' + projectId;
  //   this._http.requestCall(endpoint, ApiMethod.GET).subscribe((res: any) => {
  //     this._loaderService.loaderHide();
  //     this.logFrameDataSubmission.next(res);
  //   }, (err: any) => {
  //     this._loaderService.loaderHide();
  //   })
  // }

  showAlertModal(titleName: string, contentName: string, actionType: string) {
    // return this.dialog.open(ConfirmModalComponent,
    //   {
    //     exitAnimationDuration: '50ms',
    //     width: '410px',
    //     data: {
    //       title: titleName,
    //       action: actionType,
    //       content: contentName
    //     },
    //   }).afterClosed();
  }
  showSuccessModal(titleName: string, contentName: string) {
    // return this.dialog.open(SuccessModalComponent,
    //   {
    //     exitAnimationDuration: '50ms',
    //     width: '410px',
    //     data: {
    //       title: titleName,
    //       content: contentName
    //     },
    //     hasBackdrop: false,
    //   }).afterClosed();
  }
  // showViewModal(titleName: string, contentName: string, type: string) {
  //   return this.dialog.open(ViewModalComponent,
  //     {
  //       exitAnimationDuration: '50ms',
  //       // height: '238px',
  //       width: 'auto',
  //       data: {
  //         title: titleName,
  //         content: contentName,
  //         type: type,
  //       },
  //       hasBackdrop: false,
  //     }).afterClosed();
  // }

  userLogout() {
    this.permissionsService.flushPermissions();
    localStorage.clear();
    this.router.navigate(['/auth/login']);
  }

  // resetPasswordPopup() {
  //   this.dialog.open(ResetPasswordPromptModalComponent, {
  //     exitAnimationDuration: '50ms',
  //     width: '410px',
  //     disableClose: true,
  //     hasBackdrop: true,
  //   })
  // }

  /**
   * @description
   * This function based on work view permission validation..........................
   * @param {Array} permission view permission
   * @returns {Boolean}
   */
  gotPermission(permission?: any) {
    try {
      let result = false;
      let permissions: any = JSON.parse(
        localStorage.getItem('permissions') || ''
      );
      permission = permission ?? [];
      permission.forEach((val: any, idx: number) => {
        if (permissions.indexOf(val) !== -1) {
          result = true;
        }
      });
      return result;
    } catch (ex: any) {
      throw new Error(ex);
    }
  }

  generateRegex(params: any) {
    const {
      length,
      maxLength,
      specialChars,
      upperCase,
      lowerCase,
      digits,
      regexPattern,
    } = params;

    // Build the dynamic regex
    const regexParts = [
      upperCase > 0 ? `(?=.*[A-Z]{${upperCase},})` : '', // At least 'upperCase' uppercase letters
      lowerCase > 0 ? `(?=.*[a-z]{${lowerCase},})` : '', // At least 'upperCase' uppercase letters
      digits > 0 ? `(?=.*\\d{${digits},})` : '', // At least 'digits' numbers
      specialChars > 0
        ? `(?=.*[${regexPattern}]{${specialChars},})`
        : `(?=.*[${regexPattern}])`, // At least 'specialChar' special characters
      `[A-Za-z\\d\\${regexPattern}]{${length},${maxLength}}`, // Allowed characters and length
    ];

    // Combine parts and return the final regex
    const regex = `^${regexParts.join('')}$`;
    return new RegExp(regex);
  }

  extractValues(input: string): Record<string, string | number> {
    const regex = PASSWORD_POLICY;
    const values: Record<string, string | number> = {};
    let match: RegExpExecArray | null;

    while ((match = regex.exec(input)) !== null) {
      const key = match[1];
      const value = isNaN(Number(match[2])) ? match[2] : Number(match[2]);
      values[key] = value;
    }

    return values;
  }

  generatePassword(params: any): string {
    const {
      length,
      maxLength,
      specialChars,
      upperCase,
      lowerCase,
      digits,
      regexPattern,
    } = params;
    const upperCaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowerCaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const digitsChars = '0123456789';
    const specialStr = regexPattern;
    const allChars = upperCaseChars + lowerCaseChars + digitsChars + specialStr;

    const pwdParamsLength = lowerCase + upperCase + digits + specialChars;
    console.log('pwdParamsLength', pwdParamsLength);

    const passwordLength =
      Math.floor(this.getRandom() * (maxLength - length + 1)) + length; // Random length between 8 and 12

    let password = '';

    // Ensure at least one character of each type
    if (upperCase > 0) {
      for (let i = 0; i < upperCase; i++) {
        password +=
          upperCaseChars[Math.floor(this.getRandom() * upperCaseChars.length)];
      }
    }

    if (lowerCase > 0) {
      for (let i = 0; i < lowerCase; i++) {
        password +=
          lowerCaseChars[Math.floor(this.getRandom() * lowerCaseChars.length)];
      }
    }
    if (digits > 0) {
      for (let i = 0; i < digits; i++) {
        password +=
          digitsChars[Math.floor(this.getRandom() * digitsChars.length)];
      }
    }
    if (specialChars > 0) {
      for (let i = 0; i < specialChars; i++) {
        password +=
          specialStr[Math.floor(this.getRandom() * specialStr.length)];
      }
    } else {
      password += specialStr[Math.floor(this.getRandom() * specialStr.length)];
    }

    // Fill the rest of the password with random characters from allChars
    for (let i = pwdParamsLength; i < passwordLength; i++) {
      password += allChars[Math.floor(this.getRandom() * allChars.length)];
    }

    // Shuffle the password to avoid predictable patterns
    password = password
      .split('')
      .sort(() => 0.5 - this.getRandom())
      .join('');

    return password;
  }

  getRandom(): number {
    return (Date.now() % 1000) / 1000;
  }

  generateMonthList(startDate: Date, endDate: Date) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const monthList: any = {
      fullNameList: [],
      shortNameList: [],
      defaultPlan: {},
    };

    while (start <= end) {
      const longMonth = start.toLocaleString('default', { month: 'long' });
      const shortMonth = start.toLocaleString('default', { month: 'short' });
      monthList?.fullNameList?.push(`${longMonth}`);
      monthList?.shortNameList?.push(`${shortMonth}`);
      start.setMonth(start.getMonth() + 1);
    }

    monthList.defaultPlan = monthList?.shortNameList.reduce(
      (acc: any, month: string) => {
        acc[month.toLowerCase()] = [''];
        return acc;
      },
      {}
    );

    return monthList;
  }
}
