

<div class="filter_flx flx_entity_col  pt_users d-flex align-items-center w-100">
  <div class="flex-column filter_title me-auto">
    <div class="text-capitalize mb-0 list_heading">
      <span [innerHtml]="staticText?.user_management?.user?.list?.list_card_title"></span>
        <span class="total_records mx-2 " *ngIf="entityList">({{totalRecords}})</span>
    </div>
    <span class="users_clr">{{staticText?.user_management?.entity?.list?.breadcrumbs}}</span>
  </div>
  <div class="custom_filter_flx w_full_users d-flex justify-content-end align-items-center">
    
    
      <div class="d-flex w_full_filter">

        <custom-table-filter class="w_full_filter" [customTableFilterLabelClass]="''"
          [customTableFilterContainerClass]="'roles_width'" [customTableFilterSource]="filterSource"
          (customTableFilterValueEmitter)="filterParams($event)">
        </custom-table-filter>
      </div>

      <div class=" padding_left-none d_flx_edit entity_end_add_btn">
        <button mat-raised-button class="next-btn create-default-style mx-2 line_before"
          *ngxPermissionsOnly="[permission.ADD_ENTITY]" (click)="addEntity()">
          <div class="d-flex align-items-center">
            <img src="assets/images/common/icons/plus.svg" alt="">
            <span class="btn-text adduser_btn">{{staticText?.user_management?.entity?.list?.new_user}}</span>
          </div>
        </button>
        <button class="edit_icn" disabled>
          <img src="assets/images/common/icons/more.svg" alt="">
        </button>
      </div>
    </div>
  </div>


<mat-card-content>
  <div class="d-flex flex-column p-4">
    <!-- <custom-table-filter [customTableFilterSource]="filterSource"
            (customTableFilterValueEmitter)="filterParams($event)">
            </custom-table-filter> -->
    <div class="d-flex">
      @for (item of _common.userMangementTab; track $index) {
      <div class="user-mgnt-tab-btn cursor-pointer" [ngClass]="{'active' : item.active}" (click)="tabClick(item)">
        <span class="users_grp">{{item.title}}</span>
      </div>
      }
    </div>
    <custom-table tableId="cust-tbl-entitylist" [localColumnSearch]="true" [columnSearchOptionsList]="[]"
      [internalColumnSearch]="false" (columnSearch)="columnSearch($event)" [tableCustomActionSelectAll]="tableHeader"
      [columnDefs]="tableFields" [tablePaginationCustom]="true" [lastTwoColFreezed]="true" [tableRowData]="entityList"
      [tableTotalRecords]="totalRecords" (pageChange)="pageChange($event)"
      [tableCustomActionPermissions]="actionPermissions" (actionParam)="editEntity($event)"
      (sortChange)="sortColumn($event)">
    </custom-table>
  </div>
</mat-card-content>