import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/services/common/common.service';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { Endpoints } from 'src/app/core/services/utils/constants';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
})
export class LanguageSwitcherComponent implements OnInit {
  selectedLanguage: any = 'en';
  languageCodeValue: any = [
    {
      code: 'en',
      value: 'English',
    },
    {
      code: 'fr',
      value: 'French',
    },
  ];

  constructor(
    private _common: CommonService,
    private translationService: TranslationService
  ) {
    this.languageCodeValue = this._common.getLocalization();
  }

  ngOnInit() {
    if (localStorage.getItem(`lang`)) {
      this.selectedLanguage = localStorage.getItem(`lang`)?.toString();
    }
  }

  handleLanguageSwitch(lang: any) {
    this.selectedLanguage = lang?.code;
    localStorage.setItem(`lang`, this.selectedLanguage);
    this.translationService.updateTranslations(lang).subscribe((resolve) => {
      window.location.reload();
    });
  }
}
