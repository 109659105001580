import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, map, retry, takeUntil, tap, throwError } from 'rxjs';
import { HttpService } from '../http/http.service';
import { ApiMethod, Endpoints } from '../utils/constants';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { StorageService } from '../storage/storage.service';
import { MarkASRead } from '../common/common.interface';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SocketService {

  socket$!: WebSocketSubject<any>;
  private destroy$ = new Subject<void>();
  refreshInProgress: boolean = false;

  constructor(private _storage: StorageService,
    private _http: HttpService) { }

  buildEndpoint(base: string, param?: any): string {
    return param ? `${base}${param}` : base;
  }

  makeRequest(endpoint: any, method: ApiMethod): Observable<any> {
    return this._http.requestCall(endpoint, method).pipe(
      map((e: any) => e),
      takeUntil(this.destroy$)
    );
  }

  getNotificationList(param?: any): Observable<any> {
    const endpoint = this.buildEndpoint(Endpoints.GET_NOTIFICATION, param);
    return this.makeRequest(endpoint, ApiMethod.GET);
  }

  updateMarkAsReadStatus(ids: MarkASRead) {
    let endpoint: any = `${Endpoints.UPDATE_READ}`;
    return this._http.requestCall(endpoint, ApiMethod.POST, ids, { observe: 'response' })
  }

  connect(url: any, token: any) {
    this.socket$ = webSocket(`${url}?token=${token}`);
    this.socket$
      .pipe(
        retry(), // Retry connection if it fails
        catchError((error) => {
          console.error('WebSocket Error:', error);
          return throwError(() => new Error('WebSocket connection failed'));
        })
      ).subscribe({
        next: (notification) => { },
        error: (error) => this.catchError(error),
        complete: () => console.log('WebSocket connection closed'),
      });

  }

  private catchError(error: any) {
    console.error('WebSocket Error:', error)
  }

  getMessages(): Observable<any> {
    return this.socket$.asObservable();
  }

  // Handle token expiration and reconnection
  reconnectWithNewToken(): Observable<any> {
    if (this.refreshInProgress) {
      return new Subject(); // Avoid multiple refresh calls
    }

    this.refreshInProgress = true;

    return this._http.refreshToken().pipe(
      tap((newToken) => {
        this.refreshInProgress = false;
        if (this.socket$) {
          this.socket$.complete(); // Close current connection
        }
        const WS_URL = environment.notification_websocket;
        this.connect(WS_URL, newToken?.data?.access); // Reconnect with the new token
      }),
      catchError((error) => {
        console.error('Token refresh failed:', error);
        this.refreshInProgress = false;
        return throwError(() => error);
      })
    );
  }

  closeConnection(): void {
    if (this.socket$) {
      this.socket$.complete();
    }
  }

  onDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.closeConnection();
  }

}

