<div class=" onboarding-container border_top_onboard">
    <!-- Header section .................-->
    <header class="d-flex align-items-center onboarding-header container-xl">
        <div class="view_header d-flex">
            <div class="d-flex  onboarding-header-title">
                <div class="mx-2 cursor-pointer" (click)="close()"><i class="fa fa-arrow-left"></i></div>

                @if (pageMode == screenMode?.CREATE) {
                <div>{{staticText?.scp?.onboard?.edit_title}}</div>
                }

            </div>
            <div class="ms-2 me-0 btn_clse">
                @if (pageMode == screenMode?.VIEW) {
                <ng-container *ngxPermissionsOnly="[permission.CHANGE_SCP]">
                    <button class="primary-outline-default-style" mat-stroked-button (click)="edit()">
                        <span class="mx-1"><img src="../../../../../assets/images/common/icons/edit_table.svg"
                                alt=""></span>
                        <span>{{staticText?.scp?.view_register?.edit}}</span>
                    </button>
                </ng-container>
                }@else if (pageMode == screenMode?.EDIT) {
                <ng-container *ngxPermissionsOnly="[permission.VIEW_SCP]">
                    <button class="primary-outline-default-style" mat-stroked-button (click)="view()">
                        <span class="mx-1"><img src="../../../../../assets/images/common/icons/view_table.svg"
                                alt=""></span>
                        <span>{{staticText?.scp?.view_register?.view}}</span>
                    </button>
                </ng-container>
                }
            </div>
        </div>
    </header>
    <!--................. Header section -->

    <!-- Container section .................-->
    <section class="scp-onboarding-screen container-xl">
        <mat-card class="card-default-style card_style p-0">
            @if (pageMode == screenMode?.CREATE) {
            <mat-horizontal-stepper #stepperValidation labelPosition="bottom" [selectedIndex]="selectedIndex"
                [orientation]="(stepperOrientation | async)!" [linear]="true"
                (selectionChange)="onSteperChange($event)">
                <ng-template matStepperIcon="edit">
                    <i class="fa fa-check custom-fs"></i>
                </ng-template>
                <mat-step [completed]="validSites" label="Site">
                    <div class=" fs-24  business-title-card">
                        <span>{{ staticText?.scp?.onboard?.sub_heading_1}}</span>
                    </div>
                    @if (siteInfoConfig?.length > 1) {
                    <div class="d-flex gap_added" *ngIf="enableSiteList">

                        <div class="d-flex align-items-center fs-16 fw-bold">
                            <span class="sites_added">{{ staticText?.scp?.onboard?.add_site_text}}</span>
                        </div>
                        @for (item of siteInfoConfig; track item) {
                        @if (item?.name) {
                        <div [hidden]="item.isDeleted" [ngClass]="{'active': item.active}"
                            (click)="selectSite(item, $index)" class="list-of-site  py-0 mx-2 cursor-pointer">
                            <div>

                                <span class="added_name">{{item?.name}}</span>

                            </div>
                            @if (siteInfoConfig?.length > 1) {
                            <span class="border_close"><i class="fa-solid fa-xmark "
                                    (click)="deleteSite($event, $index)"></i></span>
                            }
                        </div>
                        }
                        }

                    </div>
                    }

                    <div class="px-1 px-sm-3 py-2 fw-bold fs-14 business-info-card align-items-center alert alert-info"
                        (click)="collapsed = !collapsed" role="alert">
                        <div class="collapse_arw">
                            <div class="collapse_icn_flex">

                                <span>
                                    <img class="mx-2 fs_icn"
                                        src="../../../../assets/images/common/icons_usp/question.svg" alt="question" />

                                </span>
                                <span
                                    class="collapse_heading site-heading">{{staticText?.scp?.onboard?.site_notes_1}}</span>
                            </div>

                            <!-- Expand/Collapse Icons -->
                            <i class="fas toggle-icon pull-right" [ngClass]="collapsed ? 'fa-chevron-down' : 'fa-times'"
                                (click)="collapsed = !collapsed"></i>

                        </div>
                        <div class="ms-auto">
                            <p class="note-desc site-heading mb-0" *ngIf="!collapsed">{{
                                staticText?.scp?.onboard?.site_notes_2}}</p>
                        </div>
                    </div>
                    <!-- select site..................... -->

                    @for (item of siteInfoConfig; track item) {

                    <div [hidden]="!item.active" class="custom-style-onboarding">
                        <app-dynamic-form class="site_form" #site [sections]="item.section" [apiData]="item?.data"
                            (formSubmit)="handleSiteInfo($event)" [disableForm]="disableForm" [mode]="item?.mode"
                            [processAttachment]="true">
                        </app-dynamic-form>
                    </div>
                    }
                    <!-- .....................select site -->

                    <!-- Add another site.................... -->

                    <div class="p-4 d-flex justify-content-center border_top_add">
                        <button mat-raised-button class="btn_login_user mx-2" (click)="addMoreSite()">
                            <div>
                                <img src="assets/images/common/icons/add.svg" alt="Add" class="me-2" />
                                <span [innerHtml]="'Add Another Site'" class="text-light"></span>
                            </div>
                        </button>
                    </div>

                    <!-- ....................Add another site -->
                </mat-step>

                <mat-step [completed]="isValidUserInfo" label="User">
                    <ng-template matStepperIcon="edit">
                        <i class="fa fa-check custom-fs"></i>
                    </ng-template>

                    <div class=" fs-24  business-title-card">
                        <span class="site-heading">{{ staticText?.scp?.onboard?.sub_heading_4}}</span>
                    </div>

                    <div class="px-1 px-sm-3 py-2 fw-bold fs-12 business-info-card align-items-center alert alert-info"
                        (click)="collapsed = !collapsed" role="alert">
                        <div class="collapse_arw">
                            <div class="collapse_icn_flex">
                                <span>
                                    <img class="mx-2 fs_icn"
                                        src="../../../../assets/images/common/icons_usp/question.svg" alt="">
                                </span>
                                <span
                                    class="collapse_heading site-heading">{{staticText?.scp?.onboard?.user_site_note_1}}</span>
                            </div>

                            <!-- Expand/Collapse Icons -->
                            <i class="fas toggle-icon pull-right" [ngClass]="collapsed ? 'fa-chevron-down' : 'fa-times'"
                                (click)="collapsed = !collapsed"></i>
                        </div>
                        <div class="ms-auto">
                            <p class="note-desc site-heading mb-0" *ngIf="!collapsed">{{
                                staticText?.scp?.onboard?.user_site_note_2}}</p>
                        </div>
                    </div>
                    @if(bankInfoMode === 'add' || onboardData){
                    <div class="custom-style-onboarding">
                        <app-dynamic-form [sections]="userInfoConfig" [refID]="registerId" #userInfo
                            [disableForm]="disableForm" [apiData]="onboardData" [mode]="userMode">
                        </app-dynamic-form>
                    </div>
                    }
                </mat-step>
                <mat-step [completed]="isValidSupplyChainInfo" label="Supply">
                    <ng-template matStepperIcon="edit">
                        <i class="fa fa-check custom-fs"></i>
                    </ng-template>


                    <div class="fs-24  business-title-card">
                        <span>{{ staticText?.scp?.onboard?.sub_heading_3}}</span>
                    </div>

                    <div class="px-1 px-sm-3 py-2 fw-bold fs-12 business-info-card align-items-center alert alert-info"
                        (click)="collapsed = !collapsed" role="alert">
                        <div class="collapse_arw">
                            <div class="collapse_icn_flex">
                                <span> <img class="mx-2 fs_icn"
                                        src="../../../../assets/images/common/icons_usp/question.svg" alt=""></span>
                                <span
                                    class="collapse_heading site-heading">{{staticText?.scp?.onboard?.supply_site_note_1}}</span>
                            </div>

                            <!-- Expand/Collapse Icons -->
                            <i class="fas toggle-icon pull-right" [ngClass]="collapsed ? 'fa-chevron-down' : 'fa-times'"
                                (click)="collapsed = !collapsed"></i>
                        </div>
                        <div class="ms-auto">
                            <p class="note-desc site-heading mb-0" *ngIf="!collapsed">{{
                                staticText?.scp?.onboard?.supply_site_note_2}}
                            </p>
                        </div>
                    </div>

                    @if(bankInfoMode === 'add' || onboardData) {
                    <div class="custom-style-onboarding">
                        <app-dynamic-form [sections]="supplyChainInfoConfig" [refID]="registerId" #supplierChainInfo
                            [apiData]="onboardData" [disableForm]="disableForm" [mode]="supplyChainMode">
                        </app-dynamic-form>
                    </div>
                    }
                </mat-step>
                <mat-step [completed]="isValidBankInfo" label="Bank">
                    <ng-template matStepperIcon="edit">
                        <i class="fa fa-check custom-fs"></i>
                    </ng-template>


                    <div class=" fs-24  business-title-card">
                        <span>{{ staticText?.scp?.onboard?.sub_heading_2}}</span>
                    </div>


                    <div class="px-1 px-sm-3  py-2 fw-bold fs-12 mb-4 business-info-card align-items-center alert alert-info"
                        (click)="collapsed = !collapsed" role="alert">
                        <div class="collapse_arw">
                            <div class="collapse_icn_flex">
                                <span class=""> <img class="mx-2 fs_icn"
                                        src="../../../../assets/images/common/icons_usp/question.svg" alt=""></span>
                                <span
                                    class="collapse_heading site-heading">{{staticText?.scp?.onboard?.bank_site_note_1}}</span>
                            </div>

                            <!-- Expand/Collapse Icons -->
                            <i class="fas toggle-icon pull-right" [ngClass]="collapsed ? 'fa-chevron-down' : 'fa-times'"
                                (click)="collapsed = !collapsed"></i>
                        </div>
                        <div class="ms-auto">
                            <p class="note-desc site-heading mb-0" *ngIf="!collapsed">{{
                                staticText?.scp?.onboard?.bank_site_note_2}}</p>
                        </div>
                    </div>

                    @if(bankInfoMode === 'add' || onboardData){
                    <div class="custom-style-onboarding float_left">
                        <app-dynamic-form #bankInfo [sections]="bankInfoConfig" [disableForm]="disableForm"
                            [mode]="bankInfoMode" [apiData]="onboardData" [processAttachment]="true">
                        </app-dynamic-form>
                    </div>
                    }
                </mat-step>
                <mat-step [completed]="isValidBankInfo" label="Other">
                    <ng-template matStepperIcon="edit">
                        <i class="fa fa-check custom-fs"></i>
                    </ng-template>

                    <div class=" fs-24 business-title-card">
                        <span class="site-heading">{{ staticText?.scp?.onboard?.sub_heading_5}}</span>
                    </div>

                    <div class="px-1 px-sm-3 py-2 fw-bold fs-12 business-info-card align-items-center alert alert-info"
                        (click)="collapsed = !collapsed" role="alert">
                        <div class="collapse_arw">
                            <div class="collapse_icn_flex">
                                <span> <img class="mx-2 fs_icn"
                                        src="../../../../assets/images/common/icons_usp/question.svg" alt=""></span>
                                <span
                                    class="collapse_heading site-heading">{{staticText?.scp?.onboard?.other_site_note_1}}</span>
                            </div>

                            <!-- Expand/Collapse Icons -->
                            <i class="fas toggle-icon pull-right" [ngClass]="collapsed ? 'fa-chevron-down' : 'fa-times'"
                                (click)="collapsed = !collapsed"></i>
                        </div>
                        <div class="ms-auto">
                            <p class="note-desc site-heading mb-0" *ngIf="!collapsed">{{
                                staticText?.scp?.onboard?.other_site_note_2}}</p>
                        </div>
                    </div>
                    @if(bankInfoMode === 'add' || onboardData){
                    <div class="custom-style-onboarding">
                        <app-dynamic-form [sections]="otherInfoConfig" #otherInfo [disableForm]="disableForm"
                            [apiData]="otherInfoData" [mode]="otherMode" [processAttachment]="true">
                        </app-dynamic-form>
                    </div>
                    }
                </mat-step>
            </mat-horizontal-stepper>
            }
        </mat-card>
    </section>
    <!--................. Container section -->
</div>



@if (currentStepperDetails.nextBtn.previewBtn) {
<div class="agree_btn_foot"></div>
<div class="agree-btn">
    <div class="container checkbox_flx">
        <custom-checkbox [inputCheckBoxFormGroup]="othersForm" inputCheckBoxFormControlName="agree"
            inputCheckBoxColor="primary" [inputCheckBoxValue]="'I agree to the'">
        </custom-checkbox>
        <a class="terms_condition cursor-pointer" (click)="termsClick()">{{
            staticText?.scp?.onboard?.terms_condition}}</a>
    </div>
</div>
}
<!-- Footer section .................-->
<div class="foot_height_cmn"></div>



<div class=" guest-footer py-2">
    <div class="container d-flex submit_flx btn_justify btn_two_sm">


        <div class="btn-align">
            <button class="btn_default_user  " mat-stroked-button (click)="close()">
                <span>{{staticText?.common?.cancel_button}}</span>
            </button>
            <button class="mx-2  draft_save" mat-stroked-button (click)="saveCurrentStep('draft')">
                <span>{{staticText?.common?.save_as_draft_button}}</span>
            </button>

        </div>

        <ng-container>
            <div class="action-btns-2 justify-content-end align-items-center text-capitalize cursor-pointer">
                <div class="py-2 d-flex flex_col_user">
                    <button class="btn_login_user" mat-raised-button *ngIf="currentStepperDetails.prevBtn.title != ''"
                        class="btn_login_user user_btn_border" (click)="prevStep()">
                        <div>
                            <i class="fa fa-arrow-left mx-2"></i>
                            <span [innerHtml]="currentStepperDetails.prevBtn.title" class=""></span>
                        </div>
                    </button>


                    @if (currentStepperDetails.nextBtn.previewBtn) {
                    <!-- Preview button ................ -->
                    <button class="btn_login_user" [ngClass]="{'pointerEventNone':!isValidAgree}"
                        *ngIf="currentStepperDetails.nextBtn.title != ''" mat-raised-button class="btn_login_user mx-2"
                        (click)="saveCurrentStep('preview')">
                        <div>
                            <span [innerHtml]="currentStepperDetails.nextBtn.title" class="text-light"></span>

                            <img class="mx-2" src="../../../../assets/images/common/icons_usp/right.svg" alt="right" />
                        </div>
                    </button>
                    <!-- ................Preview button  -->
                    }@else{
                    <button class="btn_login_user" *ngIf="currentStepperDetails.nextBtn.title != ''" mat-raised-button
                        class="btn_login_user mx-2" (click)="saveCurrentStep('next')">
                        <div>
                            <span [innerHtml]="currentStepperDetails.nextBtn.title" class="text-light"></span>

                            <img class="mx-2" src="../../../../assets/images/common/icons_usp/right.svg" alt="right" />
                        </div>
                    </button>
                    }
                </div>
            </div>
        </ng-container>
    </div>
</div>


<!--................. Footer section -->