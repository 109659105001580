<!-- header part -->



<section class="users-view-edit-page">

  <div class="category-header d-flex flex-column justify-content-center">
    <div class="container category-title">
      <div class="d-flex flex-row justify-content-between align-items-center">

        <label class="text-black fw-600 fs-20" [innerHtml]="majorDialogData?.title">
        </label>
        <img class="cursor-pointer" src="./assets/images/common/icons/close_black.svg" alt="close" (click)="close()" />
      </div>
    </div>
  </div>
  <mat-card class="card-default-style card_style">

    <!-- <mat-card-title class="text-capitalize px-1 pb-2 fs-20">
      <div
        class="d-inline-flex flex-row justify-content-start align-items-center fs-16 fw-600"
      >
       
        <p class="text-capitalize mb-0">
          {{
            type === "add"
              ? staticText?.user_management?.user?.view_edit?.add_page_title_4
              : type === "edit"
              ? staticText?.user_management?.user?.view_edit
                  ?.view_edit_page_title_2
              : staticText?.user_management?.user?.view_edit
                  ?.view_edit_page_title_1
          }}
        </p>
       
      </div>
      <ng-container>
        
      </ng-container>
    </mat-card-title> -->

    <mat-card-content *ngIf="type == 'view'" class="mb-0 h_user_popop">
      <div class="container-fluid">
        <div class="basic_details margin_basic">{{staticText?.user_management?.user?.user_title_field1}}</div>
        <div class="row border_all mx-auto">


          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12  border_view_col">
            <label for="" class="color_label"><span [innerHTML]="
                    staticText?.user_management?.user?.view_edit
                      ?.view_edit_input_1
                  "></span></label>

            <p class="label_txt_view" title="{{ viewUser.first_name }}">
              {{ viewUser.first_name }}
            </p>
          </div>
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12  border_view_col">
            <label for="" class="color_label"><span [innerHTML]="
                    staticText?.user_management?.user?.view_edit
                      ?.view_edit_input_2
                  "></span></label>
            <p class="label_txt_view" title="{{ viewUser.last_name}}">
              {{ viewUser.last_name }}
            </p>
          </div>
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12  border_view_col">
            <label for="" class="color_label"><span [innerHTML]="
                    staticText?.user_management?.user?.view_edit
                      ?.view_edit_input_3
                  "></span></label>
            <p class="label_txt_view" title="{{ viewUser.username }}">
              {{ viewUser.username }}
            </p>
          </div>
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12  border_view_col">
            <label for="" class="color_label"><span [innerHTML]="
                    staticText?.user_management?.user?.view_edit
                      ?.view_edit_input_4
                  "></span></label>
            <p class="label_txt_view mail_overflow" title="{{ viewUser.email }}">
              {{ viewUser.email }}
            </p>
          </div>
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12  border_view_col">
            <label for="" class="color_label"><span [innerHTML]="
                    staticText?.user_management?.user?.view_edit
                      ?.view_edit_input_5
                  "></span></label>

            <p class="label_txt_view">
              {{ viewUser.date_of_birth || "-" }}
            </p>
          </div>
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12  border_view_col">
            <label for="" class="color_label"><span [innerHTML]="
                    staticText?.user_management?.user?.view_edit
                      ?.view_edit_input_6
                  "></span></label>
            <p class="label_txt_view" title="{{ viewUser.phone_number }}">
              {{
              viewUser?.phone_number &&
              (viewUser?.country_code
              ? "(" + viewUser.country_code + ")"
              : "(+250)")
              }}
              {{ viewUser?.phone_number }}
            </p>
          </div>
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12  border_view_col">
            <label for="" class="color_label"><span [innerHTML]="
                    staticText?.user_management?.user?.view_edit
                      ?.view_edit_input_10
                  "></span></label>
            <p class="label_txt_view" title="{{ viewUser.user_type }}">
              {{ viewUser.user_type }}
            </p>
          </div>
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12  border_view_col">
            <label for="" class="color_label"><span [innerHTML]="
                    staticText?.user_management?.user?.view_edit
                      ?.view_edit_input_11
                  "></span></label>
            <p class="label_txt_view" title="{{ viewUser.groups }}">
              {{ viewUser.groups || "-" }}
            </p>
          </div>
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12  border_view_col">
            <label for="" class="color_label"><span [innerHTML]="
                    staticText?.user_management?.user?.view_edit
                      ?.view_edit_input_14
                  "></span></label>

            <p class="label_txt_view"
              [ngClass]="{'active_sts': viewUser?.status == true,'inactive_sts':viewUser?.status == false}"
              title="{{ viewUser?.status ? 'Active' : 'Inactive' }}">

              {{ viewUser.status ? 'Active' : 'Inactive' }}
            </p>
          </div>
          <!-- <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
              <label for="" class="form-label color-primary fs-12"
                ><span
                  [innerHTML]="
                    staticText?.user_management?.user?.view_edit
                      ?.view_edit_input_13
                  "
                ></span
              ></label>
              <p class="label_txt_view">
                {{
                  viewUser.view_all_entity == true
                    ? staticText?.user_management?.user?.view_edit
                        ?.all_entity_enabled_label
                    : staticText?.user_management?.user?.view_edit
                        ?.all_entity_disabled_label
                }}
              </p>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
              <label for="" class="form-label color-primary fs-12"
                ><span
                  [innerHTML]="
                    staticText?.user_management?.user?.view_edit
                      ?.view_edit_input_7
                  "
                ></span
              ></label>
              <p
                class="label_txt_view view_text_wrap"
                title="{{ viewUser.region }}"
              >
                {{ viewUser.region }}
              </p>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
              <label for="" class="form-label color-primary fs-12"
                ><span
                  [innerHTML]="
                    staticText?.user_management?.user?.view_edit
                      ?.view_edit_input_8
                  "
                ></span
              ></label>
              <p
                class="label_txt_view view_text_wrap"
                title="{{ viewUser.zone }}"
              >
                {{ viewUser.zone }}
              </p>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
              <label for="" class="form-label color-primary fs-12"
                ><span
                  [innerHTML]="
                    staticText?.user_management?.user?.view_edit
                      ?.view_edit_input_9
                  "
                ></span
              ></label>
              <p
                class="label_txt_view view_text_wrap"
                title="{{ viewUser.district }}"
              >
                {{ viewUser.district }}
              </p>
            </div> -->
        </div>
        <div *ngIf="viewLocationDetails.length" class="basic_details margin_basic">
          {{staticText?.user_management?.user?.user_title_field2}}</div>

        <div class="row mx-auto">
          <ng-container *ngFor="let location of viewLocationDetails">


            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12  border_view_col">
              <label for="" class="color_label"><span class="text-capitalize"
                  [innerHTML]="location.display_name"></span></label>
              <p class="label_txt_view view_text_wrap" title="{{ location.display_name }}">
                {{ location?.concatName }}
              </p>
            </div>
          </ng-container>
        </div>


      </div>
    </mat-card-content>
    <mat-card-content class="mb-0 h_user_popop" *ngIf="type == 'add' || type == 'edit'">
      <div class="container-fluid">

        <div class="basic_details margin_basic">{{staticText?.user_management?.user?.user_title_field1}}</div>
        <form [formGroup]="usersForm" class="pb-1 mt-4">
          <div class="row input_autocomplete">



            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 column_input_gap">
              <custom-input [inputAppearance]="" [inputFormGroup]="usersForm" inputFormControlName="first_name"
                [inputRequiredStatus]="true" [inputDisableState]="userApiResponse?.ldap_user"
                [inputDisableStateColor]="userApiResponse?.ldap_user"
                [inputLabel]="staticText?.user_management?.user?.view_edit?.view_edit_input_1"
                [inputPlaceholderLabel]="staticText?.user_management?.user?.view_edit?.view_edit_input_1"
                [inputErrorLabel]="usersForm.get('first_name').errors?.validation_message">
              </custom-input>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 column_input_gap">
              <custom-input [inputAppearance]="" [inputFormGroup]="usersForm" inputFormControlName="last_name"
                [inputRequiredStatus]="true" [inputDisableState]="userApiResponse?.ldap_user"
                [inputDisableStateColor]="userApiResponse?.ldap_user"
                [inputLabel]="staticText?.user_management?.user?.view_edit?.view_edit_input_2"
                [inputPlaceholderLabel]="staticText?.user_management?.user?.view_edit?.view_edit_input_2"
                [inputErrorLabel]="usersForm.get('last_name').errors?.validation_message">
              </custom-input>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 column_input_gap">
              <custom-input [inputAppearance]="" [inputFormGroup]="usersForm" inputFormControlName="username"
                [inputRequiredStatus]="true" [inputDisableState]="type === 'edit'"
                [inputDisableStateColor]="type === 'edit'"
                [inputLabel]="staticText?.user_management?.user?.view_edit?.view_edit_input_3"
                [inputPlaceholderLabel]="staticText?.user_management?.user?.view_edit?.view_edit_input_3"
                [inputErrorLabel]="usersForm.get('username').errors?.validation_message">
              </custom-input>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 column_input_gap">
              <custom-input [inputAppearance]="" [inputFormGroup]="usersForm" inputType="email"
                inputFormControlName="email" [inputRequiredStatus]="true"
                [inputDisableState]="userApiResponse?.ldap_user" [inputDisableStateColor]="userApiResponse?.ldap_user"
                [inputLabel]="staticText?.user_management?.user?.view_edit?.view_edit_input_4"
                [inputPlaceholderLabel]="staticText?.user_management?.user?.view_edit?.view_edit_input_4"
                [inputErrorLabel]="usersForm.get('email').errors?.validation_message">
              </custom-input>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 column_input_gap">
              <custom-date-picker class="date_details" inputAppearance="Outline" [inputDatePickerFormGroup]="usersForm"
                inputDatePickerFormControlName="date_of_birth" [inputDatePickerRequiredStatus]="false"
                [inputDatePickerPlaceholderLabel]=" staticText?.user_management?.user?.view_edit?.view_edit_input_5"
                [inputDatePickerAppearance]="" [inputDatePickerMaxDate]="maxDate" [inputDatePickerMinDate]="minDate"
                [inputDatePickerErrorLabel]="usersForm.get('date_of_birth').errors?.validation_message">
              </custom-date-picker>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 column_input_gap">
              <custom-input-dropdown [inputDropdownFormGroup]="usersForm" inputDropdownFormControlName="country_code"
                inputFormControlName="phone_number" [inputDropdownRequiredStatus]="false" [dropdownReadonly]="true"
                [inputDropdownPreffix]="true" [inputRequiredStatus]="true" inputType="text"
                [inputMaxLength]="phoneNumberMaxLength" [inputDropdownreadonly]="false"
                [inputOnKeyPressFunction]="_common.acceptNumberOnlyEvent" inputAppearance=""
                [inputDropdownArray]="countries" [inputDropdownPlaceholder]="staticText?.common?.country_code"
                [inputPlaceholder]="staticText?.user_management?.user?.view_edit?.view_edit_input_6"
                [inputErrorLabel]="usersForm.get('phone_number').errors?.validation_message">
              </custom-input-dropdown>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 column_input_gap">

              <custom-autocomplete [inputAppearance]="" [inputAutocompleteFormGroup]="usersForm"
                [inputAutocompleteArray]="departmentOptions" inputAutocompleteFormControlName="user_type"
                [inputAutocompleteDisableState]="userApiResponse?.is_private ? true : false"
                [inputAutoCompletereadonly]="userApiResponse?.is_private ? true : false"
                [inputAutoCompletereadonlyColor]="userApiResponse?.is_private ? true : false "
                [inputAutocompleteRequiredStatus]="true" (inputAutoCompleteEmitter)="onSelectDepartment($event)"
                [inputAutocompletePlaceholderLabel]="staticText?.user_management?.user?.view_edit?.view_edit_input_10"
                [inputAutoCompleteErrorLabel]="usersForm.get('user_type').errors?.validation_message">
              </custom-autocomplete>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 column_input_gap">
              <custom-autocomplete [inputAppearance]="" [inputAutocompleteFormGroup]="usersForm"
                [inputAutocompleteDisableState]="userApiResponse?.is_private ? true : false"
                [inputAutoCompletereadonly]="userApiResponse?.is_private ? true : false"
                [inputAutoCompletereadonlyColor]="userApiResponse?.is_private ? true : false "
                [inputAutocompleteArray]="groupsOptions" inputAutocompleteFormControlName="groups"
                [inputAutocompleteRequiredStatus]="true" (inputAutoCompleteEmitter)="onSelectRole($event)"
                [inputAutocompletePlaceholderLabel]="staticText?.user_management?.user?.view_edit?.view_edit_input_11"
                [inputAutoCompleteErrorLabel]="usersForm.get('groups').errors?.validation_message">
              </custom-autocomplete>
            </div>
            <!-- <div class="col">
                <custom-multiselect-group
                  [inputSelectArray]="sampleData?.data"
                  [inputSelectFormContolName]="sampleData?.controlName"
                  [inputSelectFormGroup]="usersForm"
                  [inputSelectRequiredStatus]="false"
                  [inputSelectLabel]="sampleData?.name"
                  [inputSelectPlaceholder]="sampleData?.name"
                  [inputSelectGroupBy]="true"
                >
                </custom-multiselect-group>
              </div> -->
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12  " ngClass="{border_view_col: type === 'view'}"
              *ngIf="type == 'add' || type == 'edit'">
              <div>
                <label class="status-label" [innerHtml]="
                      staticText?.user_management?.entity?.edit?.edit_input_3
                    "></label>
              </div>
              <div class="active-inactive py-1">
                <mat-label (click)="updateStatus(true)" [ngClass]="{
                  'status-active': this.usersForm.value.status
                }" class=" px-3 cursor-pointer" [innerHtml]="staticText?.common?.active"></mat-label>
                <mat-label (click)="updateStatus(false)" [ngClass]="{
                      'status-in-active': !this.usersForm.value.status
                    }" class=" px-3  cursor-pointer" [innerHtml]="staticText?.common?.inactive"></mat-label>
                <mat-slide-toggle formControlName="status" style="display: none;"></mat-slide-toggle>
              </div>

            </div>

            <!-- <div class="col">
                                <custom-multiselect [inputSelectArray]="[]"
                                    inputSelectFormContolName="location" [inputSelectFormGroup]="usersForm"
                                    [inputSelectRequiredStatus]="false" (inputEmitSelected)="onSelectLocation($event)"
                                    [inputSelectLabel]="'Location'"
                                    [inputSelectPlaceholder]="'Location'"
                                    [inputSelectErrorLabel]="usersForm.get('location').errors?.validation_message">
                                </custom-multiselect>
                            </div> -->

            <!-- <div class="col">
                                <custom-multiselect [inputSelectArray]="regionOptions" [inputSelectDisableState]="(userApiResponse?.is_private ? true : false) || enableEditForSameUser"
                                    inputSelectFormContolName="region" [inputSelectFormGroup]="usersForm"
                                    [inputSelectRequiredStatus]="false" (inputEmitSelected)="onSelectRegion($event)"
                                    [inputSelectLabel]="staticText?.user_management?.user?.view_edit?.view_edit_input_7"
                                    [inputSelectPlaceholder]="staticText?.user_management?.user?.view_edit?.view_edit_input_7"
                                    [inputSelectErrorLabel]="usersForm.get('region').errors?.validation_message">
                                </custom-multiselect>
                            </div>
                            <div class="col">
                                <custom-multiselect [inputSelectArray]="zoneOptions" [inputSelectDisableState]="(userApiResponse?.is_private ? true : false) ||enableEditForSameUser"
                                    inputSelectFormContolName="zone" [inputSelectFormGroup]="usersForm"
                                    [inputSelectRequiredStatus]="false" (inputEmitSelected)="onSelectMZO($event)"
                                    [inputSelectLabel]="staticText?.user_management?.user?.view_edit?.view_edit_input_8"
                                    [inputSelectPlaceholder]="staticText?.user_management?.user?.view_edit?.view_edit_input_8"
                                    [inputSelectErrorLabel]="usersForm.get('zone').errors?.validation_message">
                                </custom-multiselect>
                            </div>
                            <div class="col">
                                <custom-multiselect [inputSelectArray]="districtOptions" [inputSelectDisableState]="(userApiResponse?.is_private ? true : false) ||enableEditForSameUser"
                                    inputSelectFormContolName="district" [inputSelectFormGroup]="usersForm"
                                    [inputSelectRequiredStatus]="false" (inputEmitSelected)="onSelectDistrict($event)"
                                    [inputSelectLabel]="staticText?.user_management?.user?.view_edit?.view_edit_input_9"
                                    [inputSelectPlaceholder]="staticText?.user_management?.user?.view_edit?.view_edit_input_9"
                                    [inputSelectErrorLabel]="usersForm.get('district').errors?.validation_message">
                                </custom-multiselect>
                            </div>    -->
            <div *ngIf="userApiResponse?.is_private == 'true'">
              <div class="col p-3" *ngxPermissionsOnly="[permssions.CHANGE_ENTITY]">
                <custom-checkbox [inputCheckBoxFormGroup]="usersForm" inputCheckBoxFormControlName="view_all_entity"
                  inputCheckBoxColor="primary" [inputCheckBoxValue]="
                      staticText?.user_management?.user?.view_edit
                        ?.view_edit_input_13
                    ">
                </custom-checkbox>
              </div>
            </div>

          </div>
        </form>
        <!-- <div class="basic_details">Location Details</div>
        <form [formGroup]="getLocationForm" class="mt-4 mb-3">
          <div class="row">
            <ng-container *ngIf="_userService?.locationJson?.length">
              <ng-container *ngFor="let data of _userService?.locationJson">
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 column_input_gap pb-1">
                  <ng-container *ngIf="!data?.group">
                    <custom-select [inputSelectArray]="data?.data" [inputSelectFormContolName]="data?.controlName"
                      [inputSelectFormGroup]="getLocationForm" [inputSelectRequiredStatus]="false"
                      [inputSelectMutipleOptionStatus]="true" [inputSelectGroupBy]="false"
                      (inputEmitSelected)="onSelectLocation($event, data)" [inputAppearance]=""
                      [inputSelectPlaceholderLabel]="data?.name">
                    </custom-select>
                  </ng-container>
                  <ng-container *ngIf="data?.group">
                    <custom-select [inputAppearance]="" [inputSelectArray]="data?.groupData"
                      [inputSelectFormContolName]="data?.controlName" [inputSelectFormGroup]="getLocationForm"
                      [inputSelectRequiredStatus]="false" [inputSelectMutipleOptionStatus]="true"
                      [inputSelectGroupBy]="true" (inputEmitSelected)="onSelectLocation($event, data)"
                      [inputSelectPlaceholderLabel]="data?.name">
                    </custom-select>
                  </ng-container>


                </div>
              </ng-container>
            </ng-container>
          </div>
        </form> -->
        <div class="basic_details margin_basic">{{staticText?.user_management?.user?.user_title_field2}}</div>
        <app-location-form #dynamicLocation [mode]="type" [config]="locationFormConfig"></app-location-form>
      </div>
    </mat-card-content>
    <ng-container>
      <!-- *ngxPermissionsOnly="[permssions.CHANGE_USER_PERMISSION]" -->

      <mat-card-actions align="end" class="py-2 btn_users">
        <div class="w-100 d-flex  justify-content-between align-items-center text-capitalize cursor-pointer px-2"
          *ngIf="type == 'add'">
          <div class="p-2">
            <button class="btn_default_user" mat-stroked-button (click)="close()"
              [innerHtml]="staticText?.common?.cancel_button"></button>
          </div>
          <div class="p-2">
            <button *ngxPermissionsOnly="[permission.ADD_CUSTOMUSER]" mat-raised-button
              class="create-default-style btn_login_user" (click)="submitUsers()">
              <div>
                <span [innerHtml]="staticText?.common?.add_button_user" class="text-light"></span>
              </div>
            </button>
          </div>
        </div>
        <div
          class="d-flex flex-row w-100 justify-content-between align-items-center text-capitalize cursor-pointer px-2"
          *ngIf="type == 'edit'">
          <div class="p-2">
            <button class="btn_default_user " mat-stroked-button (click)="close()"
              [innerHtml]="staticText?.common?.cancel_button"></button>
          </div>
          <div class="p-2">
            <button *ngxPermissionsOnly="[permssions.CHANGE_CUSTOMUSER]" mat-raised-button
              class="create-default-style btn_login_user" (click)="submitUsers()">
              <div>
                <span
                  [innerHtml]="staticText?.user_management?.user?.view_edit?.edit_user || staticText?.common?.update_button"
                  class="text-light"></span>
                <!-- <img src="/assets/images/common/icons/arrow_right_dark.svg" alt="button_icon" class="mx-2" /> -->
              </div>
            </button>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-around align-items-center text-capitalize cursor-pointer"
          *ngIf="type == 'view'">
          <div class="p-2">
            <button class=" btn_default_user" mat-stroked-button (click)="close()"
              [innerHtml]="staticText?.common?.close_button"></button>
          </div>
        </div>

        <div class="d-flex flex-row justify-content-start align-items-center cursor-pointer" *ngIf="false">
          <div class="p-2">
            <button class="cancel-default-style" mat-stroked-button (click)="close()"
              [innerHtml]="staticText?.common?.close_button"></button>
          </div>
          <ng-container *ngxPermissionsOnly="[permssions.VIEW_APPROVE_PRIVATE_VALUER]">
            <ng-container *ngIf="userApiResponse?.is_private && !userApiResponse?.is_active">
              <div class="p-2">
                <button mat-raised-button class="reject-default-style" (click)="approveUsers(false)">
                  <div>
                    <span [innerHtml]="staticText?.common?.reject_button" class="text-dark fw-bold"></span>
                  </div>
                </button>
              </div>
              <div class="p-2">
                <button mat-raised-button class="success-default-style" (click)="approveUsers(true)">
                  <div>
                    <span [innerHtml]="staticText?.common?.approve_button" class="text-dark fw-bold"></span>
                  </div>
                </button>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </mat-card-actions>
    </ng-container>
  </mat-card>
</section>