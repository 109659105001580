import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { template } from 'lodash';
import { forkJoin } from 'rxjs';
import { CommonService } from 'src/app/core/services/common/common.service';
import { HttpService } from 'src/app/core/services/http/http.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { LodashService } from 'src/app/core/services/lodash/lodash.service';
import { RoleService } from 'src/app/core/services/role.service';
import { RouterService } from 'src/app/core/services/router/router.service';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import {
  ApiMethod,
  Endpoints,
  ErrorCodes,
  ErrorMessage,
  FailedMessage,
  PagePermssions,
  SuccessMessage,
  colorCodes,
  routePath,
} from 'src/app/core/services/utils/constants';
import { BreadCrumbService } from 'src/app/shared/services/bread-crumb/bread-crumb.service';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { TranslationService } from 'src/app/shared/services/translation.service';

@Component({
  selector: 'app-role-mgmt',
  templateUrl: './role-mgmt.component.html',
  styleUrls: ['./role-mgmt.component.scss'],
})
export class RoleMgmtComponent implements OnInit {
  permission: any = PagePermssions;
  roleMgmtForm: FormGroup | any;
  staticText: any = TranslationService.staticTextData;
  entityOptions: any = [];
  type: any;
  roleId: any;
  rolesObj: any = {};
  isEditPage: boolean = false;
  addDetails: any;
  rolesList: any[] = [];
  permissionList: any[] = [];
  selectedIndex!: number;
  checked: boolean = false;
  selectedRoleList: any[] = [];
  allPermissionList: any[] = [];
  rolePatchList: any[] = [];
  getUpdatedList: any = {};
  getUpdatedPermission: any;
  entityName: string = '';
  routeEventSubscription: any;
  userDetails: any;
  showPermissionError: boolean = false;
  permissionErrorMsg: any = ErrorMessage.ROLES_PERMISSION_ERROR_MSG;
  data: any[] | undefined;
  groupedData: any[] | undefined;
  breadcrumbs: any = [];
  toggleMenu: boolean[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private _formValidator: FormValidatorService,
    private activated_route: ActivatedRoute,
    private _snackBar: SnackbarService,
    private _http: HttpService,
    private _loadash: LodashService,
    private _loader: LoaderService,
    private route: Router,
    private _modal: ModalService,
    public _routeService: RouterService,
    private router: Router,
    protected breadcrumbService: BreadCrumbService,
    private _storage: StorageService,
    private cdr: ChangeDetectorRef,
    private _common: CommonService,
    private _roleService: RoleService
  ) {
    this.getRouteParams();
    this.getUserDetailsId();
  }
  ngOnInit(): void {
    this.breadcrumbService.breadCrumbView(true);
  }

  getUserDetailsId() {
    this.userDetails = this._storage.getUserDetails();
  }

  onClickFn() {
    this._routeService.navigatePages(
      'iam/user-management/role/edit/' + this.roleId
    );
  }

  setBreadCrumb() {
    let breadcrumbActionBtn =
      this.type == 'view'
        ? {
            // for edit button in view
            label: 'Edit Role',
            onClickActionFn: this.onClickFn.bind(this),
            icon: 'arrow_right_dark',
            permission: [this.permission.CHANGE_CUSTOMGROUP],
          }
        : {};
    this.breadcrumbs = [
      {
        label: 'User Management',
        url: 'iam/user-management/roles',
      },
    ];
    if (this.type == 'edit') {
      this.breadcrumbs.push({
        label: 'Edit Role',
        url: '',
      });
    }
    if (this.type == 'view') {
      this.breadcrumbs.push({
        label: 'View Role',
        url: '',
      });
    }
    if (this.type == 'add') {
      this.breadcrumbs.push({
        label: 'Add Role',
        url: '',
      });
    }

    this.breadcrumbService.setCustomBreadCrumb(this.breadcrumbs);
    this.breadcrumbService.setCustomBreadCrumbAction(breadcrumbActionBtn);
  }

  getRouteParams() {
    //this.getPermissionList();

    this.routeEventSubscription = this.route.events.subscribe((event: any) => {
      if (event.routerEvent) {
        if (event.routerEvent.url.includes('iam/user-management/role/edit')) {
          this.type = 'edit';
          this.roleId = this.activated_route.snapshot.paramMap.get('id');
          this._roleService.getRoleList(this.roleId);
        } else if (
          event.routerEvent.url.includes('iam/user-management/role/view')
        ) {
          this.type = 'view';
          this.roleId = this.activated_route.snapshot.paramMap.get('id');
          this._roleService.getRoleList(this.roleId);
        } else if (
          event.routerEvent.url.includes('iam/user-management/role/add')
        ) {
          this.type = 'add';
        }

        if (
          event.routerEvent.url.includes('iam/user-management/role/edit') ||
          event.routerEvent.url.includes('iam/user-management/role/view') ||
          event.routerEvent.url.includes('iam/user-management/role/add')
        ) {
          this.setBreadCrumb();
          this.initialDependencies();
        }
      }
    });
  }

  initialDependencies() {
    this.createForm();
    this._loader.show();

    // Prepare API calls
    let apis: any = {
      getPermissionList: this._roleService.getPermissionList(),
      getEntity: this._roleService.getEntity(),
    };

    // Conditionally add getRoleDetails API call if roleId is defined
    if (this.roleId) {
      apis.getRoleDetails = this._roleService.getRoleList(this.roleId);
    }

    forkJoin(apis).subscribe((data: any) => {
      this._loader.hide();

      // Handle the data returned by the API calls
      this.getAllPermission(data);
      if (this.roleId) {
        this.getRoleDetails(data);
      }
      this.getEntityList(data);
    });
  }

  getAllPermission(data: any) {
    let permissionData: any = data?.getPermissionList?.data || {};
    this.allPermissionList = this._loadash.Clonedata(permissionData);
    this.permissionList = permissionData;
    this.permissionList = this.groupArrayOfObjects(
      this.permissionList,
      'category'
    );
  }

  selectedPermissions(
    evt: any,
    permissionIndex: any,
    permissionName: any,
    permission: any
  ) {
    this.selectedIndex = permissionIndex;
    this.checked = evt?.target?.checked;
    this.permissionList[permissionName][permissionIndex].checked =
      evt?.target?.checked;
    this.setSelectedAllPermission();
    this.selectedRoleList = this.selectedRoleList.filter(
      (o: any) =>
        o !=
        this._loadash.getData(
          this.permissionList,
          permissionName + '.' + permissionIndex + '.id',
          ''
        )
    );
    if (evt?.target?.checked) {
      this.selectedRoleList.push(
        this._loadash.getData(
          this.permissionList,
          permissionName + '.' + permissionIndex,
          ''
        )
      );
    } else {
      const index = this._loadash.findIndexBy(
        this.selectedRoleList,
        'id',
        permission?.id
      );
      this.selectedRoleList.splice(index, 1);
    }
  }

  getRoleDetails(data: any) {
    let roleData: any = data?.getRoleDetails?.data || {};
    this.getUpdatedList = roleData;
    this.rolePatchList = roleData?.permissions;
    this.patchRoleData(this.permissionList);
    this.entityName = this._loadash.getData(
      roleData,
      'user_type.display_name',
      ''
    );
  }
  groupArrayOfObjects(array: any = [], key: string): any {
    return array.reduce((rv: any, x: any) => {
      let v = this._loadash.getData(x, key, '');
      (rv[v] = rv[v] || []).push(x);
      return rv;
    }, {});
  }

  onClick(evt: MouseEvent) {
    evt.stopPropagation();
  }

  patchRoleData(patchArray: any) {
    if (this.type == 'edit' || this.type == 'view') {
      if (this._loadash.getData(this.getUpdatedList, 'user_type', false)) {
        this.isEditPage = true;
        // const selectedEntity = this.entityOptions.find((x: { name: any }) => x.name === this.getUpdatedList.user_type[0].display_name);

        const selectedEntity =
          this.entityOptions?.find(
            (x: any) =>
              x.id ===
              this._loadash.getData(this.getUpdatedList, 'user_type.id', '')
          ) || null;

        this.roleMgmtForm?.patchValue({
          user_type: selectedEntity || null,
          role_name: this.getUpdatedList?.display_name || '',
          status: this.getUpdatedList?.is_active,
          description: this.getUpdatedList?.description || '',
        });
        this.rolePatchList = this.getUpdatedList?.permissions;
        let selectedPermissionIds = this._loadash.mapData(
          this.getUpdatedList?.permissions,
          'id'
        );
        if (selectedPermissionIds.length) {
          this.selectedRoleList = this.getUpdatedList?.permissions;
          Object.keys(this.permissionList).forEach((key: any) => {
            if (this.permissionList.hasOwnProperty(key)) {
              let pList = this.permissionList[key];
              pList.forEach((p: any, index: any) => {
                this.permissionList[key][index]['checked'] =
                  selectedPermissionIds.includes(p.id);
              });
            }
          });
          this.toggleMenu = new Array(
            Object.keys(this.permissionList).length
          ).fill(true);
          this.setSelectedAllPermission();
        }
      }
    }
  }

  createForm() {
    this.formInit();
    this._roleService.getEntity();
  }

  formInit() {
    this.roleMgmtForm = this.formBuilder.group({
      user_type: [
        '',
        [this._formValidator.requiredValidationCheck('User Group')],
      ],
      role_name: [
        '',
        [
          this._formValidator.requiredValidationCheck('Role Name'),
          this._formValidator.minMaxLengthCheck(1, 100),
        ],
      ],
      description: [''],
      status: [true],
    });
  }
  drawTemplate(response: any) {
    return null;
  }

  getEntityList(data: any) {
    this.entityOptions = data?.getEntity?.data;
    this.roleMgmtForm?.patchValue({
      user_type:
        this.entityOptions?.find(
          (x: any) =>
            x.id ==
            this._loadash.getData(this.getUpdatedList, 'user_type.id', '')
        ) || null,
    });
  }

  getValidAPIPermissionList(permissionIds: any = []) {
    let finalPermissionList: any = [];
    this.allPermissionList.forEach((v) => {
      if (this._loadash.loostIncludes(permissionIds, v.id)) {
        finalPermissionList.push(v);
      }
    });
    return finalPermissionList;
  }

  addEditRole() {
    if (this.roleMgmtForm.invalid) {
      this._common.errorFocusScroll();
      this.roleMgmtForm.markAllAsTouched();
      return;
    }

    this.showPermissionError = false;
    if (!this.selectedRoleList.length) {
      this.showPermissionError = true;
      return;
    }

    if (
      this.type == 'edit' &&
      this.roleId ==
        this._loadash.getData(this.userDetails, 'groups.0.id', '-1')
    ) {
      this._snackBar.loadSnackBar(
        FailedMessage.PERMISSION_FAILED_MSG,
        colorCodes.WARNING
      );
      return;
    }
    const selectedRoleList = this.selectedRoleList?.map((x: any) => ({
      id: x.id,
      name: x.name,
    }));

    const payload = {
      name: this.roleMgmtForm.value.role_name,
      display_name: this.roleMgmtForm.value.role_name,
      description: this.roleMgmtForm.value.description,
      is_active: this.roleMgmtForm.value.status,
      user_type: this.roleMgmtForm.value.user_type.id,
      permissions: selectedRoleList || [],
      // "roles": this.getValidAPIPermissionList(this.selectedRoleList)
    };

    this._loader.show();

    const endpoint: any =
      this.type === 'add'
        ? Endpoints.GET_ROLES_LIST
        : Endpoints.GET_ROLES_LIST + this.roleId + '/';
    const apiMethod = this.type === 'add' ? ApiMethod.POST : ApiMethod.PUT;

    this._http
      .requestCall(endpoint, apiMethod, payload, { observe: 'response' })
      .subscribe(
        (getCreatedResponse: any) => {
          if (
            getCreatedResponse?.status === ErrorCodes?.HTTP_200_SUCCESS ||
            getCreatedResponse?.status === ErrorCodes?.HTTP_201_CREATED
          ) {
            this.roleMgmtForm.reset();
            this._modal.closeDialog();
            this._modal.openSucceedDialog({
              data: {
                header:
                  this.type == 'add'
                    ? `<div>${this.staticText?.user_management?.roles?.add?.add_header_msg}</div>`
                    : `<div>${this.staticText?.user_management?.roles?.edit?.edit_header_msg}</div>`,
                msg:
                  this.type == 'add'
                    ? ` <p class=" success-text"  [innerHTML]="successDialogData?.msg">
                    ${this.staticText?.user_management?.roles?.add?.add_success_msg}
                    </p>
                    `
                    : `<p class=" success-text"  [innerHTML]="successDialogData?.msg">
                        Changes of <span class="user_name"></span> ${this.staticText?.user_management?.roles?.edit?.edit_success_msg}
                    </p>`,
                template: this.drawTemplate(Response),

                paragraph:
                  this.type === 'add'
                    ? SuccessMessage.ROLES_ADD_MSG
                    : SuccessMessage.ROLES_UPDATE_MSG,
              },
            });
            this._routeService.navigatePages(
              routePath?.ROLES_LIST_REDIRECT_PATH
            );
          } else if (
            getCreatedResponse?.status === ErrorCodes?.HTTP_208_REPORTED
          ) {
            this._snackBar.loadSnackBar(
              this._loadash.getData(
                getCreatedResponse,
                'body.details',
                this.type === 'add'
                  ? FailedMessage.ROLE_ADD_FAILED_MSG
                  : FailedMessage.ROLE_UPDATE_FAILED_MSG
              ),
              colorCodes.ERROR
            );
          }
          this._loader.hide();
        },
        (error: any) => {
          this._loader.hide();
        }
      );
  }

  close() {
    this.router.navigateByUrl(routePath?.PRIVATE_LIST_REDIRECT_PATH);
  }

  ngOnDestroy() {
    this.routeEventSubscription.unsubscribe();
  }

  selectAllPermissions(evt: any, permission: any) {
    let selectAllChecked = evt?.target?.checked;
    this.permissionList[permission.key].forEach((permission: any) => {
      permission.checked = selectAllChecked;
    });
    this.setSelectedAllPermission();
    if (selectAllChecked) {
      this.selectedRoleList = [
        ...new Set([
          ...this.selectedRoleList,
          ...this.permissionList[permission.key].map((p: any) => p),
        ]),
      ];
    } else {
      this.selectedRoleList = this.selectedRoleList.filter(
        (role: any) =>
          !this.permissionList[permission.key].some(
            (p: any) => p.id === role.id
          )
      );
    }
  }

  setSelectedAllPermission() {
    Object.keys(this.permissionList).forEach((key: any) => {
      this.permissionList[key]['select_all'] = this.permissionList[key]?.every(
        (o: any) => o?.checked
      );
      this.permissionList[key]['toggle'] = true;
    });
  }

  updateStatus(status: boolean) {
    this.roleMgmtForm.get('status').setValue(status);
  }
}
