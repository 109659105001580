import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/core/services/common/common.service';
import { APPLICATION } from 'src/app/core/services/utils/constants';
import { TranslationService } from 'src/app/shared/services/translation.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  staticText: any = TranslationService.staticTextData;
  appConfig: any = APPLICATION.config;
  currentYear: number = new Date().getFullYear();
  copyRight!: string;

  footerName$: Observable<any> = new Observable<any>();

  constructor(private _common: CommonService) {}

  ngOnInit(): void {
    this.footerName$ = this._common.getFooterName();
    this._common.getAppConfig().subscribe((response: any) => {
      this.appConfig = response;
    });
  }
}
