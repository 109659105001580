import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common/common.service';
import { Subscription, filter } from 'rxjs';
import { DataService } from 'src/app/shared/services/data/data.service';
import { TranslationService } from 'src/app/shared/services/translation.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnChanges, OnDestroy {
  @Input() menuMode: any = 'standard';
  @Input() isViewInSmaller: boolean = false;
  @Input() sideNav: any;

  staticText: any = TranslationService.staticTextData;
  menuList: any[];
  toggleMenuStatus: boolean[];
  currentUrl: any;
  showMenuToggle: boolean = false;
  showCollapseState: boolean = true;

  private routeSubscription: Subscription | undefined;

  constructor(
    private route: Router,
    private _common: CommonService,
    private dataService: DataService
  ) {
    this.menuList = this._common?.sideNavMenu;
    this.toggleMenuStatus = new Array(this.menuList.length).fill(false);
    this.initializeSidebar();
  }

  ngOnInit(): void {
    this._common.fetchPermssions();
  }

  ngOnChanges(): void {
    this.showMenuToggle = this.isViewInSmaller;
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
    localStorage.removeItem('activeMenu');
  }

  initializeSidebar(): void {
    this.routeSubscription = this.route.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentUrl = event.url;
        this.resetMenuStates();
        this.activateMenu(this.menuList, this.currentUrl);
      });
  }

  resetMenuStates(): void {
    this.inactiveAllMenus(this.menuList);
    this.collapseAllMenus(this.menuList);
  }

  inactiveAllMenus(menuList?: any[]): void {
    menuList?.forEach(menu => {
      menu.activate = false;
      this.inactiveAllMenus(menu.children);
    });
  }

  collapseAllMenus(menuList?: any[]): void {
    menuList?.forEach(menu => {
      menu.expand = false;
      this.collapseAllMenus(menu.children);
    });
  }

  activateMenu(menuList: any[], url: string): void {
    const activeMenu = this.findActiveMenu(menuList, url.split('/'), 0);
    this.expandActivateMenu(activeMenu);
  }

  findActiveMenu(menuList: any[], urlSegments: string[], level: number): any {
    const activeMenu = menuList.find(menu => menu.uniq_name === urlSegments[level]);
    if (activeMenu?.children?.length) {
      activeMenu.children = this.findActiveMenu(activeMenu.children, urlSegments, level + 1);
    }
    return activeMenu;
  }

  expandActivateMenu(activeMenu: any): void {
    while (activeMenu) {
      activeMenu.expand = true;
      activeMenu.activate = true;
      activeMenu = activeMenu.children?.find((child: any) => child.activate);
    }
  }

  toggleSidenav(): void {
    this.showCollapseState = !this.showCollapseState;
  }

  sideNavToggle(): void {
    this.sideNav?.toggle();
    this.dataService.chartRender.next(true);
  }

  menuClick(menu: any): void {
    menu.expand = !menu.expand;
  }
}
