import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { error } from 'jquery';
import _ from 'lodash';
import { NgxPermissionsModule } from 'ngx-permissions';
import { forkJoin, map, Observable } from 'rxjs';
import { CommonService } from 'src/app/core/services/common/common.service';
import { ConfigurationService } from 'src/app/core/services/common/configuration.service';
import { HttpService } from 'src/app/core/services/http/http.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { LodashService } from 'src/app/core/services/lodash/lodash.service';
import { RouterService } from 'src/app/core/services/router/router.service';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import {
  APPLICATION,
  ApiMethod,
  Endpoints,
  SCREENMODE,
  SETUP_CONFIGUARTION,
  colorCodes,
  configurationStepper,
} from 'src/app/core/services/utils/constants';
import { DynamicFormComponent } from 'src/app/shared/components/dynamic-form/dynamic-form.component';
import { BreadCrumbService } from 'src/app/shared/services/bread-crumb/bread-crumb.service';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { SharedModule } from 'src/app/shared/shared.module';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';

@Component({
  selector: 'app-setup-configuration',
  standalone: true,
  imports: [
    DynamicFormComponent,
    CommonModule,
    SharedModule,
    NgxPermissionsModule,
  ],
  templateUrl: './setup-configuration.component.html',
  styleUrl: './setup-configuration.component.scss',
})
export class SetupConfigurationComponent {
  staticText: any = (textConfiguration as any).default;
  @ViewChild('brandConfigRef') brandConfigRef!: DynamicFormComponent;
  @ViewChild('geoConfigRef') geoConfigRef!: DynamicFormComponent;
  @ViewChild('localizationConfigRef')
  localizationConfigRef!: DynamicFormComponent;
  @ViewChild('accountingConfigRef') accountingConfigRef!: DynamicFormComponent;
  @ViewChild('kycConfigRef') kycConfigRef!: DynamicFormComponent;

  appConfig: any = APPLICATION.config;
  currentYear: number = new Date().getFullYear();
  copyRight: string = ` ${this.appConfig?.branding_setup?.copyright}`;

  brandConfig: any;
  geoConfig: any;
  localizationConfig: any;
  accountingConfig: any;
  configData: any;
  kycConfig: any;
  countryLogoName: any;
  countryLogoAttachment: any;
  id: any;
  mode: string = 'add';
  collapsed: boolean = true;
  @ViewChild(MatStepper) stepper!: MatStepper;
  stepperOrientation!: Observable<StepperOrientation>;
  selectedIndex: any = SETUP_CONFIGUARTION.BRANDING_DETAILS;
  stepperDetails: any = [
    {
      position: configurationStepper.branding,
      display_name: 'Branding Setup',
      prevBtn: { title: '', previewBtn: false },
      nextBtn: { title: 'Administrative Setup', previewBtn: false },
    },
    {
      position: configurationStepper.geographical,
      display_name: 'Administrative Setup',
      prevBtn: { title: 'Branding Setup', previewBtn: false },
      nextBtn: { title: 'Localization Setup', previewBtn: false },
    },
    {
      position: configurationStepper.localization,
      display_name: 'Localization Setup',
      prevBtn: { title: 'Administrative Setup', previewBtn: false },
      nextBtn: { title: 'Transaction Setup', previewBtn: false },
    },
    {
      position: configurationStepper.accounting,
      display_name: 'Transaction Setup',
      prevBtn: { title: 'Localization Setup', previewBtn: false },
      nextBtn: { title: 'KYC & IAM Setup', previewBtn: false },
    },
    {
      position: configurationStepper.kyc,
      display_name: 'Transaction Setup',
      prevBtn: { title: 'Transaction Setup', previewBtn: false },
      nextBtn: { title: 'Save Configuration', previewBtn: false },
    },
  ];
  currentStepperDetails: any = this.stepperDetails[0];
  isLanguageAvailable: boolean = false;
  initialDefaultLangage: any = {};

  constructor(
    public _routeService: RouterService,
    private _modal: ModalService,
    private _http: HttpService,
    private activated_route: ActivatedRoute,
    private _common: CommonService,
    private _loader: LoaderService,
    private _router: Router,
    private _snackBar: SnackbarService,
    protected breadcrumbService: BreadCrumbService,
    public breakpointObserver: BreakpointObserver,
    private _lodash: LodashService,
    private _storage: StorageService,
    private formBuilder: FormBuilder,
    private _config: ConfigurationService
  ) {}

  ngOnInit(): void {
    const appConfig = JSON.parse(localStorage.getItem('appConfig') || '{}');
    this.countryLogoName = appConfig?.branding_setup?.name;
    this.countryLogoAttachment =
      appConfig?.branding_setup?.country_logo[0]?.attachment;

    this.mode = this.getMode == SCREENMODE.ADD ? 'add' : 'edit';

    if (
      this.mode == 'add' &&
      this.activated_route.snapshot.data['resolvedData']?.data
        ?.is_setup_completed == true
    ) {
      this._router.navigate(['auth/login']);
    }

    this.loadInfialDefaultLangFile();
    document.body.style.backgroundColor = '#0b2036';
    this.stepperResponsiveCheck();
    this.brandConfig = this._config?.brandConfig;
    this.geoConfig = this.getGeoFormConfig();
    this.localizationConfig = this._config?.localizationConfig;
    this.accountingConfig = this.getAccountingFormConfig();
    this.kycConfig = this.getKYCFormConfig();
    this.getConfigurationDetails();
  }

  get getMode(): number {
    return this.activated_route.snapshot.data['mode'] ?? SCREENMODE.ADD;
  }

  async loadInfialDefaultLangFile() {
    this.initialDefaultLangage = await this.fileUploadFromUrl();
  }

  getGeoFormConfig() {
    let formConfig: any = [];
    formConfig = _.cloneDeep(this._config?.geoConfig);

    for (let i = formConfig.length - 1; i >= 0; i--) {
      const section = formConfig[i];

      if (section.key === 'geographical_classification') {
        section.fields.forEach((field: any) => {
          if (field.name === 'country' && field?.meta?.onchangeCallback) {
            field.meta.onchangeCallback = this.toggleCountryFields.bind(this);
          }
        });
      }
    }

    return formConfig;
  }

  getAccountingFormConfig() {
    let formConfig: any = [];
    formConfig = _.cloneDeep(this._config?.accountingConfig);

    for (let i = formConfig.length - 1; i >= 0; i--) {
      const section = formConfig[i];

      if (section.key === 'accounting_setup') {
        section.fields.forEach((field: any) => {
          if (field.name === 'start_month' && field?.meta?.onchangeCallback) {
            field.meta.onchangeCallback =
              this.toggleAccountingFields.bind(this);
          }
        });
      }
    }

    return formConfig;
  }

  getKYCFormConfig() {
    let formConfig: any = [];
    formConfig = _.cloneDeep(this._config?.kycConfig);

    for (let i = formConfig.length - 1; i >= 0; i--) {
      const section = formConfig[i];

      if (section.key === 'tin_information') {
        section.fields.forEach((field: any) => {
          if (
            field.name === 'is_tin_validation_required' &&
            field?.meta?.onchangeCallback
          ) {
            field.meta.onchangeCallback = this.toggleKYCFields.bind(this);
          }
        });
      } else if (section.key === 'ldap_information') {
        section.fields.forEach((field: any) => {
          if (
            field.name === 'is_ldap_enabled' &&
            field?.meta?.onchangeCallback
          ) {
            field.meta.onchangeCallback = this.toggleLDAPFields.bind(this);
          }
        });
      }
    }

    return formConfig;
  }

  toggleAccountingFields(data: any, form?: any, isEdit?: boolean): void {
    const endMonthCtrl = form.get('accounting_setup.end_month');
    const endMonth: any = this._config?.findMonthInterval(data?.id, 12) ?? '';
    endMonthCtrl.setValue(endMonth);
    endMonthCtrl.disable();
  }

  toggleLDAPFields(isLDAPRequired: any, form?: any, isEdit?: boolean): void {
    const controls = [
      form.get('ldap_information.ldap_client_id'),
      form.get('ldap_information.ldap_client_secret'),
      form.get('ldap_information.ldap_tenant_id'),
    ];

    // Update control states and labels
    controls.forEach((control) => {
      if (control) {
        if (isLDAPRequired) {
          control.enable();
        } else {
          control.setValue('');
          control.disable();
        }
      }
    });
  }

  toggleKYCFields(isTINRequired: any, form?: any, isEdit?: boolean): void {
    const controls = [
      form.get('tin_information.tin_api_url'),
      form.get('tin_information.tin_api_username'),
      form.get('tin_information.tin_api_password'),
    ];

    // Update control states and labels
    controls.forEach((control) => {
      if (control) {
        if (isTINRequired) {
          control.enable();
        } else {
          control.setValue('');
          control.disable();
        }
      }
    });
  }

  toggleCountryFields(data: any, form?: any, isEdit?: boolean): void {
    const countryCodeCtrl = form.get(
      'geographical_classification.country_isd_code'
    );
    const currencyCtrl = form.get('currency.currency_type');
    countryCodeCtrl.setValue(data?.phone_code);
    currencyCtrl.setValue(data?.currency_name + ' - ' + data?.currency_symbol);
    countryCodeCtrl.disable();
    currencyCtrl.disable();
  }

  stepperResponsiveCheck() {
    this.stepperOrientation = this.breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({ matches }: any) => (matches ? 'horizontal' : 'vertical')));
  }

  onSteperChange(ev: any = '') {
    this.selectedIndex = this._lodash.getData(ev, 'selectedIndex', 0);
    const selectedIndex = this._lodash.getData(ev, 'selectedIndex', 0);

    this.currentStepperDetails =
      this.stepperDetails?.find((x: any) => x.position == selectedIndex) || {};
  }

  close() {
    this._modal
      .openCancelDialog({})
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.intialSetup();
        } else {
          this._router.navigate(['/dts/setup-configuration']);
        }
      });
  }

  intialSetup() {
    this._router.navigate(['/dts/configuration']);
  }

  private setDefaultUser() {
    this.configData?.admin_users?.forEach((element: any) => {
      element.is_default = true;
    });
  }

  getConfigurationDetails() {
    this._loader.show();
    const obj: any = {
      apiKey: this._config.getDataBaseKey(),
    };
    if (this.getMode == SCREENMODE.ADD)
      obj.config = this._config.getConfigurationDetails(
        this.activated_route.snapshot.data['resolvedData']?.data
      );
    else obj.config = this._config.getConfigurationLoginDetails();
    forkJoin(obj).subscribe({
      next: (response: any) => {
        this._loader.hide();
        this.configData = response?.config?.data?.config_data || {};

        if (!response?.config?.data?.config_data?.languages) {
          this.isLanguageAvailable = false;

          this.configData.languages = [
            {
              name: 'English',
              code: 'en',
              language_file: [
                {
                  attachment: Endpoints.LOCALE_EN_FILE,
                  name: this.initialDefaultLangage?.name,
                },
              ],
              is_default: true,
            },
          ];
        } else {
          this.isLanguageAvailable = true;
        }

        this.setDefaultUser();

        if (this.configData?.branding_setup?.name) {
          this.configData?.tin_api_keys?.forEach((element: any) => {
            element.database_key = response?.apiKey?.data?.find(
              (x: any) => x.name == element.database_key
            );
          });

          this.mode = 'edit';
          this.parseGeographicData();
          this.parseAccountingData();
          this.parseKYCData();
        }
      },
      error: (err: any) => {
        this.configData = {};
      },
    });
  }

  parseGeographicData() {
    this.configData.geographical_classification = {
      ...this.configData?.geographical_classification,
      country_isd_code:
        this.configData?.geographical_classification?.country?.phone_code,
    };
    this.configData.currency = {
      currency_type:
        this.configData.geographical_classification?.country?.currency_symbol +
        ' - ' +
        this.configData.geographical_classification?.country?.currency_name,
    };

    this.configData.geographical_hierarchy = this.convertObjectToArray(
      this.configData?.geographical_classification?.geographical_hierarchy
    );

    setTimeout(() => {
      this.toggleCountryFields(
        this.configData?.geographical_classification?.country,
        this.geoConfigRef?.form
      );
    }, 100);
  }

  parseAccountingData() {
    this.configData.payment_section = {
      payment_gateway: this.configData?.accounting_setup?.payment_gateway,
    };

    const findMonthById = (id: number) =>
      this._config?.fullMonthOptions.find((option: any) => option.id == id);
    this.configData.accounting_setup.start_month = findMonthById(
      this.configData?.accounting_setup?.start_month
    );
    this.configData.accounting_setup.end_month = findMonthById(
      this.configData?.accounting_setup?.end_month
    );
    setTimeout(() => {
      this.toggleAccountingFields(
        this.configData.accounting_setup.start_month,
        this.accountingConfigRef?.form
      );
    }, 100);
  }

  parseKYCData() {
    setTimeout(() => {
      this.toggleKYCFields(
        this.configData?.tin_information?.is_tin_validation_required,
        this.kycConfigRef?.form
      );
      this.toggleLDAPFields(
        this.configData?.ldap_information?.is_ldap_enabled,
        this.kycConfigRef?.form
      );
    }, 100);
  }

  convertObjectToArray(inputObject: any): any {
    return Object.values(inputObject).map((value) => ({
      name: value,
    }));
  }

  /**
   * @description
   * @returns return boolean value based on valid site
   */

  get isValidBrandInfo(): boolean {
    return this.brandConfigRef?.form?.valid;
  }

  get isValidGeographicalInfo(): boolean {
    return this.geoConfigRef?.form?.valid;
  }

  get isValidLocalizationInfo(): boolean {
    return this.localizationConfigRef?.form?.valid;
  }

  get isValidAccountingInfo(): boolean {
    return this.accountingConfigRef?.form?.valid;
  }

  get isValidKYCInfo(): boolean {
    return this.kycConfigRef?.form?.valid;
  }

  saveCurrentStep(loadStep: string = '', prevTxt: string = '') {
    const stepperDetails =
      this.stepperDetails?.find((x: any) => x.position == this.selectedIndex) ||
      {};

    if (stepperDetails.position == configurationStepper.branding) {
      this.saveBrandingDetails();
    } else if (stepperDetails.position == configurationStepper.geographical) {
      this.saveGeographicalDetails();
    } else if (stepperDetails.position == configurationStepper.localization) {
      this.saveLocalizationDetails();
    } else if (stepperDetails.position == configurationStepper.accounting) {
      this.saveAccountingInformation();
    } else if (stepperDetails.position == configurationStepper.kyc) {
      this.saveKYCDetails();
    }
  }

  prevStep() {
    this.stepper.previous();
  }

  saveBrandingDetails() {
    if (!this.isValidBrandInfo) {
      this._common.errorFocusScroll();
      this.brandConfigRef?.validateForm();
      this._snackBar.loadSnackBar(
        this.staticText?.setup_configuration?.brand_form?.error_msg,
        colorCodes.ERROR
      );
      return;
    }

    const rawData = this.brandConfigRef?.extractFormData() ?? {};
    const payload = rawData;
    const formData = new FormData();
    formData.append('json_data', JSON.stringify(payload));
    const attachedFiles = [this.brandConfigRef?.attachmentList];
    this._common.appendMultipleComponentFilesToFormData(
      attachedFiles,
      formData
    );
    this.saveConfigurationDetails(this.id, formData);
  }

  saveGeographicalDetails() {
    if (!this.isValidGeographicalInfo) {
      this._common.errorFocusScroll();
      this.geoConfigRef?.validateForm();
      this._snackBar.loadSnackBar(
        this.staticText?.setup_configuration?.geo_form?.error_msg,
        colorCodes.ERROR
      );
      return;
    }

    const rawData: any = this.geoConfigRef?.extractFormData() ?? {};
    let levelData: any = {};
    if (
      Array.isArray(rawData?.geographical_hierarchy) &&
      rawData?.geographical_hierarchy.length > 0
    ) {
      rawData?.geographical_hierarchy?.forEach((level: any, key: any) => {
        levelData['level_' + (key + 1)] = level?.name;
      });
      delete rawData?.geographical_hierarchy;
    }

    delete rawData?.currency;
    delete rawData?.geographical_classification?.country_isd_code;
    rawData.geographical_classification.geographical_hierarchy = levelData;
    const formData = new FormData();
    formData.append('json_data', JSON.stringify(rawData));
    this.saveConfigurationDetails(this.id, formData);
  }

  async saveLocalizationDetails() {
    if (!this.isValidLocalizationInfo) {
      this._common.errorFocusScroll();
      this.localizationConfigRef?.validateForm();
      this._snackBar.loadSnackBar(
        this.staticText?.setup_configuration?.localization_form?.error_msg,
        colorCodes.ERROR
      );
      return;
    }

    const rawData = this.localizationConfigRef?.extractFormData() ?? {};
    let attachedFiles = this.localizationConfigRef?.attachmentList;

    if (!this.isLanguageAvailable && Object.keys(attachedFiles).length === 0) {
      rawData?.languages[0].language_file.push(
        this.initialDefaultLangage?.name
      );

      attachedFiles = this.initialDefaultLangage?.file;
    }

    const payload = rawData;
    const formData = new FormData();
    formData.append('json_data', JSON.stringify(payload));
    this._common.appendFilesToFormData(attachedFiles, formData);
    this.saveConfigurationDetails(this.id, formData);
  }

  saveAccountingInformation() {
    if (!this.isValidAccountingInfo) {
      this._common.errorFocusScroll();
      this.accountingConfigRef?.validateForm();
      this._snackBar.loadSnackBar(
        this.staticText?.setup_configuration?.accounting_form?.error_msg,
        colorCodes.ERROR
      );
      return;
    }

    const rawData = this.accountingConfigRef?.extractFormData() ?? {};
    rawData.accounting_setup = {
      ...rawData?.accounting_setup,
      payment_gateway: rawData?.payment_section?.payment_gateway,
    };
    delete rawData?.payment_section;
    const formData = new FormData();
    formData.append('json_data', JSON.stringify(rawData));
    this.saveConfigurationDetails(this.id, formData);
  }

  saveKYCDetails() {
    if (!this.isValidKYCInfo) {
      this._common.errorFocusScroll();
      this.kycConfigRef?.validateForm();
      this._snackBar.loadSnackBar(
        this.staticText?.setup_configuration?.kyc_form?.error_msg,
        colorCodes.ERROR
      );
      return;
    }

    const rawData = this.kycConfigRef?.extractFormData() ?? {};
    rawData['internal'] = {
      is_setup_completed: true,
    };
    const formData = new FormData();
    formData.append('json_data', JSON.stringify(rawData));
    this.saveConfigurationDetails(this.id, formData);
  }

  private openDialogue(response: any) {
    const msg =
      this.getMode != SCREENMODE.ADD && this.configData[0]?.is_setup_completed
        ? this.staticText?.setup_configuration?.update_acklg_msg
        : this.staticText?.setup_configuration?.create_acklg_msg;
    this._modal
      .openSucceedDialog({
        data: {
          header:
            this.getMode == SCREENMODE.ADD
              ? this.staticText?.setup_configuration?.create_acklg_msg_title
              : this.staticText?.setup_configuration?.update_acklg_msg_title,
          msg: msg,
        },
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (result) {
          this._common.initialSetupData = null;
          if (this.getMode == SCREENMODE.ADD)
            this._routeService.navigatePages('/auth/login');
          else this._routeService.navigatePages('/iam/user-management/users');
        }
      });
  }

  /**
   * Save or update configuration details
   * @param id
   * @param formData
   */
  saveConfigurationDetails(id: any, formData: any) {
    this._loader.show();
    const saveOrUpdate$ = this._config.saveDetails(formData);

    saveOrUpdate$.subscribe((response: any) => {
      this._loader.hide();
      const msg = this._lodash.getData(response, 'body.message', false);

      if (msg) {
        this.configData = this._lodash.getData(response, 'body.data', {});
        this._common.setAppConfig(this.configData?.[0]?.config_data ?? {});
        setTimeout(() => {
          this.patchFormValues(true);
        });

        if (this.selectedIndex === configurationStepper.kyc) {
          this._snackBar.loadSnackBar(msg, colorCodes.SUCCESS);
          this.openDialogue(response);
        } else {
          this.stepper.next();
        }
      }
    });
  }

  patchFormValues(isRefresh?: boolean) {
    if (isRefresh) {
      if (this.selectedIndex === configurationStepper.branding) {
        this.brandConfigRef.loadDataIntoForm(this.configData);
      } else if (this.selectedIndex === configurationStepper.geographical) {
        this.parseGeographicData();
        this.geoConfigRef.loadDataIntoForm(this.configData);
      } else if (this.selectedIndex === configurationStepper.localization) {
        this.localizationConfigRef.loadDataIntoForm(this.configData);
      } else if (this.selectedIndex === configurationStepper.accounting) {
        this.parseAccountingData();
        this.accountingConfigRef.loadDataIntoForm(this.configData);
      } else if (this.selectedIndex === configurationStepper.kyc) {
        this.kycConfig.loadDataIntoForm(this.configData);
      }
    }
  }

  async fileUploadFromUrl() {
    let fileName = `${new Date().getTime()}_en.json`;
    let attachment: any = {};
    this._http
      .requestCallExternal(Endpoints?.LOCALE_EN_FILE, ApiMethod.GET, '', {
        responseType: 'blob',
      })
      .subscribe({
        next: (blob: any) => {
          const fileBlob = new File([blob], fileName, { type: blob.type });
          attachment[`${fileName}`] = fileBlob;
          this.initialDefaultLangage = {
            file: attachment,
            name: fileName,
            path: Endpoints?.LOCALE_EN_FILE,
          };
          return this.initialDefaultLangage;
        },
        error: (err: any) => {
          console.error('Error fetching file:', err);
        },
      });
  }
}
