import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CommonService } from './core/services/common/common.service';
import { Subscription } from 'rxjs';
import { BreadCrumbService } from './shared/services/bread-crumb/bread-crumb.service';
import { DOCUMENT } from '@angular/common';
import {
  APPLICATION,
  DEFAULT_LANGUAGE_CODE,
} from './core/services/utils/constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  standalone: false,
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit, OnDestroy {
  appConfig: any = APPLICATION.config;
  title = 'uspTaxStamp';
  currentUrl: any;
  userMode: any;
  paramSubscription!: Subscription;
  routeSubscription!: Subscription;
  enableMultiLingual: boolean = environment.enableMultiLingual;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private router: Router,
    private route: ActivatedRoute,
    private _common: CommonService,
    public _breadCrumb: BreadCrumbService
  ) {}

  ngOnInit(): void {
    if (this.enableMultiLingual) {
      const defaultLang = localStorage.getItem('lang') || DEFAULT_LANGUAGE_CODE;
      if (!localStorage.getItem('lang')) {
        localStorage.setItem(`lang`, defaultLang);
      }
    }

    // Access the mode (prefix) from the URL
    this.paramSubscription = this.route.params.subscribe((params) => {
      this.userMode = params['usermode'];
    });

    this.routeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) this.currentUrl = event.url;
      if (event instanceof NavigationEnd) this.handleRouteChange(event.url);
    });

    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.onload = () => {
      console.log('checkout js loaded');
    };
    this._document.head.appendChild(script);
  }

  ngOnDestroy(): void {
    this.paramSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  handleRouteChange(url: string): void {
    this._breadCrumb.isOnboardScreen =
      url?.includes('/onboard-scp') || url?.includes('setup-configuration');
    console.log(
      this._breadCrumb.isOnboardScreen,
      ' this._breadCrumb.isOnboardScreen'
    );
  }
}
