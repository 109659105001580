<section class="entity-add-edit-page">
    <div class="entity-card-container d-flex flex-column">
        <!-- header part -->
        <div class="entity-header d-flex flex-column justify-content-center">
            <div class="container entity-title">
                <div class="d-flex flex-row justify-content-between align-items-center">
                   
                    
                    <label class="text-black fw-600 fs-20"
                        [innerHtml]="
                majorDialogData?.header?.title
                    ? majorDialogData?.header?.title 
                    : staticText?.comments?.title
                    ">
                    </label>
                    <img class="cursor-pointer" src="./assets/images/common/icons/close_black.svg" alt="close"
                        (click)="close()" />
                </div>
            </div>
        </div>
        <!-- body part -->
       
@if (majorDialogData?.messagetxt?.message) {
    <div  class="px-4 py-3 mb-2 sts_data">
        <p class="py-2 mb-1">{{majorDialogData?.messagetxt?.message}}
            </p>
        </div>
        }


        <div class="entity-body">
            <div [innerHTML]="majorDialogData?.template"></div>
            <div class="add-comments-content row-cols-1 px-4 py-4">
                @if (majorDialogData?.enableComments) {
                <div class="col comments-input fs-16">
                    <custom-text-area [inputTextAreaClass]="'width-top example-full-width'"
                        [inputTextAreaMaxLength]="1000" [inputTextAreaFormGroup]="commentsForm"
                        [inputTextAreaAppearance]="" inputTextAreaFormControlName="comments"
                        [inputTextAreaRequiredStatus]="true" [inputTextAreaPlaceholderLabel]="staticText?.comments?.Add"
                        [inputTextAreaMinRowSize]="3"
                        [inputTextAreaErrorLabel]="staticText?.comments?.title + ' ' + staticText?.common?.is_required">
                    </custom-text-area>
                </div>
                }
                @if (majorDialogData?.enableAttachment) {
                @if (majorDialogData?.multiFileUpload) {
                <div class="col-md-7 py-4">
                    <app-custom-multi-fileupload [inputFileFormGroup]="commentsForm" [disableFileUpload]="disableForm"
                        [inputFileFormControlName]="multi_attachments"
                        [inputFileAcceptExtenstions]="'.jpg, .png, .doc, .ppt, .pdf'"
                        [inputFileLabel]="staticText?.comments?.attachment"
                        [inputFilePlaceholderLabel]="staticText?.comments?.attachment" [assignFileLink]="filePath"
                        [fileName]="showAttachment" [customDownloadDelete]="true"
                        (inputFileOnSelect)="multiSelectFile($event, commentsForm, multi_attachments)">
                    </app-custom-multi-fileupload>
                </div>
                }@else {
                <div class="col-md-9 py-4">
                    <custom-file-attachment [inputFileFormGroup]="commentsForm" inputFileFormControlName="attachment"
                        [inputFileAcceptExtenstions]="'.jpg, .png, .doc, .ppt, .pdf'"
                        
                        [inputFileUploadLabel]="staticText?.comments?.attachment" [assignFileLink]="filePath"
                        [fileName]="showAttachment" [customDownloadDelete]="true"
                        (inputFileOnSelect)="selectFile($event)">
                    </custom-file-attachment>
                </div>
                }
                }
            </div>
        </div>
        <!-- footer part -->
        <div class="major-footer">

            <div class="major-modal-actions  cursor-pointer p-3">
                <div class="major-modal-close-button px-2">
                    <button class="btn_default_user" mat-stroked-button [innerHtml]="
                  majorDialogData?.footer?.cancelBtnTxt || staticText?.common?.cancel_button
                " (click)="close()"></button>
                </div>
                <div class="major-modal-event-button px-2">
                   
                    <button mat-raised-button class="btn_comments_user"
                        [ngStyle]="majorDialogData?.footer?.submitBtnStyle || { 'background-color': '#0061f7', 'color': '#ffffff' }"(click)="addComments()">
                        <div>
                       
                            <span
                                  [innerHtml]="
          majorDialogData?.footer?.submitBtnTxt
            ? majorDialogData.footer.submitBtnTxt  +  ' Request'
            : staticText?.comments?.add_btn
        "></span>

                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>