import { CommonModule, DatePipe } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/core/services/http/http.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { LodashService } from 'src/app/core/services/lodash/lodash.service';
import { RouterService } from 'src/app/core/services/router/router.service';
import { CustomTableComponent } from 'src/app/shared/components/custom-table/custom-table.component';
import { BreadCrumbService } from 'src/app/shared/services/bread-crumb/bread-crumb.service';
import { CommonService } from 'src/app/core/services/common/common.service';
import { CustomTableService } from 'src/app/shared/services/custom-table/custom-table.service';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { SharedModule } from 'src/app/shared/shared.module';

import {
  ApiMethod,
  Endpoints,
  FORMAT,
  PagePermssions,
  registration,
  scp,
} from 'src/app/core/services/utils/constants';
import { forkJoin, map, Observable, Subject, takeUntil } from 'rxjs';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { SCP_MANEGEMENT } from '../scp-table-config/table-schema-model';
@Component({
  selector: 'app-scp-list',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './scp-list.component.html',
  styleUrl: './scp-list.component.scss',
})
export class ScpListComponent {
  @ViewChild(CustomTableComponent) cTable!: CustomTableComponent;
  @Input() searchtext: string = '';
  public destroy = new Subject<void>();
  totalRecords = 0;

  staticText: any = TranslationService.staticTextData;

  scpEnum: any = scp;
  scpList: any[] = [];
  startRow: number =
    this.staticText?.common?.custom_table_properties
      ?.custom_table_pagination_block?.custom_table_page_size?.startRow;
  endRow: number =
    this.staticText?.common?.custom_table_properties
      ?.custom_table_pagination_block?.custom_table_page_size?.endRow;
  columnDefs: any = SCP_MANEGEMENT?.table_fields;
  filterParamValues: any;
  tableFields: any;
  permission: any = PagePermssions;
  actionPermissions = {};
  lastTwoColFreezed: boolean = true;

  tableHeader: any = {
    multi: false,
    model: false,
    multiSelect: false,
  };
  filterSource: any = null;
  filterKeys: any = null;

  data: any[] | undefined;
  searchForm: FormGroup | any;
  statusList: any;
  licenseList: any;
  pageInfo: any = {};


  private destroy$ = new Subject<void>();
  onboardStatus: any;
  registerStatus:any;
  constructor(
    private _http: HttpService,
    private _common: CommonService,
    public _routeService: RouterService,
    private _loader: LoaderService,
    private _modal: ModalService,
    private _lodash: LodashService,
    private _tableSerivce: CustomTableService,
    private formbuilder: FormBuilder,
    private activated_route: ActivatedRoute,
    private breadcrumbService: BreadCrumbService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.lastTwoColFreezed = true;
    this.pageInfo = this.activated_route?.data;

    if (this.pageInfo.value.mode == this.scpEnum.registration) {
      this.tableFields = SCP_MANEGEMENT?.table_fields;
      this.filterSource = SCP_MANEGEMENT?.table_top_filter;
      this.filterKeys = [
        {
          key: 'license_type',
          source: [],
          sourceKey: 'id',
          display_key: 'display_name',
        },
        {
          key: 'status',
          source: [],
          sourceKey: 'id',
          display_key: 'action_complete_label',
        },
        { key: 'date_range' },
      ];
      this.actionPermissions = {
        view: [this.permission.VIEW_SCP, this.permission.VIEW_ONBOARD],
        edit: [this.permission.CHANGE_SCP, this.permission.CHANGE_ONBOARD],
        delete: [''],
      };
      this.mergeStatusCall();
    } else if (this.pageInfo.value.mode == this.scpEnum.scp_list) {
      this.lastTwoColFreezed = false;
      this.tableFields = SCP_MANEGEMENT?.scp_list_table_fields;
      this.filterSource = SCP_MANEGEMENT?.scp_list_table_top_filter;
      this.filterKeys = [
        {
          key: 'company',
          source: [],
          sourceKey: 'company_id',
          display_key: 'company_name',
        },
        {
          key: 'license_type',
          source: [],
          sourceKey: 'id',
          display_key: 'display_name',
        },
        { key: 'date_range' },
      ];
      this.actionPermissions = {
        view: [this.permission.VIEW_ONBOARD],
        edit: [''],
        delete: [''],
      };
      this.getCompany();
    }

    this.initialDependencies();
    this.getLicense();
    this.breadcrumbService.breadCrumbView(false);
  }

  private mergeStatusCall() {
    this.mergeStatus().subscribe((response: any) => {
      this.statusList = response || [];
      this.filterKeys[1]['source'] = this.statusList;
      this._common?.filterSourceConstructor(this.filterSource, this.filterKeys);
    });
  }

  getColumnSearch(): any {
    return this.cTable?.searchObj;
  }

  private constructFilterParams(): any {
    return { ...this.filterParamValues, ...this.getColumnSearch() };
  }

  keyWordSearchFilter(ev: any) {
    this.resetPagination();
    let param: any = this.constructFilterParams();
    let result: any = this._common.filterParamsConstructor(
      param,
      this.startRow,
      this.endRow
    );
    this.getScpList(result);
  }

  searchKeyword(data: any) {
    this.resetPagination();
    let param: any = this.constructFilterParams();
    this.getScpList(
      this._common.filterParamsConstructor(param, this.startRow, this.endRow)
    );
  }

  getSearchFormVal() {
    return this.searchForm?.get('keyword')?.value ?? '';
  }

  loadRoleList() {
    this.keyWordSearchFilter(this.getSearchFormVal());
  }

  initialDependencies() {
    this._common?.filterSourceConstructor(this.filterSource, this.filterKeys);
    if (!this.searchtext) {
      this.loadRoleList();
    }

    this.initiateForm();
  }

  initiateForm() {
    this.searchForm = this.formbuilder.group({
      keyword: [''],
    });
  }

  resetPagination() {
    this._tableSerivce.currentPage = 1;
    this.startRow =
      this.staticText?.common?.custom_table_properties?.custom_table_pagination_block?.custom_table_page_size?.startRow;
    this.endRow =
      this._tableSerivce.pageSize ||
      this.staticText?.common?.custom_table_properties
        ?.custom_table_pagination_block?.custom_table_page_size?.endRow;
  }

  filterParams(data?: any) {
    this.resetPagination();
    this.filterParamValues = data?.value;
    let param: any = this.constructFilterParams();
    let result: any = this._common.filterParamsConstructor(
      param,
      this.startRow,
      this.endRow
    );
    this.getScpList(result);
  }

  sortColumn(data: any) {
    this.filterParamValues = data;
    let param: any = this.constructFilterParams();
    let result: any = this._common.filterParamsConstructor(
      param,
      this.startRow,
      this.endRow
    );
    this.getScpList(result, true);
  }

  pageChange(evt: any) {
    this.startRow = evt?.startValue;
    this.endRow = evt?.endValue;
    let param: any = this.constructFilterParams();
    let result: any = this._common.filterParamsConstructor(
      param,
      this.startRow,
      this.endRow
    );
    this.getScpList(result);
  }

  updateRoleStatus(roleObj: any, clonedRoleList: any) {
    this._modal
      .openWarningDialog({
        data: {
          paragraph: `<p class="fs-14">Selected roles are mapped to users and will be no longer active.</p>`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this._loader.show();
          let endpoint: any = Endpoints.GET_SCP_LIST + roleObj.id + '/';
          let payload = {
            is_active: roleObj.is_active,
          };
          this._http.requestCall(endpoint, ApiMethod.PATCH, payload).subscribe(
            (response: any) => {
              this.resetList(this.scpList);
              this._loader.hide();
            },
            (error: any) => {
              this.resetList(clonedRoleList);
              this._loader.hide();
            }
          );
        } else {
          this.resetList(clonedRoleList);
        }
      });
  }

  resetList(list: any) {
    this.scpList = list;
    this.cTable.refreshTableData(list, false, this.startRow);
  }

  buildSitePopover(data: any): any {
    let listOfSites = ``;
    if (!data?.sites?.length) return listOfSites;
    for (let index = 0; index < data?.sites.length; index++) {
      const element = data?.sites[index];
      listOfSites += `<span class="site-list-container">${element}</span><br>`;
    }
    return `<div>${listOfSites}</div>`;
  }

  buildSitetemplate(data: any) {
    let listOfSites = ``;
    if (!data?.sites?.length) return listOfSites;

    return (
      `<span>${data?.sites?.length} sites</span>` +
      `<span class="site-ellipsis position-relative align-items-center cursor-pointer d-inline-flex mx-3">
            <i class="fa fa-ellipsis"></i>
          </span>`
    );
  }

  private mapScpListData(data: any): any[] {
    return data?.records?.map((data: any) => ({
      ...data,
      tin: data?.tin_number,
      business_site: this.buildSitetemplate(data),
      business_site_template: this.buildSitePopover(data),
      registration_code: data?.registration_code || data?.code || '',
      company_name: data?.company_name,
      created_by: data?.created_by || '',
      created_on: this.datePipe?.transform(data.created_on, FORMAT.DATE_TIME),
      last_updated_on: this.datePipe?.transform(
        data.last_updated_on,
        FORMAT.DATE_TIME
      ),
      license_type:
        data?.license_type?.display_name ??
        data?.license_type_display_name ??
        '',

      status: {
        color_code: data?.status?.color_code || '#000',
        text_color_code: data?.status?.text_color_code || '#FFFFFF',
        label: data?.status?.action_complete_label || '-',
        class: 'status_align',
      },
      id:
        this.pageInfo.value.mode == this.scpEnum.registration
          ? data?.id
          : data?.company,
      route: [{ mode: 'delete', path: data.id, status: true }],
    }));
  }

  private mapSerialNumberAndRoutePath() {
    this._common.mapSerialNumberAndRoutePath(
      this['startRow'] || 0,
      this.scpList,
      [
        {
          status: true,
          route: '/users/view/',
          routeStatus: false,
          tooltip: 'view',
        },
        {
          status: true,
          mode: 'view',
          route: 'edit/',
          tooltip: 'view',
          routeStatus: false,
          icon: 'view_action',
        },
        {
          status: true,
          route: 'edit',
          mode: 'edit',
          routeStatus: false,
          tooltip: 'edit',
          modalStatus: true,
        },
      ],
      false
    );
  }

  getScpList(params?: any, sorting: boolean = false) {
 
    this._loader.show();
    let endpoint: any = '';
    if (this.pageInfo.value.mode == this.scpEnum.registration) {
      endpoint = Endpoints.GET_SCP_LIST + params;
    } else if (this.pageInfo.value.mode == this.scpEnum.scp_list) {
      endpoint = Endpoints.ONBOARD_SCP_USER + `${params}&is_scp_list=true`;
    } else return;
    this._http
      .requestCall(endpoint, ApiMethod.GET)
      .subscribe((apiResponse: any) => {
        let response = apiResponse.data || {};
        this._loader.hide();
        this.onboardStatus = response?.additional_data?.onboard?.status_count;
        this.registerStatus = response?.additional_data?.self_registration?.status_count;

        console.log(this.registerStatus, "registerStatus");
      

        this.totalRecords = response?.total_records;
        this.scpList = this.mapScpListData(response);
        this.cTable.setTableTotalRecordsCnt(this.totalRecords);
        this.cTable.refreshTableData(this.scpList, sorting, this.startRow);
        this.mapSerialNumberAndRoutePath();
        this.scpList?.forEach((element: any) => {
          element.route?.forEach((routeData: any) => {
            if (routeData?.mode === 'edit')
              routeData['hidden'] = !element?.allow_edit;
            if (routeData?.mode === 'delete') routeData['hidden'] = false;
            if (routeData?.icon == 'view_action') routeData['hidden'] = false;
          });
        });
      });
  }

  onCustomAction(actionData: any) { }

  editRole(ev: any) {
    if (ev?.action?.mode == 'view' || ev?.action?.icon == 'view_action') {
      if (this.pageInfo.value.mode == this.scpEnum.scp_list)
        this._routeService.navigatePages(
          '/scp-mgmt/view-onboarding-status/' + ev.Data.id + '/3/'
        );
      else if (ev?.Data?.onboard_exists)
        this._routeService.navigatePages(
          '/scp-mgmt/view-onboarding-status/' + ev.Data.id + '/2/'
        );
      else
        this._routeService.navigatePages(
          '/scp-mgmt/view-status/' + ev.Data.id + '/1/'
        );
    } else {
      if (this.pageInfo.value.mode == this.scpEnum.scp_list)
        this._routeService.navigatePages(
          '/scp-mgmt/onboard-scp/' + ev.Data.id + '/'
        );
      else if (ev?.Data?.onboard_exists)
        this._routeService.navigatePages(
          '/scp-mgmt/onboard-scp/' + ev.Data.id + '/'
        );
      else
        this._routeService.navigatePages(
          '/scp-mgmt/edit-registration/' + ev.Data.id + '/'
        );
    }
  }

  /**
   * @description
   * Table column wise search emitter...............
   * @param ev
   */
  columnSearch(ev: any) {
    this.resetPagination();
    let param: any = this.constructFilterParams();
    let result: any = this._common.filterParamsConstructor(
      param,
      this.startRow,
      this.endRow
    );
    this.getScpList(result);
  }

  /**DROPDOWN API USING  */

  getCompany() {
    let endpoint: any = '';
    endpoint = Endpoints.GET_DROPDOWN_COMPANY;
    this._http
      .requestCall(endpoint, ApiMethod.GET)
      .subscribe((response: any) => {
        this.filterKeys[0]['source'] = response?.data || [];
        this._common?.filterSourceConstructor(
          this.filterSource,
          this.filterKeys
        );
      });
  }

  private buildEndpoint(base: string, param?: any): string {
    return param ? `${base}${param}` : base;
  }

  private makeRequest(endpoint: any, method: ApiMethod): Observable<any> {
    return this._http.requestCall(endpoint, method).pipe(
      map((e: any) => e),
      takeUntil(this.destroy$)
    );
  }

  private getRegistrationSattus() {
    let endpoint: any = Endpoints.GET_DROPDOWN_STATUS;
    return this.makeRequest(endpoint, ApiMethod.GET);
  }

  private getOnboardingStatus() {
    let endpoint: any = Endpoints.GET_ONBOARD_DROPDOWN_STATUS;
    return this.makeRequest(endpoint, ApiMethod.GET);
  }

  private dataMerge(response: any): any {
    return [
      {
        name: 'registration',
        action_complete_label: 'Registration',
        groupData: response?.registration?.data,
      },
      {
        name: 'onboarding',
        action_complete_label: 'Onboarding',
        groupData: response?.onboarding?.data,
      },
    ];
  }

  private mergeStatus() {
    const obj = {
      registration: this.getRegistrationSattus(),
      onboarding: this.getOnboardingStatus(),
    };
    return forkJoin(obj).pipe(
      map((response: any) => this.dataMerge(response)),
      takeUntil(this.destroy$)
    );
  }

  getLicense() {
    let endpoint: any = Endpoints.GET_DROPDOWN_LICENSE;
    this._http
      .requestCall(endpoint, ApiMethod.GET)
      .subscribe((response: any) => {
        this.licenseList = response?.data || [];

        if (
          this.pageInfo.value.mode == this.scpEnum.registration ||
          this.pageInfo.value.mode == this.scpEnum.onboard
        ) {
          this.filterKeys[0]['source'] = this.licenseList;
        } else {
          this.filterKeys[1]['source'] = this.licenseList;
        }
        this._common?.filterSourceConstructor(
          this.filterSource,
          this.filterKeys
        );
      });
  }
  convertToRgba(color: string | undefined, alpha: number): string {
    if (!color) return '';
if (color.startsWith('#')) {
      const hex = color.replace('#', '');
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;

      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    const match = color.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    if (match) {
      const [_, r, g, b] = match.map(Number);
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    return 'rgba(0, 0, 0, 0.1)';
  }

}
