<div class="d-flex align-items-center" style="margin-left: 7px;">
    @if (nextAction?.next_states?.length > 1) {
    <div [matMenuTriggerFor]="actionList" class="d-flex align-items-center custom-action-changes">
        <div>Actions</div>
        <!-- <div class="mx-2">|</div> -->
        <div class="border_arw">
            <i class="fa fa-chevron-down"></i>
        </div>
    </div>
    <mat-menu #actionList="matMenu">
        @for (action of actionOptions; track action) {
        <button class="action_btn" mat-menu-item (click)="doAction(action?.value)" [style.color]="action?.color_code">
            <!-- <span [innerHTML]="action?.icon"></span> -->
             <!-- <img src="../../../../assets/../assets/images/common/icons_usp/{{action?.icon}}"/> -->
            <img src="../../../../assets/images/common/icons_usp/{{action?.icon}}.svg" alt="Icon" />

            <span class="mx-2"></span>
            <span>{{action?.name}}</span>
            <span class="mx-2"></span>
        </button>
        }
    </mat-menu>
    }

    <!-- accept/appeal/dispatch/receive or preview completed -->
    <ng-container *ngIf="nextAction?.next_states?.length == 1">
        <button *ngFor="let a of actionOptions" mat-raised-button class="next-btn create-default-style mx-2"
            [ngClass]="a?.value" (click)="doAction(a?.value)">
            <div>
                <span class="fw-bold btn-text">{{a?.name}}</span>
            </div>
        </button>
    </ng-container>
</div>