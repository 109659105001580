<!-- Header section .................-->
<header>
  <div class="view_header view_header_flx">
    <div class="d-flex">
      <div class="me-2 cursor-pointer" (click)="close()">
        <i class="fa fa-arrow-left left_arw"></i>
      </div>
      @if (pageMode == screenMode?.VIEW || pageMode == screenMode?.PREVIEW) {
      <!-- for registration -->
      @if (!scpHeaderInfo?.onboard_exists &&
      !scpHeaderInfo?.allow_onboard_initiation && operationType ==
      screenMode?.REGISTRATION) {
      <div *ngxPermissionsExcept="[permission.UPDATE_SCP_STATUS]">
        {{ staticText?.scp?.view_register?.title }}
      </div>
      <div *ngxPermissionsOnly="[permission.UPDATE_SCP_STATUS]">
        <div class="view_title_font">
          <span>{{ staticText?.scp?.view_register?.approver_title }}</span>
          <span class="primary_txt_clr">
            {{ registrationData?.validation_info?.registration_code }}</span>
        </div>
        <div class="fs-15 d-flex mt-2 flx_col">
          <div class="cursor-pointer" (click)="close()">
            <span class="registration_txt">{{
              staticText?.scp?.view_register?.self_reg
              }}</span>
          </div>
          <div class="mx-1 d_none_sm"><span> / </span></div>
          <div>
            <span class="font_family_thin">{{ staticText?.scp?.view_register?.application }} -
              {{ registrationData?.validation_info?.registration_code }}</span>
          </div>
        </div>
      </div>
      <!-- for onboarding -->
      }@else if(scpHeaderInfo?.onboard_exists && operationType ==
      screenMode?.ONBOARDING){
      <div *ngxPermissionsExcept="[permission.UPDATE_SCP_ONBOARD_STATUS]">
        {{ staticText?.scp?.view_register?.onboard_title }}
      </div>
      <div *ngxPermissionsOnly="[permission.UPDATE_SCP_ONBOARD_STATUS]">
        <div class="view_title_font">
          <span>{{
            staticText?.scp?.view_register?.onboard_approver_title
            }}</span>
          <span class="primary_txt_clr">
            {{ registrationData?.validation_info?.registration_code }}</span>
        </div>
        <div class="fs-15 d-flex mt-2 flx_col">
          <div class="cursor-pointer" (click)="close()">
            <span class="registration_txt">{{
              staticText?.scp?.view_register?.onboard_self_reg
              }}</span>
          </div>
          <div class="mx-1 d_none_sm"><span> / </span></div>
          <div>
            <span class="font_family_thin">{{ staticText?.scp?.view_register?.application }} -
              {{ registrationData?.validation_info?.registration_code }}</span>
          </div>
        </div>
      </div>
      }@else {
      <div class="view_title_font">{{ staticText?.scp?.view_register?.onboard_title }}</div>
      } }@else if (pageMode == screenMode?.EDIT) {
      <div class="view_title_font">{{ staticText?.scp?.view_register?.edit_title }}</div>
      }
    </div>
    <div class="ms-2 me-0 btn_clse d_flx_gap">
      @if (pageMode == screenMode?.VIEW) {
      <div class="align-items-center d-flex right-side-action">
        <div class="mx-2 status-history cursor-pointer" (click)="navigateToHistory()">
          <i class="fa fa-history mx-1"></i>
          <span>{{staticText?.common?.audit_log}}</span>
        </div>
      </div>

      @if (!scpHeaderInfo?.onboard_exists &&
      !scpHeaderInfo?.allow_onboard_initiation && scpHeaderInfo?.allow_edit &&
      operationType == screenMode?.REGISTRATION) {
      <!-- for registration -->

      <ng-container *ngxPermissionsOnly="[permission.CHANGE_SCP]">
        <button class="primary-outline-default-style" mat-stroked-button (click)="edit()">
          <span class="mx-1"><img src="../../../../../assets/images/common/icons/edit_table.svg" alt="" /></span>
          <span>{{ staticText?.scp?.view_register?.edit }}</span>
        </button>
      </ng-container>
      }@else if(scpHeaderInfo?.onboard_exists && scpHeaderInfo?.allow_edit &&
      operationType == screenMode?.ONBOARDING){
      <!-- for onboarding -->
      <ng-container *ngxPermissionsOnly="[permission.CHANGE_ONBOARD]">
        <button class="primary-outline-default-style" mat-stroked-button (click)="editOnboarding()">
          <span class="mx-1"><img src="../../../../../assets/images/common/icons/edit_table.svg" alt="" /></span>
          <span>{{ staticText?.scp?.view_register?.edit }}</span>
        </button>
      </ng-container>
      } }@else if (pageMode == screenMode?.EDIT) {
      <ng-container *ngxPermissionsOnly="[permission.VIEW_SCP]">
        <button class="primary-outline-default-style" mat-stroked-button (click)="view()">
          <span class="mx-1"><img src="../../../../../assets/images/common/icons/view_table.svg" alt="" /></span>
          <span>{{ staticText?.scp?.view_register?.view }}</span>
        </button>
      </ng-container>
      }
    </div>
  </div>
</header>
<!--................. Header section -->
<!-- Container section .................-->
@if (registrationData) {
<section class="users-view-edit-page section-wrapper mt-2 mb-4">
  <mat-card class="card-default-style card_style p-0 mx-4">
    @if (pageMode == screenMode?.VIEW || pageMode == screenMode?.PREVIEW) {
    @if (pageMode == screenMode?.VIEW) {
    <!-- header preview section .................-->
    <div class="row mx-0 bg_scp_registration">
      <div class="details-blk col-xl-9 col-lg-8 col-md-12 col-sm-12 col-12">
        <div class="fs-24 fw-bold">
          <span class="business_info">{{
            registrationData?.business_info?.name
            }}</span>
        </div>
        <div class="primary_txt_clr number_scp">
          <div class="mail_name">

            <img class="mail_icn" src="../../../../assets/images/common/icons_usp/mail 3.svg" />
            <span class="mx-1 me-0 word_break">{{
              registrationData?.business_info?.email
              }}</span>
          </div>
          <div class="mx-3 me-1 color-Orient">|</div>
          <div class="pos_relative_phone">

            <img class="phone_icn" src="../../../../assets/images/common/icons_usp/phone 1.svg" />
            <span>
              {{
              registrationData?.business_info?.country_code
              }}
              {{ registrationData?.business_info?.contact_no }}</span>
          </div>
        </div>
      </div>
      <div class="details-blk col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12">
        <div>
          <span class="address_company">{{
            staticText?.scp?.view_register?.address
            }}</span>
        </div>
        <div class="txt_font">
          <span class="line_break">{{
            registrationData?.business_info?.address
            }}</span>
        </div>
      </div>

      <div class="details-blk col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
        <div>
          <span class="address_company">{{
            appConfig?.business_information?.business_indentification_label ??
            staticText?.scp?.view_register?.tin_number
            }}</span>
        </div>
        <div class="txt_font">
          <span>{{ registrationData?.validation_info?.tin_number }}</span>
        </div>
      </div>
      <div class="details-blk col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
        <div>
          <span class="address_company">{{
            staticText?.scp?.view_register?.lic_type
            }}</span>
        </div>
        <div class="txt_font">
          <span>{{
            registrationData?.business_info?.license_type?.display_name
            }}</span>
        </div>
      </div>
      <div class="details-blk col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
        <div>
          <span class="address_company">{{
            staticText?.scp?.view_register?.date_of_incorporation
            }}</span>
        </div>
        <div class="txt_font">
          <span>{{
            registrationData?.business_info?.date_of_incorporation
            | date : "dd/MM/yyyy"
            }}</span>
        </div>
      </div>
      <div class="details-blk col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
        <div>
          <span class="address_company">{{
            staticText?.scp?.view_register?.company_ownership
            }}</span>
        </div>
        <div class="txt_font">
          <span>{{
            registrationData?.business_info?.ownership?.display_name
            }}</span>
        </div>
      </div>

      <!-- location ............... -->
      @for (item of viewLocationDetails; track $index) {
      <div class="details-blk col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
        <div class="text-capitalize">
          <span class="address_company">{{ item.display_name }}</span>
        </div>
        <div class="txt_font">
          <span>{{ item?.concatName }}</span>
        </div>
      </div>
      }
      <!--...............  location -->
    </div>
    <!--................. header preview section -->
    }
    <!-- stepper section .................-->
    <mat-tab-group class="view-prop-mat onboarding_tab view-registration" [(selectedIndex)]="selectedIndex"
      (selectedTabChange)="tabChanged($event)">
      @if (pageMode == screenMode?.VIEW) {
      <!-- License .................. -->
      <mat-tab>
        <ng-template mat-tab-label> {{
          staticText?.scp?.view_register?.mat_tab_field1
          }} </ng-template>

        @if(registrationData){
        <app-dynamic-form [sections]="formConfig" [loadMasterAPI]="loadMasterAPI" [apiEndpoint]=""
          [apiData]="registrationData" [disableForm]="disableForm" [mode]="mode">
        </app-dynamic-form>
        }
      </mat-tab>
      <!-- .................. License -->
      }

      @if (scpHeaderInfo?.onboard_exists && operationType == screenMode?.ONBOARDING) {

      <ng-container *ngxPermissionsOnly="[permission.VIEW_ONBOARD]">
        <!-- Business sites .................. -->
        <mat-tab>
          <ng-template mat-tab-label> {{
            staticText?.scp?.view_register?.mat_tab_field2
            }} </ng-template>

          <div>
            @for (item of siteInfoConfig; track item) {
            <div class="site-accordion" [ngClass]="{ active: item.expand }">
              <div class="d-flex fs-20 my-2 cursor-pointer border_btm_accordion"
                (click)="expandSiteDetails(item, siteInfoConfig)">
                <div class="mx-2 primary_txt_clr">
                  @if (item.expand) {
                  <i class="fa fa-minus"></i>
                  }@else {
                  <i class="fa fa-plus"></i>
                  }
                </div>
                <div>
                  <span>{{ item?.name }}</span>
                </div>
                @if (hasEditOnboarding && scpHeaderInfo?.allow_edit) {
                <div class="section-edit" (click)="editOnboarding(0)" *ngxPermissionsOnly="[permission.CHANGE_SCP]">
                  <i class="fa fa-edit cursor-pointer mx-2"></i>
                  <span>Edit</span>
                </div>
                }
              </div>
              @if (item.expand) {
              <div class="site-container">
                <app-dynamic-form [sections]="item.section" [apiData]="item?.data" (formSubmit)="handleSiteInfo($event)"
                  [disableForm]="disableForm" [mode]="item?.mode">
                </app-dynamic-form>
              </div>
              }
            </div>
            }
          </div>
        </mat-tab>
        <!-- .................. Business sites -->

        <!-- Users .................. -->
        <mat-tab>
          <ng-template mat-tab-label> {{
            staticText?.scp?.view_register?.mat_tab_field3
            }} </ng-template>

          <div>
            <div class="d-flex align-items-center pt-3">
              @if (hasEditOnboarding && scpHeaderInfo?.allow_edit) {
              <div class="section-edit" (click)="editOnboarding(3)" *ngxPermissionsOnly="[permission.CHANGE_SCP]">
                <i class="fa fa-edit cursor-pointer mx-2"></i>
                <span>{{staticText?.common?.edit}}</span>
              </div>
              }
            </div>
            @if(onboardData) {
            <app-dynamic-form [refID]="editId" [sections]="userInfoConfig" #userInfo [disableForm]="disableForm"
              [apiData]="onboardData" [mode]="'view'">
            </app-dynamic-form>
            }
          </div>
        </mat-tab>
        <!-- .................. Users -->

        <!-- Supply chain .................. -->
        <mat-tab>
          <ng-template mat-tab-label> {{
            staticText?.scp?.view_register?.mat_tab_field4
            }} </ng-template>

          <div>
            <div class="d-flex align-items-center pt-3">
              @if (hasEditOnboarding && scpHeaderInfo?.allow_edit) {
              <div class="section-edit" (click)="editOnboarding(2)" *ngxPermissionsOnly="[permission.CHANGE_SCP]">
                <i class="fa fa-edit cursor-pointer mx-2"></i>
                <span>Edit</span>
              </div>
              }
            </div>
            @if(onboardData) {
            <app-dynamic-form [refID]="editId" [sections]="supplyChainInfoConfig" #supplierChainInfo
              [apiData]="onboardData" [disableForm]="disableForm" [mode]="'view'">
            </app-dynamic-form>
            }
          </div>
        </mat-tab>
        <!-- .................. Supply chain -->

        <!-- Bank Details .................. -->
        <mat-tab>
          <ng-template mat-tab-label> {{
            staticText?.scp?.view_register?.mat_tab_field5
            }} </ng-template>

          <div>
            <div class="d-flex align-items-center pt-3">
              @if (hasEditOnboarding && scpHeaderInfo?.allow_edit) {
              <div class="section-edit" (click)="editOnboarding(1)" *ngxPermissionsOnly="[permission.CHANGE_SCP]">
                <i class="fa fa-edit cursor-pointer mx-2"></i>
                <span>Edit</span>
              </div>
              }
            </div>
            @if(onboardData) {
            <app-dynamic-form #bankInfo [sections]="bankInfoConfig" [disableForm]="disableForm" [mode]="'view'"
              [apiData]="onboardData" [processAttachment]="true">
            </app-dynamic-form>
            }
          </div>
        </mat-tab>
        <!-- .................. Bank Details -->

        <!-- Other Information .................. -->
        <mat-tab>
          <ng-template mat-tab-label> {{
            staticText?.scp?.view_register?.mat_tab_field_6
            }} </ng-template>

          <div>
            <div class="d-flex align-items-center pt-3">
              @if (hasEditOnboarding && scpHeaderInfo?.allow_edit) {
              <div class="section-edit" (click)="editOnboarding(4)" *ngxPermissionsOnly="[permission.CHANGE_SCP]">
                <i class="fa fa-edit cursor-pointer mx-2"></i>
                <span>Edit</span>
              </div>
              }
            </div>
            @if(onboardData) {
            <app-dynamic-form [sections]="otherInfoConfig" #otherInfo [disableForm]="disableForm"
              [apiData]="otherInfoData" [mode]="'view'" [processAttachment]="true">
            </app-dynamic-form>
            }
          </div>
        </mat-tab>
        <!-- .................. Other Information -->

        <!-- Inspection .................. -->
        <mat-tab>
          <ng-template mat-tab-label> {{
            staticText?.scp?.view_register?.mat_tab_field_7
            }} </ng-template>

          <div class="w-100 flex_no_data">
            <div class="fs-24 mx-4">
              <span><img class="title-card" src="../../../../assets/images/common/icons_usp/no-data.png"
                  alt="info" /></span>
            </div>
            <div class="no_data">
              <span>{{
                staticText?.scp?.view_register?.table_no_record_msg
                }}</span>
              <br />
              <span>{{
                staticText?.scp?.view_register?.assign_msg
                }}</span>
            </div>
          </div>
        </mat-tab>
        <!-- .................. Inspection -->
      </ng-container>

      }
    </mat-tab-group>
    <!--................. stepper section -->

    <!--................. Container section -->
    <!-- Comment section .................-->
    <div *ngxPermissionsOnly="[permission.CAN_VIEW_REGISTRATIONCOMMENT]">
      @if (selectedIndex == 0 && pageMode == screenMode?.VIEW) {
      <app-custom-comments [id]="editId" [apis]="commentsApis" [permission]="commentsPermission"></app-custom-comments>
      }
    </div>
    <div *ngxPermissionsOnly="[permission.CAN_VIEW_ONBOARDCOMMENT]">
      @if (selectedIndex != 0 || pageMode == screenMode?.PREVIEW) {
      <app-custom-comments [id]="editId" [apis]="onboard_commentsApis"
        [permission]="onboard_commentsPermission"></app-custom-comments>
      }
    </div>
    <!--................. Comment section -->
    } @else if (pageMode == screenMode?.EDIT && registrationData) {
    <div class="section-card verified-scp-form">
      <div class="title-card d-flex align-items-center mt-3">
        <div class="fs-20 fw-bold p-3 title title_width_registration">
          <span class="section_title tin_txt">{{
            staticText?.scp?.view_register?.tin_validation
            }}</span>
        </div>
      </div>
      <div class="d-flex flex-column px-4 pb-3">
        <div class="row">
          <div class="business-verified">
            <label>
              <i class="fa fa-check-circle"></i>
              {{
              appConfig?.business_information?.business_indentification_label ??
              staticText?.scp?.view_register?.tin_verified
              }}
              <span class="fw-bold">-{{ registrationData?.validation_info?.tin_number }}</span></label>
          </div>
        </div>
      </div>
    </div>
    <app-dynamic-form [sections]="formConfig" [loadMasterAPI]="loadMasterAPI" [apiEndpoint]=""
      (formSubmit)="handleFormSubmit($event)" [apiData]="registrationData" [disableForm]="disableForm" [mode]="'edit'">
    </app-dynamic-form>
    }
  </mat-card>
</section>
}@else {
<!-- Loader start ............ -->
<div class="w-100 text-center">
  <img src="/assets/images/common/icons/table_spinner.gif" class="spinner-style" alt="FirstPage" />
</div>
<!-- ............ Loader End  -->

}


<div class="foot_height_cmn"></div>
<!-- Footer section .................-->
<div class="px-4 py-3 submit_flx guest-footer">
  <div>
    <button class="btn_default_user" mat-stroked-button (click)="close()">
      <span>{{staticText?.common?.cancel_button}}</span>
    </button>
  </div>
  @if (pageMode == screenMode?.PREVIEW) {
  <div>
    <button *ngxPermissionsOnly="[permission.CHANGE_SCP]" class="primary-outline-default-style" mat-stroked-button
      (click)="editOnboarding(4)">
      <span class="mx-1"><i class="fa fa-arrow-left"></i></span>
      <span>{{
        staticText?.scp?.view_register?.mat_tab_field_6
        }}</span>
    </button>
    @if (hasOnboardfullfill && hasEditOnboarding) {
    <button mat-raised-button class="next-btn create-default-style mx-2" (click)="saveOnboardDetails(editId)">
      <div>
        <span class="">{{staticText?.common?.submit_button}}</span>
      </div>
    </button>
    }
  </div>
  }
  @if (pageMode == screenMode?.VIEW && nextStatus?.next_states?.length) {
  <div *ngxPermissionsOnly="[
        permission.UPDATE_SCP_STATUS,
        permission.UPDATE_SCP_ONBOARD_STATUS
      ]">
    <app-next-status-action style="display: inline-block" [nextAction]="nextStatus"
      (action)="nextActionEmit($event)"></app-next-status-action>
  </div>
  }
  @if (pageMode == screenMode?.EDIT) {

  <ng-container *ngxPermissionsOnly="[permission.CHANGE_SCP]">
    <div class="ng-star-inserted rem-6">
      <button mat-raised-button="" (click)="triggerValidation()"
        class="btn_login_user mdc-button mdc-button--raised mat-mdc-raised-button mat-unthemed mat-mdc-button-base"
        mat-ripple-loader-uninitialized="" mat-ripple-loader-class-name="mat-mdc-button-ripple">
        <span class="mat-mdc-button-persistent-ripple mdc-button__ripple"></span><span class="mdc-button__label">
          <div><span class="text-light">{{staticText?.scp?.view_register?.save_application}}</span></div>
        </span><span class="mat-mdc-focus-indicator"></span><span class="mat-mdc-button-touch-target"></span>
      </button>
    </div>
  </ng-container>
  }
</div>

<!--................. Footer section -->