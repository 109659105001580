<div style="display: flex; align-items: center;">
    <span class="language_dropdown">
        <mat-nav-list class="list-item">
            <button mat-button [matMenuTriggerFor]="belowMenu" class="language-selection">
                LANGUAGE - {{ selectedLanguage.toUpperCase() }}
                <mat-icon iconPositionEnd fontIcon="arrow_drop_down"></mat-icon>
            </button>
            <mat-menu #belowMenu="matMenu">
                <button mat-menu-item *ngFor="let lang of languageCodeValue" (click)="handleLanguageSwitch(lang)"
                    [ngClass]="(lang.code == selectedLanguage) ? 'active' : '' ">
                    <span>
                        <span>{{ lang.value }}</span>
                    </span>
                </button>
            </mat-menu>
        </mat-nav-list>
    </span>
</div>