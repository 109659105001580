import { Injectable } from '@angular/core';
import { AppState } from './master.state';
import { State } from '@ngrx/store';
import { LodashService } from 'src/app/core/services/lodash/lodash.service';

@Injectable({
    providedIn: 'root'
})
export class MasterService {

    constructor(
        private _lodash: LodashService,
        private state: State<AppState>,
    ) {
    }

    getMasterDataByIds(idString: string = '', storeName: string = '', fieldName: string = '') {
        let masterData = this._lodash.getData(this.state.getValue(), 'master.' + storeName, {});
        let listData = this._lodash.getData(masterData, 'list', []);
        let self = this;
        const filterFunction = function (data: any) {
            return self._lodash.loostIncludes(idString.split(','), self._lodash.getData(data, fieldName + '.id', ''))
        }
        let resp = this._lodash.filterDataWithIncludes(listData, filterFunction) || [];
        
        return resp;
    }
}
