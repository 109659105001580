import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';

import {
  PagePermssions,
  routePath,
  SCREENMODE,
  SCP_ONBOARD_REQUEST,
  colorCodes,
  onboardingStepper,
  USERGROUPS,
  APPLICATION,
  Endpoints,
} from 'src/app/core/services/utils/constants';
import { DynamicFormComponent } from 'src/app/shared/components/dynamic-form/dynamic-form.component';
import {
  ONBOARD_SITEINFO_FORM_SECTIONS,
  ONBOARD_BANKINFO_FORM_SECTIONS,
  ONBOARD_SUPPLYINFO_FORM_SECTIONS,
  ONBOARD_USERINFO_FORM_SECTIONS,
  ONBOARD_OTHERINFO_FORM_SECTIONS,
} from '../form-config/form-config.model';
import {
  FormSection,
  postalCodeField,
} from 'src/app/shared/components/dynamic-form/form-sections.model'; // Adjust the path as needed
import { CommonService } from 'src/app/core/services/common/common.service';
import { HttpService } from 'src/app/core/services/http/http.service';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { BreadCrumbService } from 'src/app/shared/services/bread-crumb/bread-crumb.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { Observable, Subscription, map } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { LodashService } from 'src/app/core/services/lodash/lodash.service';
import { OnboardingService } from 'src/app/core/services/registration/onboarding.service';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RouterService } from 'src/app/core/services/router/router.service';
import _ from 'lodash';
import { BulkUploadService } from 'src/app/core/services/bulkUpload/bulk-upload.service';
import { TranslationService } from 'src/app/shared/services/translation.service';

@Component({
  selector: 'app-onboard-scp',
  standalone: true,
  imports: [
    DynamicFormComponent,
    CommonModule,
    SharedModule,
    NgxPermissionsModule,
  ],
  templateUrl: './onboard-scp.component.html',
  styleUrl: './onboard-scp.component.scss',
})
export class OnboardScpComponent implements OnInit, AfterViewInit, OnDestroy {
  staticText: any = TranslationService.staticTextData;
  screenMode: any = SCREENMODE;
  permission: any = PagePermssions;
  pageMode: any;
  disableForm: boolean = false;
  mode: string = 'add';
  siteInfoConfig: any[] = [];
  bankInfoConfig: FormSection[] = [];
  supplyChainInfoConfig: FormSection[] = [];
  userInfoConfig: FormSection[] = [];
  otherInfoConfig: FormSection[] = [];
  stepperOrientation!: Observable<StepperOrientation>;
  onboardData: any = null;
  otherInfoData: any = null;
  onboardDataExist: boolean = false;
  licenceType: any = null;
  collapsed: boolean = true;
  @ViewChild(MatStepper) stepper!: MatStepper;
  @ViewChild('site') site!: DynamicFormComponent;
  @ViewChildren('site') sites!: QueryList<DynamicFormComponent>;
  @ViewChild('bankInfo') bankInfo!: DynamicFormComponent;
  @ViewChild('supplierChainInfo') supplierChainInfo!: DynamicFormComponent;
  @ViewChild('userInfo') userInfo!: DynamicFormComponent;
  @ViewChild('otherInfo') otherInfo!: DynamicFormComponent;
  bankInfoMode: string = '';
  supplyChainMode: string = '';
  userMode: string = '';
  otherMode: string = '';
  prevBtnText: string = '';
  nextBtnText: string = '';
  saveDraftTxt: string = this.staticText?.common?.save_as_draft_button;
  selectedIndex: any = SCP_ONBOARD_REQUEST.SITE_DETAILS;
  stepperDetails: any = [
    {
      position: onboardingStepper.site,
      display_name: this.staticText?.common?.site,
      prevBtn: { title: '', previewBtn: false },
      nextBtn: {
        title:
          this.staticText?.scp?.onboard?.stepper?.user_info ??
          'User Information',
        previewBtn: false,
      },
    },
    {
      position: onboardingStepper.user,
      display_name: this.staticText?.common?.user,
      prevBtn: {
        title:
          this.staticText?.scp?.onboard?.stepper?.site_details ??
          'Site Details',
        previewBtn: false,
      },
      nextBtn: {
        title:
          this.staticText?.scp?.onboard?.stepper?.supply_chain_info ??
          'Supply Chain Info',
        previewBtn: false,
      },
    },
    {
      position: onboardingStepper.supply,
      display_name: this.staticText?.common?.supply,
      prevBtn: {
        title:
          this.staticText?.scp?.onboard?.stepper?.user_info ??
          'User Information',
        previewBtn: false,
      },
      nextBtn: {
        title:
          this.staticText?.scp?.onboard?.stepper?.bank_details ??
          'Bank Details',
        previewBtn: false,
      },
    },
    {
      position: onboardingStepper.bank,
      display_name: this.staticText?.common?.bank,
      prevBtn: {
        title:
          this.staticText?.scp?.onboard?.stepper?.supply_chain_info ??
          'Supply Chain Info',
        previewBtn: false,
      },
      nextBtn: {
        title:
          this.staticText?.scp?.onboard?.stepper?.other_info ??
          'Other Information',
        previewBtn: false,
      },
    },
    {
      position: onboardingStepper.others,
      display_name: this.staticText?.common?.others,
      prevBtn: {
        title:
          this.staticText?.scp?.onboard?.stepper?.other_info ??
          'Other Information',
        previewBtn: false,
      },
      nextBtn: {
        title: this.staticText?.common?.preview,
        previewBtn: true,
      },
    },
  ];
  currentStepperDetails: any = this.stepperDetails[0];
  siteIndex: number = 0;
  registerId: any;
  othersForm!: FormGroup;
  productClonePending: boolean = false;
  productionClonePending: boolean = false;
  sectionTitleConfig: any = {
    production_details: this.staticText?.common?.production ?? 'Production',
    product_details: this.staticText?.common?.product ?? 'Product',
    shipping_address:
      this.staticText?.common?.shipping_address ?? 'Shipping Address',
    billing_address:
      this.staticText?.common?.billing_address ?? 'Billing Address',
  };
  appConfig: any = APPLICATION.config;
  private subscription: Subscription | undefined;
  companyId: any;
  constructor(
    private _bulkUpload: BulkUploadService,
    public _routeService: RouterService,
    private _modal: ModalService,
    private _http: HttpService,
    private activated_route: ActivatedRoute,
    private _common: CommonService,
    private _loader: LoaderService,
    private _router: Router,
    private _snackBar: SnackbarService,
    protected breadcrumbService: BreadCrumbService,
    public breakpointObserver: BreakpointObserver,
    private _lodash: LodashService,
    private _onBoard: OnboardingService,
    private _storage: StorageService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getRouteParams();
    this._common.getAppConfig().subscribe((response: any) => {
      this.appConfig = response;
      this.bankInfoConfig = ONBOARD_BANKINFO_FORM_SECTIONS;
      this.appConfig?.bank_keys?.forEach((element: any) => {
        const alreadyExist = this.bankInfoConfig?.[0]?.fields?.some(
          (x) => x.name == element?.key_name
        );
        if (!alreadyExist) {
          this.bankInfoConfig?.[0]?.fields?.push({
            type: 'text',
            label: element?.key_value,
            name: element?.key_name,
            validators: [],
          });
        }
      });
    });

    this.bulkUploadProgress();
    this.registerId = this.activated_route.snapshot.paramMap.get('id');
    this.checkOnboardDataExist(this.registerId);
    this.createAgreeForm();
    this.siteIndex = 0;

    this.supplyChainInfoConfig = this.getSupplyChainFormConfig();
    this.userInfoConfig = ONBOARD_USERINFO_FORM_SECTIONS;
    // ONBOARD_USERINFO_FORM_SECTIONS[0].meta.bulkUpload.queryparam =
    //   this.companyId;
    this.otherInfoConfig = ONBOARD_OTHERINFO_FORM_SECTIONS;
    const selectedIndex = this.activated_route.snapshot.paramMap.get('stepper');
    if (selectedIndex) {
      this.selectedIndex = selectedIndex;
      this.onSteperChange({
        selectedIndex: this.selectedIndex,
      });
    } else {
      this.onSteperChange({
        selectedIndex: SCP_ONBOARD_REQUEST.SITE_DETAILS,
      });
    }

    this.stepperResponsiveCheck();
  }

  getRouteParams() {
    this.companyId = this.activated_route.snapshot.paramMap.get('id');
    console.log(this.companyId);
  }

  ngAfterViewInit(): void {
    // this.site?.form.get("site_info").get("name").valueChanges.subscribe((value: any) => {
    //   this.siteInfoConfig.find((x: any) => x.active == true).name = value;
    // });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  bulkUploadProgress() {
    this.subscription = this._bulkUpload.bulkUploadProgress.subscribe({
      next: (response: any) => this.bulkUploadAction(response),
    });
  }

  getSupplyChainFormConfig() {
    let formConfig: any = [];
    formConfig = _.cloneDeep(ONBOARD_SUPPLYINFO_FORM_SECTIONS);

    formConfig[0].meta.bulkUpload.queryparam = this.companyId;

    for (let i = formConfig.length - 1; i >= 0; i--) {
      const section = formConfig[i];

      if (section.key === 'supplier_info') {
        const isPostalCodeRequired =
          JSON.parse(localStorage.getItem('appConfig') ?? '{}')
            ?.geographical_classification?.is_postal_code_required || false;

        section.fields.forEach((field: any, index: number) => {
          if (
            field.name === 'supply_company' &&
            field?.meta?.onchangeCallback
          ) {
            field.meta.onchangeCallback = this.patchSCPCompanyFields.bind(this);
          } else if (field.type === 'location_field' && isPostalCodeRequired) {
            section.fields.splice(index + 1, 0, postalCodeField);
          }
        });
      }
    }

    return formConfig;
  }

  patchSCPCompanyFields(data: any, form?: any, locationRef?: any): void {
    const fieldNames = [
      'tin_number',
      'email',
      'country_code',
      'contact_no',
      'address',
      'postal_code',
    ];

    form.get('tin_number')?.disable();

    fieldNames.forEach((fieldName) => {
      const control = form.get(fieldName);
      if (control) {
        if (data[fieldName] !== undefined) {
          control.setValue(data[fieldName]); // Dynamically set the value from `data`
        }
      }
    });

    locationRef(data?.location);
  }

  bulkUploadAction(response: any) {
    this.getOnboard(this.registerId, true);
  }

  createAgreeForm() {
    this.othersForm = this.formBuilder.group({
      agree: [false],
    });
  }

  initOnboardingForm() {
    if (this.onboardDataExist) this.getOnboard(this.registerId);
    else {
      this.siteInfoConfig[0] = {
        mode: 'add',
        name: '',
        valid: false,
        active: true,
        section: this.getOnboardSiteFormConfig(this.licenceType, ''),
      };
      this.bankInfoMode = 'add';
      this.supplyChainMode = 'add';
      this.userMode = 'add';
      this.otherMode = 'add';
    }
  }

  checkOnboardDataExist(id: any) {
    this._onBoard.getSCPRegistrationHeaderInfo(id).subscribe(
      (response: any) => {
        const data = response?.data || {};
        this.licenceType = data?.license_type ?? {};
        this.onboardDataExist = data.onboard_exists || false;
        this.initOnboardingForm();
      },
      (error: any) => {
        this.initOnboardingForm();
        console.error('Error in onboard data exist check!', error);
      }
    );
  }

  removeById(idToRemove: number, options: any) {
    return options.filter((option: any) => option.name !== idToRemove);
  }

  getOnboardSiteFormConfig(
    licenseType: any,
    currentSite?: any,
    onSiteCreate: boolean = false
  ) {
    let config = [];
    let initialConfig = _.cloneDeep(ONBOARD_SITEINFO_FORM_SECTIONS);
    if (licenseType?.name?.toLowerCase() !== 'manufacturers') {
      const clonerFormConfig = initialConfig.map((x) => x);
      const formConfig = this._common.removeSectionByKey(
        clonerFormConfig,
        'production_details'
      );
      config = formConfig.map((x) => x);
    } else {
      config = initialConfig.map((x) => x);
    }

    config = this._common.includePostalCode(config);
    let siteConfig = this.getSiteConfig(onSiteCreate);

    if (currentSite) {
      siteConfig = this.removeById(currentSite, siteConfig);
    }

    this.updateOptions('billing_address', siteConfig, config);
    this.updateOptions('shipping_address', siteConfig, config);

    /* this.updateOptions('product_details', siteConfig, config);
     if (licenseType?.name == 'Manufacturer')
       this.updateOptions('production_details', siteConfig, config); */

    return config;
  }

  getSiteConfig(onSiteCreate?: boolean) {
    let siteConfig = [];
    if (this.onboardDataExist && !onSiteCreate) {
      this._onBoard.onboardingInfo.site_info.forEach(
        (element: any, index: any) => {
          siteConfig.push({
            id: element?.site_details?.name,
            name: element?.site_details?.name,
            display_name: element?.site_details?.name,
          });
        }
      );
    } else {
      siteConfig = this.siteInfoConfig.map((x) => x);
    }
    return siteConfig;
  }

  // Function to update the options for a specific key
  updateOptions(sectionKey: string, newOptions: any, config: any) {
    // Find the index of the section
    let sectionIndex = config.findIndex(
      (section: any) => section.key === sectionKey
    );

    if (sectionIndex !== -1) {
      // Deep clone using lodash's cloneDeep
      let section = _.cloneDeep(config[sectionIndex]);

      // Update section meta options and selection handler if `meta.copyDataFrom` exists
      if (section?.meta?.copyDataFrom) {
        section.meta.copyDataFrom.selectionChangeHandler =
          this.onSelectionChanged.bind(this);
        section.meta.copyDataFrom.options = newOptions;
      } else {
        console.error(
          `Meta data for copying options not found in section with key '${sectionKey}'.`
        );
      }

      // Merge the updated section back into the config
      config[sectionIndex] = section;
    } else {
      console.error(`Section with key '${sectionKey}' not found.`);
    }

    return config;
  }

  getOnboard(id: any, isRefresh: boolean = false) {
    this._loader.show();
    this._onBoard.getOnboard(id).subscribe((response: any) => {
      this._loader.hide();
      this._onBoard.onboardingInfo = response?.data;
      this.onboardData = response?.data ?? {};
      this.patchFormValues(isRefresh);
    });
  }

  patchFormValues(isRefresh?: boolean) {
    let activeIndex = 0;
    if (isRefresh)
      activeIndex =
        this.siteInfoConfig?.findIndex((x) => x.active == true) || 0;
    this.siteInfoConfig = [];
    this._onBoard.onboardingInfo.site_info.forEach(
      (element: any, index: any) => {
        if (this._lodash.isEmpty(element?.billing_address?.location))
          element.billing_address.location = '';
        if (this._lodash.isEmpty(element?.shipping_address?.location))
          element.shipping_address.location = '';
        if (this._lodash.isEmpty(element?.site_details?.location))
          element.site_details.location = '';
        if (this._lodash.isEmpty(element?.site_details?.facility_type))
          element.site_details.facility_type = '';
        this.siteInfoConfig.push({
          id: element?.site_details?.name,
          name: element?.site_details?.name,
          display_name: element?.site_details?.name,
          mode: 'edit',
          valid: true,
          active: index == activeIndex ? true : false,
          data: element,
          section: this.getOnboardSiteFormConfig(
            this.licenceType,
            element?.site_details?.name
          ),
        });

        if (isRefresh) {
          this.sites.toArray().at(index)?.loadDataIntoForm(element);
          // if (this.selectedIndex === onboardingStepper.bank) {
          //   this.sites.toArray().at(index)?.loadDataIntoForm(element);
          // }
        }
      }
    );

    this.otherInfoData = this.onboardData?.general_info;
    this.bankInfoMode = this.onboardData?.bank_info?.id ? 'edit' : 'add';
    this.supplyChainMode = this.onboardData?.supplier_info?.length
      ? 'edit'
      : 'add';
    this.userMode = this.onboardData.user_info?.length ? 'edit' : 'add';
    this.otherMode = this.onboardData?.general_info?.id ? 'edit' : 'add';

    if (isRefresh) {
      if (this.selectedIndex === onboardingStepper.bank) {
        this.bankInfo.loadDataIntoForm(this.onboardData);
      } else if (this.selectedIndex === onboardingStepper.supply) {
        this.supplierChainInfo.loadDataIntoForm(this.onboardData);
      } else if (this.selectedIndex === onboardingStepper.user) {
        this.userInfo.loadDataIntoForm(this.onboardData);
      } else if (this.selectedIndex === onboardingStepper.others) {
        try {
          this.otherInfo.loadDataIntoForm(this.onboardData?.general_info);
        } catch (error) {
          console.log(error);
        }
      }
    }
  }

  stepperResponsiveCheck() {
    this.stepperOrientation = this.breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({ matches }: any) => (matches ? 'horizontal' : 'vertical')));
  }

  onSteperChange(ev: any = '') {
    this.selectedIndex = this._lodash.getData(ev, 'selectedIndex', 0);
    const selectedIndex = this._lodash.getData(ev, 'selectedIndex', 0);
    this.currentStepperDetails =
      this.stepperDetails?.find((x: any) => x.position == selectedIndex) || {};
    // this.prevBtnText = thisstepperDetails.prevBtnText.title;
    // this.nextBtnText = stepperDetails.nextBtnText.title;
  }

  saveCurrentStep(loadStep: string = '', prevTxt: string = '') {
    const stepperDetails =
      this.stepperDetails?.find((x: any) => x.position == this.selectedIndex) ||
      {};

    const isSaveASDraft = loadStep == 'draft' ? true : false;

    if (stepperDetails.position == onboardingStepper.site) {
      this.saveSiteDetails(isSaveASDraft);
    } else if (stepperDetails.position == onboardingStepper.bank) {
      this.saveBankDetails(isSaveASDraft);
    } else if (stepperDetails.position == onboardingStepper.supply) {
      this.saveSupplyChainDetails(isSaveASDraft);
    } else if (stepperDetails.position == onboardingStepper.user) {
      this.saveUserInfoDetails(isSaveASDraft);
    } else if (stepperDetails.position == onboardingStepper.others) {
      this.saveOtherInfoDetails(isSaveASDraft);
    }
  }

  navigateView() {
    this._routeService.navigatePages(
      '/scp-mgmt/onboarding-preview/' + this.registerId + '/'
    );
  }

  prevStep() {
    this.stepper.previous();
  }

  close() {
    const userDetails: any = this._storage.getUserDetails();
    if (
      userDetails?.groups?.[0]?.name ==
      USERGROUPS.SUPPLY_CHAIN_PARTICIPANT_ADMINISTRATOR
    )
      this._router.navigate([`${routePath?.SCP_APPLICATION_PATH}`]);
    else this._router.navigate([routePath?.SCP_PATH]);
  }

  /**
   * @description
   * Form validation
   * @returns return boolean value based on valid site
   */

  get validSites(): boolean {
    return this.sites
      ?.toArray()
      ?.map((x) => x.isvalidForm)
      ?.every((x) => x);
  }

  /**
   * @description
   * @returns return boolean value based on valid site
   */

  get enableSiteList(): boolean {
    return true;
    //this.sites?.toArray()?.map((x: any) => x.isvalidForm)?.includes(true)
  }

  /**
   * @description
   * @returns return boolean value based on valid site
   */

  get inValidSites(): boolean {
    return !this.sites
      ?.toArray()
      ?.map((x) => x?.isvalidForm)
      ?.every((x) => x);
  }

  get isValidAgree(): any {
    return this.othersForm?.getRawValue()?.agree;
  }

  /**
   * @description
   * Current Ele
   * @param ev
   */
  deleteSite(ev: any, index: any) {
    ev.stopPropagation();
    this._modal
      .openDeleteDialog({
        data: {
          paragraph: `<p class="">${this.staticText?.common?.delete_confirm_site}`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this._loader.hide();
          this.siteInfoConfig.splice(index, 1);
          this.sites.toArray().splice(index, 1);
          this.siteIndex = 0;
          this.siteInfoConfig[this.siteIndex].active = true;

          setTimeout(() => {
            let siteConfig: any = this.getSiteConfig(true);
            const currentSite = this.siteInfoConfig[this.siteIndex].name;
            this.sites.toArray()?.forEach((element: any, idx: any) => {
              if (currentSite) {
                siteConfig = this.removeById(currentSite, siteConfig);
              }

              /* element.sections = this.updateOptions('product_details', siteConfig, element.sections);
 
             if (this.licenceType?.name == 'Manufacturer')
               this.updateOptions('production_details', siteConfig, element.sections);*/
            });
          }, 100);
        }
      });
  }

  /**
   * @description
   * @param ev Current Element details
   */
  addMoreSite(ev: any) {
    if (this.inValidSites) {
      this._common.errorFocusScroll();
      this.sites.toArray().at(this.siteIndex)?.hitValidation();
      return;
    }

    this.siteInfoConfig[this.siteIndex].name = this.sites
      .toArray()
      .at(this.siteIndex)
      ?.form?.getRawValue()?.site_details.name;
    this.siteInfoConfig[this.siteIndex].valid = this.sites
      .toArray()
      .at(this.siteIndex)?.isvalidForm;

    this.siteInfoConfig?.forEach((element) => {
      element.active = false;
    });

    if (this.inValidSites) {
      const obj = this.siteInfoConfig.find((x) => x.valid === false);
      const index = this.siteInfoConfig?.length - 1;
      this.selectSite(obj, index);
      return;
    }

    this.siteInfoConfig.push({
      name: 'New Site',
      mode: 'add',
      active: true,
      valid: false,
      section: this.getOnboardSiteFormConfig(
        this.licenceType,
        'New Site',
        true
      ),
    });

    this.siteIndex = this.siteInfoConfig?.length - 1;
  }

  selectSite(data: any, index: any) {
    this.siteIndex = index;
    this.siteInfoConfig?.forEach((element) => {
      element.active = false;
    });
    data.active = true;
  }

  /**
   * @description
   * @returns return boolean value based on valid site
   */

  get isValidBankInfo(): boolean {
    return this.bankInfo?.form?.valid;
  }

  get isValidSupplyChainInfo(): boolean {
    return this.supplierChainInfo?.form?.valid;
  }

  get isValidUserInfo(): boolean {
    return this.userInfo?.form?.valid;
  }

  get isValidOtherInfo(): boolean {
    return this.otherInfo?.form?.valid;
  }

  saveSiteDetails(isSaveASDraft: boolean) {
    if (this.inValidSites && !isSaveASDraft) {
      this._common.errorFocusScroll();
      this.sites.toArray()?.forEach((element) => {
        element?.hitValidation();
      });
      this._snackBar.loadSnackBar(
        this.staticText?.scp?.onboard?.msg1,
        colorCodes.ERROR
      );
      return;
    }

    const rawData = this.sites.toArray().map((x: any) => x.extractFormData());
    const attachedFiles = this.sites
      .toArray()
      .map((x: any) => x.attachmentList);
    let validProduct = true;

    rawData?.forEach((element: any) => {
      // Site payload changes.........................

      element.site_details.company = this.registerId;
      // element.site_details.country_code = '+250';
      // element.site_details.postal_code = '34567';
      element.site_details.attachments = [];

      // .........................Site payload changes

      //product details..............................

      element.product_details.forEach((product: any) => {
        if (product?.display_name) {
          product.name = product.display_name;
          product.maximum_retail_price = parseFloat(
            product.maximum_retail_price
          ).toFixed();
          validProduct = true;
        } else {
          validProduct = false;
          return;
        }
      });

      //..............................product details

      if (isSaveASDraft) {
        const production_details = element?.production_details?.filter(
          (x: any) => x.machine_name
        )?.length;
        if (!production_details) element.production_details = [];
        const product_details = element?.product_details?.filter(
          (x: any) => x.name
        )?.length;
        if (!product_details) element.product_details = [];
      }
    });

    if (!validProduct && !isSaveASDraft) {
      this._snackBar.loadSnackBar(
        this.staticText?.scp?.onboard?.msg2,
        colorCodes.ERROR
      );
      return;
    }

    const payload = {
      site_info: rawData,
    };
    const formData = new FormData();
    formData.append('json_data', JSON.stringify(payload));

    this._common.appendMultipleComponentFilesToFormData(
      attachedFiles,
      formData
    );
    this.saveOnboardDetails(this.registerId, formData, isSaveASDraft);
  }

  saveBankDetails(isSaveASDraft: boolean) {
    if (!this.isValidBankInfo && !isSaveASDraft) {
      this._common.errorFocusScroll();
      this.bankInfo?.validateForm();
      this._snackBar.loadSnackBar(
        this.staticText?.common?.scp?.onboard?.msg3,
        colorCodes.ERROR
      );
      return;
    }

    const rawData = this.bankInfo?.extractFormData() ?? {};
    rawData.bank_info.company = this.registerId;
    const attachedFiles = this.bankInfo?.attachmentList;

    const payload = rawData;
    const formData = new FormData();
    formData.append('json_data', JSON.stringify(payload));
    this._common.appendFilesToFormData(attachedFiles, formData);
    this.saveOnboardDetails(this.registerId, formData, isSaveASDraft);
  }

  saveSupplyChainDetails(isSaveASDraft: boolean) {
    if (
      (!this.isValidSupplyChainInfo && !isSaveASDraft) ||
      !this.supplierChainInfo?.extractFormData()?.supplier_info?.length
    ) {
      this._common.errorFocusScroll();
      this._snackBar.loadSnackBar(
        this.staticText?.scp?.onboard?.msg4,
        colorCodes.ERROR
      );
      return;
    }

    const rawData = this.supplierChainInfo?.extractFormData() ?? {};

    if (isSaveASDraft)
      rawData.supplier_info =
        rawData?.supplier_info?.filter((x: any) => x.company_name) || [];

    if (
      Array.isArray(rawData.supplier_info) &&
      rawData.supplier_info.length > 0
    )
      rawData.supplier_info?.forEach((supplierInfo: any) => {
        supplierInfo.company = this.registerId;
        // supplierInfo.product = [2];
      });

    const payload = rawData;
    const formData = new FormData();
    formData.append('json_data', JSON.stringify(payload));
    this.saveOnboardDetails(this.registerId, formData, isSaveASDraft);
  }

  saveUserInfoDetails(isSaveASDraft: boolean) {
    if (
      (!this.isValidUserInfo && !isSaveASDraft) ||
      !this.userInfo?.extractFormData()?.user_info?.length
    ) {
      this._common.errorFocusScroll();
      this._snackBar.loadSnackBar(
        this.staticText?.scp?.onboard?.msg5,
        colorCodes.ERROR
      );
      return;
    }

    const rawData = this.userInfo?.extractFormData() ?? {};

    if (isSaveASDraft)
      rawData.user_info =
        rawData.user_info?.filter((x: any) => x.first_name) || [];

    if (Array.isArray(rawData.user_info) && rawData.user_info.length > 0)
      rawData.user_info?.forEach((supplierInfo: any) => {
        if (supplierInfo?.id) {
          const oldUserData = this._common.findById(
            this._onBoard?.onboardingInfo?.user_info,
            supplierInfo?.id
          );
          supplierInfo.old_email = oldUserData.email;
        }

        supplierInfo.company = this.registerId;
      });

    const payload = rawData;
    const formData = new FormData();
    formData.append('json_data', JSON.stringify(payload));
    this.saveOnboardDetails(this.registerId, formData, isSaveASDraft);
  }

  saveOtherInfoDetails(isSaveASDraft: boolean) {
    if (!this.isValidOtherInfo && !isSaveASDraft) {
      this._common.errorFocusScroll();
      this.otherInfo?.validateForm();
      this._snackBar.loadSnackBar(
        this.staticText?.scp.onboard?.msg6,
        colorCodes.ERROR
      );
      return;
    }

    const rawData = this.otherInfo?.extractFormData() ?? {};
    rawData.company = this.registerId;
    const attachedFiles = this.otherInfo?.attachmentList;
    const payload = { general_info: rawData };
    const formData = new FormData();
    formData.append('json_data', JSON.stringify(payload));
    this._common.appendFilesToFormData(attachedFiles, formData);
    this.saveOnboardDetails(this.registerId, formData, isSaveASDraft);
  }

  /**
   *
   * @param id
   * @param formData
   */
  saveOnboardDetails(id: any, formData: any, isSaveASDraft: boolean) {
    this._loader.show();
    this.productClonePending = false;
    if (this.onboardDataExist) {
      this._onBoard.updateDetails(id, formData).subscribe((response: any) => {
        this._loader.hide();
        let msg = this._lodash.getData(response, 'body.message', false);
        if (msg) {
          this._snackBar.loadSnackBar(msg, colorCodes.SUCCESS);
          this.onboardData = this._lodash.getData(response, 'body.data', {});
          this._onBoard.onboardingInfo = {
            ...this._onBoard.onboardingInfo,
            ...this.onboardData,
          };
          this.patchFormValues(true);
          if (!isSaveASDraft) {
            if (this.selectedIndex == onboardingStepper.others)
              this.navigateView();
            else this.stepper.next();
          }
        }
      });
    } else {
      this._onBoard.saveDetails(id, formData).subscribe((response: any) => {
        this._loader.hide();
        this.onboardDataExist = true;
        let msg = this._lodash.getData(response, 'body.message', false);
        if (msg) {
          this._snackBar.loadSnackBar(msg, colorCodes.SUCCESS);
          this.onboardData = this._lodash.getData(response, 'body.data', {});
          this._onBoard.onboardingInfo = {
            ...this._onBoard.onboardingInfo,
            ...this.onboardData,
          };
          this.patchFormValues(true);
          if (!isSaveASDraft) {
            if (this.selectedIndex == onboardingStepper.others)
              this.navigateView();
            else this.stepper.next();
          }
        }
      });
    }
  }

  onSelectionChanged(selectedOption: any, sectionKey: any) {
    const isAddressClone =
      sectionKey === 'billing_address' || sectionKey === 'shipping_address';
    const sectionTitle = this.sectionTitleConfig[sectionKey] ?? '';
    const title = isAddressClone
      ? 'Would you like to copy the address from the site address?'
      : `Do you want to move the ${sectionTitle} data from the selected site?`;

    if (!this.isValidSelection(selectedOption, sectionKey, isAddressClone))
      return;

    if (isAddressClone && selectedOption) {
      this.showDialogAndHandleResult(
        sectionKey,
        title,
        () => this.copyAddress(sectionKey),
        selectedOption
      );
    } else if (!isAddressClone) {
      this.showDialogAndHandleResult(sectionKey, title, () =>
        this.copyDataToActiveSite(selectedOption, sectionKey, title)
      );
    }
  }

  private showDialogAndHandleResult(
    sectionKey: any,
    title: string,
    action: () => void,
    selectedOption?: any
  ) {
    this._modal
      .openWarningDialog({ data: { paragraph: title } })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          action();
        } else if (selectedOption) {
          const activeSiteRef = this.getActiveSiteRef();
          if (sectionKey == 'billing_address')
            activeSiteRef?.dynamicCheckbox?.first?.toggleCheckbox();
          else activeSiteRef?.dynamicCheckbox?.last?.toggleCheckbox();
        }
      });
  }

  private getActiveSiteRef() {
    const activeIndex = this.getActiveSiteIndex();
    if (activeIndex === -1) return;

    return this.sites.toArray().at(activeIndex);
  }

  private getSiteLocation(activeSiteRef: any, filterBy: string): any {
    let data: any = null;
    activeSiteRef.dynamicFormFieldList?.forEach((element: any) => {
      if (element?.section?.key == filterBy) {
        data = element;
      }
    });
    return data.dynamicLocation;
  }

  private dataMapFromSitelocation(fromLocation: any, toLocation: any): void {
    toLocation?.locationJson?.forEach((element: any) => {
      const findData = this._lodash.find(
        fromLocation?.locationJson,
        'controlName',
        element?.controlName
      );
      element.data = findData?.data;
    });
  }

  private copyAddress(sectionKey: any): void {
    this._loader.show();
    const activeSiteRef = this.getActiveSiteRef();
    const currentData = this.getActiveSiteData(activeSiteRef);
    const fromLocation = this.getSiteLocation(activeSiteRef, 'site_details');
    const toLocation = this.getSiteLocation(activeSiteRef, sectionKey);

    if (!currentData) return;

    const siteAddress = currentData['site_details'] ?? {};
    const hasRequiredValue = [
      'email',
      'contact_no',
      'address',
      'location',
      'postal_code',
    ].some((key) => siteAddress[key] !== '');

    if (!hasRequiredValue) {
      this.showSnackBar(this.staticText?.scp?.onboard?.msg7, colorCodes.ERROR);
      return;
    }

    if (activeSiteRef?.form) {
      const obj = {
        email: siteAddress?.email,
        address: siteAddress?.address,
        contact_no: siteAddress?.contact_no,
        country_code: siteAddress?.country_code,
        postal_code: siteAddress?.postal_code,
      };
      activeSiteRef?.form?.patchValue({ [sectionKey]: obj });
      toLocation?.dataMapping(
        fromLocation?.location?.getRawValue(),
        fromLocation?.locationJson?.map((x: any) => x)
      );
    }

    this.showSnackBar(this.staticText?.scp?.onboard?.msg8, colorCodes.SUCCESS);
    this._loader.hide();
  }

  private getActiveSiteData(activeSiteRef: any) {
    const currentData = activeSiteRef?.form?.value;
    if (!currentData) {
      this.showSnackBar(this.staticText?.scp?.onboard?.msg9, colorCodes.ERROR);
      return;
    }

    return currentData;
  }

  // Helper function to copy data to the active site
  private copyDataToActiveSite(
    selectedOption: any,
    sectionKey: any,
    title: string
  ): void {
    const index = this.getSiteIndex(selectedOption);
    if (index === -1) return;

    const siteData = this.getSiteData(index, sectionKey);
    if (!siteData) return;

    const activeIndex = this.getActiveSiteIndex();
    if (activeIndex === -1) return;

    const activeSiteRef = this.sites.toArray().at(activeIndex);
    const currentData = this.getActiveSiteData(activeSiteRef);

    if (currentData)
      this.updateSiteData(
        activeSiteRef,
        currentData,
        sectionKey,
        siteData,
        title
      );
  }

  // Helper function to validate the initial selection
  private isValidSelection(
    selectedOption: any,
    sectionKey: any,
    isAddressClone: boolean = false
  ): boolean {
    if (isAddressClone) return true;

    if (!selectedOption || !sectionKey) {
      this.showSnackBar(this.staticText?.scp?.onboard?.msg10, colorCodes.ERROR);
      return false;
    }
    return true;
  }

  // Helper function to retrieve the index of the selected site
  private getSiteIndex(selectedOption: any): number {
    const index = this.siteInfoConfig?.findIndex(
      (x) => x.name === selectedOption.name
    );
    if (index === -1) {
      this.showSnackBar(this.staticText?.scp?.onboard?.msg11, colorCodes.ERROR);
    }
    return index;
  }

  // Helper function to retrieve site data based on section key
  private getSiteData(index: number, sectionKey: any): any {
    const siteRef = this.sites.toArray().at(index);
    const formData = siteRef?.form?.value;

    if (!formData || !this.hasDataToCopy(formData, sectionKey, siteRef)) {
      this.showSnackBar(this.staticText?.scp?.onboard?.msg12, colorCodes.ERROR);
      return null;
    }

    return _.cloneDeep(this.removeIdsFromData(formData[sectionKey]));
  }

  // Helper function to check if data is available to copy
  private hasDataToCopy(data: any, sectionKey: string, siteRef: any): boolean {
    const sectionData = data[sectionKey];
    return (
      sectionData &&
      Array.isArray(sectionData) &&
      sectionData.length > 0 &&
      !(sectionData.length === 1 && siteRef?.isFirstGroupEmpty(sectionKey))
    );
  }

  // Helper function to remove 'id' key from data objects
  private removeIdsFromData(data: any): any {
    return this._common.removeKeyFromObjects(data, 'id');
  }

  // Helper function to find the active site index
  private getActiveSiteIndex(): number {
    const activeIndex = this.siteInfoConfig?.findIndex(
      (x) => x.active === true
    );
    if (activeIndex === -1) {
      this.showSnackBar(this.staticText?.scp?.onboard?.msg13, colorCodes.ERROR);
    }
    return activeIndex;
  }

  // Helper function to update and load data into the form
  private updateSiteData(
    siteRef: any,
    data: any,
    sectionKey: any,
    newData: any,
    title: string
  ): void {
    data[sectionKey] = newData;

    if (siteRef?.apiData) {
      siteRef.apiData[sectionKey] = newData;
    }

    siteRef?.loadDataIntoForm(data, true);
    this.showSnackBar(
      `${title} ${this.staticText?.scp?.onboard?.msg14}`,
      colorCodes.SUCCESS
    );
  }

  // Helper function to show a snackbar message
  private showSnackBar(message: string, colorCode: any): void {
    this._snackBar.loadSnackBar(message, colorCode);
  }

  //............Site relavent functions herecloneData

  termsClick() {
    const terms = this.othersForm.get('agree')?.value;
    this.othersForm.get('agree')?.setValue(!terms);
  }
}
