import {
  FormSection,
  percentageValidator,
  numberValidator,
  minDate,
  maxDate,
  noUnderscoreValidator,
  requiredValidator,
  maxLengthValidator,
  hiddenIdField,
  locationField,
  FormField,
  customEmailValidator,
  locationOldField,
  postalCodeField,
} from 'src/app/shared/components/dynamic-form/form-sections.model'; // Adjust the path as needed
import { Validators } from '@angular/forms';
import {
  Endpoints,
  PagePermssions,
} from 'src/app/core/services/utils/constants';
import { TranslationService } from 'src/app/shared/services/translation.service';

const staticText: any = TranslationService.staticTextData;

const emailField = {
  type: 'text',
  label: staticText?.common?.email_id,
  name: 'email',
  validators: [
    requiredValidator,
    {
      name: 'email',
      validator: customEmailValidator(),
      message: staticText?.common?.validation_error_msg?.email_invalid,
    },
  ],
};

// Address field generator
export function generateAddressFields(addressType: string): FormField[] {
  let config: any = [
    hiddenIdField,
    {
      type: 'text',
      label: `${addressType} ${staticText?.common?.email}`,
      name: 'email',
      validators: [
        {
          name: 'required',
          validator: Validators.required,
          message: `${addressType} ${staticText?.common?.validation_error_msg?.email_required}`,
        },
        {
          name: 'email',
          validator: customEmailValidator(),
          message: `${staticText?.common?.invalid} ${addressType} ${staticText?.common?.email}`,
        },
      ],
    },
    {
      type: 'phone_field',
      label: `${addressType} ${staticText?.common?.contact_number}`,
      name: 'contact_no',
      altName: 'country_code',
      validators: [
        {
          name: 'required',
          validator: Validators.required,
          message: `${addressType} ${staticText?.common?.validation_error_msg?.contact_number_rquired}`,
        },
        {
          name: 'minlength',
          validator: Validators.minLength(3),
          message: `${addressType} ${staticText?.common?.validation_error_msg?.phone_number_min}`,
        },
        {
          name: 'maxlength',
          validator: Validators.maxLength(9),
          message: `${addressType} ${staticText?.common?.validation_error_msg?.phone_number_max}`,
        },
        {
          name: 'number',
          validator: numberValidator(),
          message: staticText?.common?.validation_error_msg?.number_invalid,
        },
      ],
    },
    {
      type: 'textarea',
      label: `${addressType} ${staticText?.common?.address}`,
      name: 'address',
      validators: [
        {
          name: 'required',
          validator: Validators.required,
          message: `${addressType} ${staticText?.common?.validation_error_msg?.address_required}`,
        },
        {
          name: 'maxlength',
          validator: Validators.maxLength(500),
          message: `${addressType} ${staticText?.common?.validation_error_msg?.site_address_max}`,
        },
      ],
      meta: { viewConfig: { colspan: 2 } },
    },
    locationOldField,
  ];

  const isPostalCodeRequired =
    JSON.parse(localStorage.getItem('appConfig') ?? '{}')
      ?.geographical_classification?.is_postal_code_required || false;

  if (isPostalCodeRequired) config.push(postalCodeField);

  return config;
}

export const FORM_SECTIONS: FormSection[] = [
  {
    title: staticText?.common?.business_info,
    key: 'business_info',
    sectionType: 'single',
    columns: 4, // Number of columns per row
    meta: { viewConfig: { visible: false } },
    fields: [
      hiddenIdField,
      {
        type: 'text',
        label: staticText?.common?.company_name,
        name: 'name',
        validators: [
          requiredValidator,
          maxLengthValidator(
            250,
            staticText?.common?.validation_error_msg?.company_name_max
          ),
        ],
      },
      {
        type: 'select',
        label: staticText?.common?.license_type,
        name: 'license_type',
        validators: [requiredValidator],
        options: [],
        apiEndpoint: Endpoints.GET_LICENSE_TYPE,
        meta: {
          onchangeCallback: () => {},
        },
      },
      {
        type: 'date',
        label: staticText?.common?.date_of_incorporation,
        name: 'date_of_incorporation',
        labelReq: false,
        validators: [
          requiredValidator,
          {
            name: 'max',
            validator: maxDate(new Date()),
            message: staticText?.common?.validation_error_msg?.date_max,
          },
        ],
        meta: {
          max_date: new Date(), // , 'min_date': new Date(new Date().setDate(new Date().getDate() - 900))
        },
      },
      {
        type: 'select',
        label: staticText?.scp?.view_register?.company_ownership,
        name: 'ownership',
        validators: [requiredValidator],
        apiEndpoint: Endpoints.GET_OWNERSHIP_TYPE,
        meta: {
          controlSection: {
            controlValue: 'individual',
            sectionKeys: ['ownership_info'],
            actions: ['clear'],
          },
        },
      },
      {
        type: 'text',
        label: staticText?.scp?.view_register?.company_email_id,
        name: 'email',
        validators: [
          requiredValidator,
          {
            name: 'email',
            validator: customEmailValidator(),
            message: staticText?.common?.validation_error_msg?.email_invalid,
          },
        ],
      },
      {
        type: 'phone_field',
        label: staticText?.scp?.view_register?.company_contact_number,
        name: 'contact_no',
        altName: 'country_code',
        validators: [
          requiredValidator,
          {
            name: 'minlength',
            validator: Validators.minLength(3),
            message:
              staticText?.common?.validation_error_msg
                ?.company_contact_number_min,
          },
          {
            name: 'maxlength',
            validator: Validators.maxLength(9),
            message:
              staticText?.common?.validation_error_msg
                ?.company_contact_number_max,
          },
          {
            name: 'number',
            validator: numberValidator(),
            message: staticText?.common?.validation_error_msg?.number_invalid,
          },
        ],
      },
      {
        type: 'textarea',
        label: staticText?.scp?.view_register?.company_address,
        name: 'address',
        validators: [
          requiredValidator,
          maxLengthValidator(
            500,
            staticText?.common?.validation_error_msg?.company_address_max
          ),
        ],
        meta: { viewConfig: { colspan: 3 } },
      },
      locationOldField,
    ],
  },
  {
    title: staticText?.scp?.view_register?.registration_info,
    key: 'certificate_info',
    columns: 4,
    sectionType: 'multiple',
    meta: {
      viewColumns: 4,
      hideActionBtn: true,
      renderDataOnload: true,
      uniqueKey: 'certificate',
      dataAPIEndpoint: Endpoints.GET_REGISTRATION_CERTIFICATES,
      data: [],
      withBorder: true,
    },
    fields: [
      {
        type: 'hidden',
        label: staticText?.scp?.view_register?.certificate_or_license,
        name: 'certificate',
        labelReq: false,
        visible: false,
      },
      {
        type: 'hidden',
        label: staticText?.scp?.view_register?.certificate_or_license,
        name: 'id',
        validators: [requiredValidator],
        labelReq: true,
        meta: {
          viewConfig: { showSectionTitle: true, sectionField: 'certificate' },
        },
      },
      {
        type: 'text',
        label: staticText?.scp?.view_register?.certificate_number,
        name: 'certificate_number',
        validators: [
          requiredValidator,
          maxLengthValidator(
            100,
            staticText?.common?.validation_error_msg?.certificate_number_max
          ),
        ],
      },
      {
        type: 'date',
        label: staticText?.scp?.view_register?.expiry_date,
        name: 'expiry_date',
        labelReq: false,
        validators: [
          requiredValidator,
          {
            name: 'min',
            validator: minDate(new Date()),
            message: staticText?.common?.validation_error_msg?.date_min,
          },
        ],
        meta: {
          min_date: new Date(),
        },
      },
      {
        type: 'hidden',
        label: staticText?.common?.status,
        name: 'certificate_status',
        labelReq: false,
        meta: {
          viewConfig: {
            colorRefField: 'color_code',
            colorRefField2: 'text_color_code',
          },
        },
      },
      {
        type: 'hidden',
        label: staticText?.scp?.view_register?.color_code,
        name: 'color_code',
        visible: false,
      },
      {
        type: 'hidden',
        label: staticText?.scp?.view_register?.color_code,
        name: 'text_color_code',
        visible: false,
      },
      {
        type: 'hidden',
        label: staticText?.scp?.view_register?.is_mandatory,
        name: 'is_mandatory',
        visible: false,
      },
      {
        type: 'multi_file',
        label: staticText?.scp?.view_register?.upload_certificate_copy,
        labelReq: false,
        name: 'attachments',
        validators: [requiredValidator],
      },
      /*   {
        type: 'checkbox',
        label: 'Not Applicable',
        labelReq: true,
        name: 'is_not_applicable',
        class: 'is-not-applicable',
        controlsDisabling: true,
        validators: [],
        meta: { excludeFields: ['is_not_applicable', 'id', 'certificate'] },
      }, */
    ],
  },
  {
    title: staticText?.common?.ownership_info,
    key: 'ownership_info',
    columns: 2,
    sectionType: 'multiple', // This section allows multiple entries
    meta: {
      // bulkUpload: {
      //   enable: true,
      //   label: 'OwnerShip  Upload Info',
      //   upload_template: 'OwnerShip Bulk Upload Template',
      //   get: Endpoints.OWNER_SHIP_BULK_TEMPLATE,
      //   post: Endpoints.POST_OWNER_SHIP_BULK_TEMPLATE,
      //   filterKey: null,
      //   fileName: 'OwnerShip',
      //   permission: [],
      //   withoutLogin: true,
      //   tooltip:
      //     'Download the template file to see the required format, fill in OwnerShip Information, and upload it here.',
      //   tooltipPosition: 'top',
      // },
      inlineEdit: false,
      btnText: staticText?.common?.add_ownership,
      triggerOverallPercentageCalculation: true,
      disableActionBtn: false,
      controlValue: 'individual',
      noRecTemplate: `<div class="no_records_data">
        <img src="../../../../../assets/images/common/icons_usp/No records created.svg" alt="records_img"/>
        <div class="no_forcast_data">${staticText?.common?.no_entries_added_yet}</div>
        <div>`,
    },
    layout: 'table',
    fields: [
      {
        type: 'hidden',
        label: staticText?.common?.owner_id,
        name: 'id',
        validators: [],
        labelReq: false,
        visible: false,
      },
      {
        type: 'text',
        label: staticText?.common?.ownership_name,
        name: 'name',
        validators: [
          requiredValidator,
          maxLengthValidator(
            150,
            staticText?.common?.validation_error_msg?.ownership_name_max
          ),
        ],
      },
      emailField,
      {
        type: 'phone_field',
        altName: 'country_code',
        label: staticText?.common?.contact_number,
        name: 'contact_no',
        validators: [
          requiredValidator,
          {
            name: 'minlength',
            validator: Validators.minLength(3),
            message: staticText?.common?.validation_error_msg?.phone_number_min,
          },
          {
            name: 'maxlength',
            validator: Validators.maxLength(9),
            message: staticText?.common?.validation_error_msg?.phone_number_max,
          },
          {
            name: 'number',
            validator: numberValidator(),
            message: staticText?.common?.validation_error_msg?.number_invalid,
          },
        ],
      },
      {
        type: 'text',
        label: staticText?.common?.percentage_of_ownership,
        name: 'ownership_percentage',
        validators: [
          requiredValidator,
          {
            name: 'percentage',
            validator: percentageValidator(),
            message:
              staticText?.common?.validation_error_msg
                ?.percentage_of_ownership_invalid,
          },
          // { name: 'percentageExceeded', validator: ownershipPercentageValidator('ownership_percentage'), message: 'Overall percentage should not exceed.' },
        ],
        meta: { inputSuffixText: '%' },
      },
    ],
  },
];

export const ONBOARD_SITEINFO_FORM_SECTIONS: FormSection[] = [
  {
    title: staticText?.common?.site_info,
    key: 'site_details',
    sectionType: 'single',
    columns: 4, // Number of columns per row
    meta: { isEmptyCheck: true },
    fields: [
      hiddenIdField,
      {
        type: 'hidden',
        label: staticText?.common?.company_id,
        name: 'company',
        labelReq: false,
        visible: false,
      },
      {
        type: 'text',
        label: staticText?.common?.site_name,
        name: 'name',
        validators: [
          requiredValidator,
          maxLengthValidator(
            250,
            staticText?.common?.validation_error_msg?.site_name_max
          ),
        ],
      },
      {
        type: 'select',
        label: staticText?.common?.facility_type,
        name: 'facility_type',
        validators: [requiredValidator],
        apiEndpoint: Endpoints.GET_FACILITY_TYPE,
        options: [],
      },
      emailField,
      {
        type: 'phone_field',
        label: staticText?.common?.contact_number,
        name: 'contact_no',
        altName: 'country_code',
        validators: [
          requiredValidator,
          {
            name: 'minlength',
            validator: Validators.minLength(3),
            message: staticText?.common?.validation_error_msg?.phone_number_min,
          },
          {
            name: 'maxlength',
            validator: Validators.maxLength(9),
            message: staticText?.common?.validation_error_msg?.phone_number_max,
          },
          {
            name: 'number',
            validator: numberValidator(),
            message: staticText?.common?.validation_error_msg?.number_invalid,
          },
        ],
      },
      {
        type: 'textarea',
        label: staticText?.common?.site_address,
        name: 'address',

        validators: [
          requiredValidator,
          maxLengthValidator(
            500,
            staticText?.common?.validation_error_msg?.site_address1_max
          ),
        ],
      },
      locationOldField,
    ],
  },
  {
    title: staticText?.common?.billing_address,
    key: 'billing_address',
    sectionType: 'single',
    columns: 4, // Number of columns per row
    meta: {
      isEmptyCheck: true,
      copyDataFrom: {
        options: [],
        key: 'copy_billing_address',
        selectionChangeHandler: null,
        type: 'checkbox',
        label: staticText?.common?.ba_same,
      },
      info: true,
      help: {
        // text: 'The billing address details will be used for placing stamp orders.',
        text: staticText?.scp?.onboard.info?.billing,
      },
    },
    fields: generateAddressFields(staticText?.common?.billing),
  },
  {
    title: staticText?.common?.shipping_address,
    key: 'shipping_address',
    sectionType: 'single',
    columns: 4, // Number of columns per row
    meta: {
      isEmptyCheck: true,
      copyDataFrom: {
        options: [],
        key: 'copy_shipping_address',
        selectionChangeHandler: null,
        type: 'checkbox',
        label: staticText?.common?.ba_same,
      },
      info: true,
      help: {
        // text: 'The shipping address details will be used for placing stamp orders.',
        text: staticText?.scp?.onboard.info?.shipping,
      },
    },
    fields: generateAddressFields(staticText?.common?.shipping),
  },
  {
    title: staticText?.common?.production_info,
    key: 'production_details',
    columns: 2,
    sectionType: 'multiple', // This section allows multiple entries
    meta: {
      inlineEdit: false,
      btnText: staticText?.common?.add_production_info,
      btnActionText: ' Production',
      bulkUpload: {
        enable: true,
        label: 'Upload Production Info',
        upload_template: 'Production Bulk Upload Template',
        get: Endpoints.PRODUCTION_BULK_TEMPLATE,
        post: Endpoints.POST_PRODUCTION_BULK_TEMPLATE,
        filterKey: 'site_details.id',
        fileName: 'Production',
        permission: [PagePermssions.ONBOARD_BULK_UPLOAD],
        tooltip:
          'Download the template file to see the required format, fill in Production Information, and upload it here.',
        tooltipPosition: 'top',
      },
      viewConfig: { action: true },
      noRecTemplate: `<div class="no_records_data">
        <img src="../../../../../assets/images/common/icons_usp/No records created.svg" alt="records_img"/>
        <div class="no_forcast_data">${staticText?.common?.no_entries_added_yet}</div>
        <div>`,
      /* copyDataFrom: {
                 options: [],
                 key: 'copy_from_production',
                 selectionChangeHandler: null
             } */
    },
    layout: 'table',
    fields: [
      {
        type: 'hidden',
        label: staticText?.common?.production_id,
        name: 'id',
        labelReq: false,
        visible: false,
      },
      {
        type: 'hidden',
        label: staticText?.common?.is_copy,
        name: 'is_copy',
        labelReq: false,
        visible: false,
      },
      {
        type: 'text',
        label: staticText?.common?.machine_name,
        name: 'machine_name',
        validators: [
          requiredValidator,
          maxLengthValidator(
            250,
            staticText?.common?.validation_error_msg?.machine_name_max
          ),
        ],
      },
      {
        type: 'text',
        label: staticText?.common?.machine_number,
        name: 'machine_number',
        validators: [
          requiredValidator,
          maxLengthValidator(
            250,
            staticText?.common?.validation_error_msg?.machine_number_max
          ),
        ],
      },
      {
        type: 'select',
        label: staticText?.common?.machine_type,
        name: 'machine_type',
        validators: [requiredValidator],
        apiEndpoint: Endpoints.GET_MACHINE_TYPE,
        options: [],
      },
      {
        type: 'text',
        label: staticText?.common?.production_line,
        name: 'production_line',
        validators: [
          maxLengthValidator(
            250,
            staticText?.common?.validation_error_msg?.production_line_max
          ),
        ],
      },
      {
        type: 'text',
        label: staticText?.common?.production_per_month,
        name: 'production_avg',
        validators: [
          {
            name: 'number',
            validator: numberValidator(),
            message: staticText?.common?.validation_error_msg?.number_invalid,
          },
          maxLengthValidator(
            250,
            staticText?.common?.validation_error_msg?.production_avg_max
          ),
        ],
        meta: { table_display: false },
      },
      {
        type: 'textarea',
        columns: 1,
        label: staticText?.common?.description,
        name: 'description',
        validators: [],
        meta: { table_display: false },
      },
    ],
  },
  {
    title: staticText?.common?.product_and_packaging_info,
    key: 'product_details',
    columns: 2,
    sectionType: 'multiple', // This section allows multiple entries
    meta: {
      inlineEdit: false,
      btnText: staticText?.common?.add_product_info,
      btnActionText: ' Product',
      bulkUpload: {
        enable: true,
        label: 'Upload Product Info',
        upload_template: 'Product Bulk Upload Template',
        get: Endpoints.PRODUCT_BULK_TEMPLATE,
        post: Endpoints.POST_PRODUCT_BULK_TEMPLATE,
        filterKey: 'site_details.id',
        fileName: 'Product',
        permission: [PagePermssions.ONBOARD_BULK_UPLOAD],
        tooltip:
          'Download the template file to see the required format, fill in Product & Packaging details, and upload it here.',
        tooltipPosition: 'top',
      },
      viewConfig: {
        action: true,
        showImgPreview: true,
        previewRefField: 'attachments',
      },
      noRecTemplate: `<div class="no_records_data">
        <img src="../../../../../assets/images/common/icons_usp/No records created.svg" alt="records_img"/>
        <div class="no_forcast_data">${staticText?.common?.no_entries_added_yet}</div>
        <div>`,
      /*  copyDataFrom: {
                  options: [],
                  key: 'copy_from_product',
                  selectionChangeHandler: null
              } */
    },
    layout: 'table',
    fields: [
      {
        type: 'hidden',
        label: staticText?.common?.product_id,
        name: 'id',
        labelReq: false,
        visible: false,
      },
      {
        type: 'hidden',
        label: staticText?.common?.is_copy,
        name: 'is_copy',
        labelReq: false,
        visible: false,
      },
      {
        type: 'text',
        label: staticText?.common?.brand_name,
        name: 'brand_name',
        validators: [
          requiredValidator,
          maxLengthValidator(
            250,
            staticText?.common?.validation_error_msg?.brand_name_max
          ),
        ],
        meta: { viewConfig: { colspan: 2 } },
      },
      {
        type: 'select',
        label: staticText?.common?.product_type,
        name: 'product_type',
        validators: [requiredValidator],
        options: [],
        apiEndpoint: Endpoints.GET_PRODUCT_TYPE,
      },
      {
        type: 'select',
        label: staticText?.common?.product_category,
        name: 'category',
        validators: [requiredValidator],
        options: [],
        apiEndpoint: Endpoints.GET_PRODUCT_CATEGORY,
        dependsOn: 'product_type',
      },
      {
        type: 'text',
        label: staticText?.common?.product_name,
        name: 'display_name',
        validators: [
          requiredValidator,
          maxLengthValidator(
            250,
            staticText?.common?.validation_error_msg?.product_name_max
          ),
          {
            name: 'noUnderscore',
            validator: noUnderscoreValidator(),
            message: staticText?.common?.validation_error_msg?.no_underscore,
          },
        ],
        meta: { viewConfig: { colspan: 2 } },
      },
      {
        type: 'select',
        label: staticText?.common?.type_of_packaging,
        name: 'package',
        validators: [requiredValidator],
        options: [],
        dependsOn: 'product_type',
        apiEndpoint: Endpoints.GET_PRODUCT_CATEGORY_PACKAGE,
        meta: { table_display: true },
      },
      {
        type: 'select',
        label: staticText?.common?.volume_and_unit,
        name: 'uom',
        dependsOn: 'product_type',
        meta: { table_display: false },
        validators: [requiredValidator],
        apiEndpoint: Endpoints.GET_PRODUCT_VOLUME_UOM,
        options: [],
      },
      {
        type: 'text',
        label: `${staticText?.common?.product_code} (${staticText?.common?.gtin})`,
        name: 'sku_code',
        validators: [
          requiredValidator,
          maxLengthValidator(
            250,
            staticText?.common?.validation_error_msg?.sku_code
          ),
        ],
        meta: {},
      },
      {
        type: 'text',
        label: staticText?.common?.maximum_retail_price,
        name: 'maximum_retail_price',
        validators: [
          requiredValidator,
          {
            name: 'number',
            validator: numberValidator(),
            message: staticText?.common?.validation_error_msg?.number_invalid,
          },
          maxLengthValidator(
            250,
            staticText?.common?.validation_error_msg?.maximum_retail_price_max
          ),
        ],
        meta: { isCurrency: true, inputSuffixText: '$' },
      },
      {
        type: 'file',
        label: staticText?.common?.upload_product_image,
        labelReq: false,
        name: 'attachments',
        validators: [],
        meta: {
          table_display: false,
          max_file_size: 5,
          allowed_type: '.jpeg, .jpg, .png',
        },
      },
      {
        type: 'textarea',
        label: staticText?.common?.description,
        columns: 1,
        name: 'description',
        validators: [
          requiredValidator,
          maxLengthValidator(
            250,
            staticText?.common?.validation_error_msg?.product_description_max
          ),
        ],
        meta: { table_display: false, viewConfig: { colspan: 2 } },
      },
    ],
  },
];

export const ONBOARD_BANKINFO_FORM_SECTIONS: FormSection[] = [
  {
    title: staticText?.scp?.onboard?.sub_heading_2,
    key: 'bank_info',
    sectionType: 'single',
    columns: 4, // Number of columns per row
    meta: { hideSectionTitle: true },
    fields: [
      hiddenIdField,
      {
        type: 'hidden',
        label: staticText?.common?.company_id,
        name: 'company',
        labelReq: false,
        visible: false,
      },
      {
        type: 'text',
        label: staticText?.common?.bank_name,
        name: 'bank_name',
        validators: [
          requiredValidator,
          maxLengthValidator(
            250,
            staticText?.common?.validation_error_msg?.bank_name_max
          ),
        ],
      },
      {
        type: 'text',
        label: staticText?.common?.branch,
        name: 'branch',
        validators: [
          requiredValidator,
          maxLengthValidator(
            250,
            staticText?.common?.validation_error_msg?.branch_name_max
          ),
        ],
      },
      {
        type: 'text',
        label: staticText?.common?.account_name,
        name: 'accountant_name',
        validators: [
          requiredValidator,
          maxLengthValidator(
            250,
            staticText?.common?.validation_error_msg?.account_name_max
          ),
        ],
      },
      {
        type: 'text',
        label: staticText?.common?.account_number,
        name: 'account_number',
        validators: [
          requiredValidator,
          maxLengthValidator(
            250,
            staticText?.common?.validation_error_msg?.account_number_max
          ),
        ],
      },
      {
        type: 'select',
        label: staticText?.common?.account_type,
        name: 'account_type',
        validators: [requiredValidator],
        options: [],
        apiEndpoint: Endpoints.ONBOARD_ACCOUNT_TYPE_DROPDOWN,
      },
      {
        type: 'text',
        label: staticText?.common?.bic_code,
        name: 'bic_code',
        validators: [
          requiredValidator,
          maxLengthValidator(
            250,
            staticText?.common?.validation_error_msg?.ibc_code_max
          ),
        ],
      },
      {
        type: 'text',
        label: staticText?.common?.iban,
        name: 'iban_number',
        validators: [
          requiredValidator,
          maxLengthValidator(
            250,
            staticText?.common?.validation_error_msg?.iban_max
          ),
        ],
      },
      {
        type: 'multi_file',
        label: `${staticText?.common?.supportive_document} (${staticText?.common?.Optional})`,
        labelReq: false,
        name: 'attachments',
        validators: [],
      },
    ],
  },
];

export const ONBOARD_SUPPLYINFO_FORM_SECTIONS: FormSection[] = [
  {
    title: staticText?.common?.supply_chain_list,
    key: 'supplier_info',
    columns: 2,
    sectionType: 'multiple', // This section allows multiple entries
    meta: {
      bulkUpload: {
        enable: true,
        label: 'Supply Chain Upload Info',
        upload_template: 'Supply Chain Bulk Upload Template',
        get: Endpoints.SUPPLY_BULK_TEMPLATE,
        post: Endpoints.POST_SUPPLY_BULK_TEMPLATE,
        filterKey: null,
        fileName: 'Supply Chain',
        permission: [PagePermssions.ONBOARD_BULK_UPLOAD],
        tooltip:
          'Download the template file to see the required format, fill in Supply Chain Information, and upload it here.',
        tooltipPosition: 'top',
      },
      inlineEdit: false,
      btnText: staticText?.common?.add_new_supply_chain,
      hideSectionTitle: false,
      viewConfig: { action: true },
      modalConfig: { height: '95vh' },
      noRecTemplate: `<div class="no_records_data">
        <img src="../../../../../assets/images/common/icons_usp/No records created.svg" alt="records_img"/>
        <div class="no_forcast_data">${staticText?.common?.no_entries_added_yet}</div>
        <div>`,
    },
    layout: 'table',
    fields: [
      {
        type: 'hidden',
        label: staticText?.common?.supply_chain_id,
        name: 'id',
        labelReq: false,
        visible: false,
      },
      {
        type: 'select',
        label: staticText?.common?.license_type,
        name: 'license_type',
        validators: [requiredValidator],
        options: [],
        apiEndpoint: Endpoints.GET_LICENSE_TYPE,
      },
      {
        type: 'select',
        label: staticText?.common?.supplier_company_name,
        name: 'supply_company',
        validators: [requiredValidator],
        options: [],
        dependsOn: 'license_type',
        apiEndpoint: Endpoints.GET_DROPDOWN_COMPANY_SUPPLY_CHAIN,
        meta: { onchangeCallback: () => {} },
      },
      {
        type: 'text',
        label: staticText?.common?.tin_number,
        name: 'tin_number',
        readonly: true,
        validators: [
          requiredValidator,
          maxLengthValidator(
            100,
            staticText?.common?.validation_error_msg?.tin_number_max
          ),
        ],
      },
      {
        type: 'text',
        label: staticText?.common?.supplier_email_id,
        name: 'email',
        validators: [
          requiredValidator,
          {
            name: 'email',
            validator: customEmailValidator(),
            message: staticText?.common?.validation_error_msg?.email_invalid,
          },
        ],
      },
      {
        type: 'phone_field',
        label: staticText?.common?.contact_number,
        name: 'contact_no',
        altName: 'country_code',
        validators: [
          requiredValidator,
          {
            name: 'minlength',
            validator: Validators.minLength(3),
            message: staticText?.common?.validation_error_msg?.phone_number_min,
          },
          {
            name: 'maxlength',
            validator: Validators.maxLength(9),
            message: staticText?.common?.validation_error_msg?.phone_number_max,
          },
          {
            name: 'number',
            validator: numberValidator(),
            message: staticText?.common?.validation_error_msg?.number_invalid,
          },
        ],
        meta: { table_display: false },
      },
      {
        type: 'text',
        label: staticText?.common?.site_address,
        //columns: 1,
        name: 'address',
        validators: [
          requiredValidator,
          {
            name: 'maxlength',
            validator: Validators.maxLength(500),
            message: staticText?.common?.validation_error_msg?.site_address_max,
          },
        ],
        meta: { viewConfig: { colspan: 2 }, table_display: false },
      },
      locationOldField,
      {
        type: 'select',
        label: staticText?.common?.product_type,
        name: 'product_type',
        validators: [requiredValidator],
        options: [],
        apiEndpoint: Endpoints.GET_PRODUCT_TYPE_DEPEND_COMPANY,
        meta: { multiple: true, table_display: false },
      },
      {
        type: 'select',
        label: staticText?.common?.product_category,
        name: 'category',
        validators: [requiredValidator],
        options: [],
        apiEndpoint: Endpoints.GET_PRODUCT_CATEGORY_DEPEND_COMPANY,
        dependsOn: 'product_type',
        meta: { multiple: true, table_display: false },
      },
      {
        type: 'select',
        label: staticText?.common?.product_name,
        name: 'product',
        validators: [requiredValidator],
        options: [],
        apiEndpoint: Endpoints.GET_PRODUCT_DROPDOWN,
        dependsOn: 'category',
        meta: { multiple: true },
      },
    ],
  },
];

export const ONBOARD_USERINFO_FORM_SECTIONS: FormSection[] = [
  {
    title: staticText?.scp?.onboard?.sub_heading_4,
    key: 'user_info',
    columns: 2,
    sectionType: 'multiple', // This section allows multiple entries
    meta: {
      inlineEdit: false,
      btnText: staticText?.common?.add_new_user,
      hideSectionTitle: false,
      // bulkUpload: {
      //   label: 'User Upload Info',
      //   enable: true,
      //   upload_template: 'Users Bulk Upload Template',
      //   get: Endpoints.USER_BULK_TEMPLATE,
      //   post: Endpoints.POST_USER_BULK_TEMPLATE,
      //   filterKey: null,
      //   fileName: 'Users',
      //   permission: [PagePermssions.ONBOARD_BULK_UPLOAD],
      //   tooltip:
      //     'Download the template file to see the required format, fill in Users Information, and upload it here.',
      //   tooltipPosition: 'top',
      // },
      viewConfig: { action: true },
      noRecTemplate: `<div class="no_records_data">
        <img src="../../../../../assets/images/common/icons_usp/No records created.svg" alt="records_img"/>
        <div class="no_forcast_data">${staticText?.common?.no_entries_added_yet}</div>
        <div>`,
      uniqueFields: [{ key: 'email', data_type: 'string' }],
    },
    layout: 'table',
    fields: [
      {
        type: 'hidden',
        label: staticText?.common?.user_id,
        name: 'id',
        labelReq: false,
        visible: false,
      },
      {
        type: 'text',
        label: staticText?.common?.first_name,
        name: 'first_name',
        validators: [
          requiredValidator,
          {
            name: 'maxlength',
            validator: Validators.maxLength(150),
            message: staticText?.common?.validation_error_msg?.first_name_max,
          },
        ],
      },
      {
        type: 'text',
        label: staticText?.common?.last_name,
        name: 'last_name',
        validators: [
          requiredValidator,
          {
            name: 'maxlength',
            validator: Validators.maxLength(150),
            message: staticText?.common?.validation_error_msg?.last_name_max,
          },
        ],
      },
      emailField,
      {
        type: 'phone_field',
        label: staticText?.common?.contact_number,
        name: 'phone_number',
        altName: 'country_code',
        validators: [
          requiredValidator,
          {
            name: 'minlength',
            validator: Validators.minLength(3),
            message: staticText?.common?.validation_error_msg?.phone_number_min,
          },
          {
            name: 'maxlength',
            validator: Validators.maxLength(9),
            message: staticText?.common?.validation_error_msg?.phone_number_max,
          },
          {
            name: 'number',
            validator: numberValidator(),
            message: staticText?.common?.validation_error_msg?.number_invalid,
          },
        ],
      },
      {
        type: 'select',
        label: staticText?.common?.business_site,
        name: 'site',
        validators: [requiredValidator],
        options: [],
        apiEndpoint: Endpoints.ONBOARD_SITE_DROPDOWN,
      },
    ],
  },
];

export const ONBOARD_OTHERINFO_FORM_SECTIONS: FormSection[] = [
  {
    title: staticText?.common?.attachments,
    key: 'general_info',
    sectionType: 'root',
    columns: 4, // Number of columns per row
    meta: {},
    fields: [
      {
        type: 'multi_file',
        label: staticText?.common?.upload_document,
        labelReq: false,
        name: 'attachments',
        validators: [],
      },
    ],
  },
  {
    title: staticText?.common?.remarks,
    key: 'general_info_remarks',
    sectionType: 'root',
    columns: 2, // Number of columns per row
    meta: {},
    fields: [
      {
        type: 'textarea',
        label: `${staticText?.common?.remarks} (${staticText?.common?.Optional})`,
        name: 'remarks',
        validators: [
          {
            name: 'maxlength',
            validator: Validators.maxLength(500),
            message: staticText?.common?.validation_error_msg?.remarks_max,
          },
        ],
        meta: { viewConfig: { colspan: 2 } },
      },
    ],
  },
];
