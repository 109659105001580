import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common/common.service';
import { HttpService } from 'src/app/core/services/http/http.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { LodashService } from 'src/app/core/services/lodash/lodash.service';
import { RouterService } from 'src/app/core/services/router/router.service';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import {
  ApiMethod,
  Endpoints,
  PHONE_NUMBER_MAX_LENGTH,
  SuccessMessage,
  colorCodes,
  routePath,
} from 'src/app/core/services/utils/constants';
import { BreadCrumbService } from 'src/app/shared/services/bread-crumb/bread-crumb.service';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { TranslationService } from 'src/app/shared/services/translation.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  parentFlag: boolean = false;
  usersForm: FormGroup | any;
  userDetails: any;
  userId: any;
  userApiData: any;
  userApiResponse: any;
  phoneNumberMaxLength: number;
  attachedFile: any;
  selectedAttachedFile: any;
  staticText: any = TranslationService.staticTextData;
  previewImg: any;
  breadcrumbs: any = [];
  routePath: any = routePath;
  countries = [{ code: '+250', name: 'Uganda' }];
  currentDate = new Date();
  departmentOptions: any;
  groupsOptions: any;
  currentYear: number = new Date().getFullYear();

  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private _http: HttpService,
    protected _common: CommonService,
    private sanitizer: DomSanitizer,
    private _modal: ModalService,
    public _routeService: RouterService,
    private activated_route: ActivatedRoute,
    private _formValidator: FormValidatorService,
    private router: Router,
    private _storage: StorageService,
    private _lodash: LodashService,
    private _loader: LoaderService,
    protected breadcrumbService: BreadCrumbService,
    private _snackBar: SnackbarService,
    private cdr: ChangeDetectorRef
  ) {
    this.phoneNumberMaxLength = PHONE_NUMBER_MAX_LENGTH;
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.usersForm = this.formBuilder.group({
      username: [
        '',
        [this._formValidator.requiredValidationCheck('First Name')],
      ],
      first_name: [
        '',
        [this._formValidator.requiredValidationCheck('Last Name')],
      ],
      last_name: [
        '',
        [this._formValidator.requiredValidationCheck('Given Name')],
      ],
      email: [
        '',
        [
          this._formValidator.requiredValidationCheck('Email'),
          this._formValidator.emailValidationCheck,
        ],
      ],
      date_of_birth: [''],
      profile_photo: [''],
      local_photo: [''],
      phone_number: [
        '',
        [
          this._formValidator.requiredValidationCheck('Phone Number'),
          this._formValidator.mobilePatternValidationCheck(
            this.phoneNumberMaxLength
          ),
          this._formValidator.firstDigitZeroValidationCheck,
        ],
      ],
      country_code: [''],
    });
  }
  getFinalFormData() {
    let data = this.usersForm.getRawValue();
    let finalData: any;
    data['date_of_birth'] = this.datePipe?.transform(
      this.usersForm.value.date_of_birth,
      'yyyy-MM-dd',
      'en-US'
    );
    data['region'] = this._lodash.mapData(data.region, 'id') || [];
    data['zone'] = this._lodash.mapData(data.zone, 'id') || [];
    data['district'] = this._lodash.mapData(data.district, 'id') || [];
    delete data.mzo;
    delete data.local_photo;
    delete data.profile_photo;
    finalData = data;

    return finalData;
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.usersForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  initiateSaveAPI() {
    this._loader.show();
    let endpoint: any = Endpoints.POST_SIGNUP;
    const apiMethod = ApiMethod.POST;
    const payload = this.getFinalFormData();
    let formData: any = new FormData();
    formData.append('data', JSON.stringify(payload));
    this._http
      .requestCall(endpoint, apiMethod, formData, { observe: 'response' })
      .subscribe(
        (response: any) => {
          this._loader.hide();
          this._modal.closeDialog();
          this._snackBar.loadSnackBar(
            SuccessMessage.SIGNUP_SUBMIT_MSG,
            colorCodes.SUCCESS
          );
          this.router.navigate(['/auth/login']);
        },
        (err: any) => {
          this._loader.hide();
        }
      );
  }

  submitUsers() {
    if (this.usersForm.invalid) {
      this.findInvalidControls();
      this.usersForm.markAllAsTouched();
      return;
    } else {
      this.initiateSaveAPI();
    }
  }
}
