<div class="filter_flx flx_users_col pt_users d-flex align-items-center w-100 user_content">
  <div class="flex-column filter_title me-auto">
    <div class="text-capitalize mb-0 list_heading">
      <span [innerHtml]="approvelist? staticText?.user_management?.user?.list?.list_card_title :'Approver List'"></span>
      <span class="total_records mx-2 " *ngIf="usersList">({{totalRecords}})</span>
    </div>
    <span class=" users_clr">{{staticText?.user_management?.user?.list?.breadcrumbs}}</span>
  </div>
  <div class="custom_filter_flx w_full_users d-flex justify-content-end align-items-center ">
    <div class="d-flex w_full_filter">
      <custom-table-filter class="w_full_filter" [customTableFilterLabelClass]="''"
        [customTableFilterSource]="filterSource" [customTableFilterInDependentType]="true"
        (dependencyFilter)="dependencyFilter($event)" (customTableFilterValueEmitter)="filterParams($event)"
        [customTableFilterInDependentType]="true">
      </custom-table-filter>
    </div>

<custom-table-actions [tableExport]="tableExport" [addBtnTitle]="staticText?.user_management?.user?.list?.new_user"
  [permission]="permission.ADD_CUSTOMUSER" (add)="addUser()">
</custom-table-actions>

  </div>
</div>
<mat-card-content>
  <div class="d-flex flex-column p-4">
    <div class="d-flex">
      @for (item of _common.userMangementTab; track $index) {
      <div *ngxPermissionsOnly="item.permission">
        <div class="user-mgnt-tab-btn cursor-pointer" [ngClass]="{'active' : item.active}" (click)="tabClick(item)">
          <span class="users_grp">{{item.title}}</span>
        </div>
      </div>
      }
    </div>

    <custom-table tableId="cust-tbl-userlist" [columnDefs]="tableFields" [tablePaginationCustom]="true"
      [localColumnSearch]="true" [columnSearchOptionsList]="columnSearchOptionsList" [internalColumnSearch]="false"
      (columnSearch)="columnSearch($event)" [tableCustomActionSelectAll]="tableHeader" [lastTwoColFreezed]="true"
      [tableRowData]="usersList" [tableTotalRecords]="totalRecords" (pageChange)="pageChange($event)"
      [tableCustomActionPermissions]="actionPermissions" (deleteParam)="deleteData($event)"
      (actionParam)="onEditAction($event)" (CustomActionParam)="onCustomAction($event)"
      (sortChange)="sortColumn($event)">
    </custom-table>
  </div>
</mat-card-content>