import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  APPLICATION,
  colorCodes,
  ErrorCodes,
  routePath,
} from 'src/app/core/services/utils/constants';
import { environment } from 'src/environments/environment';
import { NgOtpInputComponent, NgOtpInputConfig } from 'ng-otp-input';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import { CommonService } from 'src/app/core/services/common/common.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import { Subscription } from 'rxjs';
import { OtpTimerResendComponent } from '../otp-timer-resend/otp-timer-resend.component';
import { AutofillMonitor } from '@angular/cdk/text-field';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { InitiateOnboardingComponent } from 'src/app/components/scp/initiate-onboarding/initiate-onboarding.component';
import { TranslationService } from 'src/app/shared/services/translation.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  appConfigKeys: any = APPLICATION?.keys;
  appConfig: any = APPLICATION.config;
  countryLogoName: any;
  countryLogoAttachment: any;
  currentYear: number = new Date().getFullYear();
  copyRight: string = ` ${this.appConfig?.branding_setup?.copyright}`;
  appLogo: any = {};
  appearenceoutline: any;
  mailid: any = 'Email ID';
  passwordid: any = 'Password';
  userEmail: string = '';

  loginForm: FormGroup | any;
  staticText: any = TranslationService.staticTextData;
  enableCaptcha: boolean = false;
  loginFailed: boolean = false;
  showOTPForm: boolean = true;
  otpValue: any = '';
  isOtpValid: boolean = false;
  routePath: any = routePath;
  landingUrl = environment.websiteLandingUrl;
  parentFlag: boolean = false;
  // For OTP verisication...........................
  enableLoginOtp: boolean = environment?.enableLoginOtp;
  otpProgress: boolean = false;
  @ViewChild(NgOtpInputComponent, { static: false })
  ngOtpInput!: NgOtpInputComponent;
  @ViewChild(OtpTimerResendComponent)
  otpTimerResendComponent!: OtpTimerResendComponent;
  config: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
  };
  otpInputFormEleRef: any;
  showNewUserRegn: boolean = true;
  showPassword: boolean = false;

  otpAction: string = 'send';
  loginFormSection: boolean = true;
  otpFormSection: boolean = false;
  verifyOtpBtn: boolean = true;
  loginErrorValidation!: Subscription;

  otpErrorMsg: string = '';
  autofilled: boolean = false;
  private autofillSubscriptions: Subscription[] = [];
  // ...........................For OTP verisication
  constructor(
    private autofillMonitor: AutofillMonitor,
    private _authService: AuthService,
    private _formValidator: FormValidatorService,
    private _formbuilder: FormBuilder,
    private _router: Router,
    private cdr: ChangeDetectorRef,
    private _storage: StorageService,
    private activated_route: ActivatedRoute,
    private _loader: LoaderService,
    private _common: CommonService,
    private _snackBar: SnackbarService,
    private router: Router,
    private _modal: ModalService
  ) {}

  ngOnInit(): void {
    const appConfig = JSON.parse(localStorage.getItem('appConfig') || '{}');
    this.countryLogoName = appConfig?.branding_setup?.name;
    this.countryLogoAttachment =
      appConfig?.branding_setup?.country_logo[0]?.attachment;

    this.appLogo =
      this.appConfig?.branding_setup?.country_logo?.length > 0
        ? this.appConfig?.branding_setup?.country_logo[0]
        : 'assets/images/common/rwanda_logo.svg';

    // Store in local storage
    localStorage.setItem('appLogoasset', this.appLogo);

    if (
      this.activated_route.snapshot.data['resolvedData']?.data
        ?.is_setup_completed == false
    ) {
      this.router.navigate(['dts/configuration']);
    }

    this.enableCaptcha = environment?.enableCaptcha;
    if (this._authService.isLoggedIn()) {
      let permission: any = this._storage.getPermssions();
      localStorage.removeItem('activeMenu');
      this._authService.checkRole();
    } else {
      this.initLoginform();
    }

    this._authService.loginFailed.subscribe((data: any) => {
      this.loginFailed = data;
      this.otpValue = '';
      this.cdr?.detectChanges();
    });
    this._authService.isOTPRequired.subscribe((data: any) => {
      if (data) {
        this.showOTPForm = true;
      }
      this.cdr?.detectChanges();
    });
    this.loginErrorValidation =
      this._authService.loginErrorValidation.subscribe((data: any) => {
        this.otpErrorMsg = '';
        if (data?.error?.message.includes('OTP'))
          this.otpErrorMsg = data?.error?.message;
        if (data?.error?.message.includes('user does not exist'))
          this.loginForm
            .get('email')
            .setErrors({ validation_message: data?.error?.message });
        else
          this.loginForm
            .get('password')
            .setErrors({ validation_message: data?.error?.message });
        this.cdr?.detectChanges();
      });
  }

  ngAfterViewInit() {
    this.otpInputFormEleRef = this.ngOtpInput.otpForm;

    Object.keys(this.loginForm?.controls).forEach((controlName) => {
      if (controlName == 'email' || controlName == 'password') {
        const controlElement = document.querySelector(
          `[inputformcontrolname="${controlName}"] input[matinput]`
        );
        if (controlElement instanceof HTMLElement) {
          const autofillSub = this.autofillMonitor
            .monitor(controlElement)
            .subscribe((e) => {
              this.autofilled = e.isAutofilled;
            });
          this.autofillSubscriptions.push(autofillSub);
        }
      }
    });
  }

  showHidePassword(ev: any) {
    ev.stopPropagation();
    this.showPassword = !this.showPassword;
  }

  initiateRegistration(ev: any) {
    this._modal
      .openCommonDialog({
        width: '700px',
        component: InitiateOnboardingComponent,
        data: {
          title: this.staticText?.scp?.view_register?.initiate_reg_title,
          proceedBtnTxt:
            this.staticText?.scp?.view_register?.proceed_reg_init_btn,
          instruction: [
            this.staticText?.scp?.view_register?.initiate_detail_reg_field1,
            this.staticText?.scp?.view_register?.initiate_detail_reg_field2,
            this.staticText?.scp?.view_register?.initiate_detail_reg_field3,
            this.staticText?.scp?.view_register?.initiate_detail_reg_field4,
          ],
        },
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (result !== false) {
          this.navigateSelfRegister();
        }
      });
  }

  navigateSelfRegister() {
    return this._router.navigate([routePath?.SELF_REGISTER_PATH]);
  }

  initLoginform() {
    this.loginForm = this._formbuilder.group({
      recaptcha: [
        !environment?.enableCaptcha,
        [this._formValidator.requiredValidationCheck('Recaptcha')],
      ],
      email: [
        '',
        [Validators.required, this._formValidator.emailValidationCheck],
      ],
      password: ['', []],
    });
  }

  onOtpChange(otp: any) {
    this.isOtpValid = false;
    this.loginFailed = false;
    if (otp.length == 6) {
      this.otpValue = otp;
      this.isOtpValid = true;
    }
  }

  onResendOtpHandler(event: any) {
    if (event == 'resend') {
      this.otpAction = event;
      this.ngOtpInput.setValue(0);
      this.otpInputFormEleRef.disable();
      this.sendOtp();
    }
  }

  SpaceValidator(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
      return { required: true };
    } else {
      return null;
    }
  }

  doLogin() {
    this.otpErrorMsg = '';
    let loginPayload: any = {
      username: this.loginForm.value?.email,
      password: this.loginForm.value?.password,
    };

    if (this.enableLoginOtp) {
      if (this.isOtpValid) {
        loginPayload = { ...loginPayload, ...{ otp: this.otpValue } };
      } else {
        this._snackBar.loadSnackBar('Enter the valid OTP', colorCodes.WARNING);
        return;
      }
    }

    this._authService.login(loginPayload, this.enableLoginOtp);
  }

  onCaptchaResolved(captchaResponse: any) {
    if (captchaResponse) {
      this.loginForm.get('recaptcha').setValue(true);
    }
  }

  onCaptchaErrored(captchaError: any) {
    this.loginForm.get('recaptcha').setValue('');
  }
  navigation() {
    this._router.navigate(['/auth/signup']);
  }
  redirectForgot() {
    this._router.navigate(['/auth/forgot']);
  }

  /**
   * @description
   * OTP Generate API...................
   * @param ev
   */
  sendOtp(ev?: any) {
    let loginPayload: any = {
      username: this.loginForm.value?.email,
      password: this.loginForm.value?.password,
    };
    this._loader.show();
    this.otpErrorMsg = '';
    this._authService.sendOTP(loginPayload).subscribe((otpRes: any) => {
      this._loader.hide();
      if (otpRes?.code == ErrorCodes.HTTP_200_SUCCESS) {
        this._snackBar.loadSnackBar(otpRes?.data?.detail, colorCodes.SUCCESS);
        this.userEmail = otpRes?.data?.email;
        this.otpInputFormEleRef.enable();
        this.otpTimerResendComponent.showTimer();
        this.otpProgress = true;
        this.showOTPForm = true;
        this.loginFormSection = false;
        this.otpFormSection = true;
        this.cdr?.detectChanges();
        // this._router.navigate(['/auth/otp']);
      }
    });
  }
  checkRouteForNewUser() {
    // const currentUrl = this.router.url;
    // if (currentUrl === '/scp/auth/login') {
    //   this.showNewUserRegn = true;
    // } else {
    //   this.showNewUserRegn = false;
    // }
  }

  hasPasswordValue(): boolean {
    return this.loginForm?.get('password')?.value?.length > 0;
  }

  showLoginForm() {
    this.otpFormSection = false;
    this.loginFormSection = true;
    this.otpProgress = false;
  }

  ngOnDestroy() {
    this.loginErrorValidation.unsubscribe();
    this.autofillSubscriptions.forEach((sub) => sub.unsubscribe());
  }
  navigate() {
    this.router.navigate(['/auth/login']);
  }
}
