<div class="view-container">
    <div class="view-header">
        <h1 class="welcome_note">{{staticText?.scp?.application_status?.welcome_note}}</h1>
        <p class="hub_details">{{staticText?.scp?.application_status?.hub_details}}</p>
    </div>
    <div class="view-body">
        <span class="status-title">{{staticText?.scp?.application_status?.title}}</span>
        <div class="container-fluid">
            @for (item of scpDetails?.records;track $index){
            <div class="row field-data d-flex align-items-center">
                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 mt_application">
                    <label for=""
                        class="form-label color-primary fs-16">{{staticText?.scp?.application_status?.req_id}}</label>
                    <p class="color-darkgrey fw-600 mb-0" title="">{{item?.registration_code}}</p>
                </div>
                <div class="col-xl-8 col-lg-8 col-md-7 col-sm-12 center-content">
                    <div class="row  d-flex justify-content-between padding_gap_view">
                        <div class="col-xl-2 col-lg-6  col-md-6 col-sm-4 col-6 mb-2">
                            <label for=""
                                class="form-label color-primary fs-16 mb-0">{{staticText?.scp?.application_status?.lic_type}}</label>
                            <p class="color-darkgrey fw-600 mb-0" title="">{{item?.license_type?.display_name}}</p>
                        </div>
                        <div class="col-xl-2  col-lg-6  col-md-6  col-sm-4 col-6 mb-2">
                            <label for=""
                                class="form-label color-primary fs-16 mb-0">{{staticText?.scp?.application_status?.company_name}}</label>
                            <p class="color-darkgrey fw-600  mb-0 company_name" title="">{{item?.company_name}}</p>
                        </div>
                        <div class="col-xl-2  col-lg-6  col-md-6  col-sm-4 col-6 mb-2">
                            <label for=""
                                class="form-label color-primary fs-16 mb-0">{{staticText?.scp?.application_status?.submission_date}}</label>
                            <p class="color-darkgrey fw-600  mb-0" title="">{{item?.created_on | date : "dd/MM/yyyy"}}
                            </p>
                        </div>
                        <div class="col-xl-2  col-lg-6  col-md-6  col-sm-4 col-6 mb-2">
                            <label for=""
                                class="form-label color-primary fs-16 mb-0">{{staticText?.scp?.application_status?.approval_status}}</label>
                            <p class="color-darkgrey  mb-0" title="">
                                <span [style.backgroundColor]="item?.status?.color_code"
                                    [style.color]="item?.status?.text_color_code" class="status_align_sts">
                                    {{ item?.status?.action_complete_label }}
                                </span>

                            </p>
                        </div>
                        @if (item?.onboard_status) {
                        <div class="col-xl-2  col-lg-6  col-md-6  col-sm-4 col-6 mb-2">
                            <label for=""
                                class="form-label color-primary fs-16 mb-0">{{staticText?.scp?.application_status?.onboarding_status}}</label>
                            <p class="color-darkgrey   mb-0" title="">
                                <span [style.color]="item?.onboard_status?.text_color_code"
                                    [style.background-color]="item?.onboard_status?.color_code"
                                    class="status_align_sts">
                                    {{item?.onboard_status?.action_complete_label}}
                                </span>
                            </p>
                        </div>
                        }
                    </div>
                </div>

                <div class="col-xl-2 col-lg-2 col-md-3 view_col" (click)="navigateView(item)">
                    <span class="color-darkgrey " title=""><img src="../../../assets/images/common/icons/view.png"
                            alt="View" /></span>
                    <p class="view_para mb-0">{{staticText?.scp?.application_status?.view_details}}</p>
                </div>

            </div>
            }


        </div>
    </div>

    @if (!scpDetails?.records?.[0]?.allow_onboard_initiation) {
    <div class="d_view_btn" *ngIf="scpDetails?.records?.[0]?.is_comment_added">
        <div class="d-flex align-items-center d_flx_gap">
            <div>
                <img src="../../../assets/images/common/icons/info.svg" alt="info" />

            </div>
            <div class="comment_div">
                {{staticText?.scp?.application_status?.cmt}}
            </div>
        </div>
        <div>
            <button class="view_btn" (click)="navigateView(scpDetails?.records?.[0])">
                <img src="../../../assets/images/common/icons/view_white.png" alt="View" />

                <span>{{staticText?.scp?.application_status?.view_cmt}}</span> </button>

        </div>
    </div>
    }@else if(!scpDetails?.records?.[0].onboard_complete){
    <div class=" onboarding-container gap_onboard">
        <div>
            <span>
                <img src="../../../assets/images/common/icons/handshake.svg" alt="Handshake" />
            </span>
        </div>
        <div class="fs-24 fw-bold">
            <span>
                {{staticText?.scp?.application_status?.congratulation}}
            </span>
        </div>
        <div class="w_div">
            <span>{{staticText?.scp?.application_status?.onboarding_txt}}</span>
        </div>
        <div class="d-flex justify-content-center" *ngxPermissionsOnly="[permission.ADD_ONBOARD]">
            <span>
                @if (scpDetails?.records?.[0].onboard_exists) {

                <button class="view_btn" (click)="navigateToOnboard(scpDetails?.records?.[0])">
                    <span>{{staticText?.scp?.application_status?.edit_onboarding_btn}}</span></button>
                }@else {
                <button class="view_btn" (click)="initiateOnboarding(scpDetails?.records?.[0])">
                    <span>{{staticText?.scp?.application_status?.initiate_onboarding_process}}</span>
                    <img src="../../../../assets/images/common/icons_usp/right arrow.svg" alt="right arrow" />
                </button>
                }
            </span>
        </div>
        <div class="foot_height_initiate"></div>
    </div>
    }