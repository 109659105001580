import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, switchMap, filter, take, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
import { HttpService } from '../services/http/http.service';
import { RouterService } from '../services/router/router.service';
import { StorageService } from '../services/storage/storage.service';
import {
  Endpoints,
  ErrorCodes,
  ErrorMessage,
  HIDENOTIFY,
} from '../services/utils/constants';
import { LodashService } from '../services/lodash/lodash.service';
import { environment } from 'src/environments/environment';
import { SocketService } from '../services/inApp/socket.service';
import { CommonService } from '../services/common/common.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private refreshingInProgress: boolean = false;
  private accessTokenSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  urlsToNotUse: any;
  constructor(
    private _socket: SocketService,
    private _storage: StorageService,
    private _authService: AuthService,
    private _lodash: LodashService,
    private _router: RouterService,
    private _http: HttpService,
    public _common: CommonService,
  ) {
    this.urlsToNotUse = [Endpoints.GET_GEO_MINIMAL_LAYER_LIST];
  }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    let resp: any = this._lodash.some(this.urlsToNotUse, (o: any) =>
      requestUrl.match(o)
    );
    return !!resp?.length;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const accessToken: any = this._storage.getToken();
    if (request.headers.get('exclude') == 'authtoken') {
      let customReq = request.clone({
        headers: request.headers
          .delete('Content-Type')
          .delete('exclude')
          .delete('authorization'),
      });
      return next.handle(customReq);
    }

    if (this.isValidRequestForInterceptor(request.url)) {
      return next.handle(request);
    }
    return next.handle(this.addAuthorizationHeader(request, accessToken)).pipe(
      catchError((err: any): any => {
        //for hide API Error notify
        const hideNotify = request.headers.get('Error-Field-Affected');
        if (
          (err.status === 400 || err.status === 401) &&
          hideNotify == HIDENOTIFY
        ) {
          return throwError(() => err);
        }

        if (err.status === ErrorCodes?.HTTP_422_UNPROCESSABLE_ENTITY)
          return throwError(() => err);

        if (err instanceof HttpErrorResponse && err.status === 401) {
          if (
            err?.error?.errors?.detail.includes(
              ErrorMessage.REFRESH_TOKEN_EXPIRY
            )
          ) {
            const refreshToken = this._storage.getToken();

            if (refreshToken || this.refreshingInProgress) {
              return this.refreshToken(request, next);
            }
          } else {
            this._http.handleRefreshTokenExpiry(err);
          }
        }
        if (err instanceof HttpErrorResponse && err.status === 403) {
          // logout and redirect to login page
          this._common.enableMenus.next(false);
          return this.logoutAndRedirect(err);
        }
        this._http.handleError(err);
      })
    );
  }

  private logoutAndRedirect(err: any): Observable<HttpEvent<any>> {
    if (
      err?.error?.detail == ErrorMessage.AUTHORIZATION_TOKEN_ERROR ||
      err?.error?.errors?.detail.includes(ErrorMessage.AUTH_TOKEN_ERROR) ||
      err?.error?.errors?.detail.includes(ErrorMessage.BEARER_TOKEN_ERROR)
    ) {
      this._authService.logout();
    }
    this._http.handleError(err);
    return throwError(() => err?.error?.detail);
  }

  private addAuthorizationHeader(
    request: HttpRequest<any>,
    token: string
  ): HttpRequest<any> {
    // if(request.url.includes('contract-folder-view')) {
    //   return request.clone({
    //     headers: request.headers.delete('Content-Type', 'application/json'),
    //     setHeaders: { 'Content-Type': 'application/x-www-form-urlencoded' }
    //   });
    // } else

    if (
      request.url.includes(Endpoints.POST_REFRESH_TOKEN_AUTH) ||
      request.url.includes(Endpoints.POST_PASSWORD_RESET_VERIFY) ||
      request.url.includes(Endpoints.POST_PASSWORD_RESET)
    ) {
      return request.clone({
        setHeaders: { 'Content-Type': 'application/json' },
      });
    } else if (token) {
      if (request.headers.get('Content-Type') === 'multipart/form-data') {
        return request.clone({
          headers: request.headers.delete(
            'Content-Type',
            'multipart/form-data'
          ),
          setHeaders: { authorization: `Bearer ${token}` },
        });
      }

      return request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
          ...(environment.skipngRok && {
            'ngrok-skip-browser-warning': 'any-value',
          }),
        },
      });
    } else if (!token) {
      if (request.headers.get('Content-Type') === 'multipart/form-data') {
        return request.clone({
          headers: request.headers.delete(
            'Content-Type',
            'multipart/form-data'
          ),
        });
      }

      return request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          ...(environment.skipngRok && {
            'ngrok-skip-browser-warning': 'any-value',
          }),
        },
      });
    }
    return request;
  }

  private refreshToken(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.refreshingInProgress) {
      this.refreshingInProgress = true;
      this.accessTokenSubject.next('');
      return this._http.refreshToken().pipe(
        switchMap((token: any) => {
          // if (this._socket.socket$) {
          //   this._socket.socket$.complete();
          //   const WS_URL = 'wss://api.usp-dev.easyngo.com/iam/ws/notifications/';
          //   this._socket.connect(WS_URL, token?.data?.access)
          // }
          this.refreshingInProgress = false;
          this.accessTokenSubject.next(token?.data?.access);
          // repeat failed request with new token
          return next.handle(
            this.addAuthorizationHeader(request, token.data.access)
          );
        })
      );
    } else {
      // wait while getting new token
      return this.accessTokenSubject.pipe(
        filter((token) => token != ''),
        take(1),
        switchMap((access) => {
          // repeat failed request with new token
          return next.handle(this.addAuthorizationHeader(request, access));
        })
      );
    }
  }
}
