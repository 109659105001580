import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommonService } from 'src/app/core/services/common/common.service';
import { HttpService } from 'src/app/core/services/http/http.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import { Router } from '@angular/router';
import {
  ApiMethod,
  APPLICATION,
  Endpoints,
  ErrorCodes,
  DEFAULT_LANGUAGE_CODE,
  USERGROUPS,
} from 'src/app/core/services/utils/constants';
// import { DashboardComponent } from 'src/app/modules/dashboard/dashboard.component'
import { DataService } from 'src/app/shared/services/data/data.service';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() sideNav: any;
  appConfigKeys: any = APPLICATION?.keys;
  appConfig: any = APPLICATION.config;
  countryLogoName: any;
  countryLogoAttachment: any;

  // @ViewChild('dashboard',{static:false})dashboard:DashboardComponent;
  @Output() istoggle = new EventEmitter();
  showCollapseState: boolean = true;
  showNotificationPopUp: boolean = false;
  showPopup: boolean = false;
  userDetails: any;
  userName: any;
  staticText: any = TranslationService.staticTextData;
  data: any = {};
  notifications = [
    {
      title: 'Order Approved',
      message:
        'Your order "O202344" request has been approved by the Revenue Authority. Please complete your payment to receive your order.',
      sender: 'Revenue Authority',
      time: '15min ago',
      isRead: true,
    },

    // Additional notifications
  ];
  appLogo: any;
  enableMultiLingual: boolean = environment.enableMultiLingual;
  companyInfo: any = {};

  headerLogo$: Observable<any> = new Observable<any>();
  headerName$: Observable<any> = new Observable<any>();

  constructor(
    private _storage: StorageService,
    private _loader: LoaderService,
    private _http: HttpService,
    public _common: CommonService,
    private dialog: MatDialog,
    private authService: AuthService,
    private dataService: DataService,
    private router: Router,
    private _auth: AuthService
  ) {}

  ngOnInit(): void {
    this.headerLogo$ = this._common.getHeaderLogo();
    this.headerName$ = this._common.getHeaderName();

    this.getOnboardDetails();
    const appConfig = this._common.getAppConfig();

    this._common.fetchPermssions();
    this.userDetails = this._storage.getUserDetails();

    this.userName = `${this.userDetails.first_name
      .charAt(0)
      .toUpperCase()}${this.userDetails.last_name.charAt(0).toUpperCase()}`;
  }

  getOnboardDetails() {
    this._auth.getOnboardingStatus().subscribe((loginResponse: any) => {
      this.companyInfo = loginResponse;
    });
  }
  sideNavToggle() {
    this.sideNav?.toggle();
    // this.dashboard.chartReRender();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
    this.dataService.chartRender.next(true);
  }

  @HostListener('mouseleave')
  onMouseOver() {
    this.showPopup = false;
  }

  navigateToScpView(): void {
    this.router.navigate([
      `scp-mgmt/view-onboarding-status/${this.companyInfo?.company_id}`,
    ]);
  }

  get isScp(): boolean {
    const loginRes = this._storage.getUserDetails();
    return (
      loginRes?.groups?.[0]?.name ==
      USERGROUPS.SUPPLY_CHAIN_PARTICIPANT_ADMINISTRATOR
    );
  }

  toggleSidenav() {
    this.dialog.ngOnDestroy();
    this.showCollapseState = !this.showCollapseState;
    this.istoggle.emit(this.showCollapseState ? 'standard' : 'icon');
  }

  getProfilePhoto() {
    return (
      this.userDetails?.profile_photo ||
      'assets/images/common/icons/logo_img/default_profile.svg'
    );
  }

  logout() {
    this.clearLoginSession();
  }

  clearLoginSession() {
    this._loader.show();
    this._common.enableMenus.next(false);
    let endpoint: any = `${Endpoints.LOGOUT}`;
    this._http
      .requestCall(endpoint, ApiMethod.POST, {
        refresh: this._storage.geRefreshtToken(),
      })
      .subscribe((response: any) => {
        if (response?.code == ErrorCodes.HTTP_200_SUCCESS) {
          this._loader.hide();
          localStorage.setItem(`lang`, DEFAULT_LANGUAGE_CODE);
          this.authService.logout();
        }
      });
  }

  @HostListener('document:click', ['$event'])
  onGlobalClick(event: any): void {
    if (
      !document.getElementById('notification')?.contains(event?.target) &&
      !document.getElementById('notificationBox')?.contains(event?.target)
    ) {
      this.showNotificationPopUp = false;
    }
  }
  navigate() {
    this.router.navigate(['/auth/login']);
  }
}
