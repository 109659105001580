import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { CommonService as CoreCommonService } from 'src/app/core/services/common/common.service';
import { HttpService } from 'src/app/core/services/http/http.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { RouterService } from 'src/app/core/services/router/router.service';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import {
  routePath,
  Endpoints,
  ApiMethod,
  SuccessMessage,
  colorCodes,
  FailedMessage,
  SCREENMODE,
  APPLICATION,
  PWD_POLICY,
} from 'src/app/core/services/utils/constants';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';
import { TranslationService } from 'src/app/shared/services/translation.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  email: string = '';

  resetPasswordForm: any;

  resetToken: any;
  routePath: any = routePath;
  staticText: any = TranslationService.staticTextData;
  appConfig: any = APPLICATION.config;
  countryLogoName: any;
  countryLogoAttachment: any;
  showForm: boolean = true;
  currentYear: number = new Date().getFullYear();
  measureStrength: any;
  passwordStrength: number = 0;

  strengthLabel =
    this.staticText?.reset_password?.reset_password_strength_label;
  token: any;
  pageMode: any;
  screenmode: any = SCREENMODE;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  pwdRules: any = {};
  pwdRegExPattern!: any;
  pwdRuleInfo!: any;
  pwdRuleText!: any;
  pwdRuleList: any = [];
  samplePwd: string = '';
  copyRight!: string;
  constructor(
    private _authService: AuthService,
    private _http: HttpService,
    protected _router: RouterService,
    private formBuilder: FormBuilder,
    private _loader: LoaderService,
    private _snackBar: SnackbarService,
    private _formValidator: FormValidatorService,
    private router: Router,
    private _common: CommonService,
    private _coreCommon: CoreCommonService,
    private activated_route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getRouteParams();
    this._coreCommon.getAppConfig().subscribe((response: any) => {
      this.appConfig = response;
      this.copyRight = `${this.currentYear} ${this.appConfig?.branding_setup?.copyright}`;
      const appConfig = JSON.parse(localStorage.getItem('appConfig') || '{}');
      this.countryLogoName = appConfig?.branding_setup?.name;
      this.countryLogoAttachment =
        appConfig?.branding_setup?.country_logo[0]?.attachment;
    });
    const params = this.appConfig?.password_policy ?? PWD_POLICY;
    this.pwdRuleInfo = this.staticText?.common?.password_rule_info;

    params['min_length'] = Number(params['min_length']);
    params['max_length'] = Number(params['max_length']);
    params['min_lower'] = Number(params['min_lower']);
    params['min_upper'] = Number(params['min_upper']);
    params['min_digits'] = Number(params['min_digits']);

    this.pwdRules = {
      length: params['min_length'] ? params['min_length'] : 0,
      maxLength: params['max_length'] ? params['max_length'] : 0,
      specialChars: params['special'] ? params['special'] : 0,
      upperCase: params['min_upper'] ? params['min_upper'] : 0,
      lowerCase: params['min_lower'] ? params['min_lower'] : 0,
      digits: params['min_digits'] ? params['min_digits'] : 0,
      regexPattern: params['special_chars'] ? params['special_chars'] : '',
    };
    this.pwdRegExPattern = this._common.generateRegex(this.pwdRules);

    const constructRule = [
      this.pwdRuleInfo?.message,
      this.pwdRules.lowerCase > 0
        ? `${this.pwdRuleInfo?.lower
            .toString()
            .replace('{num}', this.pwdRules.lowerCase)}`
        : ``,
      this.pwdRules.upperCase > 0
        ? `${this.pwdRuleInfo?.upper
            .toString()
            .replace('{num}', this.pwdRules.upperCase)}`
        : ``,
      this.pwdRules.digits > 0
        ? `${this.pwdRuleInfo?.digit
            .toString()
            .replace('{num}', this.pwdRules.digits)}`
        : ``,
      this.pwdRules.specialChars
        ? `${this.pwdRuleInfo?.special
            .toString()
            .replace('{num}', this.pwdRules.specialChars)} from (${
            this.pwdRules.regexPattern
          })`
        : this.pwdRules.regexPattern
        ? `${this.pwdRuleInfo?.special
            .toString()
            .replace('{num}', this.pwdRules.regexPattern)}`
        : ``,
    ];

    this.pwdRuleText = `${constructRule.join(', ')}.`;
    this.pwdRuleList = [
      `${this.pwdRuleInfo?.length
        .toString()
        .replace('{min}', this.pwdRules.length)
        .replace('{max}', this.pwdRules.maxLength)}`,
      this.pwdRuleText,
    ];
    this.samplePwd = this._common.generatePassword(this.pwdRules);
    this.initialDependencies();
  }
  loginpage() {
    this.router.navigate(['/auth/login']);
  }

  showHidePassword(ev: any) {
    ev.stopPropagation();
    this.showPassword = !this.showPassword;
  }

  showHideConfirmPassword(ev: any) {
    ev.stopPropagation();
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  getRouteParams() {
    const routeInfo: any = this.activated_route?.data;
    this.pageMode = routeInfo?.value?.mode;
    if (this.pageMode == this.screenmode.RESET_PASSWORD) {
      this.verifyResetToken();
    } else {
      this.showForm = true;
    }
  }

  initialDependencies() {
    this.createForm();
  }

  getResetTokenVerification() {
    this.resetToken = this._router.getResetPasswordToken();
  }

  redirectLogin() {
    this._authService.logout();
  }

  createForm() {
    this.resetPasswordForm = this.formBuilder.group({
      new_password: [
        null,
        [
          // this._formValidator.passwordValidationCheck,
          this._formValidator.passwordValidationRuleCheck(
            'New password',
            this.pwdRegExPattern,
            this.pwdRules
          ),
          // this._formValidator.minMaxLengthCheck(8, 12),
        ],
      ],
      confirm_password: [null],
    });

    // Custom validation for confirm_password
    this.resetPasswordForm.setValidators(
      this._formValidator.confirmPasswordValidationCheck(
        'new_password',
        'confirm_password'
      )
    );

    // Listening to value changes in the 'new_password' field
    this.resetPasswordForm
      .get('new_password')
      ?.valueChanges.subscribe((password: any) => {
        this.measureStrength = password;
      });
  }

  verifyResetToken() {
    this._loader.show();
    const payload = { token: this._router.getResetPasswordToken() };
    this._http
      .requestCall(
        Endpoints.POST_PASSWORD_RESET_VERIFY,
        ApiMethod.POST,
        payload
      )
      .subscribe(
        (tokenStatus: any) => {
          this._loader.hide();
          if (tokenStatus) {
            this.showForm = true;
          } else {
            this.showForm = false;
          }
        },
        (error: any) => {
          this._loader.hide();
          this.showForm = false;
        }
      );
  }

  resetPassword() {
    if (this.pageMode == SCREENMODE.RESET_PASSWORD) {
      this.resetPasswordFromMail();
    } else if (this.pageMode == SCREENMODE.CHANGE_PASSWORD) {
      this.initialResetPassword();
    }
  }

  initialResetPassword() {
    this._loader.show();
    const payload = {
      new_password: this.resetPasswordForm.value.new_password,
    };
    this._http
      .requestCall(Endpoints.POST_PASSWORD_UPDATE, ApiMethod.POST, payload)
      .subscribe((resetStatus: any) => {
        if (resetStatus) {
          this._loader.hide();
          this._snackBar.loadSnackBar(
            SuccessMessage.RESET_PASSWORD_MSG,
            colorCodes.SUCCESS
          );
          this.router.navigate(['/auth/login']);
        } else {
          this._loader.hide();
          this._snackBar.loadSnackBar(
            FailedMessage.RESET_PASSWORD_FAILED_MSG,
            colorCodes.ERROR
          );
        }
      });
  }
  login_page() {
    this._router.navigate(['/auth/login']);
  }

  resetPasswordFromMail() {
    this._loader.show();
    const payload = {
      password: this.resetPasswordForm.value.new_password,
      token: this._router.getResetPasswordToken(),
    };
    this._http
      .requestCall(Endpoints.POST_PASSWORD_RESET, ApiMethod.POST, payload)
      .subscribe((resetStatus: any) => {
        if (resetStatus) {
          this._loader.hide();
          this._snackBar.loadSnackBar(
            SuccessMessage.RESET_PASSWORD_MSG,
            colorCodes.SUCCESS
          );
          this.router.navigate(['/auth/password-success']);
        } else {
          this._loader.hide();
          this._snackBar.loadSnackBar(
            FailedMessage.RESET_PASSWORD_FAILED_MSG,
            colorCodes.ERROR
          );
        }
      });
  }
  hasPasswordValue(): boolean {
    return this.resetPasswordForm?.get('new_password')?.value?.length > 0;
  }
  hasshowPasswordValue(): boolean {
    return this.resetPasswordForm?.get('confirm_password')?.value?.length > 0;
  }
  navigate() {
    this.router.navigate(['/auth/login']);
  }
}
