import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgOtpInputModule } from  'ng-otp-input';
import { UserManagementModule } from '../user-management/user-management.module';
import { SignUpComponent } from './sign-up/sign-up.component';

import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';

import { MatIconModule } from '@angular/material/icon';
import { OtpTimerResendComponent } from './otp-timer-resend/otp-timer-resend.component';
import { ResetpasswordSuccessComponent } from './resetpassword-success/resetpassword-success.component';

@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SignUpComponent,
    OtpTimerResendComponent,
    ResetpasswordSuccessComponent
    
  ],
  imports: [
   MatFormFieldModule, MatInputModule, MatSelectModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule,
    CommonModule,
    AuthRoutingModule,
    SharedModule,
    NgOtpInputModule,
    UserManagementModule,
   
  ],
  providers: [
  ],
  exports:[OtpTimerResendComponent]
})
export class AuthModule { }
