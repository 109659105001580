import { Component, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { DynamicFormComponent } from '../../../shared/components/dynamic-form/dynamic-form.component';
import { GuestHeaderComponent } from '../../../layout/guest-header/guest-header.component';
import { CustomFieldsModule } from '../../../shared/components/mat-elements/custom-fields/custom-fields.module';
import { HttpService } from 'src/app/core/services/http/http.service';
import {
  ApiMethod,
  Endpoints,
  routePath,
  colorCodes,
  HIDENOTIFY,
  APPLICATION,
} from 'src/app/core/services/utils/constants';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { CommonModule, DatePipe } from '@angular/common';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common/common.service';
import { FORM_SECTIONS } from '../form-config/form-config.model';
import { catchError, Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { AuthModule } from 'src/app/modules/auth/auth.module';
import { OtpTimerResendComponent } from 'src/app/modules/auth/otp-timer-resend/otp-timer-resend.component';
import { SharedModule } from 'src/app/shared/shared.module';
import _ from 'lodash';
import { postalCodeField } from 'src/app/shared/components/dynamic-form/form-sections.model';
import { TranslationService } from 'src/app/shared/services/translation.service';

@Component({
  selector: 'app-self-register',
  standalone: true,
  imports: [
    CommonModule,
    DynamicFormComponent,
    GuestHeaderComponent,
    CustomFieldsModule,
    AuthModule,
    SharedModule,
  ],
  templateUrl: './self-register.component.html',
  styleUrl: './self-register.component.scss',
})
export class SelfRegisterComponent {
  appConfig: any = APPLICATION.config;
  currentYear: number = new Date().getFullYear();
  formName = 'scbRegisterForm';
  form: FormGroup | any;
  @ViewChild(DynamicFormComponent) dynamicFormComponent!: DynamicFormComponent;
  @ViewChild(OtpTimerResendComponent)
  otpTimerResendComponent!: OtpTimerResendComponent;
  staticText: any = TranslationService.staticTextData;
  disableForm: boolean = true;
  mode: string = 'add';
  otpInitiated: boolean = false;
  selectedFiles: { [key: string]: File } = {};
  routePath: any = routePath;
  formConfig: any;
  loadMasterAPI: string = 'certificate_info';
  tinFormElement!: any;
  isTinValidationRequired: boolean =
    this.appConfig?.tin_information?.is_tin_validation_required;
  enableTINOtp: boolean =
    this.appConfig?.tin_information?.is_tin_otp_validation_required;
  copyRight!: string;

  constructor(
    private fb: FormBuilder,
    private _http: HttpService,
    private _modal: ModalService,
    private _loader: LoaderService,
    private _formValidator: FormValidatorService,
    private datePipe: DatePipe,
    private router: Router,
    private _common: CommonService
  ) {
    this.prepareTinFormConfig();
  }

  ngOnInit() {
    this._common.getAppConfig().subscribe((response: any) => {
      this.appConfig = response;
    });
    this.copyRight = ` ${this.appConfig?.branding_setup?.copyright}`;
    this.isTinValidationRequired =
      this.appConfig?.tin_information?.is_tin_validation_required;
    this.enableTINOtp =
      this.appConfig?.tin_information?.is_tin_otp_validation_required;

    this.disableForm = this.isTinValidationRequired ? true : false;
    this.prepareTinFormConfig();
    this.formConfig = this.processFormConfig();
  }

  prepareTinFormConfig() {
    this.tinFormElement = {
      business_id: [
        '',
        [
          this._formValidator.requiredValidationCheck(
            this.staticText?.scp?.register?.view_edit?.business_id
          ),
          this._formValidator.minMaxLengthCheck(3, 100),
          // this._formValidator.alphanumericValidationcheck,
        ],
      ],
      id_verified: [
        false,
        this.isTinValidationRequired ? Validators.requiredTrue : '',
      ],
    };

    if (this.enableTINOtp) {
      this.tinFormElement['otp'] = [
        '',
        [
          this._formValidator.requiredValidationCheck(
            this.staticText?.scp?.register?.view_edit?.otp
          ),
          this._formValidator.minMaxLengthCheck(1, 50),
          this._formValidator.numberValidationcheck,
        ],
      ];
    }

    this.form = this.fb.group(this.tinFormElement);
  }

  processFormConfig() {
    let formConfig: any = [];
    formConfig = _.cloneDeep(FORM_SECTIONS);

    for (let i = formConfig.length - 1; i >= 0; i--) {
      const section = formConfig[i];

      if (section.key === 'business_info') {
        const isPostalCodeRequired =
          JSON.parse(localStorage.getItem('appConfig') ?? '{}')
            ?.geographical_classification?.is_postal_code_required || false;

        section.fields.forEach((field: any, index: number) => {
          if (field.name === 'license_type' && field?.meta?.onchangeCallback) {
            field.meta.onchangeCallback =
              this.loadLicenseInfoFormConfig.bind(this);
          } else if (field.type === 'location_field' && isPostalCodeRequired) {
            section.fields.splice(index + 1, 0, postalCodeField);
          }
        });
      }
    }

    return formConfig;
  }

  loadLicenseInfoFormConfig(data: any, form?: any) {
    if (!data?.id) return;
    this._loader.show();
    const endpoint: any =
      Endpoints.GET_REGISTRATION_CERTIFICATES + `?licensetype=` + data?.id;
    this._http
      .requestCall(endpoint, ApiMethod.GET)
      .subscribe((response: any) => {
        const apidata: any = {};
        apidata[this.loadMasterAPI] = response?.data || [];
        this._loader.hide();

        const section = this.formConfig.filter(
          (option: any) => option.key === this.loadMasterAPI
        );
        this.dynamicFormComponent.loadFormForMultipleSection(
          section[0],
          apidata,
          this.loadMasterAPI,
          true
        );
      });
  }

  navigateLoginPage() {
    return this.router.navigate([routePath.AUTH_LOGIN_REDIRECT_PATH]);
  }

  resetdata(ev: any) {
    this.form.value = '';
  }

  handleFormSubmit(formData: any) {
    if (
      this.form.valid &&
      this.dynamicFormComponent?.form?.valid &&
      this.dynamicFormComponent?.dynamicFormField?.dynamicLocation?.location
        ?.valid &&
      this._formValidator.validateOwnershipPercentage(formData)
    ) {
      // formData.business_info.location= 1;
      const formDataObject = new FormData();
      this.selectedFiles = {};

      // Transform the certificate_info
      const transformedCertificateInfo = formData.certificate_info
        .filter((info: any) => info.certificate_number) // Filter out entries without certificate_number
        .map((info: any) => {
          // Ensure info.attachments is an array
          const attachments = Array.isArray(info.attachments)
            ? info.attachments
            : [];

          // Map attachments to unique names and store in selectedFiles
          const uniqueAttachments = attachments.length
            ? attachments.map((file: File) => {
                if (!file || !file?.name) {
                  return null;
                }
                const uniqueName = this._common.generateUniqueFileName(file);
                this.selectedFiles[uniqueName] = file; // Store file with unique name
                return uniqueName;
              })
            : [];

          return {
            certificate: info.id,
            certificate_number: info.certificate_number,
            expiry_date: info.expiry_date,
            attachments: uniqueAttachments,
            is_not_applicable: info.is_not_applicable,
          };
        });

      formData = this._common.removeDefaultSuffix(formData);

      // Include the entire form data with the transformed certificate info
      const completeFormData = {
        ...formData,
        certificate_info: transformedCertificateInfo,
        validation_info: {
          tin_number: this.form.get('business_id').value,
        },
      };

      // Append the transformed certificate info as JSON
      formDataObject.append('json_data', JSON.stringify(completeFormData));

      // Append files directly
      if (this._common.isNotEmptyObject(this.selectedFiles)) {
        Object.keys(this.selectedFiles).forEach((uniqueName) => {
          const file = this.selectedFiles[uniqueName];
          formDataObject.append(uniqueName, file, file.name);
        });
      }

      // Proceed with the form submission
      this.submitFormData(formDataObject);
    } else {
      // Handle form validation errors
      console.error('Form is invalid');
      this._common.errorFocusScroll();
    }
  }

  // Separate method to submit the form data to the API
  submitFormData(formDataObject: FormData) {
    this._loader.show();
    this._http
      .requestCall(
        Endpoints.CREATE_REGISTERATION_API,
        ApiMethod.POST,
        formDataObject,
        {
          observe: 'response',
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )
      .subscribe(
        (response: any) => {
          this._loader.hide();
          if (response.ok || response.status == 201) {
            let data = response?.body?.data || {};
            let businessInfo = data?.business_info || {};
            const regnID = data?.validation_info?.registration_code || '';
            let msg =
              this.staticText?.scp?.register?.view_edit?.regn_success_msg ||
              response.message;
            let bdyContent =
              `
          <div class="row mx-0 bg_user_add">
          <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 text_align_left">
          



            <span>Licence Type</span>
            <p class="user_name_display mb-0">` +
              businessInfo?.license_type?.display_name +
              `</p>
          </div>
          <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 text_align_left">
           <span>Submission Date</span>
           <p class="user_name_display mb-0">` +
              this.datePipe.transform(new Date(), 'dd MMM yyyy') +
              `</p>
          </div>
          <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 text_align_left">
            <span>Request ID</span>
           <p class="user_name_display mb-0">` +
              regnID +
              `</p>
          </div>
          </div> 
          `;
            this._modal
              .openSucceedDialog({
                data: {
                  header: 'Self - Registration Success!',

                  msg:
                    `<p class="text_align_left para_txt_register">` +
                    msg +
                    `</p>` +
                    bdyContent,
                },
              })
              .afterClosed()
              .subscribe((result) => {
                if (result) {
                  // Handle successful form submission
                  this.navigateLoginPage();
                }
              });
          } else {
            this._modal
              .openWarningDialog({
                data: {
                  paragraph:
                    `<p class="fs-14">` +
                    'Error during form submission. Please check the details and try again.' +
                    `</p>`,
                },
              })
              .afterClosed()
              .subscribe((result) => {
                if (result) {
                  // Handle form submission error
                }
              });
          }
        },
        (error: any) => {
          this._loader.hide();
          console.error('Error during form submission:', error);
        }
      );
  }

  triggerValidation() {
    if (
      !this.enableTINOtp &&
      !this.form.valid &&
      this.form.get('business_id').invalid
    ) {
      this.form.get('business_id')?.markAsTouched();
    }

    if (this.dynamicFormComponent) {
      this.dynamicFormComponent.validateForm();
      if (this.dynamicFormComponent?.isFirstGroupEmpty('ownership_info')) {
        this._formValidator.validOwnershipData();
        this._common.errorFocusScroll(true);
      }
    } else {
      console.error('DynamicFormComponent is not available');
    }
  }

  onResendOtpHandler(event: any) {
    if (event == 'resend') {
      this.form.get('otp').value = '';
      this.verifyBusinessInfo();
    }
  }

  verifyBusinessInfo() {
    if (this.form.get('business_id').value) {
      this._loader.show();
      const existingOptions = {
        headers: new HttpHeaders({
          'Error-Field-Affected': HIDENOTIFY,
        }),
      };
      this._http
        .requestCall(
          Endpoints.BUSINESS_VERIFY,
          ApiMethod.POST,
          { tin_number: this.form.get('business_id').value },
          existingOptions
        )
        .pipe(
          catchError((err: any): any => {
            this._loader.hide();
            this.form
              .get('business_id')
              .setErrors({ validation_message: err?.error?.message });
          })
        )
        .subscribe((response: any) => {
          this._loader.hide();
          if (response.code == 200) {
            let data = response?.data;
            if (this.enableTINOtp) {
              this.otpTimerResendComponent.startTimer();
              this._modal
                .openSuccessDialog({
                  data: {
                    title: 'Onetime Password Sent!',
                    paragraph:
                      `<p class="mb-0">` +
                      response.message +
                      ` <span class=" email-txt mb-0 pb-2">` +
                      response?.data?.email +
                      `</span></p>`,
                  },
                })
                .afterClosed()
                .subscribe((result) => {
                  if (result) {
                  }
                });
              this.otpInitiated = true;
            } else {
              this.form.get('id_verified').setValue(true);
              this._modal
                .openSucceedDialog({
                  data: {
                    header: 'TIN Verification',
                    msg: `<p class="mb-0">Your TIN verified successfully!, Please fill up rest of the details and submit the form. </p>`,
                  },
                })
                .afterClosed()
                .subscribe(() => {
                  this.disableForm = false;
                });
            }
          } else {
            this._modal
              .openWarningDialog({
                data: {
                  paragraph: `<p class="fs-18">` + response.message + `</p>`,
                },
              })
              .afterClosed()
              .subscribe((result) => {
                if (result) {
                }
              });
          }
        });
    } else {
      // Handle form validation errors if needed
      console.error('Form is invalid');
    }
  }

  verifyOTP() {
   
    if (this.form.get('otp').value) {
      this._loader.show();
      const formData = this.form.value;
      const businessInfoData = formData.business_info || {}; // Ensure business_info is included
      const existingOptions = {
        headers: new HttpHeaders({
          'Error-Field-Affected': HIDENOTIFY,
        }),
      };
      this._http
        .requestCall(
          Endpoints.VERIFY_OTP,
          ApiMethod.POST,
          {
            tin_number: this.form.get('business_id').value,
            otp: this.form.get('otp').value,
          },
          existingOptions
        )
        .pipe(
          catchError((err: any): any => {
            this._loader.hide();
            this.form
              .get('otp')
              .setErrors({ validation_message: err?.error?.message });
          })
        )
        .subscribe((response: any) => {
          this._loader.hide();
          if (response.code == 200) {
            let data = response?.data;
            if (data) {
              this.disableForm = false;
              this.prePopulateBusinessInfo(data);
              this.form.get('id_verified').setValue(true);
            }
          } else {
            this._modal
              .openWarningDialog({
                data: {
                  paragraph: `<p class="fs-14">` + response.message + `</p>`,
                },
              })
              .afterClosed()
              .subscribe((result) => {
                if (result) {
                }
              });
          }
        });
    } else {
      // Handle form validation errors if needed
      console.error('Form is invalid');
    }
  }

  prePopulateBusinessInfo(data: any) {
    const section = this.formConfig.find(
      (sec: any) => sec.key === 'business_info'
    );
    const fields = this.formConfig?.find(
      (x: any) => x.key == 'business_info'
    )?.fields;
    const licenseType = fields?.find((x: any) => x.name == 'license_type');
    const ownership = fields?.find((x: any) => x.name == 'ownership');
    const phoneCode =
      this.appConfig?.geographical_classification?.country?.phone_code;
    const contactNo = data?.contact_no?.replaceAll(phoneCode, '');
    console.log(contactNo, "phoneCode");
    
    this.dynamicFormComponent.loadFormForNonMultipleSection(
      section,
      {
        business_info: {
          name: data?.name,
          license_type: licenseType?.options?.find(
            (x: any) => x.name == data?.license_type
          ),
          date_of_incorporation: data?.date_of_incorporation,
          ownership: ownership?.options?.find(
            (x: any) => x.name == data?.ownership
          ),
        
        contact_no: contactNo != undefined ? parseInt(contactNo) : '',
          address: data?.address,
        },
      },
      'business_info'
    );
  }

  get shouldShowResendOtp(): boolean {
    return this.otpInitiated && this.form.get('otp')?.value;
  }

  get getTextMask(): string {
    return this._common.generatePattern(
      this.appConfig?.business_information?.business_indentification_number
    );
  }
}
