<section class="header container-fluid fixed_top">
    <div class="row d-flex align-items-center py" #header>
        <div class="header_logo_flx">
            <div class="application-logo cursor-pointer" (click)="navigate()">
                <img src="assets/images/common/logo.svg" class="" alt="logo">
            </div>
            <div class="">
                <div class="mb-0 fw-300 d_flx_rwanda cursor-pointer" (click)="navigate()">
                    <span class="username header-logo">
                        <img *ngIf="countryLogoAttachment; else fallbackLogo" [src]="countryLogoAttachment"
                            class="logo-image" alt="logo" />
                        <ng-template #fallbackLogo>
                            <img src="assets/images/common/rwanda_logo.svg" class="logo-image" alt="default logo" />
                        </ng-template>
                    </span>
                    <span class="rwanda_img">
                        {{
                        countryLogoName ||
                        staticText?.common?.company_secondary_name
                        }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="login-screen">
    <div class="login_div_two">
        <div class="login_left_usp ">
            <div class="border_left_usp">
                <p class="my-3 login_left_description">{{staticText?.login?.login_page_description}}</p>
                <span class="welcome_msg">{{staticText?.login?.login_welcome_msg}}</span>
            </div>
        </div>
        <div class="login_ryt_usp">
            <div class="form_login h_full">
                <ng-container *ngIf="!showForm">
                    <div class="password_successful h_full">
                        <img class="mb-3" src="../../../../assets/images/common/login_page_usp/expired.png" alt="">
                        <p class="login_txt_password">{{staticText?.login?.reset_msg}}</p>
                        <p class="new_portal_txt_center">{{staticText?.login?.reset_msg_description}}</p>
                        <button mat-raised-button class="btn_login"
                            (click)="loginpage()">{{staticText?.login?.login_page}}</button>
                    </div>
                </ng-container>
                <ng-container *ngIf="showForm">
                    <div class="pb-3">
                        <div class="d-flex flex-row justify-content-start align-items-center mb-3">
                            <label class=" login_txt"
                                [innerHtml]="staticText?.reset_password?.reset_password_title"></label>
                        </div>
                    </div>
                    <form class="row row-cols-1 login_gap_btm" [formGroup]="resetPasswordForm">
                        <div class="col my-2 position-relative">
                            <custom-input [inputFormGroup]="resetPasswordForm" inputFormControlName="new_password"
                                [inputType]="showPassword ? 'text' : 'password'" [inputAppearance]=""
                                [inputLabel]="staticText?.reset_password?.reset_password_input_1 "
                                [inputPlaceholderLabel]="staticText?.reset_password?.reset_password_input_1 "
                                [inputRequiredStatus]="true"
                                [inputErrorLabel]="resetPasswordForm?.get('new_password')?.errors?.validation_message">
                            </custom-input>
                        </div>
                        <div class="col my-3 position-relative">
                            <custom-input [inputFormGroup]="resetPasswordForm" inputFormControlName="confirm_password"
                                [inputType]="showConfirmPassword ? 'text' : 'password'" [inputAppearance]=""
                                [inputLabel]="staticText?.reset_password?.reset_password_input_2 "
                                [inputPlaceholderLabel]="staticText?.reset_password?.reset_password_input_2 "
                                [inputRequiredStatus]="true"
                                [inputErrorLabel]="resetPasswordForm?.get('confirm_password')?.errors?.validation_message">
                            </custom-input>
                        </div>
                        <div class="col">
                            <!-- below component to Indicate password Strength -->
                            <!-- <password-indicator [passwordMeasureStrength]="measureStrength"></password-indicator> -->
                        </div>
                        <div class="col d-flex justify-content-end align-items-center mt-2">
                            @if (pageMode == screenmode.CHANGE_PASSWORD) {
                            <div class="d-flex py-4 mx-4 justify-content-end">
                                <a (click)="redirectLogin()" class="forgot_txt">Back to Login</a>
                            </div>
                            }
                            <button mat-raised-button [ngClass]="{'cursor-not-allowed': resetPasswordForm.invalid}"
                                class="  btn_login" [disabled]="resetPasswordForm.invalid"
                                [innerHtml]="staticText?.common?.submit_button_reset"
                                (click)="resetPassword()"></button>
                        </div>
                        <div class="reset_btn_bg">
                            <span class="new_user_heading">
                                Password must contain </span>
                            <ul class="new_portal_txt">
                                <li *ngFor="let rule of pwdRuleList"> {{ rule }}</li>
                                <li><span class="confirm_password"> (Eg: {{samplePwd}})</span></li>
                            </ul>
                        </div>
                    </form>
                </ng-container>
            </div>
        </div>
    </div>
</section>
<footer class="footer_fixed">
    <span class="text-sm text-black"> {{ copyRight }} </span>
</footer>