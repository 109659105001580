

<div class="filter_flx flx_roles_col  pt_users d-flex align-items-center w-100">
  <div class="flex-column filter_title me-auto">
    <div class="text-capitalize mb-0 list_heading" >
      <span [innerHtml]="staticText?.user_management?.user?.list?.list_card_title"></span>
        <span class="total_records mx-2 " *ngIf="rolesList">({{totalRecords}})</span>
    </div>
    <span class=" users_clr">{{staticText?.user_management?.roles?.list?.breadcrumbs}}</span>
  </div>
  <div class="custom_filter_flx w_full_users d-flex justify-content-end align-items-center">
    
      <div class="d-flex w_full_filter">


        <custom-table-filter class="w_full_filter" [customTableFilterLabelClass]="''"  [customTableFilterContainerClass]="'role_width_new'"  [customTableFilterSource]="filterSource"
          (customTableFilterValueEmitter)="filterParams($event)" >
        </custom-table-filter>
        
      </div>
      <div class="padding_left-none d_flx_edit roles_end_add_btn">
        <button (click)="addRole()" *ngxPermissionsOnly="[permission.ADD_CUSTOMGROUP]" mat-raised-button class="next-btn create-default-style mx-2 line_before">
         
            <div class="d-flex align-items-center">
              <img src="assets/images/common/icons/plus.svg" alt="">
              <span class=" btn-text adduser_btn">{{staticText?.user_management?.roles?.list?.new_user}}</span>
            </div>
        </button>
        <button class="edit_icn" disabled>
          <img src="assets/images/common/icons/more.svg" alt="">
        </button>
      </div>
    </div>
  </div>


<mat-card-content>
  <!-- <div class="d-flex flex-column p-0">
        <custom-table-filter [customTableFilterSource]="filterSource"
        (customTableFilterValueEmitter)="filterParams($event)">
        </custom-table-filter>
    </div> -->



  <div class="d-flex flex-column p-4">
    <div class="d-flex">
      @for (item of _common.userMangementTab; track $index) {
      <div class="user-mgnt-tab-btn cursor-pointer" [ngClass]="{'active' : item.active}" (click)="tabClick(item)">
        <span class="users_grp">{{item.title}}</span>
      </div>
      }
    </div>
    <custom-table tableId="cust-tbl-rolelist" [localColumnSearch]="true" [columnSearchOptionsList]="[]"
      [internalColumnSearch]="false" (columnSearch)="columnSearch($event)" [tableCustomActionSelectAll]="tableHeader"
      [columnDefs]="tableFields" [tablePaginationCustom]="true" [lastTwoColFreezed]="true" [tableRowData]="rolesList"
      [tableTotalRecords]="totalRecords" (pageChange)="pageChange($event)"
      [tableCustomActionPermissions]="actionPermissions" (CustomActionParam)="onCustomAction($event)"
      (actionParam)="editRole($event)" (sortChange)="sortColumn($event)">
    </custom-table>
  </div>
</mat-card-content>