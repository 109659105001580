<div class="onboarding-header">
  <h2 class="header-title width_onboarding" mat-dialog-title>{{majorDialogData?.title}}</h2>

  <img class="title-card" src="../../../assets/images/common/icons/stamp.svg" alt="info" />

  <span class="close-dialog" (click)="close()">
    <i class="fa fa-close"></i>
  </span>
</div>

<mat-dialog-content class="mat-typography">
  <div class="pt-4">
    <ul class="onboarding_details">
      @for (item of majorDialogData?.instruction; track $index) {
      <li>{{item}}</li>
      }
    </ul>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center" class="pb-4">
  <button class="onboard_btn mx-2" (click)="proceed()">
    <span>{{majorDialogData?.proceedBtnTxt}}</span></button>
</mat-dialog-actions>