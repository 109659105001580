<section class="header container-fluid fixed_top">
  <div class="row d-flex align-items-center py" #header>
    <div class="header_logo_flx">
      <div class="application-logo cursor-pointer" (click)="navigate()">
       
        <img src="assets/images/common/logo.svg" class="" alt="logo" />
        
      </div>

      <div class="">
        <div class="mb-0 fw-300 d_flx_rwanda cursor-pointer" (click)="navigate()">
          <span class="username header-logo">
            <img *ngIf="countryLogoAttachment; else fallbackLogo" [src]="countryLogoAttachment" class="logo-image" alt="logo" />
            <ng-template #fallbackLogo>
              <img src="assets/images/common/rwanda_logo.svg" class="logo-image" alt="default logo" />
            </ng-template>
          </span>
          <span class="rwanda_img">
            {{
            countryLogoName ||
          
            staticText?.common?.company_secondary_name
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="login-screen" *ngIf="loginForm">
  <div class="login_div_two">
    <div class="login_left_usp">
      <div class="border_left_usp">
        <p class="my-3 login_left_description">
          {{
          staticText?.login?.login_page_description
          }}
        </p>
        <span class="welcome_msg">{{
          staticText?.login?.login_welcome_msg
          }}</span>
      </div>
    </div>
    <div class="login_ryt_usp" [ngClass]="showOTPForm ? 'otp-section' : 'right-section'">
      <div id="loginFormSection" [hidden]="!loginFormSection" class="h_full">
        <div class="form_login h_full">
          <span class="login_txt">{{ staticText?.login?.login_label }}</span>
          <p class="my-1 pb-2 text-sm font-normal login_ryt_description">
            {{ staticText?.login?.login_description }}
          </p>
          <form class="h_full" [formGroup]="loginForm" novalidate>
            <div class="form_end_flx_div">
              <div class="pt-3 form-group login_gap_btm">
                <div class="mt-2 input_btm">
                  <!-- <custom-input [inputPlaceholderLabel]="staticText?.login?.login_field_1"
                    [inputDisableState]="otpProgress" [inputDisableStateColor]="otpProgress"
                    [inputFormGroup]="loginForm" inputFormControlName="username" [inputRequiredStatus]="true"
                    [inputAppearance]="" [inputLabel]="staticText?.login?.login_field_1" [inputErrorLabel]="
                      loginForm?.get('username').errors?.validation_message
                    ">
                  </custom-input> -->
                  <custom-input [inputPlaceholderLabel]="staticText?.login?.login_field_1"
                    [inputDisableState]="otpProgress" [inputDisableStateColor]="otpProgress"
                    [inputFormGroup]="loginForm" inputFormControlName="email" [inputRequiredStatus]="true"
                    [inputAppearance]="" [inputLabel]="staticText?.login?.login_field_1" [inputErrorLabel]="
                    loginForm?.get('email').errors?.validation_message
                  "></custom-input>
                </div>

                <div class="my-3 position-relative">
                  <custom-input [inputPlaceholderLabel]="staticText?.login?.login_field_2"
                    [inputDisableState]="otpProgress" [inputDisableStateColor]="otpProgress"
                    [inputFormGroup]="loginForm" inputFormControlName="password"
                    [inputType]="showPassword ? 'text' : 'password'" [inputRequiredStatus]="true" [inputAppearance]=""
                    [inputLabel]="staticText?.login?.login_field_2" [inputErrorLabel]="
                      loginForm?.get('password').errors?.validation_message
                    ">
                  </custom-input>

                
                </div>
                <ng-container *ngIf="enableCaptcha">
                  <div class="mt-2 mb-3 d-flex justify-content-center align-items-center">
                    <re-captcha (resolved)="onCaptchaResolved($event)" (errored)="onCaptchaErrored($event)"
                      errorMode="handled"></re-captcha>
                  </div>
                </ng-container>
                <ng-container *ngIf="loginFailed">
                  <div class="d-flex align-content-center failed_msg_container_parent">
                    <div class="d-flex align-content-center failed_msg_container">
                      <div class="mx-2">{{ loginFailed }}</div>
                      <div><i class="fa fa-exclamation"></i></div>
                    </div>
                  </div>
                </ng-container>

                <div class="forgot_div">
                  <a (click)="redirectForgot()" class="forgot_txt">{{
                    staticText?.login?.login_forgot_password_text
                    }}</a>

                  @if (enableLoginOtp && !otpProgress) {
                  <button mat-raised-button class="btn_login" [ngClass]="{
                      'cursor-not-allowed': this.loginForm.touched
                        ? this.loginForm.invalid
                        : !this.autofilled
                    }" [disabled]="
                      this.loginForm.touched
                        ? this.loginForm.invalid
                        : !this.autofilled
                    " (click)="sendOtp()">
                    {{ staticText?.login?.otp_button_text }}
                  </button>
                  } @else{
                  <button mat-raised-button class="btn_login" [ngClass]="{
                      'cursor-not-allowed': this.loginForm.touched
                        ? this.loginForm.invalid
                        : !this.autofilled
                    }" [disabled]="
                      this.loginForm.touched
                        ? this.loginForm.invalid
                        : !this.autofilled
                    " (click)="doLogin()">
                    {{ staticText?.login?.login_button_text }}
                  </button>
                  }
                </div>
              </div>
              <div class="h_full footer_user">
                <div class="new_user" *ngIf="showNewUserRegn">
                  <div class="user_flx">
                    <span class="new_user_heading">New User?</span>
                    <span class="new_portal_txt">New to our portal? Register your business today.</span>
                  </div>
                  <div>
                    <a class="register_btn cursor-pointer" (click)="initiateRegistration()">
                      Register Here!
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div [hidden]="!otpFormSection" id="otpFormSection" class="h_full">
        <div class="form_login h_full">
          <span style="font-size: large">
            <a style="margin-right: 5px" (click)="showLoginForm()"><i class="fa fa-arrow-left arrow_otp"
                aria-hidden="true"></i></a>
          </span>

          <span class="login_txt">OTP Verification</span>
          <p class="my-1 text-sm font-normal pl_otp login_ryt_description">
            Enter the OTP sent to
            <span class="user_mail" *ngIf="userEmail">{{ userEmail }}</span> to
            verify your identity.
          </p>
          <div class="py-2 pointerEventNone">
            <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
            @if (otpErrorMsg) {
            <div class="err-msg my-2 fs-14 text-end">
              {{ otpErrorMsg }}
            </div>
            }
          </div>

          <div class="resend_otp_flx">
            <app-otp-timer-resend (onResendOtpHandler)="onResendOtpHandler($event)"></app-otp-timer-resend>
            <button mat-raised-button class="btn_login" [disabled]="!isOtpValid" (click)="doLogin()">
              {{staticText?.common?.verify_button}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<footer class="footer_fixed">
  <span class="text-sm text-black"> {{ copyRight }} </span>
</footer>