import {
  TABLE_COLUMN_OPTIONS,
  TABLE_TOP_FILTERS,
} from 'src/app/shared/components/custom-table/custom-table-model';
import { TranslationService } from 'src/app/shared/services/translation.service';

const staticText = TranslationService.staticTextData;
export const USER_MANAGEMENT = {
  user: {
    table_fields: {
      fullname: {
        field: staticText?.common?.fullname ?? 'Full Name',
        column_options: {
          set_prefix_icon: true,
          icon_template_name: 'user_status_icon',
          icon_template_title: 'user_status_title',
          icon_template_title_position: 'above',
          column_minWidth: 120,
          column_sortable: true,
          column_sorting_key: 'full_name',
          column_search_config: {
            search_enabled: true,
            search_type: 'text',
            search_key: 'full_name',
          },
        },
      },
      email: {
        field: staticText?.common?.email ?? 'Email',
        view_Action: false,
        column_options: {
          column_minWidth: 120,
          column_sortable: true,
          column_search_config: {
            search_enabled: true,
            search_type: 'text',
            search_key: 'email',
          },
        },
      },
      username: {
        field: staticText?.common?.username ?? 'Username',
        view_Action: false,
        column_options: {
          column_minWidth: 150,
          column_sortable: true,
          column_search_config: {
            search_enabled: true,
            search_type: 'text',
            search_key: 'username',
          },
        },
      },
      user_type: {
        field: staticText?.common?.user_group ?? 'User Group',
        column_options: {
          column_minWidth: 150,
          column_sortable: true,
          column_search_config: {
            search_enabled: true,
            search_type: 'text',
            search_key: 'user_type__display_name',
          },
        },
      },
      groups: {
        field: staticText?.common?.role ?? 'Role',
        column_options: {
          column_minWidth: 150,
          column_sortable: true,
          column_sorting_key: 'groups__name',
          column_search_config: {
            search_enabled: true,
            search_type: 'text',
            search_key: 'groups__name',
          },
        },
      },
      created_by: TABLE_COLUMN_OPTIONS?.created_by,
      created_on: TABLE_COLUMN_OPTIONS?.created_on,
      last_updated_by: {
        ...TABLE_COLUMN_OPTIONS?.last_updated_by,
        column_options: {
          ...TABLE_COLUMN_OPTIONS.last_updated_by?.column_options,
          column_sorting_key: 'last_updated_by',
        },
      },
      last_updated_on: {
        ...TABLE_COLUMN_OPTIONS?.last_updated_on,
        column_options: {
          ...TABLE_COLUMN_OPTIONS.last_updated_on?.column_options,
          column_sorting_key: 'last_updated_on',
        },
      },
      is_active: TABLE_COLUMN_OPTIONS?.is_active,
      ACTION: {
        ...TABLE_COLUMN_OPTIONS?.action,
        column_options: {
          ...TABLE_COLUMN_OPTIONS?.action?.column_options,
          column_minWidth: 80,
        },
      },
    },
    table_top_filter: [
      {
        ...TABLE_TOP_FILTERS?.multi_dd_search,
        customclass: 'role_width',
        placeholder: staticText?.common?.user_group ?? 'User Group',
      },
      {
        ...TABLE_TOP_FILTERS?.multi_dd_search,
        placeholder: staticText?.common?.role ?? 'Role',
      },
      {
        ...TABLE_TOP_FILTERS?.dd_search,
        placeholder: staticText?.common?.starus ?? 'Status',
      },
      {
        ...TABLE_TOP_FILTERS?.date_search,
        placeholder: staticText?.common?.created_on ?? 'Created On',
      },
    ],
    table_actions: {
      export: true
    }
  },
  entity: {
    table_fields: {
      entity_name: {
        field: staticText?.common?.user_group_name ?? 'User Group Name',
        view_Action: true,
        column_options: {
          column_minWidth: 200,
          column_sortable: true,
          column_sorting_key: 'display_name',
          column_search_config: {
            search_enabled: true,
            search_type: 'text',
            search_key: 'display_name',
          },
        },
      },
      created_by: TABLE_COLUMN_OPTIONS?.created_by,
      created_on: TABLE_COLUMN_OPTIONS.created_on,
      last_updated_by: TABLE_COLUMN_OPTIONS.last_updated_by,
      last_updated_on: TABLE_COLUMN_OPTIONS.last_updated_on,
      is_active: {
        ...TABLE_COLUMN_OPTIONS.is_active,
        type: 'custom_status',
      },
      ACTION: {
        ...TABLE_COLUMN_OPTIONS.action,
        column_options: {
          ...TABLE_COLUMN_OPTIONS.action?.column_options,
          column_maxWidth: 100,
        },
      },
    },
    table_top_filter: [
      {
        ...TABLE_TOP_FILTERS?.dd_search,
        placeholder: staticText?.common?.status ?? 'Status',
      },
      {
        ...TABLE_TOP_FILTERS?.date_search,
        placeholder: staticText?.common?.created_on ?? 'Created On',
      },
    ],
    table_actions: {
      export: true
    }
  },
  roles: {
    table_fields: {
      role_name: {
        field: staticText?.common?.roles ?? 'Roles',
        view_Action: true,
        column_options: {
          column_minWidth: 100,
          column_sortable: true,
          column_sorting_key: 'display_name',
          column_search_config: {
            search_enabled: true,
            search_type: 'text',
            search_key: 'display_name',
          },
        },
      },
      user_type: {
        field: staticText?.common?.user_group ?? 'User Group',
        column_options: {
          column_minWidth: 100,
          column_sortable: true,
          column_sorting_key: 'user_type__name',
          column_search_config: {
            search_enabled: true,
            search_type: 'text',
            search_key: 'user_type__name',
          },
        },
      },
      created_by: {
        ...TABLE_COLUMN_OPTIONS?.created_by,
        column_options: {
          ...TABLE_COLUMN_OPTIONS?.created_by?.column_options,
          column_sorting_key: 'created_by',
        },
      },
      created_on: {
        ...TABLE_COLUMN_OPTIONS?.created_on,
        column_options: {
          ...TABLE_COLUMN_OPTIONS?.created_on?.column_options,
          column_sorting_key: 'created_on',
        },
      },
      last_updated_by: {
        ...TABLE_COLUMN_OPTIONS?.last_updated_by,
        column_options: {
          ...TABLE_COLUMN_OPTIONS?.last_updated_by?.column_options,
          column_sorting_key: 'last_updated_by',
        },
      },
      last_modified_on: {
        ...TABLE_COLUMN_OPTIONS?.last_updated_on,
        column_options: {
          ...TABLE_COLUMN_OPTIONS?.last_updated_on?.column_options,
          column_minWidth: 100,
          column_sorting_key: 'last_updated_on',
        },
      },
      permission: {
        field: staticText?.common?.permission ?? 'Permission',
        type: 'custom_status',
        column_options: {
          column_minWidth: 100,
          column_sortable: false,
          column_sorting_key: 'permission',
        },
      },
      is_active: {
        ...TABLE_COLUMN_OPTIONS?.is_active,
        type: 'custom_status',
        column_options: {
          ...TABLE_COLUMN_OPTIONS?.is_active?.column_options,
          column_search_config: {},
        },
      },
      ACTION: {
        ...TABLE_COLUMN_OPTIONS?.action,
        column_options: {},
      },
    },
    table_top_filter: [
      {
        ...TABLE_TOP_FILTERS?.multi_dd_search,
        customclass: 'role_width',
        placeholder: staticText?.common?.user_group ?? 'User Group',
      },
      {
        ...TABLE_TOP_FILTERS?.dd_search,
        placeholder: staticText?.common?.status ?? 'Status',
      },
      {
        ...TABLE_TOP_FILTERS?.date_search,
        placeholder: staticText?.common?.created_on ?? 'Created On',
      },
    ],
  },
};
