import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { APPLICATION } from 'src/app/core/services/utils/constants';
import { TranslationService } from 'src/app/shared/services/translation.service';

@Component({
  selector: 'app-resetpassword-success',
  templateUrl: './resetpassword-success.component.html',
  styleUrls: ['./resetpassword-success.component.scss'],
})
export class ResetpasswordSuccessComponent implements OnInit {
  staticText: any = TranslationService.staticTextData;

  appConfigKeys: any = APPLICATION?.keys;
  appConfig: any = APPLICATION.config;
  countryLogoName: any;
  countryLogoAttachment: any;
  currentYear: number = new Date().getFullYear();
  copyRight: string = ` ${this.appConfig?.branding_setup?.copyright}`;

  constructor(private router: Router) {}
  ngOnInit(): void {
    const appConfig = JSON.parse(localStorage.getItem('appConfig') || '{}');
    this.countryLogoName = appConfig?.branding_setup?.name;
  }

  loginpage() {
    this.router.navigate(['/auth/login']);
  }
}
