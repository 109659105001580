import { DatePipe } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/core/services/common/common.service';
import { HttpService } from 'src/app/core/services/http/http.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { RouterService } from 'src/app/core/services/router/router.service';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import {
  ApiMethod,
  Endpoints,
  FORMAT,
  PagePermssions,
} from 'src/app/core/services/utils/constants';
import { LodashService } from 'src/app/core/services/lodash/lodash.service';
import { CustomTableComponent } from 'src/app/shared/components/custom-table/custom-table.component';
import { CustomTableService } from 'src/app/shared/services/custom-table/custom-table.service';
import { ActivatedRoute } from '@angular/router';
import { BreadCrumbService } from 'src/app/shared/services/bread-crumb/bread-crumb.service';
import { CustomTableFilterComponent } from 'src/app/shared/components/custom-table/custom-table-filter/custom-table-filter.component';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { USER_MANAGEMENT } from '../../users/user-table-config/table-schema-model';

@Component({
  selector: 'app-roles-list',
  templateUrl: './roles-list.component.html',
  styleUrls: ['./roles-list.component.scss'],
  providers: [CustomTableService],
})
export class RolesListComponent implements OnInit {
  @ViewChild(CustomTableComponent) cTable!: CustomTableComponent;
  @ViewChild(CustomTableFilterComponent) cFilter!: CustomTableFilterComponent;
  @Input() searchtext: string = '';

  rolesSearchForm: FormGroup | undefined;
  totalRecords = 0;
  staticText: any = TranslationService.staticTextData;
  rolesList: any[] = [];
  groupsOptions: any = [];
  entityOptions: any = [];
  startRow: number =
    this.staticText?.common?.custom_table_properties
      ?.custom_table_pagination_block?.custom_table_page_size?.startRow;
  endRow: number =
    this.staticText?.common?.custom_table_properties
      ?.custom_table_pagination_block?.custom_table_page_size?.endRow;
  columnDefs: any = USER_MANAGEMENT?.entity?.table_fields;
  filterParamValues: any;
  tableFields: any;
  permission: any = PagePermssions;
  actionPermissions = {
    view: [this.permission.VIEW_CUSTOMGROUP],
    edit: [this.permission.CHANGE_CUSTOMGROUP],
    delete: [this.permission.DELETE_CUSTOMGROUP],
  };
  roleFilterStatusData: any = [
    {
      id: 1,
      name: 'Active',
      key: 'True',
    },
    {
      id: 2,
      name: 'Inactive',
      key: 'False',
    },
  ];
  tableHeader: any = {
    multi: false,
    model: false,
    multiSelect: false,
  };
  filterSource: any = USER_MANAGEMENT?.roles?.table_top_filter;
  filterKeys: any = [
    {
      key: 'user_type',
      source: [],
      sourceKey: 'id',
      display_key: 'display_name',
    },
    { key: 'is_active', source: [], sourceKey: 'key' },
    { key: 'date_range' },
  ];

  data: any[] | undefined;
  searchForm: FormGroup | any;
  pageMode: any;
  constructor(
    private _http: HttpService,
    public _common: CommonService,
    public _routeService: RouterService,
    private _loader: LoaderService,
    private _modal: ModalService,
    private _lodash: LodashService,
    private _tableSerivce: CustomTableService,
    private formbuilder: FormBuilder,
    private activated_route: ActivatedRoute,
    protected breadcrumbService: BreadCrumbService,

    private datePipe: DatePipe
  ) {
    this.tableFields = USER_MANAGEMENT?.roles.table_fields;
  }

  ngOnInit(): void {
    this.getRouteParams();
    this.breadcrumbService.breadCrumbView(false);
    this.updateCustomTableConfig();
    this.initialDependencies();
  }

  addRole() {
    this._routeService.navigatePages(`iam/user-management/role/add`);
  }

  getRouteParams() {
    const routeInfo: any = this.activated_route?.data;
    this.pageMode = routeInfo?.value?.mode;
    this._common.userMangementTab?.forEach((element: any) => {
      element.active = false;
    });
    this._common.userMangementTab.find(
      (x: any) => x.mode == this.pageMode
    ).active = true;
  }

  tabClick(menu: any) {
    this._common.navigatePages(menu.route);
  }

  ngOnChanges(changes: SimpleChanges | any) {
    // if (!changes.searchtext.firstChange)
    //   this.searchKeyword(this.searchtext);
  }

  getColumnSearch(): any {
    return this.cTable?.searchObj;
    // let searchObj:any = {};
    // let params = new URLSearchParams(searchObj);
    // return params.toString()
  }

  keyWordSearchFilter(ev: any) {
    this.resetPagination();
    let param: any = {
      ...this.filterParamValues,
      ...{ search: ev },
      ...{ column_search: this.getColumnSearch() },
    };
    let result: any = this._common.filterParamsConstructor(
      param,
      this.startRow,
      this.endRow
    );
    this.getRolesList(result);
  }

  searchKeyword(data: any) {
    this.resetPagination();
    let param: any = {
      ...this.filterParamValues,
      ...{ search: this.searchtext },
    };
    this.getRolesList(
      this._common.filterParamsConstructor(param, this.startRow, this.endRow)
    );
  }

  getSearchFormVal() {
    return (this.searchForm && this.searchForm.get('keyword').value) || '';
  }

  loadRoleList() {
    this.keyWordSearchFilter(this.getSearchFormVal());
  }

  initialDependencies() {
    this._common?.filterSourceConstructor(this.filterSource, this.filterKeys);
    if (!this.searchtext) {
      this.loadRoleList();
    }
    this.getRoleStatusList();

    this.getEntityList();

    this.initiateForm();
  }

  initiateForm() {
    this.searchForm = this.formbuilder.group({
      keyword: [''],
    });
  }

  getRoleStatusList() {
    this.filterKeys[1]['source'] = this.roleFilterStatusData;
    this._common?.filterSourceConstructor(this.filterSource, this.filterKeys);
  }

  getEntityList() {
    let endpoint: any = Endpoints.GET_DROPDOWN_ENTITY;
    this._http
      .requestCall(endpoint, ApiMethod.GET)
      .subscribe((response: any) => {
        this.entityOptions = response?.data || [];
        this.filterKeys[0]['source'] = this.entityOptions;
        this._common?.filterSourceConstructor(
          this.filterSource,
          this.filterKeys
        );
      });
  }

  resetPagination() {
    this._tableSerivce.currentPage = 1;
    this.startRow =
      this.staticText?.common?.custom_table_properties?.custom_table_pagination_block?.custom_table_page_size?.startRow;
    this.endRow =
      this._tableSerivce.pageSize ||
      this.staticText?.common?.custom_table_properties
        ?.custom_table_pagination_block?.custom_table_page_size?.endRow;
  }

  filterParams(data?: any) {
    this.resetPagination();
    this.filterParamValues = data?.value;
    let param: any = { ...this.filterParamValues, ...this.getColumnSearch() };
    let result: any = this._common.filterParamsConstructor(
      param,
      this.startRow,
      this.endRow
    );
    this.getRolesList(result);
    if (data?.value == null) {
      this.getEntityList();
    }
  }

  sortColumn(data: any) {
    let param: any = {
      ...this.filterParamValues,
      ...data,
      ...this.getColumnSearch(),
    };
    let result: any = this._common.filterParamsConstructor(
      param,
      this.startRow,
      this.endRow
    );
    this.getRolesList(result, true);
  }

  pageChange(evt: any) {
    this.startRow = evt?.startValue;
    this.endRow = evt?.endValue;

    let param: any = { ...this.filterParamValues, ...this.getColumnSearch() };
    let result: any = this._common.filterParamsConstructor(
      param,
      this.startRow,
      this.endRow
    );
    this.getRolesList(result);
  }

  onChangeActiveDropdown(selectedVal: any, fieldName: any, data: any) {
    let clonedRoleList = this._lodash.Clonedata(this.rolesList);
    let matchedIndex = this._lodash.findIndexBy(this.rolesList, 'id', data.id);
    if (matchedIndex > -1) {
      this.rolesList[matchedIndex][fieldName] =
        selectedVal == 'True' || selectedVal == 'true' || selectedVal == true
          ? true
          : false;
      this.updateRoleStatus(this.rolesList[matchedIndex], clonedRoleList);
    } else {
      this.resetList(clonedRoleList);
    }
  }

  updateRoleStatus(roleObj: any, clonedRoleList: any) {
    this._modal
      .openWarningDialog({
        data: {
          paragraph: `<p class="fs-14">Selected roles are mapped to users and will be no longer active.</p>`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this._loader.show();
          let endpoint: any = Endpoints.GET_ROLES_LIST + roleObj.id + '/';
          let payload = {
            is_active: roleObj.is_active,
          };
          this._http.requestCall(endpoint, ApiMethod.PATCH, payload).subscribe(
            (response: any) => {
              this.resetList(this.rolesList);
              this._loader.hide();
            },
            (error: any) => {
              this.resetList(clonedRoleList);
              this._loader.hide();
            }
          );
        } else {
          this.resetList(clonedRoleList);
        }
      });
  }

  resetList(list: any) {
    this.rolesList = list;
    this.cTable.refreshTableData(list, false, this.startRow);
  }

  updateCustomTableConfig() {
    this.tableFields['is_active'] = {
      ...this.tableFields['is_active'],
      dropdown_config: {
        options: [
          {
            key: 'ACTIVE',
            value: true,
          },
          {
            key: 'INACTIVE',
            value: false,
          },
        ],
        className: 'active-inactive-dropdown',
        events: {
          change: this.onChangeActiveDropdown.bind(this),
          click: () => {},
        },
      },
    };
  }

  getRolesList(params?: any, sorting: boolean = false) {
    this._loader.show();
    let endpoint: any = Endpoints.GET_ROLES_LIST + params;
    this._http
      .requestCall(endpoint, ApiMethod.GET)
      .subscribe((apiResponse: any) => {
        let response = apiResponse.data || {};
        this._loader.hide();
        this.totalRecords = response?.total_records;
        this.rolesList = response?.records?.map((data: any) => ({
          user_type: data?.user_type?.display_name || '',
          role_name: data?.display_name,
          display_name: data?.display_name,
          description: data?.description,
          created_by: data?.created_by || '',
          created_on: this.datePipe?.transform(
            data.created_on,
            FORMAT.DATE_TIME
          ),
          last_modified_on: this.datePipe?.transform(
            data.last_updated_on,
            FORMAT.DATE_TIME
          ),
          last_updated_by: data?.last_updated_by || '',
          is_active: data?.is_active,
          permission:
            '<span class="total-privilege-cnt">' +
            (data?.permissions_count || 0) +
            ' granted </span>',
          id: data?.id,
          route: [{ mode: 'delete', path: data.id, status: true }],
        }));

        this.cTable.setTableTotalRecordsCnt(this.totalRecords);
        this.cTable.refreshTableData(this.rolesList, sorting, this.startRow);

        this._common.mapSerialNumberAndRoutePath(
          this['startRow'] || 0,
          this.rolesList,
          [
            {
              status: true,
              route: '/users/view/',
              routeStatus: false,
              tooltip: 'view',
            },
            {
              status: true,
              mode: 'view',
              route: 'edit/',
              tooltip: 'view',
              routeStatus: false,
              icon: 'view_action',
            },
            {
              status: true,
              route: 'edit',
              mode: 'edit',
              routeStatus: false,
              tooltip: 'edit',
              modalStatus: true,
            },
          ],
          false
        );
      });
  }

  onCustomAction(actionData: any) {
    this._routeService.navigatePages(
      'iam/user-management/role/view/' + actionData['Data']['id']
    );
  }

  editRole(ev: any) {
    if (ev?.action?.mode == 'view' || ev?.action?.icon == 'view_action') {
      this._routeService.navigatePages(
        'iam/user-management/role/view/' + ev.Data.id + '/'
      );
    } else {
      this._routeService.navigatePages(
        'iam/user-management/role/edit/' + ev.Data.id + '/'
      );
    }
  }

  /**
   * @description
   * Table column wise search emitter...............
   * @param ev
   */
  columnSearch(ev: any) {
    this.resetPagination();
    let param: any = { ...this.filterParamValues, ...this.getColumnSearch() };
    let result: any = this._common.filterParamsConstructor(
      param,
      this.startRow,
      this.endRow
    );
    this.getRolesList(result);
  }

  //...............Table column wise search emitter
}
