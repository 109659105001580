<mat-card class="role_mat_card">
    <section class="role-mgmt-page">
        <!-- <mat-card-title class="text-capitalize fs-20 p-3 mb-0">
            <div class="justify-content-between align-items-center">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex flex-row align-items-center fs-20 fa-custom-semi-bold">
                        <p class="text-capitalize mb-0">
                            {{ type === 'add' ? staticText?.user_management?.roles?.add?.add_page_title : (type ===
                            'edit' ? staticText?.user_management?.roles?.edit?.edit_page_title :
                            staticText?.user_management?.roles?.view?.view_page_title) }}
                        </p>
                    </div>
                </div>
            </div>
        </mat-card-title>
        <mat-divider></mat-divider> -->
        <div class="role-mgmt-body global-bg">
            <div class="d-flex flex-column top-red-line bg-white container-bg pb-4">

                <div class="title-card d-flex align-items-center">

                    <div class="fs-20 fw-bold px-4 py-3 title title_width_registration">
                        <span class="title-text">{{staticText?.user_management?.roles?.add?.add_page_title}}</span>
                    </div>
                </div>

                <ng-container *ngIf="roleMgmtForm">
                    <div class="row">
                        <form [formGroup]="roleMgmtForm">
                            <div class="d-flex flex-column px-4 pb-0 mt-2" *ngIf="type == 'add' || type == 'edit'">
                                <div class="row ">

                                    <div class="col-xl-6 col-lg-5 col-md-6 col-sm-6 col-12 mb-3">
                                        <custom-input [inputAppearance]="" [inputFormGroup]="roleMgmtForm"
                                            inputFormControlName="role_name" [inputRequiredStatus]="true"
                                            [inputLabel]="staticText?.user_management?.roles?.add?.add_input_2"
                                            [inputPlaceholderLabel]="staticText?.user_management?.roles?.add?.add_input_2"
                                            [inputErrorLabel]="roleMgmtForm.get('role_name').errors?.validation_message">
                                        </custom-input>
                                    </div>
                                    <!-- [inputAutocompleteLabel]="staticText?.user_management?.roles?.add?.add_input_1" -->

                                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                        <custom-autocomplete [inputAppearance]=""
                                            [inputAutocompleteFormGroup]="roleMgmtForm"
                                            [inputAutocompleteArray]="entityOptions"
                                            inputAutocompleteFormControlName="user_type"
                                            [inputAutocompleteRequiredStatus]="true"
                                            [inputAutocompletePlaceholderLabel]="staticText?.user_management?.roles?.add?.add_input_1"
                                            [inputAutoCompleteErrorLabel]="roleMgmtForm.get('user_type')?.errors?.['validation_message']">
                                        </custom-autocomplete>
                                    </div>

                                    <!-- <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
                                        <custom-input [inputAppearance]="" [inputFormGroup]="roleMgmtForm"
                                            inputFormControlName="description" [inputRequiredStatus]="false"
                                            [inputLabel]="staticText?.user_management?.roles?.add?.add_input_9"
                                            [inputPlaceholderLabel]="staticText?.user_management?.roles?.add?.add_input_9"
                                            [inputErrorLabel]="roleMgmtForm.get('description').errors?.validation_message">
                                        </custom-input>
                                    </div> -->

                                    <div class="status-section col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12"
                                        *ngIf="type == 'add' || type == 'edit'">
                                        <div>
                                            <label class="status-label" [innerHtml]="
                                                    staticText?.user_management?.entity?.edit?.edit_input_3
                                                  "></label>
                                        </div>

                                        <div class="active-inactive py-1">
                                            <mat-label (click)="updateStatus(true)" [ngClass]="{
                                              'status-active': this.roleMgmtForm.value.status
                                            }" class="px-3 cursor-pointer"
                                                [innerHtml]="staticText?.common?.active"></mat-label>
                                            <mat-label (click)="updateStatus(false)" [ngClass]="{
                                                  'status-in-active': !this.roleMgmtForm.value.status
                                                }" class=" px-3 cursor-pointer"
                                                [innerHtml]="staticText?.common?.inactive"></mat-label>
                                            <mat-slide-toggle formControlName="status"
                                                style="display: none;"></mat-slide-toggle>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <!-- view -->
                            <div class="row" *ngIf="type == 'view'">
                                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                                    <div class="px-4">
                                        <label class="color_label"
                                            [innerHtml]="staticText?.user_management?.roles?.view?.view_page_label_1"></label>
                                        <p class="label_txt_view ">{{entityName}}</p>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                                    <div class="px-4">
                                        <label class="color_label"
                                            [innerHtml]="staticText?.user_management?.roles?.view?.view_page_label_2"></label>
                                        <p class="label_txt_view">{{getUpdatedList?.display_name}}</p>
                                    </div>

                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                                    <div class="px-4">
                                        <label class="color_label mb-2"
                                            [innerHtml]="staticText?.user_management?.roles?.view?.view_page_label_10"></label>

                                        <p class="color-darkgrey  fs-16"
                                            [ngClass]="{'active_sts': getUpdatedList?.is_active == true,'inactive_sts':getUpdatedList?.is_active == false}">
                                            {{getUpdatedList?.is_active ? "Active" : "Inactive"}}</p>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </ng-container>


                <!-- <div class="d-flex flex-row align-items-center fs-20 fa-custom-semi-bold pb-3 px-3">
                    <p class="text-capitalize mb-0"
                        [innerHtml]="staticText?.user_management?.roles?.view?.view_page_title2">

                    </p>
                    <span *ngIf="type =='add' || type =='edit'" class="color-red">*</span>
                </div>
                <mat-divider></mat-divider> -->

                <div class="title-card d-flex align-items-center">

                    <div class="fs-20 fw-bold px-4 py-3 title title_width_registration">
                        <span class="title-text">{{staticText?.user_management?.roles?.add?.add_page_title2}}</span>
                    </div>
                </div>

                <table class="table mb-0 mt-2">
                    <caption></caption>
                    <th class="d_none_heading"></th>
                    <!-- <thead class="text-capitalize fs-14 fa-custom-semi-bold bg-dark text-white ">
                        <tr>
                            <th class="text-capitalize  m-1"
                                [innerHtml]="staticText?.user_management?.roles?.add?.add_page_subtitle1"></th>
                            <th colspan="4" class="text-capitalize  m-1"
                                [innerHtml]="staticText?.user_management?.roles?.add?.add_page_subtitle2"></th>
                        </tr>
                    </thead> -->
                    <tbody *ngFor="let permissions of permissionList| keyvalue;let i=index;" class="category-border">

                        <tr *ngIf="permissions?.key" class="cursor-pointer tr-color"
                            (click)="permissionList[permissions?.key].toggle = !(permissionList[permissions?.key].toggle)">
                            <td class="p-0 p-3 bg ">
                                <div class="row">
                                    <ng-container *ngIf="type == 'add' || type == 'edit'">

                                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                            <span class="fw-bold">{{permissions?.key | titlecase}}</span>
                                        </div>

                                        <div *ngIf="permissions?.key"
                                            class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 d-flex align-items-center">
                                            <input class="me-2 cursor-pointer" type="checkbox"
                                                (change)="selectAllPermissions($event,permissions)"
                                                [checked]="permissionList[permissions?.key]?.select_all"
                                                id="check_{{i}}" (click)="onClick($event)">
                                            <label class="select_nowrap" for="check_{{i}}"
                                                (click)="onClick($event)">{{staticText?.user_management?.roles?.view?.permission_text}}</label>
                                        </div>

                                    </ng-container>
                                    <ng-container *ngIf="type == 'view'">
                                        <div class="px-4 col-12 d-flex align-items-center">
                                            <span class="fw-bold">{{permissions?.key | titlecase}}</span>
                                        </div>
                                    </ng-container>
                                </div>
                            </td>
                            <td class="p-0 p-3 text-end bg">
                                <img src="/assets/images/common/icons/collapse.svg" alt=""
                                    class="{{!permissionList[permissions?.key].toggle?'down-arrow':''}}">
                            </td>
                        </tr>

                        <ng-container *ngIf="permissionList[permissions?.key]?.toggle">
                            <tr *ngIf="permissions?.key">
                                <!-- <td scope="row" class="color-primary">{{permissions?.key | titlecase}}</td> -->
                                <td colspan="4">
                                    <div class="row">
                                        <div *ngFor="let permission of permissions.value;let permissionIndex=index"
                                            class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-2">
                                            <div class="role-checkbox-item" *ngIf="type == 'add' || type == 'edit'">
                                                <input type="checkbox" [checked]="permission.checked"
                                                    [(ngModel)]="permission.checked" id="permission-{{permission.id}}"
                                                    (change)="selectedPermissions($event,permissionIndex,permissions?.key, permission)"
                                                    class="m-2 ms-1">
                                                <label class=" permission-name" title="{{permission.display_name}}"
                                                    for="permission-{{permission.id}}">{{permission.display_name}}</label>
                                            </div>
                                            <div class="role-checkbox-item checkbox-item" *ngIf="type == 'view'">
                                                <input type="checkbox" [checked]="permission.checked"
                                                    id="permission-{{permission.id}}" [(ngModel)]="permission.checked"
                                                    class="m-2" disabled="true"
                                                    (change)="selectedPermissions($event,permissionIndex,permissions?.key, permission)">
                                                <label class="permission-name" title="{{permission.display_name}}"
                                                    for="permission-{{permission.id}}">{{permission.display_name}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <div *ngIf="this.showPermissionError && !this.selectedRoleList.length"
                    class="text-danger add-privilege-error-msg mt-2">
                    <p>{{permissionErrorMsg}}</p>
                </div>
            </div>

            <div class="major-footer">
                <div class="btn_users major-modal-actions w-100 d-flex flex-row cursor-pointer p-3" [ngClass]="{
                'justify-content-between': type === 'add' || type === 'edit',
                'justify-content-end': type !== 'add' && type !== 'edit'
              }">
                    <div class="major-modal-close-button px-2">
                        <button class="btn_default_user" mat-stroked-button (click)="close()">


                            {{ type === 'add' || type === 'edit' ? 'Cancel' : 'Close' }}
                        </button>
                    </div>
                    <div *ngIf="type == 'add'">
                        <button mat-raised-button class="btn_login_user" (click)="addEditRole()">
                            <div>
                                <span
                                    [innerHtml]="staticText?.user_management?.roles?.add?.add_page_button || staticText?.common?.create_button"
                                    class="text-light "></span>
                                <!-- <img src="/assets/images/common/icons/arrow_right_dark.svg" alt="button_icon" class="mx-2"> -->
                            </div>
                        </button>
                    </div>

                    <div *ngIf="type == 'edit'">
                        <button mat-raised-button class="create-default-style btn_login_user" (click)="addEditRole()">
                            <div>
                                <span
                                    [innerHtml]="staticText?.common?.update_button || staticText?.user_management?.roles?.edit?.edit_page_button "
                                    class="text-light "></span>
                                <!-- <img src="/assets/images/common/icons/arrow_right_dark.svg" alt="button_icon"
                                    class="mx-2"> -->
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>


    </section>
</mat-card>
<div class="height_role"></div>