import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  customEmailValidator,
  FormSection,
  maxLengthValidator,
  numberValidator,
  requiredValidator,
} from 'src/app/shared/components/dynamic-form/form-sections.model';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';
import { ApiMethod, Endpoints } from '../utils/constants';
import { HttpService } from '../http/http.service';
import { Subject, takeUntil, map, Observable, of } from 'rxjs';

const statusTogglerMeta = {
  isBoolean: true,
  btnConfig: {
    1: { label: 'Yes', color: '' },
    0: { label: 'No', color: '' },
  },
};

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  staticText: any = (textConfiguration as any).default;
  fullMonthOptions: any = [
    { id: 1, display_name: 'January' },
    { id: 2, display_name: 'February' },
    { id: 3, display_name: 'March' },
    { id: 4, display_name: 'April' },
    { id: 5, display_name: 'May' },
    { id: 6, display_name: 'June' },
    { id: 7, display_name: 'July' },
    { id: 8, display_name: 'August' },
    { id: 9, display_name: 'September' },
    { id: 10, display_name: 'October' },
    { id: 11, display_name: 'November' },
    { id: 12, display_name: 'December' },
  ];
  brandConfig: FormSection[] = [
    {
      title: this.staticText?.setup_configuration?.setup_heading_stepper1,
      key: 'branding_setup',
      columns: 4,
      sectionType: 'single', // This section allows multiple entries
      meta: {
        hideSectionTitle: true,
      },
      fields: [
        {
          type: 'text',
          label: this.staticText?.setup_configuration?.brand_form?.name,
          columns: 8,
          name: 'name',
          validators: [
            requiredValidator,
            maxLengthValidator(
              250,
              'Revenue Authority Name must be no longer than 250 characters.'
            ),
          ],
        },
        {
          type: 'file',
          label: this.staticText?.setup_configuration?.brand_form?.upload_logo,
          labelReq: false,
          name: 'country_logo',
          validators: [requiredValidator],
          meta: {
            table_display: false,
            max_file_size: 5,
            allowed_type: '.jpeg, .jpg, .png',
          },
        },
        {
          type: 'text',
          label: this.staticText?.setup_configuration?.brand_form?.copy_right,
          columns: 1,
          name: 'copyright',
          validators: [
            requiredValidator,
            maxLengthValidator(
              250,
              'Copyright Content must be no longer than 250 characters.'
            ),
          ],
        },
        {
          type: 'textarea',
          label: this.staticText?.setup_configuration?.brand_form?.description,
          columns: 1,
          name: 'description',
          validators: [
            maxLengthValidator(
              500,
              'Description must be no longer than 500 characters.'
            ),
          ],
        },
      ],
    },
  ];

  geoConfig: FormSection[] = [
    {
      title: this.staticText?.setup_configuration?.geo_form?.title,
      key: 'geographical_classification',
      columns: 4,
      sectionType: 'single', // This section allows multiple entries
      meta: {
        hideSectionTitle: false,
      },
      fields: [
        {
          type: 'select',
          label: this.staticText?.setup_configuration?.geo_form?.country,
          name: 'country',
          validators: [requiredValidator],
          apiEndpoint: Endpoints.GET_COUNTRY_CONFIGURATION,
          meta: { onchangeCallback: () => {} },
        },
        {
          type: 'text',
          label: this.staticText?.setup_configuration?.geo_form?.isd_code,
          name: 'country_isd_code',
          validators: [requiredValidator],
        },
        {
          type: 'select',
          label: this.staticText?.setup_configuration?.geo_form?.timezone,
          name: 'timezone',
          validators: [requiredValidator],
          dependsOn: 'country',
          apiEndpoint: Endpoints.GET_TIMEZONE_CONFIGURATION,
        },
        {
          type: 'status_toggler',
          label:
            this.staticText?.setup_configuration?.geo_form
              ?.postal_code_required,
          name: 'is_postal_code_required',
          labelReq: true,
          meta: statusTogglerMeta,
        },
      ],
    },
    {
      title: this.staticText?.setup_configuration?.geo_form?.currencies,
      key: 'currency',
      columns: 4,
      sectionType: 'single',
      fields: [
        {
          type: 'text',
          label: this.staticText?.setup_configuration?.geo_form?.currency_type,
          name: 'currency_type',
          validators: [requiredValidator],
          options: [],
        },
      ],
    },
    {
      title: this.staticText?.setup_configuration?.geo_form?.title_2,
      key: 'geographical_hierarchy',
      columns: 1,
      sectionType: 'multiple', // This section allows multiple entries
      meta: {
        inlineEdit: false,
        rowType: 'field', // field or fieldgroup
        btnText: 'Add Level',
        maxRow: 8,
      },
      layout: 'form',
      fields: [
        {
          type: 'text',
          label: this.staticText?.setup_configuration?.geo_form?.level,
          name: 'name',
          validators: [requiredValidator],
          meta: {
            inputSuffixIcon: 'delete',
            inputSuffixFunction: () => {},
          },
        },
      ],
    },
  ];

  localizationConfig: FormSection[] = [
    {
      title: this.staticText?.setup_configuration?.localization_form?.title,
      key: 'languages',
      columns: 2,
      sectionType: 'multiple', // This section allows multiple entries
      layout: 'table',
      meta: {
        hideSectionTitle: false,
        modalTitle:
          this.staticText?.setup_configuration?.localization_form?.language,
        uniqueFields: [{ key: 'name', data_type: 'string' }],
      },
      fields: [
        {
          type: 'hidden',
          label: 'Default',
          name: 'is_default',
          labelReq: false,
          visible: false,
        },
        {
          type: 'text',
          label:
            this.staticText?.setup_configuration?.localization_form?.language,
          name: 'name',
          validators: [
            requiredValidator,
            maxLengthValidator(
              250,
              'Name must be no longer than 250 characters.'
            ),
          ],
        },
        {
          type: 'text',
          label: this.staticText?.setup_configuration?.localization_form?.code,
          name: 'code',
          validators: [
            requiredValidator,
            maxLengthValidator(
              250,
              'Code must be no longer than 250 characters.'
            ),
          ],
        },
        {
          type: 'file',
          label:
            this.staticText?.setup_configuration?.localization_form?.upload,
          labelReq: false,
          name: 'language_file',
          validators: [requiredValidator],
          meta: {
            max_file_size: 5,
            allowed_type: '.json',
          },
        },
        {
          type: 'download',
          label:
            this.staticText?.setup_configuration?.localization_form
              ?.download_template,
          labelReq: false,
          name: 'download',
          meta: {
            table_display: false,
            sourceLink: `${window.origin}/assets/i18n/en.json`,
            filename: 'language_template.json',
            displayText:
              this.staticText?.setup_configuration?.localization_form?.download,
          },
        },
      ],
    },
  ];

  accountingConfig: FormSection[] = [
    {
      title: this.staticText?.setup_configuration?.accounting_form?.title_1,
      key: 'accounting_setup',
      columns: 4,
      sectionType: 'single', // This section allows multiple entries
      meta: {
        hideSectionTitle: false,
      },
      fields: [
        {
          type: 'select',
          label:
            this.staticText?.setup_configuration?.accounting_form
              ?.finance_start,
          name: 'start_month',
          validators: [requiredValidator],
          options: this.fullMonthOptions,
          meta: { onchangeCallback: () => {} },
        },
        {
          type: 'select',
          label:
            this.staticText?.setup_configuration?.accounting_form?.finance_end,
          name: 'end_month',
          validators: [requiredValidator],
          options: this.fullMonthOptions,
        },
      ],
    },
    {
      title: this.staticText?.setup_configuration?.accounting_form?.title_2,
      key: 'payment_section',
      columns: 4,
      sectionType: 'single',
      fields: [
        {
          type: 'select',
          label: this.staticText?.setup_configuration?.accounting_form?.gateway,
          name: 'payment_gateway',
          validators: [requiredValidator],
          options: [],
          apiEndpoint: Endpoints.GET_PAYMENT_GATEWAYS,
        },
      ],
    },
    {
      title:
        this.staticText?.setup_configuration?.accounting_form?.payment_keys,
      key: 'payment_keys',
      columns: 2,
      sectionType: 'multiple', // This section allows multiple entries
      layout: 'table',
      meta: {
        hideSectionTitle: false,
        modalTitle:
          this.staticText?.setup_configuration?.accounting_form?.payment_key,
        uniqueFields: [{ key: 'key_name', data_type: 'string' }],
      },
      fields: [
        {
          type: 'hidden',
          label: 'Config ID',
          name: 'id',
          labelReq: false,
          visible: false,
        },
        {
          type: 'text',
          label: this.staticText?.setup_configuration?.accounting_form?.key,
          name: 'key_name',
          validators: [
            requiredValidator,
            maxLengthValidator(
              250,
              'Type must be no longer than 250 characters.'
            ),
          ],
        },
        {
          type: 'text',
          label: this.staticText?.setup_configuration?.accounting_form?.value,
          name: 'key_value',
          validators: [
            requiredValidator,
            maxLengthValidator(
              250,
              'Key must be no longer than 250 characters.'
            ),
          ],
        },
        // {
        //   type: 'status_toggler',
        //   label: this.staticText?.setup_configuration?.accounting_form?.status,
        //   name: 'is_active',
        //   labelReq: true,
        // },
      ],
    },
    {
      title: this.staticText?.setup_configuration?.accounting_form?.title_3,
      key: 'bank_keys',
      columns: 2,
      sectionType: 'multiple', // This section allows multiple entries
      layout: 'table',
      meta: {
        hideSectionTitle: false,
        uniqueFields: [{ key: 'key_name', data_type: 'string' }],
      },
      fields: [
        {
          type: 'hidden',
          label: 'Config ID',
          name: 'id',
          labelReq: false,
          visible: false,
        },
        {
          type: 'text',
          label: this.staticText?.setup_configuration?.accounting_form?.key,
          name: 'key_name',
          validators: [
            requiredValidator,
            maxLengthValidator(
              250,
              'Key must be no longer than 250 characters.'
            ),
          ],
        },
        {
          type: 'text',
          label:
            this.staticText?.setup_configuration?.accounting_form?.field_label,
          name: 'key_value',
        },
      ],
    },
  ];

  kycConfig: FormSection[] = [
    {
      title: this.staticText?.setup_configuration?.kyc_form?.tax_info,
      key: 'business_information',
      columns: 4,
      sectionType: 'single', // This section allows multiple entries
      meta: {
        hideSectionTitle: false,
      },
      fields: [
        {
          type: 'text',
          label:
            this.staticText?.setup_configuration?.kyc_form
              ?.bussiness_identity_label,
          name: 'business_indentification_label',
          validators: [requiredValidator],
          options: [],
        },
        {
          type: 'text',
          label:
            this.staticText?.setup_configuration?.kyc_form
              ?.bussiness_identity_format,
          name: 'business_indentification_number',
          validators: [requiredValidator],
          options: [],
        },
      ],
    },
    {
      title: this.staticText?.setup_configuration?.kyc_form?.title_2,
      meta: {
        hideSectionTitle: true,
      },
      key: 'tin_information',
      columns: 4,
      sectionType: 'single',
      fields: [
        {
          type: 'status_toggler',
          columns: 2,
          label: this.staticText?.setup_configuration?.kyc_form?.tin_required,
          name: 'is_tin_validation_required',
          labelReq: true,
          meta: { ...statusTogglerMeta, onchangeCallback: () => {} },
        },
        {
          type: 'status_toggler',
          label: this.staticText?.setup_configuration?.kyc_form?.otp_required,
          name: 'is_tin_otp_validation_required',
          labelReq: true,
          meta: statusTogglerMeta,
        },
        {
          type: 'text',
          label: this.staticText?.setup_configuration?.kyc_form?.api_url,
          name: 'tin_api_url',
          validators: [requiredValidator],
          options: [],
        },
        {
          type: 'text',
          label: this.staticText?.setup_configuration?.kyc_form?.username,
          name: 'tin_api_username',
          validators: [requiredValidator],
          options: [],
        },
        {
          type: 'password',
          label: this.staticText?.setup_configuration?.kyc_form?.password,
          name: 'tin_api_password',
          validators: [requiredValidator],
          options: [],
          meta: {
            passwordvalue: true,
          },
        },
      ],
    },
    {
      title: this.staticText?.setup_configuration?.kyc_form?.title_3,
      key: 'tin_api_keys',
      columns: 2,
      sectionType: 'multiple', // This section allows multiple entries
      layout: 'table',
      meta: {
        hideSectionTitle: false,
        uniqueFields: [{ key: 'database_key', data_type: 'object' }],
      },
      fields: [
        {
          type: 'hidden',
          label: 'Config ID',
          name: 'id',
          labelReq: false,
          visible: false,
        },
        {
          type: 'select',
          label: this.staticText?.setup_configuration?.kyc_form?.database_key,
          name: 'database_key',
          // validators: [requiredValidator],
          apiEndpoint: Endpoints.GET_DATABASE_KEY,
          meta: {
            passDatabaseKey: 'name',
          },
        },
        {
          type: 'text',
          label: this.staticText?.setup_configuration?.kyc_form?.response_key,
          name: 'response_key',
          validators: [
            // requiredValidator,
            maxLengthValidator(
              250,
              'Database Key must be no longer than 250 characters.'
            ),
          ],
        },
      ],
    },
    {
      title: this.staticText?.setup_configuration?.kyc_form?.ldap_title,
      key: 'ldap_information',
      columns: 4,
      sectionType: 'single', // This section allows multiple entries
      meta: {
        hideSectionTitle: false,
      },
      fields: [
        {
          type: 'status_toggler',
          columns: 1,
          label: this.staticText?.setup_configuration?.kyc_form?.ldap_required,
          name: 'is_ldap_enabled',
          labelReq: true,
          meta: { ...statusTogglerMeta, onchangeCallback: () => {} },
        },
        {
          type: 'text',
          label: this.staticText?.setup_configuration?.kyc_form?.client_id,
          name: 'ldap_client_id',
          validators: [
            requiredValidator,
            maxLengthValidator(
              250,
              'Client ID must be no longer than 250 characters.'
            ),
          ],
        },
        {
          type: 'text',
          label: this.staticText?.setup_configuration?.kyc_form?.client_secret,
          name: 'ldap_client_secret',
          validators: [
            requiredValidator,
            maxLengthValidator(
              250,
              'Client Secret must be no longer than 250 characters.'
            ),
          ],
        },
        {
          type: 'text',
          label: this.staticText?.setup_configuration?.kyc_form?.tenant_id,
          name: 'ldap_tenant_id',
        },
      ],
    },
    {
      title: this.staticText?.setup_configuration?.kyc_form?.title_4,
      key: 'admin_users',
      columns: 2,
      sectionType: 'multiple', // This section allows multiple entries
      meta: {
        inlineEdit: false,
        btnText: 'Add New User',
        hideSectionTitle: false,
        viewConfig: { action: true },
        noRecTemplate: `<div class="no_records_data">
        <img src="../../../../../assets/images/common/icons_usp/No records created.svg" alt="records_img"/>
        <div class="no_forcast_data">No entries have been added yet!</div>
        <div>`,
        uniqueFields: [{ key: 'email', data_type: 'string' }],
      },
      layout: 'table',
      fields: [
        {
          type: 'hidden',
          label: 'User ID',
          name: 'id',
          labelReq: false,
          visible: false,
        },
        {
          type: 'hidden',
          label: 'Default',
          name: 'is_default',
          labelReq: false,
          visible: false,
        },
        {
          type: 'text',
          label: this.staticText?.common?.first_name,
          name: 'first_name',
          validators: [requiredValidator],
        },
        {
          type: 'text',
          label: this.staticText?.common?.last_name,
          name: 'last_name',
          validators: [requiredValidator],
        },
        {
          type: 'text',
          label: this.staticText?.common?.email,
          name: 'email',
          validators: [
            requiredValidator,
            {
              name: 'email',
              validator: customEmailValidator(),
              message: 'Enter a valid email address format',
            },
          ],
        },
        {
          type: 'phone_field',
          label: this.staticText?.common?.contact_number,
          name: 'phone_number',
          altName: 'country_code',
          validators: [
            requiredValidator,
            {
              name: 'minlength',
              validator: Validators.minLength(3),
              message: 'Contact Number must be at least 3 characters long',
            },
            {
              name: 'maxlength',
              validator: Validators.maxLength(9),
              message: `Contact Number must be at least 9 characters long`,
            },
            {
              name: 'number',
              validator: numberValidator(),
              message: 'Enter a valid number.',
            },
          ],
        },
      ],
    },
  ];
  private destroy$ = new Subject<void>();

  constructor(private _http: HttpService) {}

  private buildEndpoint(base: string, param?: any): string {
    return param ? `${base}${param}` : base;
  }

  private makeRequest(endpoint: any, method: ApiMethod): Observable<any> {
    return this._http.requestCall(endpoint, method).pipe(
      map((e: any) => e),
      takeUntil(this.destroy$)
    );
  }

  getDataBaseKey() {
    let endpoint: any = Endpoints.GET_DATABASE_KEY;
    return this.makeRequest(endpoint, ApiMethod.GET);
  }

  /**
   * @description
   * get configuration details................
   * @param param
   * @returns
   */
  getConfigurationDetails(resolvedData: any): Observable<any> {
    if (resolvedData) {
      return of({ data: resolvedData });
    } else {
      let endpoint: any = `${Endpoints.GET_INITAL_SETUP}`;
      return this._http.requestCall(endpoint, ApiMethod.GET).pipe(
        map((e: any) => e),
        takeUntil(this.destroy$)
      );
    }
  }

  getConfigurationLoginDetails(): Observable<any> {
    let endpoint: any = `${Endpoints.GET_INITAL_SETUP_LOGIN}`;
    return this._http.requestCall(endpoint, ApiMethod.GET).pipe(
      map((e: any) => e),
      takeUntil(this.destroy$)
    );
  }

  /**
   * @description
   * Save configuration details ............................
   * @param id current config id
   * @param formData Payload details
   * @returns Return respective API response
   */

  saveDetails(formData: any) {
    let endpoint: any;
    endpoint = `${Endpoints.CREATE_INITAL_SETUP}`;
    return this._http.requestCall(endpoint, ApiMethod.POST, formData, {
      observe: 'response',
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  findMonthInterval(id: number, count: any = 12): any {
    // Base case: When count reaches 1, return the corresponding month
    if (count === 1) {
      return this?.fullMonthOptions?.find((month: any) => month.id === id);
    }

    // Recursive case: Calculate the next month's ID
    const nextId = id === 12 ? 1 : id + 1;

    // Recursive call with updated id and count
    return this.findMonthInterval(nextId, count - 1);
  }
}
