import {
  TABLE_COLUMN_OPTIONS,
  TABLE_TOP_FILTERS,
} from 'src/app/shared/components/custom-table/custom-table-model';
import { TranslationService } from 'src/app/shared/services/translation.service';
const staticText = TranslationService.staticTextData;
export const SCP_MANEGEMENT = {
  scp_list_table_fields: {
    company_name: {
      field: staticText?.common?.company ?? 'Company',
      column_options: {
        column_minWidth: 180,
        column_sortable: true,
        column_sorting_key: 'company__company__name',
        column_search_config: {
          search_enabled: true,
          search_type: 'text',
          search_key: 'company__company__name',
        },
      },
    },
    tin: {
      field: staticText?.common?.tin ?? 'TIN',
      column_options: {
        column_minWidth: 120,
        column_sortable: true,
        column_sorting_key: 'company__tin_number',
        column_search_config: {
          search_enabled: true,
          search_type: 'text',
          search_key: 'tin_number',
        },
      },
    },
    license_type: {
      field: staticText?.common?.license_type ?? 'License Type',
      column_options: {
        column_minWidth: 120,
        column_sorting_key: 'company__company__license_type__display_name',
        column_sortable: true,
        column_search_config: {
          search_enabled: true,
          search_type: 'text',
          search_key: 'company__company__license_type__display_name',
        },
      },
    },
    business_site: {
      field: staticText?.common?.business_site ?? 'Business Site',
      column_options: {
        show_popover: true,
        popover_template_name: 'business_site_template',
        column_minWidth: 120,
        column_sortable: true,
        column_sorting_key: 'company__company_sites__name',
        column_search_config: {
          search_enabled: true,
          search_type: 'text',
          search_key: 'company__company_sites__name',
        },
      },
    },
    created_on: TABLE_COLUMN_OPTIONS?.created_on,
    created_by: TABLE_COLUMN_OPTIONS?.created_by,
    last_updated_by: TABLE_COLUMN_OPTIONS.last_updated_by,
    last_updated_on: {
      ...TABLE_COLUMN_OPTIONS?.last_updated_on,
      column_options: {
        ...TABLE_COLUMN_OPTIONS?.last_updated_on?.column_options,
        column_minWidth: 120,
      },
    },
    ACTION: {
      ...TABLE_COLUMN_OPTIONS?.action,
      column_options: {
        ...TABLE_COLUMN_OPTIONS?.action?.column_options,
        column_maxWidth: 70,
      },
    },
  },
  onboarding_table_fields: {
    registration_code: {
      field: staticText?.common?.registration_code ?? 'Registration Code',
      view_Action: true,
      column_options: {
        column_minWidth: 180,
        column_sortable: true,
        column_sorting_key: 'code',
        column_search_config: {
          search_enabled: true,
          search_type: 'text',
          search_key: 'code',
        },
      },
    },
    company_name: {
      field: staticText?.common?.company_name ?? 'Company Name',
      column_options: {
        column_minWidth: 180,
        column_sortable: true,
        column_sorting_key: 'company__company__name',
        column_search_config: {
          search_enabled: true,
          search_type: 'text',
          search_key: 'company__company__name',
        },
      },
    },
    license_type: {
      field: staticText?.common?.license_name ?? 'License Name',
      column_options: {
        column_minWidth: 170,
        column_sorting_key: 'company__company__license_type__display_name',
        column_sortable: true,
        column_search_config: {
          search_enabled: true,
          search_type: 'text',
          search_key: 'company__company__license_type__display_name',
        },
      },
    },
    created_on: TABLE_COLUMN_OPTIONS?.created_on,
    created_by: TABLE_COLUMN_OPTIONS?.created_by,
    last_updated_by: TABLE_COLUMN_OPTIONS.last_updated_by,
    last_updated_on: {
      ...TABLE_COLUMN_OPTIONS?.last_updated_on,
      column_options: {
        ...TABLE_COLUMN_OPTIONS?.last_updated_on?.column_options,
        column_minWidth: 110,
      },
    },
    status: {
      ...TABLE_COLUMN_OPTIONS?.status,
      column_options: {
        ...TABLE_COLUMN_OPTIONS?.status?.column_options,
        column_minWidth: 140,
      },
    },
    ACTION: {
      ...TABLE_COLUMN_OPTIONS?.action,
      column_options: {
        ...TABLE_COLUMN_OPTIONS?.action?.column_options,
        column_maxWidth: 70,
      },
    },
  },
  table_fields: {
    registration_code: {
      field: staticText?.common?.registration_code ?? 'Registration Code',
      view_Action: true,
      column_options: {
        column_minWidth: 120,
        column_sortable: true,
        column_sorting_key: 'registration_code',
        column_search_config: {
          search_enabled: true,
          search_type: 'text',
          search_key: 'registration_code',
        },
      },
    },
    company_name: {
      field: staticText?.common?.company_name ?? 'Company Name',
      column_options: {
        column_minWidth: 180,
        column_sortable: true,
        column_sorting_key: 'company__name',
        column_search_config: {
          search_enabled: true,
          search_type: 'text',
          search_key: 'company__name',
        },
      },
    },
    license_type: {
      field: staticText?.common?.license_name ?? 'License Name',
      column_options: {
        column_minWidth: 170,
        column_sorting_key: 'company__license_type__display_name',
        column_sortable: true,
        column_search_config: {
          search_enabled: true,
          search_type: 'text',
          search_key: 'company__license_type__display_name',
        },
      },
    },
    stage: {
      field: staticText?.common?.stage ?? 'Stage',
      column_options: {
        column_minWidth: 170,
        column_sorting_key: 'stage',
        column_sortable: true,
        column_search_config: {
          search_enabled: true,
          search_type: 'text',
          search_key: 'stage',
        },
      },
    },
    created_on: TABLE_COLUMN_OPTIONS?.created_on,
    created_by: TABLE_COLUMN_OPTIONS?.created_by,
    last_updated_by: TABLE_COLUMN_OPTIONS?.last_updated_by,
    last_updated_on: {
      ...TABLE_COLUMN_OPTIONS?.last_updated_on,
      column_options: {
        ...TABLE_COLUMN_OPTIONS?.last_updated_on?.column_options,
        column_minWidth: 110,
      },
    },
    status: {
      ...TABLE_COLUMN_OPTIONS?.status,
      column_options: {
        ...TABLE_COLUMN_OPTIONS?.status?.column_options,
        column_minWidth: 140,
        column_sortable: false,
        column_sorting_key:
          'status__action_complete_label__or__onboard_company__status__action_complete_label',
        column_search_config: {
          ...TABLE_COLUMN_OPTIONS?.status?.column_options?.column_search_config,
          search_key:
            'status__action_complete_label__or__onboard_company__status__action_complete_label',
        },
      },
    },
    ACTION: {
      ...TABLE_COLUMN_OPTIONS?.action,
      column_options: {
        ...TABLE_COLUMN_OPTIONS?.action?.column_options,
        column_maxWidth: 70,
      },
    },
  },
  scp_list_table_top_filter: [
    {
      ...TABLE_TOP_FILTERS?.dd_search,
      placeholder: staticText?.common?.company ?? 'Company',
    },
    {
      ...TABLE_TOP_FILTERS?.dd_search,
      placeholder: staticText?.common?.license_type ?? 'License Type',
    },
    {
      ...TABLE_TOP_FILTERS?.date_search,
      placeholder: staticText?.common?.created_on ?? 'Created On',
    },
  ],
  table_top_filter: [
    {
      ...TABLE_TOP_FILTERS?.dd_search,
      placeholder: staticText?.common?.license_type ?? 'License Type',
    },
    {
      status: true,
      name: '',
      placeholder: staticText?.common?.status ?? 'Status',
      type: 'group-by',
      multiple: true,
      groupBy: 'groupData',
    },
    {
      ...TABLE_TOP_FILTERS?.date_search,
      placeholder: staticText?.common?.created_on ?? 'Created On',
    },
  ],
};
