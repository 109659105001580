<app-guest-header></app-guest-header>
<div class="main-content self-register-form">

  <div class="self-register-header container "> {{staticText?.scp?.register?.view_edit?.title }}</div>
  <div class="scp-form container d-flex flex-column top-red-line bg-white container-bg"
    [ngClass]="{'disable-scp-form': disableForm}">
    <div class="row">
      <div class="section-card" [ngClass]="{'verified-scp-form': form.get('id_verified').value}">
        <div class="title-card d-flex align-items-center mt-2">

          <div class="fs-20 fw-bold p-3 title title_width_registration">
            <span class="section_title tin_txt">
              {{
              appConfig?.business_information?.business_indentification_label ??
              staticText?.scp?.register?.view_edit?.business_detail_title
              }}
            </span>
          </div>
        </div>
        <div class="d-flex flex-column px-4 pb-3">

          <div class="row">
            <ng-container *ngIf="!form.get('id_verified').value">
              <div class="col-xl-6   col-sm-12">
                <div class="row validation_btn pr_validation">
                  <div class="col-xl-9 col-md-8 col-sm-8 col-12">
                    <app-custom-input-mask [textMask]="getTextMask" [inputFormGroup]="form" [inputAppearance]=""
                      inputFormControlName="business_id" [inputRequiredStatus]="true"
                      [inputLabel]="appConfig?.business_information?.business_indentification_label ?? staticText?.scp?.register?.view_edit?.business_id"
                      [inputPlaceholderLabel]="appConfig?.business_information?.business_indentification_label ?? staticText?.scp?.register?.view_edit?.business_id"
                      [inputDisableState]="otpInitiated"
                      [inputErrorLabel]="form.get('business_id').errors?.validation_message">
                    </app-custom-input-mask>


                    <small class="text-muted flex alert_scp  file_info gap-0"> <img
                        src="../../../../assets/images/common/icons_usp/info.png" alt="" class="info_icn">
                      <span class="alert_txt">&nbsp;{{staticText?.scp?.register?.view_edit?.business_id_note
                        }}</span>
                    </small>

                  </div>

                  <div class="col-xl-3 col-md-4 col-sm-4 col-12" *ngIf="isTinValidationRequired">
                    <div class="ng-star-inserted">
                      <button
                        class="create-default-style otp-btn mdc-button mdc-button--raised mat-mdc-raised-button mat-unthemed mat-mdc-button-base"
                        (click)="verifyBusinessInfo()"
                        [disabled]="!form.get('business_id')?.value || otpInitiated || form.get('business_id')?.invalid">
                        {{otpInitiated ? "OTP Sent" :
                        staticText?.scp?.register?.view_edit?.proceed_lbl
                        }}</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-sm-12" *ngIf="enableTINOtp">
                <div class="row validation_btn pl_validation">
                  <div class="col-xl-9 col-md-8 col-sm-8 col-12 pos_relative">
                    <custom-input [inputFormGroup]="form" [inputAppearance]="" inputFormControlName="otp"
                      [inputRequiredStatus]="true" [inputLabel]="staticText?.scp?.register?.view_edit?.otp"
                      [inputPlaceholderLabel]="staticText?.scp?.register?.view_edit?.otp"
                      [inputErrorLabel]="form.get('otp').errors?.validation_message"
                      [inputDisableState]="!otpInitiated">
                    </custom-input>
                  </div>

                  <div class="col-xl-3 col-md-4 col-sm-4 col-12">
                    <div class="ng-star-inserted">
                      <button [disabled]="!this.form.get('otp').value || !otpInitiated  || form.get('otp')?.invalid"
                        (click)="verifyOTP()"
                        class="create-default-style otp-btn mdc-button mdc-button--raised mat-mdc-raised-button mat-unthemed mat-mdc-button-base">
                        {{staticText?.scp?.register?.view_edit?.verify_otp }}</button>
                    </div>
                    <app-otp-timer-resend class="otp-container"
                      (onResendOtpHandler)="onResendOtpHandler($event)"></app-otp-timer-resend>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="form.get('id_verified').value">
              <div class="business-verified"> <label><i class="fa fa-check-circle"></i> {{
                  appConfig?.business_information?.business_indentification_label ??
                  staticText?.scp?.view_register?.tin_verified
                  }} -
                  <span class="mx-1">{{form.get('business_id').value}}</span></label></div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="container d-flex flex-column bg-white container-bg px-0" style="margin-bottom: 150px;">
    <app-dynamic-form [apiEndpoint]="''" [sections]="formConfig" [loadMasterAPI]="loadMasterAPI"
      (formSubmit)="handleFormSubmit($event)" [disableForm]="disableForm" [mode]="mode">
    </app-dynamic-form>
  </div>



  <div class="guest-footer" *ngIf="!disableForm">
    <div class="row container mx-auto p-0">
      <div class="major-modal-actions  cursor-pointer p-3 px-0">
        <div class="major-modal-close-button"><button [disabled]="disableForm && form.valid" mat-stroked-button=""
            class="btn_default_user_small height_btn mdc-button mdc-button--outlined mat-mdc-outlined-button mat-unthemed mat-mdc-button-base"
            mat-ripple-loader-uninitialized="" mat-ripple-loader-class-name="mat-mdc-button-ripple"
            (click)="navigateLoginPage()">{{staticText?.common?.cancel_button}}</button></div>

        <div class="ng-star-inserted">
          <!-- <button class="btn_cancel_user me-2" (click)="resetdata($event)">Reset</button> -->
          <button [disabled]="disableForm && form.valid" mat-raised-button="" (click)="triggerValidation()"
            class="height_btn btn_login_user_small mdc-button mdc-button--raised mat-mdc-raised-button mat-unthemed mat-mdc-button-base"
            mat-ripple-loader-uninitialized="" mat-ripple-loader-class-name="mat-mdc-button-ripple"><span
              class="mat-mdc-button-persistent-ripple mdc-button__ripple"></span><span class="mdc-button__label">
              <div><span class="text-light">{{staticText?.common?.submit_approval}}</span></div>
            </span><span class="mat-mdc-focus-indicator"></span><span
              class="mat-mdc-button-touch-target"></span></button>
        </div>
      </div>
    </div>
  </div>
</div>


<footer class="footer_fixed">
  <span class="text-sm text-black">{{ copyRight }}</span>
</footer>