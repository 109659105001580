<div class="dynamic-form" [formGroup]="form" [ngClass]="{
    'disable-dynamic-form': isFormDisabled,
    'edit-dynamic-form': mode == 'edit',
    'disable-registration-form': disableForm
  }">
  <div class="row mx-auto">
    <ng-container *ngIf="mode == 'add' || mode == 'edit'; else viewDynamicFormTemplate">
      <ng-container *ngFor="let section of sections">
        <div class="section-card">
          <div *ngIf="section?.meta?.hideSectionTitle !== true" class="title-card d-flex align-items-center">
            <div class="fs-20 fw-bold p-3 title title_width_registration">
              <span class="section_title tin_txt">{{ section.title }}</span>

              @if(section?.meta?.info) {
              <span class="mx-auto header-notes" *ngIf="section?.meta?.info?.headerNote">
                <label href="javascript:;" class="custom-header-notes">
                  <img class="info-circle-img fade-img" src="../../../assets/images/common/icons/info.svg" alt="info" />
                  {{section?.meta?.info?.headerNote}}
                </label>
              </span>

              @if(section?.meta?.help) {
              <a href="javascript:;" class="custom-tooltip-surface" matTooltip="{{ section?.meta?.help?.text }}"
                [matTooltipPosition]="'right'">
                <img class="info-circle-img" src="../../../assets/images/common/icons/info.svg" alt="info" />
              </a>
              }
              }
            </div>
          </div>

          <div class="d-flex flex-column" [ngClass]="{
              padding_section: !section?.meta?.noPadding,
              'section-border': section?.meta?.withBorder,
              'table-section': section?.layout === 'table'
            }">
            <ng-container [ngSwitch]="section.sectionType == 'multiple' && section.layout">
              <ng-container *ngSwitchCase="'table'">
                <div class="row" [ngClass]="{
                    margin_add:
                      !section?.meta?.hideSectionTitle && section.title
                  }">
                  <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                    <ng-container *ngIf="
                        section.meta?.copyDataFrom &&
                        section.meta?.copyDataFrom?.options.length
                      ">
                      <custom-autocomplete class="copyFromContainer pull-right" [inputAutocompleteFormGroup]="form"
                        [inputAutocompleteAppearance]="" [inputAutocompleteFormControlName]="
                          section.meta?.copyDataFrom?.key
                        " [inputAutocompletePlaceholderLabel]="'Same As'" [autocompletevaluekey]="id"
                        [inputAutocompleteArray]="
                          section.meta?.copyDataFrom?.options
                        " (inputAutoCompleteEmitter)="
                          onOptionChange(
                            $event,
                            section.key,
                            section.meta?.copyDataFrom?.selectionChangeHandler
                          )
                        ">
                      </custom-autocomplete>
                    </ng-container>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" [ngClass]="{
                      'mt-3':
                        section.meta?.copyDataFrom &&
                        section.meta?.copyDataFrom?.options.length,
                        'flx_bulk': section?.meta?.bulkUpload?.enable
                    }">
                    <div class="pull-right d_bulk_flx">
                      @if(section?.meta?.bulkUpload?.enable){
                      <ng-container
                        *ngIf="section?.meta?.bulkUpload?.permission?.length || section?.meta?.bulkUpload?.withoutLogin">
                        <div class="bulk_img_div" *ngxPermissionsOnly="section?.meta?.bulkUpload?.permission">
                          <span (click)="getbulk(section?.meta?.bulkUpload)">
                            <img class="upload_img" src="../../../../assets/images/common/icons_usp/upload.png" />
                            <button class="bulk_txt_upload">Bulk Upload</button>
                          </span>
                          @if (section?.meta?.bulkUpload?.tooltip) {
                          <span data-bs-toggle="tooltip" data-bs-placement="bottom"
                            matTooltip="{{section?.meta?.bulkUpload?.tooltip}}" matTooltipClass="custom-tooltip">
                            <img src="../../../../assets/images/common/icons_usp/info.svg" class="cursor-pointer" />
                          </span>

                          }
                        </div>
                      </ng-container>
                      }
                      <a [disabled]="isFormDisabled" [ngClass]="{ 'disable-btn': isButtonDisabled(section) }"
                        type="button" class="pull-right add-more-table-btn" (click)="onAddMore(section)"><img
                          class="add_icn_div" src="../../../../../assets/images/common/icons/add_table.svg" alt="" />
                        {{
                        section?.meta?.btnText ||
                        "Add
                        More"
                        }}
                      </a>
                    </div>
                  </div>
                </div>

                <div class="overflow_scroll_table mt-2 mb-4">
                  <table class="table m-0"
                    [ngClass]="{ 'sticky-table': section?.meta?.stickyTable, 'sticky-table-last': section?.meta?.stickylastcolumn }">
                    <caption></caption>
                    <thead>
                      <tr>
                        <ng-container *ngFor="let field of section.fields">
                          <th class="col_width_table" *ngIf="
                              field.visible != false &&
                              field?.meta?.table_display !== false
                            " [ngStyle]="
                              field?.meta?.width
                                ? {
                                    'width.px': field.meta.width,
                                    'min-width.px': field.meta.width
                                  }
                                : {}
                            ">
                            {{ field.label }}
                          </th>
                        </ng-container>
                        <th class="text-center action_col_width_table">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody [formArrayName]="section.key">
                      <tr *ngFor="let group of form.get(section.key)?.['controls']; let i = index" [formGroupName]="i">
                        <ng-container *ngIf="section?.meta?.inlineEdit">
                          <ng-container *ngFor="let field of section.fields">
                            <td *ngIf="
                                field.visible !== false &&
                                field?.meta?.table_display !== false
                              ">
                              <app-dynamic-form-field [mode]="mode" #dynamicFormField [group]="group" [section]="
                                  convertFieldToSection(field, section.key)
                                "></app-dynamic-form-field>
                            </td>
                          </ng-container>
                        </ng-container>

                        <ng-container *ngIf="!section?.meta?.inlineEdit">
                          <ng-container
                            *ngIf="form.get(section.key)?.['controls']?.length > 1 || (form.get(section.key)?.['controls']?.length == 1 && !isFirstGroupEmpty(section.key)) ; else noRecords">
                            <ng-container *ngFor="let field of section.fields">
                              <td *ngIf="
                                  field.visible !== false &&
                                  field?.meta?.table_display !== false
                                ">
                                <div class="dynamic-td-div" [ngClass]="{'icn_tooltip_hidden': field?.meta?.ondemand_tooltip}">
                                  <span *ngIf="field.type == 'phone_field'">{{
                                    this?.staticText?.common?.country_code
                                    }}
                                    -
                                  </span>
                                  <!-- Displaying the data value of each field instead of rendering form fields -->
                                  <span data-bs-toggle="tooltip" data-bs-placement="bottom" [matTooltip]="
                                      field
                                        | tooltipster
                                          : group.get(field.name)?.value
                                    ">
                                    @if (field?.meta?.isCurrency || field?.meta?.data_type == 'number') {
                                    {{
                                    getFieldValue(
                                    field,
                                    group?.get(field?.name)?.value | commaSeparator,
                                    true, null, null, group
                                    )
                                    }}
                                    }@else {
                                    {{
                                    getFieldValue(
                                    field,
                                    group.get(field.name)?.value,
                                    true, null, null, group
                                    )
                                    }}
                                    }

                                  </span>
                                  @if (field?.meta?.ondemand_tooltip) {
                                  <span class="pos_tooltip_relative">
                                    <span class="mx-2" [innerHTML]="field?.meta?.ondemand_tooltip?.tooltip_icon"
                                      (mouseenter)="tooltipEnter(field, group)"></span>
                                    <span class="pos_tooltip_abs" [innerHTML]="group?.tooltipTemplate"></span>
                                  </span>

                                  }
                                </div>
                              </td>
                            </ng-container>
                            <td class="text-center">
                              <a class="attachment_clr" [disabled]="isFormDisabled" *ngIf="!section?.meta?.inlineEdit"
                                (click)="viewRowSection(section, i)"><img
                                  src="../../../../../assets/images/common/icons/view_table.svg" alt="" /></a>
                              &nbsp;
                              <a class="attachment_clr" [disabled]="isFormDisabled" *ngIf="!section?.meta?.inlineEdit"
                                (click)="editRow(section, i)"><img
                                  src="../../../../../assets/images/common/icons/edit_table.svg" alt="" /></a>
                              <a class="attachment_clr" [disabled]="isFormDisabled" type="button" class="ml-1 deleteBtn"
                                (click)="removeFormGroupFromSection(section, i)">
                                <img src="../../../../../assets/images/common/icons/delete_table.svg" alt="" /></a>
                            </td>
                          </ng-container>
                        </ng-container>
                      </tr>

                      <!-- Ensure at least one row exists with empty values if no data is available -->
                      <tr *ngIf="!section?.meta?.inlineEdit && (form.get(section.key)?.['controls'].length || 0) === 0">
                        <td *ngFor="let field of section.fields">
                          <app-dynamic-form-field [mode]="mode" #dynamicFormField [group]="group" [section]="
                              convertFieldToSection(field, section.key)
                            "></app-dynamic-form-field>
                        </td>
                        <td></td>
                      </tr>

                      <!-- "No records found" message when the FormArray is empty -->
                      <ng-template #noRecords>
                        @if(section?.meta?.noRecTemplate){
                        <td class="text-center" colspan="100%" [innerHTML]="section?.meta?.noRecTemplate"></td>
                        }@else {
                        <td class="text-center" colspan="100%">
                          No records found
                        </td>
                        }
                      </ng-template>
                    </tbody>
                  </table>
                </div>
              </ng-container>

              <ng-container *ngSwitchDefault>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  *ngIf="section.sectionType == 'multiple' && !section?.meta?.hideActionBtn && checkRowGroupLength(form?.get(section.key)?.['controls'], section)">
                  <div class="pull-right">
                    <a [disabled]="isFormDisabled" [ngClass]="{ 'disable-btn': isButtonDisabled(section) }"
                      type="button" class="pull-right add-more-table-btn" (click)="addFormGroupToSection(section)"><img
                        class="add_icn_div" src="../../../../../assets/images/common/icons/add_table.svg" alt="" />
                      {{
                      section?.meta?.btnText ||
                      "Add
                      More"
                      }}
                    </a>
                  </div>
                </div>

                <div class="row" [formArrayName]="section?.key"
                  *ngIf="section.sectionType == 'multiple' && section?.meta?.rowType === 'field'; else repeaterFieldGroupTemplate">
                  <div *ngFor="let group of form?.get(section.key)?.['controls']; let i = index" [formGroupName]="i"
                    class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <app-dynamic-form-field [mode]="mode" #dynamicFormField [group]="group" [section]="section"
                      [groupIndex]="i" [disableForm]="disableForm"
                      [removeGroupCallback]="removeFormGroupFromSection.bind(this,section, i)"></app-dynamic-form-field>
                  </div>
                </div>

                <ng-template #repeaterFieldGroupTemplate>
                  <ng-container *ngIf="section.sectionType == 'multiple' && section?.meta?.rowType !== 'field'">
                    <div [formArrayName]="section?.key">
                      <div *ngFor="let group of form?.get(section.key)?.['controls']; let i = index" [formGroupName]="i"
                        class="row mx-auto field-group">
                        <div [ngClass]="{
                        'col-12': section?.meta?.hideActionBtn,
                        'col-11': !section?.meta?.hideActionBtn
                      }">
                          <span *ngIf="section?.meta?.rowType === 'fieldgroup'" class="cursor-pointer pull-right"
                            (click)="removeFormGroupFromSection(section, i)">
                            <i class="fa fa-minus-circle outline remove-group"></i>
                          </span>
                          <app-dynamic-form-field [mode]="mode" #dynamicFormField [group]="group" [section]="section"
                            [groupIndex]="i" [disableForm]="disableForm"></app-dynamic-form-field>
                        </div>
                      </div>
                    </div>

                  </ng-container>
                </ng-template>

              </ng-container>
            </ng-container>

            <ng-container *ngIf="section.sectionType == 'single'">
              <div class="ml-auto text-right copyDataFromCheckbox" *ngIf="section.meta?.copyDataFrom">
                <ng-container *ngIf="
                    section.meta?.copyDataFrom &&
                    section.meta?.copyDataFrom?.type == 'checkbox'
                  ">
                  <custom-checkbox [inputCheckBoxLabel]="section.meta?.copyDataFrom?.label"
                    [inputCheckBoxFormControlName]="
                      section?.meta?.copyDataFrom?.key
                    " [inputCheckBoxFormGroup]="form?.get(section?.key)" #dynamicCheckbox [inputCheckBoxDisableState]="
                      section.meta?.copyDataFrom?.readonly
                    " [displayLabelEnd]="true" (checkboxChange)="
                      onOptionChange(
                        $event,
                        section.key,
                        section.meta?.copyDataFrom?.selectionChangeHandler
                      )
                    ">
                  </custom-checkbox>
                </ng-container>
              </div>

              <app-dynamic-form-field [mode]="mode" #dynamicFormField [group]="form.get(section.key)"
                [section]="section"></app-dynamic-form-field>
            </ng-container>

            <ng-container *ngIf="section.sectionType == 'root'">
              <app-dynamic-form-field [mode]="mode" #dynamicFormField [group]="form"
                [section]="section"></app-dynamic-form-field>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #viewDynamicFormTemplate>
      <ng-container *ngFor="let section of sections">
        <div class="section-card" *ngIf="section?.meta?.viewConfig?.visible !== false">
          <ng-container *ngIf="section?.meta?.hideSectionTitle !== true">
            <div class="title-card d-flex align-items-center">
              <div class="fs-20 fw-bold px-4 py-3 title title_width_registration">
                <span class="form-title">{{ section.title }}</span>
              </div>
            </div>
          </ng-container>
          <div class="d-flex flex-column px-0 pb-0 view-section margin_div">
            <div class="overflow_scroll_table" *ngIf="
                section.sectionType == 'multiple';
                else singleFieldTemplate
              ">
              <table class="table table_border_td_none mb-0"
                [ngClass]="{ 'sticky-table': section?.meta?.stickyTable, 'sticky-table-last': section?.meta?.stickylastcolumn }">
                <caption></caption>
                <thead class="fs-12">
                  <tr>
                    <ng-container *ngFor="let field of section.fields">
                      <th *ngIf="
                          field.visible != false &&
                          field?.meta?.table_display !== false
                        " [ngStyle]="
                          field?.meta?.width
                            ? {
                                'width.px': field.meta.width,
                                'min-width.px': field.meta.width
                              }
                            : {}
                        ">
                        {{ field.label }}
                      </th>
                    </ng-container>

                    <th *ngIf="section?.meta?.viewConfig?.action" class="text-center action_col_width_table">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody [formArrayName]="section.key">
                  <tr *ngFor="let group of form.get(section.key)?.['controls']; let i = index" [formGroupName]="i">
                    <ng-container
                      *ngIf="form.get(section.key)?.['controls']?.length > 1 || (form.get(section.key)?.['controls']?.length == 1 && !isFirstGroupEmpty(section.key)) ; else noRecords">
                      <ng-container *ngFor="let field of section.fields">
                        <td *ngIf="
                            field.visible !== false &&
                            field?.meta?.table_display !== false
                          ">
                          <div>
                            <!-- Displaying the data value of each field instead of rendering form fields -->
                            <ng-container *ngIf="
                                field.type === 'select';
                                else multiFileCheck
                              ">
                              <!-- For select type, display display_name -->
                              {{
                              getFieldValue(
                              field,
                              group.get(field.name)?.value,
                              true
                              ) || "-"
                              }}
                            </ng-container>

                            <ng-template #multiFileCheck>
                              <ng-container *ngIf="
                                  field.type === 'multi_file' ||
                                    field.type === 'file';
                                  else normalValueMultiple
                                ">
                                <!-- For multi_file type, display the number of attachments -->
                                <a class="attachment_clr" *ngIf="group.get(field.name)?.value?.length" (click)="
                                    view_file(group.get(field.name)?.value)
                                  ">
                                  <img src="../../../../../assets/images/common/icons/view_table.svg" alt="" />&nbsp;
                                  &nbsp;{{
                                  group.get(field.name)?.value?.length || 0
                                  }}
                                  attachments</a>
                                <ng-container *ngIf="!group.get(field.name)?.value?.length">
                                  -
                                </ng-container>
                              </ng-container>
                            </ng-template>

                            <ng-template #normalValueMultiple>
                              <ng-container *ngIf="
                                  field.type === 'date';
                                  else checkboxField
                                ">
                                <!-- For date type, format the date to 'yyyy-MM-dd' -->
                                {{
                                group.get(field.name)?.value
                                | date : "dd MMM yyyy" || "-"
                                }}
                              </ng-container>

                              <ng-template #checkboxField>
                                <ng-container *ngIf="
                                    field.type === 'checkbox';
                                    else phoneTemplate
                                  ">
                                  <!-- Format checkbox boolean value -->
                                  {{
                                  group.get(field.name)?.value ? "Yes" : "No"
                                  }}
                                </ng-container>
                              </ng-template>

                              <ng-template #phoneTemplate>
                                <ng-container *ngIf="
                                    field.type === 'phone_field';
                                    else hiddenTemplate
                                  ">
                                  <!-- Format phone field -->
                                  {{ this?.staticText?.common?.country_code }}
                                  {{ group.get(field.name)?.value }}
                                </ng-container>
                              </ng-template>

                              <ng-template #hiddenTemplate>
                                <div *ngIf="
                                    field.type === 'hidden';
                                    else normalField
                                  " [ngClass]="field?.meta?.className">
                                  <span *ngIf="
                                      field?.meta?.viewConfig
                                        ?.showSectionTitle === true;
                                      else defHiddenTemplate
                                    " [innerHtml]="getSectionSubTitle(
                                      section,
                                      group.get(
                                      field?.meta?.viewConfig?.sectionField
                                      )?.value
                                      )">

                                  </span>

                                  <ng-template #defHiddenTemplate>
                                    <span class="statusCard" [ngStyle]="{
                                        background: group.get(
                                          field?.meta?.viewConfig?.colorRefField
                                        )?.value,
                                        color: group.get(
                                          field?.meta?.viewConfig
                                            ?.colorRefField2
                                        )?.value
                                      }">
                                      <ng-container *ngIf="
                                          field?.meta?.data_type == 'number'
                                        ">
                                        {{
                                        getFieldValue(
                                        field,
                                        group.get(field.name)?.value
                                        | commaSeparator,
                                        true
                                        ) ?? "-"
                                        }}
                                      </ng-container>
                                      <ng-container *ngIf="
                                          field?.meta?.data_type != 'number'
                                        ">
                                        {{
                                        group.get(field.name)?.value ?? "-"
                                        }}
                                      </ng-container>
                                    </span>
                                  </ng-template>
                                </div>
                              </ng-template>

                              <ng-template #normalField>
                                {{
                                getFieldValue(
                                field,
                                group.get(field.name)?.value,
                                true
                                ) ?? "-"
                                }}
                              </ng-template>
                            </ng-template>
                          </div>
                        </td>
                      </ng-container>

                      <td *ngIf="section?.meta?.viewConfig?.action" class="text-center">
                        <a class="attachment_clr" [disabled]="isFormDisabled" mat-button
                          *ngIf="!section?.meta?.inlineEdit" (click)="viewRowSection(section, i)"><img
                            src="../../../../../assets/images/common/icons/view_table.svg" alt="" /></a>
                        &nbsp;
                      </td>
                    </ng-container>
                  </tr>

                  <!-- "No records found" message when the FormArray is empty -->
                  <ng-template #noRecords>
                    <td class="text-center" colspan="100%">No records found</td>
                  </ng-template>
                </tbody>
              </table>
            </div>

            <ng-container *ngIf="
                section.sectionType == 'single' || section.sectionType == 'root'
              ">
              <div class="d-flex flex-column px-3 pb-3" [ngClass]="{ 'section-border': section?.meta?.withBorder }">
                <div class="row" [class.with-top-left-border]="!section?.meta?.noTopLeftBorder">
                  <ng-container *ngFor="let field of section.fields; let i = index">
                    <ng-container *ngIf="
                        field.type === 'location_field';
                        else otherFieldTemplate
                      ">
                      <ng-container *ngFor="
                          let location of field?.processedApiData">
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 flex-column view-column-cell">
                          <label for="" class="form-label color-primary fs-12 view-field-label"><span
                              [innerHTML]="location.key"></span></label>
                          <p class="color-darkgrey fw-600 view_text_wrap" title="{{ location.display_name || '-' }}">
                            {{ location?.display_name || "-" }}
                          </p>
                        </div>
                      </ng-container>
                    </ng-container>

                    <ng-template #otherFieldTemplate>
                      <div [ngClass]="
                          field.type === 'location_field'
                            ? ''
                            : getColumnClasses(
                                section?.meta?.viewColumns,
                                field?.meta?.viewConfig?.colspan
                              )
                        " *ngIf="field.type !== 'hidden'" class="flex-column view-column-cell {{ field?.class }}">
                        <label class="color_label">{{ field.label }}</label>
                        <div class="label_txt_view" *ngIf="form.get(getFormKey(section, field))">
                          <ng-container *ngIf="field.type === 'select'; else normalValue">
                            <!-- Assume value is an object and we need to display display_name -->
                            {{
                            getFieldValue(
                            field,
                            form.get(getFormKey(section, field))?.value,
                            true
                            ) || "-"
                            }}
                          </ng-container>

                          <ng-template #normalValue>
                            <ng-container *ngIf="field.type === 'date'; else phoneTemplate">
                              <!-- For date type, format the date to 'yyyy-MM-dd' -->
                              {{
                              form.get(getFormKey(section, field)).value
                              | date : "dd MMM yyyy" || "-"
                              }}
                            </ng-container>

                            <ng-template #phoneTemplate>
                              <ng-container *ngIf="
                                  field.type === 'phone_field';
                                  else statusTemplate
                                ">
                                <!-- For date type, format the date to 'yyyy-MM-dd' -->
                                {{ this?.staticText?.common?.country_code }} -
                                {{
                                form.get(getFormKey(section, field)).value ||
                                "-"
                                }}
                              </ng-container>
                            </ng-template>

                            <ng-template #statusTemplate>
                              <ng-container *ngIf="
                                  field.type === 'status_toggler';
                                  else filePreviewTemplate
                                ">
                                <!-- For status type,  -->
                                <ng-container *ngIf="
                                    statusChangeable;
                                    else viewStatusTemlplate
                                  ">
                                  <app-dynamic-form-field [mode]="mode" #dynamicFormField [group]="form" [section]="
                                      convertFieldToSection(
                                        field,
                                        section.key,
                                        true
                                      )
                                    "></app-dynamic-form-field>
                                </ng-container>

                                <ng-template #viewStatusTemlplate>
                                  <div class="active-inactive py-1">
                                    <span [ngClass]="{
                                        'status-active': form.get(
                                          getFormKey(section, field)
                                        )?.value,
                                        'status-inactive': !form.get(
                                          getFormKey(section, field)
                                        )?.value
                                      }">
                                      {{
                                      form.get(getFormKey(section, field))
                                      .value
                                      ? "Active"
                                      : "Inactive"
                                      }}</span>
                                  </div>
                                </ng-template>
                              </ng-container>
                            </ng-template>

                            <ng-template #filePreviewTemplate>
                              <ng-container *ngIf="
                                  field.type === 'multi_file' ||
                                    field.type === 'file';
                                  else normalFieldTemplate
                                ">
                                <!-- For multi_file type, display the number of attachments -->
                                <a class="attachment_clr" *ngIf="
                                    form.get(getFormKey(section, field))?.value
                                      ?.length
                                  " (click)="
                                    view_file(
                                      form.get(getFormKey(section, field))
                                        ?.value
                                    )
                                  ">
                                  <img src="../../../../../assets/images/common/icons/view_table.svg" alt="" />&nbsp;
                                  &nbsp;{{
                                  form.get(getFormKey(section, field))?.value
                                  ?.length || 0
                                  }}
                                  attachments</a>
                                <ng-container *ngIf="
                                    !form.get(getFormKey(section, field))?.value
                                      ?.length
                                  ">
                                  -
                                </ng-container>
                              </ng-container>
                            </ng-template>

                            <ng-template #normalFieldTemplate>
                              <!-- for currency -->
                              <span *ngIf="
                                  field.type == 'input_dropdown' &&
                                  form.get(getFormKey(section, field, true))
                                    ?.value?.flag
                                ">
                                <span [innerHTML]="
                                    form.get(getFormKey(section, field, true))
                                      ?.value?.flag
                                  "></span>
                              </span>
                              <!-- Normal value display for other field types -->
                              {{
                              getFieldValue(
                              field,
                              form.get(getFormKey(section, field))?.value,
                              true
                              ) || "-"
                              }}
                              <!-- Except currency -->
                              <span *ngIf="
                                  field.type == 'input_dropdown' &&
                                  !form.get(getFormKey(section, field, true))
                                    ?.value?.flag
                                ">
                                <span [innerHTML]="
                                    form.get(getFormKey(section, field, true))
                                      ?.value?.display_name
                                  "></span>
                              </span>
                            </ng-template>
                          </ng-template>
                        </div>
                      </div>

                      <div *ngIf="field.type === 'hidden'" [innerHtml]="getSectionSubTitle(
                        section,
                        form.get(getFormKey(section, field))?.value
                        )">
                      </div>
                    </ng-template>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>