import * as i0 from '@angular/core';
import { EventEmitter, Directive, Input, Output, NgModule } from '@angular/core';
import * as i1 from '@asymmetrik/ngx-leaflet';
import { LeafletDirectiveWrapper, LeafletUtil, LeafletModule } from '@asymmetrik/ngx-leaflet';
import { drawLocal, Control, Draw } from 'leaflet';
import 'leaflet-draw';
class LeafletDrawUtil {
  /**
   * Deep copy the source object into the dest object. Will only copy literal values.
   * @param dest
   * @param src
   */
  static deepLiteralCopy(dest, src) {
    const toReturn = dest;
    if (null != src) {
      for (const k in src) {
        if (src.hasOwnProperty(k)) {
          if (typeof src[k] === 'string' || src[k] instanceof String) {
            toReturn[k] = src[k];
          } else {
            this.deepLiteralCopy(toReturn[k], src[k]);
          }
        }
      }
    }
    return toReturn;
  }
}
class LeafletDrawDirective {
  constructor(leafletDirective, zone) {
    this.zone = zone;
    this.drawOptions = null;
    // Using 'any' here to avoid duplicating the DrawLocal interface with a bunch of optional properties
    this.drawLocal = null;
    // Configure callback function for the map
    this.drawReady = new EventEmitter();
    // Draw Events
    this.onDrawCreated = new EventEmitter();
    this.onDrawEdited = new EventEmitter();
    this.onDrawDeleted = new EventEmitter();
    this.onDrawStart = new EventEmitter();
    this.onDrawStop = new EventEmitter();
    this.onDrawVertex = new EventEmitter();
    this.onDrawEditStart = new EventEmitter();
    this.onDrawEditMove = new EventEmitter();
    this.onDrawEditResize = new EventEmitter();
    this.onDrawEditVertex = new EventEmitter();
    this.onDrawEditStop = new EventEmitter();
    this.onDrawDeleteStart = new EventEmitter();
    this.onDrawDeleteStop = new EventEmitter();
    this.onDrawToolbarOpened = new EventEmitter();
    this.onDrawToolbarClosed = new EventEmitter();
    this.onDrawMarkerContext = new EventEmitter();
    this.leafletDirective = new LeafletDirectiveWrapper(leafletDirective);
  }
  ngOnInit() {
    this.leafletDirective.init();
    // Configure localization options
    if (null != this.drawLocal) {
      LeafletDrawUtil.deepLiteralCopy(drawLocal, this.drawLocal);
    }
    // Create the control
    this.drawControl = new Control.Draw(this.drawOptions);
    // Add the control to the map
    this.leafletDirective.getMap().addControl(this.drawControl);
    // Register the main handler for events coming from the draw plugin
    const map = this.leafletDirective.getMap();
    // add draw event pass throughs
    map.on(Draw.Event.CREATED, e => LeafletUtil.handleEvent(this.zone, this.onDrawCreated, e));
    map.on(Draw.Event.EDITED, e => LeafletUtil.handleEvent(this.zone, this.onDrawEdited, e));
    map.on(Draw.Event.DELETED, e => LeafletUtil.handleEvent(this.zone, this.onDrawDeleted, e));
    map.on(Draw.Event.DRAWSTART, e => LeafletUtil.handleEvent(this.zone, this.onDrawStart, e));
    map.on(Draw.Event.DRAWSTOP, e => LeafletUtil.handleEvent(this.zone, this.onDrawStop, e));
    map.on(Draw.Event.EDITSTART, e => LeafletUtil.handleEvent(this.zone, this.onDrawEditStart, e));
    map.on(Draw.Event.EDITMOVE, e => LeafletUtil.handleEvent(this.zone, this.onDrawEditMove, e));
    map.on(Draw.Event.EDITRESIZE, e => LeafletUtil.handleEvent(this.zone, this.onDrawEditResize, e));
    map.on(Draw.Event.EDITVERTEX, e => LeafletUtil.handleEvent(this.zone, this.onDrawEditVertex, e));
    map.on(Draw.Event.EDITSTOP, e => LeafletUtil.handleEvent(this.zone, this.onDrawEditStop, e));
    map.on(Draw.Event.DELETESTART, e => LeafletUtil.handleEvent(this.zone, this.onDrawDeleteStart, e));
    map.on(Draw.Event.DELETESTOP, e => LeafletUtil.handleEvent(this.zone, this.onDrawDeleteStop, e));
    map.on(Draw.Event.TOOLBAROPENED, e => LeafletUtil.handleEvent(this.zone, this.onDrawToolbarOpened, e));
    map.on(Draw.Event.TOOLBARCLOSED, e => LeafletUtil.handleEvent(this.zone, this.onDrawToolbarClosed, e));
    // Notify others that the draw control has been created
    this.drawReady.emit(this.drawControl);
  }
  ngOnDestroy() {
    this.leafletDirective.getMap().removeControl(this.drawControl);
  }
  getDrawControl() {
    return this.drawControl;
  }
}
LeafletDrawDirective.ɵfac = function LeafletDrawDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LeafletDrawDirective)(i0.ɵɵdirectiveInject(i1.LeafletDirective), i0.ɵɵdirectiveInject(i0.NgZone));
};
LeafletDrawDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: LeafletDrawDirective,
  selectors: [["", "leafletDraw", ""]],
  inputs: {
    drawOptions: [0, "leafletDrawOptions", "drawOptions"],
    drawLocal: [0, "leafletDrawLocal", "drawLocal"]
  },
  outputs: {
    drawReady: "leafletDrawReady",
    onDrawCreated: "leafletDrawCreated",
    onDrawEdited: "leafletDrawEdited",
    onDrawDeleted: "leafletDrawDeleted",
    onDrawStart: "leafletDrawStart",
    onDrawStop: "leafletDrawStop",
    onDrawVertex: "leafletDrawVertex",
    onDrawEditStart: "leafletDrawEditStart",
    onDrawEditMove: "leafletDrawEditMove",
    onDrawEditResize: "leafletDrawEditResize",
    onDrawEditVertex: "leafletDrawEditVertex",
    onDrawEditStop: "leafletDrawEditStop",
    onDrawDeleteStart: "leafletDrawDeleteStart",
    onDrawDeleteStop: "leafletDrawDeleteStop",
    onDrawToolbarOpened: "leafletDrawToolbarOpened",
    onDrawToolbarClosed: "leafletDrawToolbarClosed",
    onDrawMarkerContext: "leafletDrawMarkerContext"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LeafletDrawDirective, [{
    type: Directive,
    args: [{
      selector: '[leafletDraw]'
    }]
  }], function () {
    return [{
      type: i1.LeafletDirective
    }, {
      type: i0.NgZone
    }];
  }, {
    drawOptions: [{
      type: Input,
      args: ['leafletDrawOptions']
    }],
    drawLocal: [{
      type: Input,
      args: ['leafletDrawLocal']
    }],
    drawReady: [{
      type: Output,
      args: ['leafletDrawReady']
    }],
    onDrawCreated: [{
      type: Output,
      args: ['leafletDrawCreated']
    }],
    onDrawEdited: [{
      type: Output,
      args: ['leafletDrawEdited']
    }],
    onDrawDeleted: [{
      type: Output,
      args: ['leafletDrawDeleted']
    }],
    onDrawStart: [{
      type: Output,
      args: ['leafletDrawStart']
    }],
    onDrawStop: [{
      type: Output,
      args: ['leafletDrawStop']
    }],
    onDrawVertex: [{
      type: Output,
      args: ['leafletDrawVertex']
    }],
    onDrawEditStart: [{
      type: Output,
      args: ['leafletDrawEditStart']
    }],
    onDrawEditMove: [{
      type: Output,
      args: ['leafletDrawEditMove']
    }],
    onDrawEditResize: [{
      type: Output,
      args: ['leafletDrawEditResize']
    }],
    onDrawEditVertex: [{
      type: Output,
      args: ['leafletDrawEditVertex']
    }],
    onDrawEditStop: [{
      type: Output,
      args: ['leafletDrawEditStop']
    }],
    onDrawDeleteStart: [{
      type: Output,
      args: ['leafletDrawDeleteStart']
    }],
    onDrawDeleteStop: [{
      type: Output,
      args: ['leafletDrawDeleteStop']
    }],
    onDrawToolbarOpened: [{
      type: Output,
      args: ['leafletDrawToolbarOpened']
    }],
    onDrawToolbarClosed: [{
      type: Output,
      args: ['leafletDrawToolbarClosed']
    }],
    onDrawMarkerContext: [{
      type: Output,
      args: ['leafletDrawMarkerContext']
    }]
  });
})();
class LeafletDrawModule {}
LeafletDrawModule.ɵfac = function LeafletDrawModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LeafletDrawModule)();
};
LeafletDrawModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: LeafletDrawModule
});
LeafletDrawModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [LeafletModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LeafletDrawModule, [{
    type: NgModule,
    args: [{
      imports: [LeafletModule],
      exports: [LeafletDrawDirective],
      declarations: [LeafletDrawDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { LeafletDrawDirective, LeafletDrawModule };
