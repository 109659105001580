import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  FormArray,
} from '@angular/forms';
import { FormSection } from '../form-sections.model'; // Adjust import as needed
import { DynamicFormFieldComponent } from '../dynamic-form-field/dynamic-form-field.component';
import { CommonModule, formatDate } from '@angular/common';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import {
  colorCodes,
  ApiMethod,
  FailedMessage,
} from 'src/app/core/services/utils/constants';
import { HttpService } from 'src/app/core/services/http/http.service';
import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { DynamicFormValidationService } from '../dynamic-form-validation.service';
import { CommonService } from 'src/app/core/services/common/common.service';
import { LodashService } from 'src/app/core/services/lodash/lodash.service';

@Component({
  selector: 'app-add-row-modal',
  templateUrl: './add-row-modal.component.html',
  styleUrls: ['./add-row-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DynamicFormFieldComponent],
})
export class AddRowModalComponent implements OnInit {
  form: FormGroup;
  section: FormSection;
  sectionArray: FormArray;
  originalData: any; // Store original data
  selectedGroup: FormGroup | null;
  refID: any = null;
  title: any;
  modalTitle: any;
  errorMessage: string | null = null;
  mode: string | null = null;
  dateFormat: any = 'yyyy-MM-dd';
  private unsubscribe$ = new Subject<void>();
  @ViewChild('dynamicFormField') dynamicFormField!: DynamicFormFieldComponent;
  locationdetails: any = [];
  imageSrc: string | null = '/assets/images/common/placeholder.png';
  isRemoteImage: boolean = false;

  private dynamicLocationSub: Subject<any> = new Subject<any>();

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddRowModalComponent>,
    private _snackBar: SnackbarService,
    private _http: HttpService,
    private validationService: DynamicFormValidationService,
    private _lodash: LodashService,
    private _common: CommonService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      refID: any;
      section: FormSection;
      sectionArray: FormArray;
      selectedGroup: FormGroup | null;
      editSectionArray: FormArray;
      disableOwnerPercentage: boolean;
      mode: 'view' | 'add' | 'edit';
    }
  ) {
    this.section = data.section;
    this.sectionArray = data.sectionArray;
    this.selectedGroup = data.selectedGroup ?? null;
    this.form = this.fb.group({});
    this.mode = this.data.mode;
    this.title = data.selectedGroup
      ? 'Edit ' + this.section.title
      : 'Add ' + this.section.title;
    if (this.section?.meta?.modalTitle) {
      if (this.mode == 'add') {
        this.modalTitle = 'Add ' + this.section?.meta?.modalTitle;
      } else if (this.mode == 'edit') {
        this.modalTitle = 'Edit ' + this.section?.meta?.modalTitle;
      } else if (this.mode == 'view') {
        this.modalTitle = 'View ' + this.section?.meta?.modalTitle;
      }
    }
    this.title = this.mode + ' ' + this.section.title;
    this.refID = data.refID;
  }

  ngOnInit(): void {
    this.dynamicLocationSub.pipe(delay(500)).subscribe((data: any) => {
      if (data) {
        this.dynamicFormField?.dynamicLocation?.dataMapping(
          data?.rawValue,
          data?.locationJson
        );
      }
    });
    this.initializeForm();
  }

  private initializeForm(): void {
    if (!this.section?.fields) return;

    if (this.selectedGroup) {
      this.initializeForEditMode();
      const previewField = this.section?.meta?.viewConfig?.previewRefField;
      this.isRemoteImage = this.form.get(previewField)?.value.length
        ? true
        : false;

      if (
        this.section?.meta?.viewConfig?.showImgPreview &&
        !this.isRemoteImage
      ) {
        const file = this.selectedGroup?.get(previewField)?.value;
        this.viewLocalImgPreview(file);
      }
    } else {
      this.initializeForCreateMode();
    }

    this.onchangeCallbackListener();
  }

  private initializeForEditMode(): void {
    if (this.selectedGroup) {
      this.form = this.selectedGroup; // Edit mode
      if (this.selectedGroup?.getRawValue()?.locationFormRawValue) {
        const rawValue =
          this.selectedGroup?.getRawValue()?.locationFormRawValue;
        const locationJson = this.selectedGroup?.getRawValue()?.locationJson;
        this.dynamicLocationSub.next({ rawValue, locationJson });
      }
      this.originalData = { ...this.selectedGroup.value }; // Store original data
      this.patchTemplateData();

      if (this.data?.disableOwnerPercentage) {
        this.setOwnershipPercentage();
      }

      if (this.mode !== 'view') {
        this.setupDependentFields();
      } else {
        this.checkAndTriggerPreviewImageCallback();
      }
    } else {
      // Handle the case where selectedGroup is null (optional)
      console.warn('Selected group is null.');
    }
  }

  onchangeCallbackListener(): void {
    this.section.fields.forEach((field: any) => {
      // Fetch controls for both 'single' and 'multiple' sections
      if (field?.meta?.onchangeCallback) {
        const control: any =
          this.section.sectionType === 'single'
            ? this.form.get(`${this.section.key}.${field.name}`)
            : this.form.get(`${field.name}`);
        control.valueChanges.subscribe((value: any) => {
          this.triggerOnchangeCallback(field, value);
        });
      }
    });
  }

  patchLocationData(data: any) {
    setTimeout(() => {
      this.dynamicFormField.dynamicLocation.mode = 'edit';
      this.dynamicFormField?.dynamicLocation?.enqueueLocation(data);
    }, 100);
  }

  triggerOnchangeCallback(field: any, parentValue: any) {
    if (typeof field?.meta?.onchangeCallback == 'function')
      field?.meta?.onchangeCallback(parentValue, this.form, (data: any) => {
        this.patchLocationData(data);
      });
  }

  private setOwnershipPercentage(): void {
    const ownershipField: any = this.form?.get('ownership_percentage');
    if (ownershipField?.value !== 100) ownershipField.setValue(100);
    ownershipField?.disable();
  }

  private setupDependentFields(): void {
    this.section.fields.forEach((field) => {
      if (field.dependsOn && this.form.get(field.name)) {
        this.addDependentFieldListener(field, this.form);
        this.form.get(field.dependsOn)?.updateValueAndValidity();
      } else {
        this.handleLocationField(field);
        this.fetchOptionsIfNeeded(field);
      }
    });
  }

  private handleLocationField(field: any): void {
    if (field.type === 'location_field') {
      setTimeout(() => {
        this.dynamicFormField?.dynamicLocation.enqueueLocation(
          this.form.get(field.name)?.value
        );
      }, 100);
    }
  }

  private fetchOptionsIfNeeded(field: any): void {
    if (field.apiEndpoint && !field.dependsOn) {
      this.fetchOptionsFromApi(
        field.apiEndpoint,
        field.name,
        null,
        null,
        null,
        field
      );
    } else if (field.dependsOn) {
      this.addDependentFieldListener(field, this.form);
    }
  }

  private initializeForCreateMode(): void {
    this.section.fields.forEach((field) => {
      this.addControlForField(field);
      this.addInputDropdownControl(field, this.form, this.fb.control('', []));
      this.handleFieldCallbacks(field);
      this.fetchOptionsIfNeeded(field);
    });

    this.originalData = this.form.value; // Store original data
  }

  private addControlForField(field: any): void {
    if (!this.form.get(field.name)) {
      const defaultValue = this.getDefaultValueForField(field);
      const validatorsArray =
        field.validators?.map((v: any) => v.validator) ?? [];

      // Create FormControl with initial value and validators
      const control = this.fb.control(defaultValue, validatorsArray);

      // Add the control to the form group
      this.form.addControl(field.name, control);
    }
  }

  private getDefaultValueForField(field: any): any {
    if (
      this.data?.disableOwnerPercentage &&
      field.name === 'ownership_percentage'
    ) {
      return { value: 100, disabled: true };
    }
    return field.defaultValue || '';
  }

  private handleFieldCallbacks(field: any): void {
    if (
      this.section?.meta?.patchTemplateDataCallBk &&
      typeof this.section?.meta?.patchTemplateDataCallBk === 'function'
    ) {
      if (field.type === 'template') {
        this.section?.meta?.patchTemplateDataCallBk(
          this.section?.meta?.defaultTemplateData,
          this.mode
        );
      }
    }
  }

  patchTemplateData() {
    if (
      this.section?.meta?.patchTemplateDataCallBk &&
      typeof this.section?.meta?.patchTemplateDataCallBk == 'function'
    ) {
      this.section.fields.forEach((field) => {
        if (field.type == 'template')
          this.section?.meta?.patchTemplateDataCallBk(
            this.form?.get(field.name)?.value,
            this.mode
          );
      });
    }
  }

  addInputDropdownControl(field: any, group: FormGroup, control: any) {
    if (field.type == 'phone_field' || field.type == 'input_dropdown') {
      group.addControl(field?.altName, control);
    }
  }

  // Listener for changes in dependent fields
  addDependentFieldListener(field: any, group: any): void {
    if (Array.isArray(field.dependsOn)) {
      for (const dependsOn of field.dependsOn) {
        this.addDependentFieldListenerValueChanges(field, group, dependsOn);
      }
    } else {
      this.addDependentFieldListenerValueChanges(field, group, field.dependsOn);
    }
  }

  // Listener for changes in dependent fields
  addDependentFieldListenerValueChanges(
    field: any,
    group: any,
    dependsOn: any
  ): void {
    // Unsubscribe from previous subscriptions
    // this.unsubscribe$.next();
    const parentControl = group.get(dependsOn);

    if (parentControl) {
      parentControl.valueChanges
        .pipe(takeUntil(this.unsubscribe$)) // Automatically unsubscribes when `unsubscribe$` emits
        .subscribe((parentValue: any) => {
          if (parentValue) {
            const fieldName =
              field?.type === 'input_dropdown' ? field?.altName : field.name;

            if (field.type == 'preview_image') {
              this.triggerPreviewImageCallback(field, parentValue);
            } else {
              // If a parent value is selected, fetch options for the dependent field
              this.fetchOptionsFromApi(
                field.apiEndpoint,
                field.name,
                parentValue,
                dependsOn,
                group,
                field
              );
            }
          } else {
            // Reset the dependent field options and clear the control value
            const dependentControl = group.get(field.name);
            dependentControl?.setValue(null);
            this.triggerPreviewImageCallback(field, parentValue);
            field.options = [];
          }
        });
    }
  }

  checkAndTriggerPreviewImageCallback(isClear: boolean = false) {
    this.section.fields.forEach((field: any) => {
      if (field.type == 'preview_image') {
        let value = isClear ? '' : this.form.get(field?.dependsOn)?.value;
        this.triggerPreviewImageCallback(field, value);
      }
    });
  }

  triggerPreviewImageCallback(field: any, parentValue: any) {
    if (field.type == 'preview_image') {
      if (field?.meta?.setImageCallBk)
        field?.meta?.setImageCallBk(parentValue, this.form);
    }
  }

  fetchOptionsFromApi(
    apiEndpoint: any,
    fieldName: string,
    parentValue?: any,
    dependentField?: any,
    group?: any,
    field?: any
  ) {
    if (this.isInvalidParentValue(parentValue, dependentField, field)) return;

    apiEndpoint = this.prepareApiEndpoint(
      apiEndpoint,
      parentValue,
      dependentField,
      field,
      group
    );

    this._http
      .requestCall(apiEndpoint, ApiMethod.GET)
      .subscribe((options: any) => {
        this.updateFieldOptions(fieldName, options?.data, group);
      });
  }

  private isInvalidParentValue(
    parentValue: any,
    dependentField: any,
    field: any
  ): boolean {
    if (field?.meta?.multiple) {
      const allHaveIds = parentValue?.every((x: any) => x.id);
      const allHaveDisplayname = parentValue?.every((x: any) => x.display_name);
      return dependentField && !allHaveIds && !allHaveDisplayname;
    } else
      return dependentField && !parentValue?.id && !parentValue?.display_name;
  }

  private prepareApiEndpoint(
    apiEndpoint: string,
    parentValue: any,
    dependentField: any,
    field: any,
    group: any
  ): string {
    apiEndpoint = apiEndpoint.replace(':refID', this.refID);
    if (parentValue && dependentField) {
      const param = this.createQueryParam(field, group);
      apiEndpoint += apiEndpoint.includes('?') ? `&${param}` : `?${param}`;
    }
    return apiEndpoint;
  }

  private updateFieldOptions(
    fieldName: string,
    options: any,
    group: any
  ): void {
    const field = this.findFieldByName(fieldName);
    if (field) {
      field.options = options;
    }

    this.validateControlValue(fieldName, field, group);
  }

  private validateControlValue(
    fieldName: string,
    field: any,
    group: any
  ): void {
    const controlValue = group?.get(fieldName)?.value;
    if (
      controlValue?.id &&
      !this._common.findById(field.options, controlValue.id)
    ) {
      group?.get(fieldName)?.setValue(null);
    }
  }

  setKeyValue(value: any, field: any) {
    if (field?.meta?.multiple) {
      const ids = value?.map((x: any) => x.id)?.toString();
      const names = value?.map((x: any) => x.display_name)?.toString();
      return ids ?? names;
    } else return value?.id ?? value?.display_name;
  }

  createQueryParam(field: any, group: any) {
    const obj: any = {};
    if (Array.isArray(field.dependsOn)) {
      for (const dependsOn of field.dependsOn) {
        if (group.get(dependsOn)?.value) {
          obj[dependsOn] = this.setKeyValue(group.get(dependsOn)?.value, field);
        }
      }
    } else if (group.get(field.dependsOn)?.value) {
      obj[field.dependsOn] = this.setKeyValue(
        group.get(field.dependsOn)?.value,
        field
      );
    }

    const params = new URLSearchParams(obj);
    return params.toString();
  }

  onCancel(): void {
    if (this.selectedGroup) {
      // Restore original data if editing
      this.selectedGroup.patchValue(this.originalData, { emitEvent: false });
      if (this.selectedGroup.get('deleted_attachments')) {
        this.selectedGroup.get('deleted_attachments')?.setValue(null);
      }
    }
    this.checkAndTriggerPreviewImageCallback(true);
    this.dialogRef.close();
  }

  onSave(): void {
    if (this.form.valid) {
      if (this.section?.meta?.triggerOverallPercentageCalculation) {
        const newGroup = this.createGroupFromForm();
        if (this.validateOwnershipPercentage(newGroup)) {
          this.dialogRef.close(this.form.getRawValue()); // Pass the form value to the parent component
        } else {
          this._snackBar.loadSnackBar(
            'Total Ownership Percentage cannot exceed 100%.',
            colorCodes.ERROR
          );
        }
      } else {
        const data = this.form.getRawValue();

        if (typeof data['location'] !== 'undefined') {
          const selectedLocation =
            this.dynamicFormField?.dynamicLocation?.getLeastRec();

          if (selectedLocation) {
            const rawValue =
              this.dynamicFormField?.dynamicLocation?.getLocationForm
                ?.get(selectedLocation?.controlName)
                ?.getRawValue();

            data['locationRawValue'] = rawValue;

            data['location'] = Array.isArray(rawValue)
              ? rawValue[0]?.id
              : rawValue && typeof rawValue === 'object'
              ? rawValue?.id
              : '';
          }

          data['locationFormRawValue'] =
            this.dynamicFormField?.dynamicLocation?.location.getRawValue();

          data['locationJson'] =
            this.dynamicFormField?.dynamicLocation?.locationJson;
        }

        if (!this.isValidUniqueData(data)) {
          // this._snackBar.loadSnackBar(
          //   this.section?.title + ' must be unique!',
          //   colorCodes.ERROR
          // );
          const uniqueFields = this.section?.meta?.uniqueFields || [];

          // Construct the error message based on unique fields
          const uniqueFieldValues = uniqueFields
            .map((field: any) => {
              const fieldKey = field.key;
              const fieldValue = data[fieldKey];

              // Handle object type fields by accessing their 'id' or other properties
              if (field.data_type === 'object' && fieldValue) {
                return (
                  fieldValue.name ||
                  fieldValue.description ||
                  fieldValue.id ||
                  'unknown'
                );
              } else {
                return fieldValue;
              }
            })
            .filter((value: any) => value !== 'unknown') // Filter out 'unknown' values
            .join(' and '); // Combine field values with 'and'

          const errorMessage = `${
            this.section?.meta?.modalTitle || this.section?.title || 'Entry'
          } already exists for ${uniqueFieldValues}.`;

          this._snackBar.loadSnackBar(errorMessage, colorCodes.ERROR);
        } else if (
          this.section?.meta?.saveTemplateDataCallBk &&
          typeof this.section?.meta?.saveTemplateDataCallBk == 'function'
        ) {
          if (this.section?.meta?.saveTemplateDataCallBk(data, this.form)) {
            this.dialogRef.close(data);
          }
        } else {
          this.dialogRef.close(data);
        }
      }
    } else {
      this.triggerValidation();
      if (
        this.dynamicFormField?.dynamicLocation &&
        this.dynamicFormField?.dynamicLocation.location.invalid
      )
        this.validationService.markAllAsTouched(
          this.dynamicFormField?.dynamicLocation.location
        );
    }
  }

  private triggerValidation(): void {
    Object.keys(this.form.controls).forEach((field) => {
      const control = this.form.get(field);
      control?.markAsTouched({ onlySelf: true });
      control?.updateValueAndValidity({ emitEvent: false });
    });
  }

  validateOwnershipPercentage(newGroup: any): boolean {
    let sectionArray = this.data.selectedGroup
      ? this.data.editSectionArray
      : this.data.sectionArray;
    const totalPercentage = sectionArray.controls
      .map((control) => control.get('ownership_percentage')?.getRawValue() || 0)
      .reduce((sum, value) => sum + Number(value), 0);

    const newGroupPercentage = this.data.selectedGroup
      ? 0
      : newGroup['ownership_percentage'] || 0;
    let percent = totalPercentage + Number(newGroupPercentage);

    // Ensure `disableActionBtn` exists and assign the value
    if (this.section && this.section.meta) {
      if (percent === 100) {
        this.section.meta.disableActionBtn = true;
      } else {
        this.section.meta.disableActionBtn = false;
      }
    }
    return percent <= 100;
  }

  isValidUniqueData(newGroup: any): boolean {
    let sectionArray = this.data.selectedGroup
      ? this.data.editSectionArray
      : this.data.sectionArray;

    if (
      !this.section ||
      !this.section.meta ||
      !this.section.meta.uniqueFields
    ) {
      return true; // No unique fields configured; no duplicates to check
    }

    const uniqueFields = this.section.meta.uniqueFields;

    // Iterate through each control in the sectionArray
    for (let control of sectionArray.controls) {
      const existingGroup = control.getRawValue();
      let selectedGroup: any = this.data?.selectedGroup?.value;

      const isSameGroup = selectedGroup
        ? selectedGroup === existingGroup ||
          existingGroup?.id === selectedGroup?.id
        : false;

      if (isSameGroup) {
        continue;
      }

      const isDuplicate = uniqueFields.every((field: any) => {
        const fieldKey = field?.key;
        const dataType = field?.data_type;

        // Get the existing value and ensure no null errors occur
        const existingValue = control?.get(fieldKey)?.getRawValue();
        const newValue = newGroup[fieldKey];

        // Skip if the newGroup or control doesn't have the unique field
        if (!existingValue || !newValue) {
          return false;
        }

        if (dataType === 'object') {
          // Compare only the `id` property
          return existingValue.id === newValue.id;
        } else {
          // Compare primitive types directly
          return existingValue === newValue;
        }
      });

      if (isDuplicate) {
        return false; // Duplicate found
      }
    }

    return true; // No duplicates found
  }

  viewLocationDetails(details: any): any {
    const locationDetails = this._common.transformData(
      details?.country,
      'country'
    );
    const locationdetails = this._common.flattenJson(locationDetails, true);
    this.locationdetails = this._lodash.groupBy(locationdetails, 'key');
    let groupDetails: any = [];

    for (const element of Object.keys(this.locationdetails)) {
      const obj: any = {
        display_name: element,
        group: this.locationdetails[element],
        concatName: this.locationdetails[element]
          ?.map((x: any) => x.display_name)
          ?.toString(),
      };
      groupDetails.push(obj);
    }

    return groupDetails;
  }

  view_file(data: any) {
    if (data?.length) {
      const attachments = data.map((item: any) => item?.attachment);
      if (attachments.length) {
        this.viewFilePreview(attachments);
      } else {
        this._snackBar.loadSnackBar(
          FailedMessage.FILE_NOT_FOUND_MSG,
          colorCodes.WARNING
        );
      }
    } else {
      this.viewFilePreview(data?.attachments);
    }
  }

  viewFilePreview(filepath: any) {
    this._common.filePreviewModal(filepath).subscribe((result) => {});
  }

  viewLocalImgPreview(file: File | null): void {
    if (!file) {
      this.imageSrc = '/assets/images/common/placeholder.png';
      return;
    }

    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = () => {
        this.imageSrc = reader.result as string;
      };
      reader.readAsDataURL(file);
    } else {
      console.error('Invalid file type. Please provide an image file.');
      this.imageSrc = '/assets/images/common/placeholder.png';
    }
  }

  createGroupFromForm(): any {
    const newGroup: any = {};
    const rawFormValue = this.form.getRawValue(); // Get all values including disabled controls

    Object.keys(rawFormValue).forEach((key) => {
      newGroup[key] = rawFormValue[key];
    });

    return newGroup;
  }

  findFieldByName(name: string): any {
    let section = this.section;
    const sectionType = section.sectionType;
    // If sectionType is root, match directly with section name
    if (sectionType === 'root' || sectionType === 'multiple') {
      for (let field of section.fields) {
        if (field.name === name) {
          return field;
        }
      }
    }
    // If sectionType is single, match with combined key
    else if (sectionType === 'single') {
      for (let field of section.fields) {
        const combinedKey = `${section.key}.${field.name}`;
        if (combinedKey === name) {
          return field;
        }
      }
    }
    return undefined;
  }

  // Helper function to get the value based on field type
  getFieldValue(field: any, value: any, isDisplay?: boolean) {
    if (field.type === 'select') {
      if (field?.meta?.multiple && Array.isArray(value)) {
        // Handle multiple selections
        return isDisplay
          ? value
              .map((item: any) => item?.display_name ?? item?.name ?? '-')
              .join(', ')
          : value.map((item: any) => item?.id) || null; // Return array of ids if not for display
      } else {
        let keyname = 'display_name';
        if (field?.name == 'location') keyname = 'title';
        // Handle single selection
        return (
          (isDisplay ? value?.[keyname] ?? value?.name ?? '-' : value?.id) ??
          null
        );
      }
    }

    if (field.type === 'date' && value) {
      return formatDate(value, this.dateFormat, 'en-US'); // Format date to YYYY-MM-DD
    } else if (field.type === 'checkbox') {
      return value || false; // set default value as false
    } else if (field.type === 'location_field') {
      // replace with your actual location field name
    }

    return value;
  }

  showErrorMessage(message: string): void {
    this.errorMessage = message;
  }

  getColClass(section: any, field: any): string {
    const colspan =
      field?.meta?.viewConfig?.colspan ??
      section?.meta?.viewConfig?.colspan ??
      1; // Default colspan is 1
    return colspan === 2
      ? 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'
      : 'col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12';
  }

  // Call this method when the component is destroyed or no longer needed
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
