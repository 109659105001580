import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { CommonService } from 'src/app/core/services/common/common.service';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import {
  MENU_UNIQ_NAME,
  routePath,
  USERGROUPS,
} from 'src/app/core/services/utils/constants';
import { DataService } from 'src/app/shared/services/data/data.service';
import { TranslationService } from 'src/app/shared/services/translation.service';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss'],
})
export class TopNavbarComponent implements OnChanges, OnDestroy {
  @Input() menuMode: string = 'standard';
  @Input() isViewInSmaller: boolean = false;
  @Input() sideNav: any;

  staticText: any = TranslationService.staticTextData;
  menuList: any[] = [];
  toggleMenuStatus: boolean[] = [];
  showMenuToggle: boolean = false;
  currentUrl: string = '';
  showCollapseState: boolean = true;
  isPopupVisible = false;

  private routeSubscription: Subscription | undefined;

  constructor(
    private route: Router,
    public commonService: CommonService,
    private dataService: DataService,
    private storageService: StorageService
  ) {
    this.menuList = this.commonService?.sideNavMenu || [];
    console.log(this.menuList);

    this.toggleMenuStatus = new Array(this.menuList.length).fill(false);
    this.getCurrentUrl();
  }

  ngOnInit(): void {
    this.commonService.fetchPermssions();
  }

  ngOnChanges(): void {
    this.showMenuToggle = this.isViewInSmaller;
  }

  sideNavToggle(): void {
    this.sideNav?.toggle();
    this.dataService.chartRender.next(true);
  }

  toggleSidenav(): void {
    this.showCollapseState = !this.showCollapseState;
  }

  redirect(menu: any): void {
    if (menu?.router) {
      const userDetails: any = this.storageService.getUserDetails();
      const isSCPAdmin =
        userDetails?.groups?.[0]?.name ===
        USERGROUPS.SUPPLY_CHAIN_PARTICIPANT_ADMINISTRATOR;

      if (
        (menu.uniq_name === MENU_UNIQ_NAME.REGISTRATION_SCP ||
          menu.uniq_name === MENU_UNIQ_NAME.ONBOARD_SCP) &&
        isSCPAdmin
      ) {
        this.route.navigate([routePath?.SCP_APPLICATION_PATH]);
      } else {
        this.route.navigate([menu?.router]);
      }
    }
  }

  private catchMenu(menu: any[], uniq_name: string): any {
    return menu?.find((x: any) => x.uniq_name === uniq_name) || null;
  }

  private getCurrentUrl(): void {
    this.routeSubscription = this.route.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentUrl = event.url;
        this.collapseAllMenu(this.menuList);
        this.inactiveAllMenu(this.menuList);

        const activeMenu: any = {};
        event?.url?.split('/')?.forEach((value: any, index: number) => {
          const previousLevel = `level_${index - 1}`;
          const currentLevel = `level_${index}`;
          const parentMenu =
            activeMenu[previousLevel]?.children || this.menuList;

          if (
            value &&
            (parentMenu?.length || !Object.keys(activeMenu)?.length)
          ) {
            activeMenu[currentLevel] = this.catchMenu(parentMenu, value);
          }
        });

        this.menuActivate(activeMenu, true);
      });
  }

  private inactiveAllMenu(menuList: any[]): void {
    menuList.forEach((menu) => {
      menu.activate = false;
      if (menu.children?.length) {
        this.inactiveAllMenu(menu.children);
      }
    });
  }

  private collapseAllMenu(menuList: any[]): void {
    menuList.forEach((menu) => {
      menu.expand = false;
      if (menu.children?.length) {
        this.collapseAllMenu(menu.children);
      }
    });
  }

  private menuActivate(parentData: any, active: boolean): void {
    Object.keys(parentData).forEach((key) => {
      const menu = parentData[key];
      if (menu) {
        menu.expand = true;
        if (active) {
          menu.activate = true;
        }
      }
    });
  }

  handleMenuClick(event: Event, menu: any, position: number): void {
    this.toggleMenu(menu);
    this.redirect(menu);
  }

  private toggleMenu(menu: any): void {
    menu.expand = !menu.expand;
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
    localStorage.removeItem('activeMenu');
  }

  togglePopup() {
    this.isPopupVisible = !this.isPopupVisible;
  }
}
