import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  fromEvent,
  map,
  debounceTime,
  switchMap,
  filter,
  distinctUntilChanged,
  catchError,
  throwError,
  retry,
} from 'rxjs';
import { MarkASRead } from 'src/app/core/services/common/common.interface';
import { SocketService } from 'src/app/core/services/inApp/socket.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { RouterService } from 'src/app/core/services/router/router.service';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import {
  FORMAT,
  NOTIFICATION_MDL,
} from 'src/app/core/services/utils/constants';
import { environment } from 'src/environments/environment';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { CommonService } from 'src/app/core/services/common/common.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss',
})
export class NotificationsComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  staticText: any = TranslationService.staticTextData;
  unreadCount: number = 0;
  onlyShowUnread = false;
  loading: boolean = false;
  searchTerm = '';
  notifications: any = [];
  offset = 0;
  limit = 10; // Number of items to fetch per page
  end = this.limit; // Number of items to fetch per page
  total = 0; // Total notifications count from API
  @ViewChild('inputNotificationBox') inputBox!: ElementRef;

  constructor(
    public _routeService: RouterService,
    private datePipe: DatePipe,
    private _storage: StorageService,
    private _socket: SocketService,
    private _loader: LoaderService,
    private _common: CommonService
  ) {}

  ngAfterViewInit(): void {
    fromEvent(this.inputBox?.nativeElement, 'input')
      .pipe(
        map((e: any) => {
          return (e.target as HTMLInputElement).value;
        }),
        // filter(text => text.length > 2),
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(async (x) => {
          this.resetPagination();
          this.getNotification();
        })
      )
      .subscribe((data) => {});
  }

  ngOnInit(): void {
    if (environment.enableWebSocket) {
      this.connectSocket();
      this.getMessages();
    }
    this.getNotification();
  }

  private connectSocket() {
    // Connect to the server
    const WS_URL = environment.notification_websocket;
    const token: any = this._storage.getToken();
    this._socket.connect(WS_URL, token);
  }

  private getMessages() {
    this._socket.getMessages().subscribe({
      next: (response) => this.addNewNotification(response),
      error: (error) => this.catchError(error),
      complete: () => {},
    });
  }

  private catchError(error: any) {
    console.error('WebSocket Error:', error);
  }

  private addNewNotification(response: any) {
    if (response?.message_type == 'refresh_configuration') {
      this._common.getAppConfig('', true).subscribe((configResponse: any) => {
        this.staticText = TranslationService.staticTextData;
      });
    } else {
      this.notifications.unshift({
        ...response?.message?.notification,
        sender: response?.message?.notification?.created_by_role,
        time: response?.message?.received_at,
        isRead: response?.message?.status != 'unread',
      });
      this.unreadCount = Number(this.unreadCount) + 1;
    }
  }

  private mapNotificationData(data: any): any[] {
    return data?.records?.map((element: any) => ({
      notification_id: element?.id,
      ...element?.notification,
      sender: element?.notification?.created_by_role,
      time: element?.received_at,
      isRead: element?.status != 'unread',
    }));
  }

  get hideNotificationBtn(): boolean {
    return !!(this.total && this.offset >= this.total);
  }

  loadMore(): void {
    this.getNotification();
  }

  private getNotification(): void {
    if (this.hideNotificationBtn && !this.total) return;
    this.loading = true;
    this._socket
      .getNotificationList(this.createQueryParam())
      .subscribe((response: any) => {
        this.loading = false;
        let data = this.mapNotificationData(response?.data);
        this.notifications = [...this.notifications, ...data];
        this.unreadCount = response?.data?.additional_data?.unread_count;
        this.total = response?.data?.total_records;
        this.offset += this.limit;
        this.end += this.limit;
      });
  }

  private createQueryParam(): any {
    const data: any = {
      status: this.onlyShowUnread ? 'unread' : '',
      search: this.searchTerm,
      offset: this.offset,
      end: this.end,
    };
    const params = new URLSearchParams(data);
    return '?' + params.toString();
  }

  navigateRespectivePage(data: any) {
    if (!data.isRead) {
      data.isRead = true;
      this.unreadCount = Number(this.unreadCount) - 1;
    }

    const payload = {
      mark_as_read_ids: [data?.notification_id],
    };
    this._socket
      .updateMarkAsReadStatus(payload as MarkASRead)
      .subscribe((response: any) => {
        const navigation: any = NOTIFICATION_MDL;
        window.open(
          `${window.origin}${navigation?.[data?.slug]?.routePath}${
            data?.url_keys?.[navigation?.[data?.slug]?.url_keys]
          }`
        );
      });
  }

  private resetPagination() {
    this.offset = 0;
    this.limit = 10;
    this.end = this.limit;
    this.notifications = [];
  }

  filterNotifications(): void {
    this.resetPagination();
    this.getNotification();
  }

  ngOnDestroy(): void {
    this._socket.onDestroy();
  }
}
